import styled from "styled-components";

import { font } from "@src/styles/mixins";

const Button = styled.button`
  ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.grey};
`;

export const Styled = { Button };
