import { configureStore } from "@reduxjs/toolkit";

import { bookingApi } from "@src/store/services/booking.api";

import {
  bannersApi,
  baseApi,
  roomApi,
  infrastructureApi,
  servicesApi,
  metaTagsApi,
  notificationsApi,
  eventsApi,
  weddingsApi,
  actionsApi,
  spaApi,
  blogApi,
} from "./services";
import {
  headerAnimation,
  bookingData,
  burgerMenu,
  popup,
  datepicker,
  statusMessage,
  requestCall,
  subscribeEmail,
  requestConferenceRoom,
  topButton,
  paymentMethod,
} from "./slices";

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [bannersApi.reducerPath]: bannersApi.reducer,
    [roomApi.reducerPath]: roomApi.reducer,
    [infrastructureApi.reducerPath]: infrastructureApi.reducer,
    [servicesApi.reducerPath]: servicesApi.reducer,
    [metaTagsApi.reducerPath]: metaTagsApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [bookingApi.reducerPath]: bookingApi.reducer,
    [weddingsApi.reducerPath]: weddingsApi.reducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
    [actionsApi.reducerPath]: actionsApi.reducer,
    [spaApi.reducerPath]: spaApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    headerAnimation,
    popup,
    burgerMenu,
    bookingData,
    datepicker,
    statusMessage,
    requestCall,
    subscribeEmail,
    topButton,
    requestConferenceRoom,
    paymentMethod,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    baseApi.middleware,
    bannersApi.middleware,
    roomApi.middleware,
    infrastructureApi.middleware,
    servicesApi.middleware,
    metaTagsApi.middleware,
    notificationsApi.middleware,
    bookingApi.middleware,
    weddingsApi.middleware,
    eventsApi.middleware,
    actionsApi.middleware,
    spaApi.middleware,
    blogApi.middleware,
  ],
  devTools: process.env.NODE_ENV !== "production",
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
