import styled, { css } from "styled-components";

import { font } from "../../styles/mixins";

const TextLabel = styled.div`
  margin-bottom: 32px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 19px;
  }

  button {
    margin-top: 4px;
    text-align: left;
  }
`;

const Label = styled.p`
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })}
  margin: 0 0 4px 0;
  color: ${({ theme }) => theme.color.grey};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 0 0 1px 0;
    ${font({ size: "14", lineHeight: "20", fontWeight: "400" })}
  }
`;

const Text = styled.p`
  ${font({ size: "28", lineHeight: "32", fontWeight: "400" })}
  margin: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "18", lineHeight: "24", fontWeight: "600" })}
  }
`;

const Link = styled.a<{ blue?: boolean }>`
  display: block;
  cursor: pointer;
  ${font({ size: "28", lineHeight: "32", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.black};
  text-decoration: none;
  margin: 0;

  &:hover {
    color: ${({ theme }) => theme.color.red};
    ${({ blue }) =>
      blue &&
      css`
        color: ${({ theme }) => theme.color.blueHover};
      `};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "18", lineHeight: "24", fontWeight: "600" })}
  }
`;

export const Styled = { Label, Text, Link, TextLabel };
