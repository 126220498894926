import { FC, useEffect } from "react";
import Div100vh from "react-div-100vh";
import { useInView } from "react-intersection-observer";

import { useActions } from "@src/hooks";

import { FullScreenCarousel } from "../FullScreenCarousel";
import { MainBookingForm } from "../MainBookingForm";
import { Styled } from "./styles";
import { useGetCarouselData } from "../FullScreenCarousel/useGetCarouselData";

export const MainBannerSection: FC = () => {
  const { banners } = useGetCarouselData();
  const { setFirstScreenIsView } = useActions();
  const { ref, inView, entry } = useInView({ threshold: 0.88 });

  useEffect(() => {
    if (entry) {
      setFirstScreenIsView(inView);
    }
  }, [inView, entry]);

  return (
    <Div100vh>
      <Styled.Container ref={ref}>
        <FullScreenCarousel banners={banners} />
        <MainBookingForm />
      </Styled.Container>
    </Div100vh>
  );
};
