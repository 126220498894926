import styled, { css } from "styled-components";

import { box, flexColumn, flexVerticalCenter, font } from "@src/styles/mixins";

const Container = styled.div`
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
  }
`;

const Button = styled.button<{ isOpen: boolean }>`
  width: 100%;
  padding: 1px 0 23px;
  ${flexVerticalCenter}
  ${font({ size: "18", lineHeight: "26", fontWeight: "700" })};
  color: ${({ theme }) => theme.color.black};
  text-align: left;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 1px 0 15px;
    text-align: left;
    ${font({ size: "15", lineHeight: "24", fontWeight: "700" })};
  }

  svg {
    margin-left: auto;
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      ${box(20)};
    }

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: rotate(180deg);
      `};
    path {
      stroke: black;
    }
  }
`;

const ButtonImage = styled.img`
  ${box(28)}
  margin-right: 40px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-right: 20px;
  }
`;

const Content = styled.div<{ isOpen: boolean }>`
  display: none;

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${flexColumn};
      align-items: flex-start;
      gap: 16px;
      padding: 8px 0 48px 66px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 9px 0 32px 0px;
        gap: 8px;
      }
    `};
`;

const Link = styled.button<{ isActive?: boolean }>`
  display: inline-block;
  text-align: left;
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  }

  &:hover {
    @media screen and (hover: hover) {
      color: ${({ theme }) => theme.color.blueSecondary};
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.color.blueSecondary};
    `};
`;

export const Styled = {
  Container,
  Button,
  ButtonImage,
  Content,
  Link,
};
