import { motion } from "framer-motion";
import styled from "styled-components";

import { flexStartBetween, fullscreenFixed } from "@src/styles/mixins";

const Nav = styled(motion.nav)`
  ${flexStartBetween};
  overflow: visible;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    ${fullscreenFixed};

    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 20px 62px;
    background-color: ${({ theme }) => theme.color.white};
  }
`;

const MobileMenuHeader = styled.div``;

const MobileMenuScrollContainer = styled.div`
  ${flexStartBetween};
  overflow: visible;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
`;

export const Styled = { Nav, MobileMenuHeader, MobileMenuScrollContainer };
