import { AxiosError } from "axios";
import { isBefore, isSameDay } from "date-fns";
import { useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";

import { ReactComponent as CheckedIcon } from "@src/assets/images/icons/check.svg";
import { ReactComponent as CloseIcon } from "@src/assets/images/icons/close.svg";
import { ReactComponent as Loader } from "@src/assets/svg/loader.svg";
import { useGetBaseData, useMediaQuery } from "@src/hooks";
import { IGetReservation } from "@src/interfaces";
import { useGetBookingQuery, getRefundData } from "@src/store/services";
import { popupStyles } from "@src/styles/popupStyles";
import { theme } from "@src/theme";

import { Button, MobileModal } from "../_Ui";
import { Styled } from "./styles";
import { getClientData, getFeeData } from "./utils";

export const BookingCancellation = () => {
  const { kod } = useParams();
  const {
    data: languagesData,
    bookingCancelText,
    bookingCantBeCancelled,
    bookingCancelButton,
    bookingAlreadyCancelledText,
    cancellationErrorText,
    cancellationSuccessText,
    toLateToCancelText,
    toLateToCancelText24hrs,
    cancellationLinkErrorText,
    refundErrorText,
    bookingCancelFeeText,
  } = useGetBaseData();

  const [isCancelSuccess, setIsCancelSuccess] = useState(false);
  const [openRequestError, setOpenRequestError] = useState(false);
  const [errorText, setErrorText] = useState<string | string[]>("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.medium);
  const checkedIconColor = !isButtonLoading ? theme.color.white : theme.color.grey;

  const { data, isLoading, error } = useGetBookingQuery(kod);

  if (isLoading) {
    return null;
  }

  if (error) {
    return (
      <Styled.BookingOkWrapper>
        <Styled.Header>{cancellationLinkErrorText}</Styled.Header>
      </Styled.BookingOkWrapper>
    );
  }

  const { date_from, can_be_canceled, cancel_with_retention } = data as IGetReservation;
  const currentDate = new Date();
  const arrivalDate = new Date(date_from);

  if (!can_be_canceled) {
    return (
      <Styled.BookingOkWrapper>
        <Styled.Header>{bookingCantBeCancelled}</Styled.Header>
      </Styled.BookingOkWrapper>
    );
  }

  if (isCancelSuccess) {
    return (
      <Styled.BookingOkWrapper>
        <Styled.Header>{cancellationSuccessText}</Styled.Header>
      </Styled.BookingOkWrapper>
    );
  }

  const formatedFeeCancellationText = cancel_with_retention
    ? getFeeData(data as IGetReservation, bookingCancelFeeText)
    : "";
  const { formatedCancellationText, isBookingCancelled } = getClientData(data as IGetReservation, bookingCancelText);

  const onSubmitBtnClickHandler = async () => {
    setIsButtonLoading((prev) => (prev = true));
    setErrorText((prev) => (prev = ""));

    try {
      const data: any = await getRefundData(kod || "");

      if (data?.status === 200) {
        setIsCancelSuccess(true);
        return;
      }
    } catch (error: unknown) {
      const errorTexts: Record<string, string | string[]> | undefined = languagesData?.lang_phrases;

      if (error instanceof AxiosError && error?.response?.data.error === "refund_validation_late") {
        setOpenRequestError(true);
        setErrorText((prev) => (prev = toLateToCancelText));
        setIsButtonLoading((prev) => (prev = false));
        return;
      }
      if (error instanceof AxiosError && error?.response?.data.error === "refund_validation_late_24_hours") {
        setOpenRequestError(true);
        setErrorText((prev) => (prev = toLateToCancelText24hrs));
        setIsButtonLoading((prev) => (prev = false));
        return;
      }
      if (error instanceof AxiosError && error?.response?.data.error === "refund_validation_canceled") {
        setOpenRequestError(true);
        setErrorText((prev) => (prev = bookingAlreadyCancelledText));
        setIsButtonLoading((prev) => (prev = false));
        return;
      }
      if (error instanceof AxiosError && error?.response?.data.error === "refund_validation_invalid") {
        setOpenRequestError(true);
        setErrorText((prev) => (prev = cancellationLinkErrorText));
        setIsButtonLoading((prev) => (prev = false));
        return;
      }
      setOpenRequestError(true);
      setErrorText((prev) => (prev = refundErrorText));
      setIsButtonLoading((prev) => (prev = false));
    }
  };

  return (
    <Styled.BookingOkWrapper>
      {!isBookingCancelled ? (
        <>
          <Styled.Header>{formatedCancellationText}</Styled.Header>
          {cancel_with_retention && <Styled.Header>{formatedFeeCancellationText}</Styled.Header>}
          <Styled.ButtonWrapper>
            <Button
              icon={<CheckedIcon stroke={checkedIconColor} />}
              title={bookingCancelButton}
              primary
              onClick={onSubmitBtnClickHandler}
              disabled={isButtonLoading}
            />
            {isButtonLoading && (
              <Styled.Loader>
                <Loader />
              </Styled.Loader>
            )}
          </Styled.ButtonWrapper>
        </>
      ) : (
        <Styled.Header>{bookingAlreadyCancelledText}</Styled.Header>
      )}

      {!isTablet ? (
        <Modal
          isOpen={openRequestError}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          onRequestClose={() => setOpenRequestError(false)}
          style={{ ...popupStyles, overlay: { ...popupStyles.overlay, display: "grid", placeItems: "center" } }}>
          <Styled.Error>
            <Styled.ModalCloseBtn onClick={() => setOpenRequestError(false)}>
              <CloseIcon stroke="black" />
            </Styled.ModalCloseBtn>
            <Styled.ErrorHeader>{cancellationErrorText}</Styled.ErrorHeader>
            <Styled.ErrorText>{errorText}</Styled.ErrorText>
          </Styled.Error>
        </Modal>
      ) : (
        openRequestError && (
          <MobileModal onClose={() => setOpenRequestError(false)}>
            <Styled.ErrorHeader>{cancellationErrorText}</Styled.ErrorHeader>
            <Styled.ErrorText>{errorText}</Styled.ErrorText>
          </MobileModal>
        )
      )}
    </Styled.BookingOkWrapper>
  );
};
