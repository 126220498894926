import styled, { css } from "styled-components";

import { font } from "@src/styles/mixins";

const Container = styled.form`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 0 48px;
`;

const Content = styled.div`
  width: 100%;
`;

const Sidebar = styled.div`
  margin-top: 120px;
  position: sticky;
  float: right;
  top: 120px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    display: none;
  }
`;

const PaymentTextRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0 8px;
  margin-bottom: 16px;
`;

const PaymentTitle = styled.p<{ withColorRed?: boolean }>`
  ${font({ size: "13", lineHeight: "21" })};
  ${({ theme, withColorRed }) =>
    withColorRed &&
    css`
      color: ${theme.color.red};
    `}
`;

const PaymentText = styled.p<{ withColorRed?: boolean }>`
  ${font({ size: "18", lineHeight: "26", fontWeight: 700 })};

  ${({ theme, withColorRed }) =>
    withColorRed &&
    css`
      color: ${theme.color.red};
    `}
`;

const Title = styled("h2")`
  margin: 40px 0 48px;
  ${font({ size: "28", lineHeight: "32", fontWeight: 500 })};
  font-family: Montserrat, sans-serif;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: 32px 0;
    ${font({ size: "20", lineHeight: "26", fontWeight: 700 })};
  }
`;

const RoomSelectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.color.greyBorder};
  padding: 20px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    border: 1px solid ${({ theme }) => theme.color.greyBorder};
    padding: 0 0 0 24px;
    margin-top: 32px;
  }
`;

const BookingDate = styled.p`
  ${font({ size: "16", lineHeight: "24", fontWeight: "300" })};
  display: flex;
  gap: 48px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 20px;
    gap: 0;
    flex-direction: column;
    border-left: 1px solid ${({ theme }) => theme.color.greyBorder};
    ${font({ size: "13", lineHeight: "21", fontWeight: "300" })};
  }
`;

const Loader = styled.p`
  width: 100%;
  margin-top: 120px;
  height: 400px;
  display: grid;
  place-items: center;
  border: 1px solid ${({ theme }) => theme.color.greyBorder};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 0;
  }
`;

const LoyaltyError = styled.div`
  width: 504px;
  padding: 48px;
  background-color: ${({ theme }) => theme.color.white};
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  position: relative;
`;

const LoyaltyErrorHeader = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 24px;

  span {
    color: ${({ theme }) => theme.color.red};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    box-sizing: border-box;
    padding: 32px 32px 24px;
    margin: 0;
  }
`;

const LoyaltyErrorText = styled.div`
  width: 500px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: auto;
    box-sizing: border-box;
    padding: 0px 32px 24px;
    ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  }
`;

const ModalCloseBtn = styled.div`
  position: absolute;
  top: 34px;
  right: 34px;
  cursor: pointer;
`;

const Rules = styled.p`
  margin: 32px 0;

  &:last-child {
    margin: 32px 0 80px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  }
`;

const RulesContainer = styled.div`
  p {
    &:last-child {
      margin-bottom: 80px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
      ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
    }
  }
`;

const Warning = styled.p`
  ${font({ size: "12", lineHeight: "20", fontWeight: 400 })};
  color: ${({ theme }) => theme.color.grey};
`;

const Buttons = styled.div`
  display: flex;
`;

const Cards = styled.div`
  display: flex;
  align-items: center;
  margin: 19px 0 108px;
  gap: 20px;
`;

const PayMobile = styled.div`
  display: none;
  ${font({ size: "13", lineHeight: "21", fontWeight: 300 })};
  margin-bottom: 64px;
  a {
    color: ${({ theme }) => theme.color.red};
    margin-left: 5px;
    cursor: pointer;
    text-decoration: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    display: block;

    button {
      width: 100%;
    }
  }
`;

const ItemTitle = styled.h3`
  margin-bottom: 32px;
  margin-top: 48px;
  ${font({ size: "18", lineHeight: "26", fontWeight: 700 })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 24px;
    margin-top: 64px;
    ${font({ size: "16", lineHeight: "22", fontWeight: 700 })};
  }
`;

const RoomsAmount = styled.p`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    display: block;
    margin-top: -12px;
    margin-bottom: 20px;
    ${font({ size: "13", lineHeight: "21", fontWeight: "400" })};
    background-color: ${({ theme }) => theme.color.pink};
    flex-grow: 1;
    padding: 16px 20px;
    text-align: center;
  }

  & span {
    color: ${({ theme }) => theme.color.red};
  }
`;

const Privacy = styled.div`
  ${font({ size: "13", lineHeight: "21", fontWeight: "300" })};
  margin-top: 28px;

  a {
    text-decoration: none;
  }
`;

const PrivacyLink = styled.a`
  color: ${({ theme }) => theme.color.red};
  cursor: pointer;
`;

export const Styled = {
  Container,
  LoyaltyError,
  ModalCloseBtn,
  LoyaltyErrorText,
  LoyaltyErrorHeader,
  RoomSelectionHeader,
  Loader,
  BookingDate,
  Content,
  Sidebar,
  Title,
  PaymentTextRow,
  PaymentTitle,
  PaymentText,
  PayMobile,
  Cards,
  Rules,
  RulesContainer,
  Buttons,
  Warning,
  ItemTitle,
  RoomsAmount,
  Privacy,
  PrivacyLink,
};
