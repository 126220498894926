import React from "react";

import { useAppSelector } from "@src/hooks";

import { Styled } from "./styles";

export const Page404 = () => {
  const { headerHeight } = useAppSelector((state) => state.headerAnimation);
  return (
    <Styled.Container headerHeight={headerHeight}>
      <Styled.PageHeader>Страница не найдена</Styled.PageHeader>
      404
      <br /> page not found
    </Styled.Container>
  );
};
