import { FC, ReactNode } from "react";

import { ModalCustom, ModalProps } from "@src/components";
import { theme } from "@src/theme";

import { Styled } from "./styles";

interface RoomSelectionModalProps extends ModalProps {
  onModalCloseClick: () => void;
  children: ReactNode;
  title: string;
}

export const RatesModal: FC<RoomSelectionModalProps> = ({ title, children, onModalCloseClick, ...props }) => (
  <ModalCustom
    onModalCloseClick={onModalCloseClick}
    withHeader
    {...props}
    closeIconStroke={theme.color.black}
    isCloseBtnSticky={true}
    hasFixedFooter={false}
    tabletFullWitdh>
    <Styled.SelectionModalContent>
      <Styled.SelectionModalHeader>{title}</Styled.SelectionModalHeader>
      {children}
    </Styled.SelectionModalContent>
  </ModalCustom>
);
