import React, { FC, ReactNode } from "react";

import { Styled } from "./styles";

type MobileModalProps = {
  onClose: () => void;
  text?: string;
  children?: ReactNode;
};

export const MobileModal: FC<MobileModalProps> = ({ children, onClose, text }) => (
  <Styled.MobileModal onClick={onClose}>
    <Styled.MobileModalUnderLine />
    <Styled.MobileModalFooter>
      <Styled.MobileModalText>{text}</Styled.MobileModalText>
      {children}
    </Styled.MobileModalFooter>
  </Styled.MobileModal>
);
