import { FC } from "react";

import { useGetMenuData } from "@src/hooks/useGetMenuData";

import { Styled } from "./styles";

export const FooterCopyright: FC = () => {
  const { copyRightText } = useGetMenuData();

  return <Styled.Copyright>{copyRightText}</Styled.Copyright>;
};
