import styled, { css } from "styled-components";

import { ReactComponent as ArrowDown } from "@src/assets/svg/arrow_down.svg";
import { absolutePseudoEl, font } from "@src/styles/mixins";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
`;

const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 30px 28px 10px 21px;
  min-height: 64px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
`;

const Select = styled.ul`
  max-width: 450px;
  height: auto;
  position: absolute;
  margin-top: 10px;
  padding: 0;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
`;

const Option = styled.li`
  list-style: none;
  padding: 20px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.color.lightGrey};
  }
`;

const Placeholder = styled.span<{ isInputRequired?: boolean }>`
  position: absolute;
  top: 10px;
  left: 20px;

  ${font({ size: "12", lineHeight: "20", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.grey};

  ${(props) =>
    props.isInputRequired &&
    css`
      &::after {
        ${absolutePseudoEl};
        width: 4px;
        height: 4px;
        border-radius: 50%;
        top: 3px;
        right: -9px;
        background-color: ${({ theme }) => theme.color.red};
      }
    `}
`;

const Title = styled.p`
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};
`;

const Arrow = styled(ArrowDown)`
  position: absolute;
  right: 26px;
  top: 29px;
`;

export const Styled = { Option, Arrow, Title, Content, Placeholder, Select, Container };
