import styled from "styled-components";
import { css } from "styled-components";

import { ReactComponent as Chevron } from "@src/assets/images/icons/chevron.svg";
import { font, height100 } from "@src/styles/mixins";

interface ContainerProps {
  imgWebp?: string;
  imgJPG?: string;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  min-height: 326px;

  background-image: image-set(
    url("${(props) => props.imgWebp}") type("image/webp"),
    url("${(props) => props.imgJPG}") type("image/jpeg")
  );

  background-position: left center;
  background-size: cover;

  background-repeat: no-repeat;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
    justify-content: space-between;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    background-size: contain;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    min-height: 470px;
    max-height: unset;

    &::after {
      content: "";
      display: block;
      padding-top: 125%;
    }
  }
`;

const LinkWrapper = styled.a`
  text-decoration: none;
`;

const TextWrapper = styled.div`
  min-width: 482px;

  width: 100%;
  padding: 72px 64px 64px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    position: absolute;
    max-width: unset;
    min-width: unset;
    width: calc(100% - 82px);
    padding: 40px 40px 25px;
  }
`;

const SecondaryText = styled.div`
  margin-bottom: 16px;
  ${font({ size: "16", lineHeight: "24" })};
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
`;

const MainText = styled.div`
  ${font({ size: "40", lineHeight: "50" })};
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "30", lineHeight: "38" })};
    white-space: pre-wrap;
  }
`;

const ImageWrapper = styled.div`
  img {
    ${height100};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      margin-bottom: -5px;
    }
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 64px;
  transform: translateY(-50%);

  &:hover button {
    background-color: ${({ theme }) => theme.color.greyBgHover};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`;

const ChevronIcon = styled(Chevron)`
  stroke: ${({ theme }) => theme.color.white};
`;

export const Styled = {
  LinkWrapper,
  Container,
  TextWrapper,
  SecondaryText,
  MainText,
  ImageWrapper,
  ButtonWrapper,
  ChevronIcon,
};
