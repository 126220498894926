import styled, { css } from "styled-components";

import { box, flexCenter } from "@src/styles/mixins";

const IconButton = styled.button<{
  size: "mediumMobile" | "small" | "medium" | "large";
  variant: "primary" | "secondary" | "outline" | "transparent";
}>`
  ${flexCenter};
  border: 1px solid transparent;

  ${({ size }) =>
    size === "small" &&
    css`
      ${box(48)};

      svg {
        width: 8px;
        height: 16px;
      }
    `}

  ${({ size }) =>
    size === "medium" &&
    css`
      ${box(56)};

      svg {
        width: 12px;
        height: 24px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
          width: 19px;
          height: 12px;
        }
      }
    `}

      ${({ size }) =>
    size === "mediumMobile" &&
    css`
      ${box(24)};

      svg {
        width: 19px;
        height: 12px;
      }
    `}

  ${({ size }) =>
    size === "large" &&
    css`
      ${box(72)};

      svg {
        width: 12px;
        height: 24px;
      }
    `}

  ${({ variant, theme }) =>
    variant === "primary" &&
    css`
      background-color: ${theme.color.red};
    `}

  ${({ variant, theme }) =>
    variant === "secondary" &&
    css`
      background-color: ${theme.color.black};
    `}

  ${({ variant, theme }) =>
    variant === "outline" &&
    css`
      background-color: ${theme.color.lightGrey};
      border-color: ${theme.color.lightGreyBorder};

      &:active,
      &:focus {
        border: 1px solid ${theme.color.lightGreyBorder};
      }
    `}

  ${({ variant, theme }) =>
    variant === "transparent" &&
    css`
      background-color: transparent;
      border: none;
    `}
`;

export const Styled = { IconButton };
