import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TopButtonState {
  hasSpace: boolean;
}

const initialState: TopButtonState = {
  hasSpace: false,
};

export const topButtonSlice = createSlice({
  name: "toTopButton",
  initialState,
  reducers: {
    toggleSpace: (state, action: PayloadAction<any>) => {
      state.hasSpace = action.payload;
    },
  },
});

export const topButtonActions = topButtonSlice.actions;

export default topButtonSlice.reducer;
