import { format } from "date-fns";

import { BOOKING_DATE_FORMAT } from "@src/constants";
import { RootState } from "@src/store";

export const getDates = ({
  datepicker,
}: RootState): {
  arrivalDateValue: string;
  departureDateValue: string;
  guestsNumber: number;
  roomsNumber: number | string;
} => {
  const range = datepicker.range;

  const formattedStartDate = range.startDate ? format(range.startDate, BOOKING_DATE_FORMAT) : "";
  const formattedEndDate = range.endDate ? format(range.endDate, BOOKING_DATE_FORMAT) : "";
  const rangeCompare = range.startDate?.getTime() === range.endDate?.getTime();
  const arrivalDateValue = rangeCompare ? "" : formattedStartDate;
  const departureDateValue = rangeCompare ? "" : formattedEndDate;

  const guestsNumber = datepicker.guestList.flatMap((guest) => guest.guests).reduce((acc, curr) => acc + curr.count, 0);
  const roomsNumber = datepicker.guestList?.length ? datepicker.guestList.length : "";

  const timeDiff = Math.abs(new Date(departureDateValue).getTime() - new Date(arrivalDateValue).getTime());

  return { arrivalDateValue, departureDateValue, guestsNumber, roomsNumber };
};
