import { FC } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import { Footer } from "@src/components/Footer";
import { useGetBaseData } from "@src/hooks";
import { useGetMetaData } from "@src/hooks/useGetMetaData";

import {
  CenterBannerSlider,
  Contacts,
  HotelGallerySection,
  MainBannerSection,
  OurRoomsSection,
  ServicesSection,
} from "../components";
import { Styled } from "./styles";
import { LongStayRoomsSection } from "@src/components/LongStayBooking/LongStayRoomsSection";
import { useGetCenterBannerSliderQuery } from "@src/store/services";

export const Home: FC = () => {
  const location = useLocation();
  let currentLocation = location.pathname;
  if (currentLocation.includes("/en")) {
    currentLocation = currentLocation.substring(0, 1);
  }

  const { metaTitle, metaDescription, metaKeywords } = useGetMetaData(currentLocation);
  const { mainPageTitle } = useGetBaseData();
  const { data: bannersData, isLoading: isBannerLoading } = useGetCenterBannerSliderQuery();

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>
      <MainBannerSection />
      {mainPageTitle && <Styled.PageHeader>{mainPageTitle}</Styled.PageHeader>}
      <OurRoomsSection />
      <LongStayRoomsSection />
      {bannersData && <CenterBannerSlider data={bannersData} />}
      <ServicesSection />
      <HotelGallerySection />
      <Contacts />
      <Footer />
    </>
  );
};
