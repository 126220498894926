import styled, { css } from "styled-components";

import { ReactComponent as CloseIcon } from "@src/assets/images/icons/close.svg";
import { font } from "@src/styles/mixins";

const GuestCount = styled.div`
  display: none;
  padding: 20px;
  color: ${({ theme }) => theme.color.grey};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme.color.black};
    ${font({ size: "18", lineHeight: "24", fontWeight: "400" })};
  }
`;

const GuestAddRoomContainer = styled.div`
  & button {
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      width: calc(100% - 40px);
      margin: 0 auto;
      padding: 11px 28px;
      ${font({ size: "14", lineHeight: "22", fontWeight: "600" })};
    }
  }
`;

const NoMoreRoomsText = styled.span`
  display: block;
  text-align: center;
  ${font({ size: "14", lineHeight: "22", fontWeight: "600" })};
  color: ${({ theme }) => theme.color.greyBold};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 24px;
  }
`;

const CloseButton = styled(CloseIcon)<{ isCloseBtnSticky?: boolean; tabletFullWitdh?: boolean }>`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 16px;
    height: 16px;
`;

export const Styled = { GuestCount, GuestAddRoomContainer, NoMoreRoomsText, CloseButton };
