import axios from "axios";

const requestsPath = "mail";

export const sendSubscribeEmailData = async (data: { email: string }) => {
  // TODO задача не согласована, будет в отдельной задаче
  const response = await axios.post<{ email: string }>(`${process.env.REACT_APP_ROOT_API_URL}/test`, {
    ...data,
  });
  return response;
};
