import { FC } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useLocation } from "react-router-dom";

import { useMediaQuery } from "@src/hooks";
import { theme } from "@src/theme";

import { ContentWrapper, SectionTitle } from "../_Ui";
import { ServicesCard } from "../ServicesCard";
import { MobileMainPageSlider } from "./MobileMainPageSlider";
import { Styled } from "./styles";
import { useGetServicesData } from "./useGetServicesData";

export const ServicesSection: FC = () => {
  const location = useLocation();
  const isMainPage = location.pathname === "/" || location.pathname === "/en";
  const isAdaptive = useMediaQuery(theme.breakpoints.medium);
  const isMobileOnMainPage = isMainPage && isAdaptive;

  const { title, detailText, services } = useGetServicesData();

  return (
    <Styled.Container isMainPage={isMainPage}>
      <ContentWrapper>
        {title && (
          <Styled.SectionTitleWrapper>
            <SectionTitle text={title} />
          </Styled.SectionTitleWrapper>
        )}
        <AnimationOnScroll animateIn="animate__fadeInUp" offset={!isAdaptive ? 50 : 0} animateOnce>
          <Styled.ServicesWrapper>
            {!isMobileOnMainPage
              ? services?.map((sd) => <ServicesCard key={sd.id} {...sd} detailText={detailText} />)
              : services && <MobileMainPageSlider slides={services} />}
          </Styled.ServicesWrapper>
        </AnimationOnScroll>
      </ContentWrapper>
    </Styled.Container>
  );
};
