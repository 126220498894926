import { FC } from "react";
import ReactSelect, { OnChangeValue } from "react-select";
import makeAnimated from "react-select/animated";

import ResetIcon from "@src/assets/svg/reset-select.svg";

import { OptionType, FormSelectType } from "./select.interface";
import { Styled } from "./styles";

const animatedComponents = makeAnimated();

const FormSelect: FC<FormSelectType> = ({ placeholder, error, options, field, inputName, resetField }) => {
  const hasError = Boolean(error);

  const onChange = (newValue: OnChangeValue<OptionType, boolean>) => {
    field.onChange((newValue as OptionType).value);
  };

  const getValue = (reset?: boolean) => {
    if (reset) {
      return "" as any;
    }
    if (field?.value) {
      return options.find((option) => option.value === field.value);
    } else {
      return "" as any;
    }
  };
  const handleResetInput = () => {
    resetField(inputName);
  };

  return (
    <Styled.SelectContainer hasError={hasError}>
      <ReactSelect
        classNamePrefix="form-select"
        placeholder={placeholder}
        options={options}
        value={getValue()}
        onChange={onChange}
        components={animatedComponents}
        isSearchable={false}
      />
      {!!field?.value?.length && <Styled.Icon src={ResetIcon} alt={ResetIcon} onClick={handleResetInput} />}
      {error && <Styled.ErrorText>{error.message}</Styled.ErrorText>}
    </Styled.SelectContainer>
  );
};

export default FormSelect;
