import { FC, useState } from "react";
import Hammer from "react-hammerjs";
import Lottie from "react-lottie";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";

import { useHover, useMediaQuery } from "@src/hooks";
import { Services } from "@src/interfaces";
import { NotificationPopupStyles } from "@src/styles/popupStyles";
import { theme } from "@src/theme";

import { ServicesPopup } from "../ServicesSection/ServicesPopup";
import { Styled } from "./styles";

Modal.setAppElement("#root");

type ServicesCardProps = Services & { detailText?: string; isInView?: boolean; id?: number; isSlide?: boolean };

export const ServicesCard: FC<ServicesCardProps> = ({
  icon,
  name,
  detailText,
  description,
  isInView,
  animation,
  id,
  isSlide,
}) => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isMainPage = location.pathname === "/" || location.pathname === "/en";
  const isAdaptive = useMediaQuery(theme.breakpoints.medium);
  const isMobileOnMainPage = isMainPage && isAdaptive;
  const isMain = isMobileOnMainPage ? isMobileOnMainPage : false;

  const handlePopupClose = () => {
    setIsOpen(false);
  };

  const handleCardClick = () => {
    if (isMobileOnMainPage) {
      setIsOpen(true);
    }
  };

  const formattedAnimation = JSON.parse(animation);

  const lottieOptions = {
    loop: false,
    repeat: false,
    autoplay: true,
    animationData: formattedAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const isDesktopServicesPage = !isMainPage && !isAdaptive;
  const showOnMainPage = animation && !isSlide && isMainPage;

  const conditionToShowAnimation = isDesktopServicesPage || showOnMainPage;

  const handleServicesPopupSwipe = () => {
    handlePopupClose();
  };

  return (
    <>
      <Styled.ServicesCard ref={hoverRef} isHovered={isHovered} isMainPage={isMain} onClick={handleCardClick}>
        <Styled.ServicesIconWrap isMainPage={isMain}>
          <Styled.ServicesImageWithHover src={icon} alt="icon" width="120" height="120" />
          <Styled.ServicesImageNoHover src={icon} alt="icon" width="120" height="120" />
          {conditionToShowAnimation && (
            <Styled.AnimationWrapper>
              <Lottie options={lottieOptions} />
            </Styled.AnimationWrapper>
          )}
        </Styled.ServicesIconWrap>
        <Styled.ServicesTitle isMainPage={isMain}>{name}</Styled.ServicesTitle>
        <Styled.ServicesDetailText isMainPage={isMain}>{detailText}</Styled.ServicesDetailText>
        <Styled.ServicesDescription dangerouslySetInnerHTML={{ __html: description }}></Styled.ServicesDescription>
      </Styled.ServicesCard>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handlePopupClose}
        style={NotificationPopupStyles}
        bodyOpenClassName={null}>
        <Hammer onSwipe={handleServicesPopupSwipe} direction="DIRECTION_DOWN">
          <div>
            <ServicesPopup slideId={id} />
          </div>
        </Hammer>
      </Modal>
    </>
  );
};
