import React, { Suspense, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";

import { Breadcrumbs, Button, TextLabel, YMap } from "@src/components";
import { MAP_MARKER } from "@src/components/Contacts/constants";
import { Footer } from "@src/components/Footer";
import { RequestCallForm } from "@src/components/RequestCallForm";
import { useActions, useGetBaseData, useMediaQuery } from "@src/hooks";
import { useGetMenuData } from "@src/hooks/useGetMenuData";
import { useGetMetaData } from "@src/hooks/useGetMetaData";
import { popupStyles } from "@src/styles/popupStyles";
import { theme } from "@src/theme";
import { numberWithSpaces } from "@src/utils";

import placemarkIcon from "../../assets/images/icons/placemark.svg";
import { Styled } from "./styles";

const ContactPage = () => {
  const {
    mainEntranceText,
    parkingText,
    cooperationMailText,
    corporateClientsMailText,
    reservingMailText,
    hotelAddressLabel,
    addressText,
    phoneNumberText,
    requestCallText,
    reservationDepartmentLabel,
    corporationClientsLabel,
    cooperationLabel,
    phoneText,
    getDirectionsText,
    contactsHRLabel,
    contactsHREmail,
  } = useGetBaseData();

  const { contactsText } = useGetMenuData();

  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  let currentLocation = location.pathname;
  if (currentLocation.includes("/en")) {
    currentLocation = currentLocation.substring(3);
  }

  const { metaTitle, metaDescription, metaKeywords } = useGetMetaData(currentLocation);
  const [isOpen, setIsOpen] = useState(false);

  const formattedPhoneNumber = phoneNumberText && numberWithSpaces(phoneNumberText, "## ### ### ## ##");

  const isMobile = useMediaQuery(theme.breakpoints.large);

  const { setFirstScreenIsView, setIsStatusMessageOnTop } = useActions();
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);

  const OFFSET_TOP_FOR_STICKY_HEADER = 30;

  const handlePageScroll = () => {
    const isScrolled = window.scrollY > OFFSET_TOP_FOR_STICKY_HEADER;
    setIsHeaderSticky(window.scrollY > 0);
    setIsStatusMessageOnTop(window.scrollY > 0);
    setFirstScreenIsView(!isScrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", handlePageScroll);
    return () => {
      window.removeEventListener("scroll", handlePageScroll);
    };
  }, []);

  const FULL_HEADER_HEIGHT = 180;
  const STICKY_HEADER_HEIGHT = 97;
  const headerHeight = !isHeaderSticky ? FULL_HEADER_HEIGHT : STICKY_HEADER_HEIGHT;

  const mobileMapHeight = "563px";
  const YMapHeight = !isMobile ? "100%" : mobileMapHeight;

  const handleRequestCallClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleClosePopupClick = () => {
    setIsOpen(false);
  };

  // geolocation
  const handleParkingBtnClick = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const currentLocation = `${position.coords.latitude}, ${position.coords.longitude}`;
        const parkingLink = `https://yandex.ru/maps/193/voronezh/?ll=39.206992%2C51.669149&mode=routes&rtext=${currentLocation}~51.668033%2C39.206559&rtt=auto&ruri=~&z=17.05`;
        window.open(parkingLink, "_blank");
      });
    } else {
      const parkingLink =
        "https://yandex.ru/maps/193/voronezh/?ll=39.206992%2C51.669149&mode=routes&rtext=51.668803%2C39.207514~51.668033%2C39.206559&rtt=auto&ruri=~&z=17.05";
      window.open(parkingLink, "_blank");
    }
  };
  const handleEntranceBtnClick = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const currentLocation = `${position.coords.latitude}, ${position.coords.longitude}`;
        const entranceLink = `https://yandex.ru/maps/193/voronezh/?ll=39.205969%2C51.668007&mode=routes&rtext=${currentLocation}~51.667870%2C39.206503&rtt=auto&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1Nzg3MzEyMhJF0KDQvtGB0YHQuNGPLCDQktC-0YDQvtC90LXQtiwg0L_RgNC-0YHQv9C10LrRgiDQoNC10LLQvtC70Y7RhtC40LgsIDM4IgoNdtMcQhXmq05C&z=17`;
        window.open(entranceLink, "_blank");
      });
    } else {
      const entranceLink =
        "https://yandex.ru/maps/193/voronezh/?ll=39.205969%2C51.668007&mode=routes&rtext=51.66  8199%2C39.203978~51.667870%2C39.206503&rtt=auto&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1Nzg3MzEyMhJF0KDQvtGB0YHQuNGPLCDQktC%2B0YDQvtC90LXQtiwg0L%2FRgNC%2B0YHQv9C10LrRgiDQoNC10LLQvtC70Y7RhtC40LgsIDM4IgoNdtMcQhXmq05C&z=17";
      window.open(entranceLink, "_blank");
    }
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Styled.ContactWrapper headerHeight={headerHeight} ref={containerRef}>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="keywords" content={metaKeywords} />
        </Helmet>
        <Breadcrumbs />
        <Styled.Header>{contactsText}</Styled.Header>
        <Styled.Contacts>
          {isMobile && (
            <>
              <Styled.Buttons>
                <Button title={parkingText} primary onClick={handleParkingBtnClick} />
                <Button title={mainEntranceText} secondary onClick={handleEntranceBtnClick} />
              </Styled.Buttons>
              <Styled.ButtonTitle>{getDirectionsText}</Styled.ButtonTitle>
            </>
          )}
          <Styled.Contact>
            <TextLabel label={hotelAddressLabel} text={addressText} />
            <TextLabel label={reservationDepartmentLabel} text={reservingMailText} isLink />
            <TextLabel label={corporationClientsLabel} text={corporateClientsMailText} isLink />
            <TextLabel label={cooperationLabel} text={cooperationMailText} isLink />
            {contactsHRLabel && contactsHREmail && <TextLabel label={contactsHRLabel} text={contactsHREmail} isLink />}
          </Styled.Contact>

          <Styled.ButtonsWrapper>
            <TextLabel label={phoneText} text={formattedPhoneNumber} isLink isPhone>
              <Styled.RequestButton onClick={handleRequestCallClick}>{requestCallText}</Styled.RequestButton>
            </TextLabel>
            {!isMobile && (
              <>
                <Styled.ButtonTitle>{getDirectionsText}</Styled.ButtonTitle>
                <Styled.Buttons>
                  <Button title={parkingText} primary onClick={handleParkingBtnClick} />
                  <Button title={mainEntranceText} secondary onClick={handleEntranceBtnClick} />
                </Styled.Buttons>
              </>
            )}
          </Styled.ButtonsWrapper>
        </Styled.Contacts>
        <Styled.Map>
          <YMap height={YMapHeight} zoom={15} center={MAP_MARKER} icon={placemarkIcon} />
        </Styled.Map>
        <Footer />
      </Styled.ContactWrapper>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={handleClosePopupClick}
        style={popupStyles}>
        <RequestCallForm handleClosePopup={handleClosePopupClick} />
      </Modal>
    </Suspense>
  );
};

export default ContactPage;
