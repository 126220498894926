import styled, { css } from "styled-components";

import { container1400, font } from "@src/styles/mixins";

const ReservationContainer = styled.div<{ headerHeight: number }>`
  background: ${({ theme }) => theme.color.white};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletXLargeMin}) {
    ${({ headerHeight }) => css`
      margin-top: ${headerHeight}px;
      padding-top: 1px;
    `};
  }
`;

const ReservationContent = styled.div`
  ${container1400};
  max-width: 1400px;
  width: calc(100% - 40px);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    width: calc(100% - 40px);
    max-width: 1184px;
    margin: 0 auto;
  }
`;

const Header = styled.h1`
  font-family: Montserrat, sans-serif;
  ${font({ size: "50", lineHeight: "58", fontWeight: "500" })};
  ${container1400};
  max-width: 1400px;
  width: calc(100% - 40px);
  padding-bottom: 64px;
  letter-spacing: 2px;
  margin-top: 8px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    width: calc(100% - 40px);
    max-width: 1184px;
    margin: 0 auto;
    ${font({ size: "48", lineHeight: "58", fontWeight: "500" })};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 0;
    ${font({ size: "28", lineHeight: "38", fontWeight: "500" })}
  }
`;
export const Styled = { Header, ReservationContainer, ReservationContent };
