import { createSlice } from "@reduxjs/toolkit";

interface IPopupState {
  isOpen: boolean;
}

const initialState: IPopupState = {
  isOpen: false,
};

export const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    openPopup: (state) => {
      state.isOpen = true;
    },
    closePopup: (state) => {
      state.isOpen = false;
    },
  },
});

export const popupActions = popupSlice.actions;

export default popupSlice.reducer;
