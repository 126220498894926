import { differenceInCalendarYears, format, parseISO } from "date-fns";
import { enGB, ru } from "date-fns/locale";
import Cookies from "js-cookie";
import { FC } from "react";
import { useNavigate, useLocation } from "react-router";

import { Button } from "@src/components/_Ui";
import { RESERVATION_ROUTES } from "@src/constants/_routes.constants";
import { useActions, useGetBaseData } from "@src/hooks";
import { useGetMenuData } from "@src/hooks/useGetMenuData";

import { Styled } from "./styles";

type CardFooterProps = {
  price: number;
  date: string;
  id: number;
  hasBookingBtn?: boolean;
};

export const CardFooter: FC<CardFooterProps> = ({ price, date, id, hasBookingBtn = true }) => {
  const { closePopup } = useActions();
  const { textFrom, textNight, textSuggestionFor, currencyValue } = useGetBaseData();
  const { bookBtnPhrase } = useGetMenuData();
  const defaultLanguageId = "1";
  const langId = Cookies.get("lang_id") || defaultLanguageId;
  const currentLocale = langId === "1" ? ru : enGB;
  const differenceInYears = differenceInCalendarYears(parseISO(date), Date.now());
  const dateToDraw = !differenceInYears
    ? format(parseISO(date), "d MMMM", { locale: currentLocale })
    : format(parseISO(date), "d MMMM yyyy", { locale: currentLocale });

  const formattedPrice = String(price)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .split(".")[0];

  const navigate = useNavigate();
  const location = useLocation();
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;

  const onSubmitBtnCLickHandler = () => {
    closePopup();

    !isEnglishVersion
      ? navigate(`${RESERVATION_ROUTES.RESERVATION_GUESTS_COUNTER}`, {
          state: { roomId: id },
        })
      : navigate(`/en${RESERVATION_ROUTES.RESERVATION_GUESTS_COUNTER}`, {
          state: { roomId: id },
        });
  };

  return (
    <Styled.CardFooter>
      <Styled.PriceContainer>
        <Styled.PriceValue>
          {textFrom} {formattedPrice} {currencyValue}
        </Styled.PriceValue>
        <Styled.AmountValue>
          {textSuggestionFor} 1 {textNight} {dateToDraw}
        </Styled.AmountValue>
      </Styled.PriceContainer>
      {hasBookingBtn && (
        <Styled.ButtonWrapper>
          <Button title={bookBtnPhrase} primary onClick={onSubmitBtnCLickHandler} />
        </Styled.ButtonWrapper>
      )}
    </Styled.CardFooter>
  );
};
