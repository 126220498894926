import { FC } from "react";

import { useGetBaseData, useHover, useMediaQuery } from "@src/hooks";
import { theme } from "@src/theme";

import { Button, Picture } from "@src/components/_Ui";
import { Styled } from "./styles";
import { SpaServices } from "@src/interfaces/spa.interface";
import { useLocation } from "react-router-dom";

type SpaServiceCardProps = {
  data: SpaServices;
};

export const SpaServiceCard: FC<SpaServiceCardProps> = ({ data }) => {
  const { sticker_text, name, img, img_mobile, img_tablet, price, term, preview_text, id } = data;
  const location = useLocation();
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;
  const link = isEnglishVersion ? `/en/spa/service/${id}` : `/spa/service/${id}`;

  const { moreText } = useGetBaseData();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const isAdaptive = useMediaQuery(theme.breakpoints.small);
  const isTablet = useMediaQuery(theme.breakpoints.medium);
  const isDesktopSize = !isAdaptive && !isTablet;
  const isTabletSize = isTablet && !isAdaptive;
  const imgSrc = isDesktopSize ? img : isTabletSize ? img_tablet : img_mobile;

  const hasText = Boolean(price || term);

  return (
    <>
      <Styled.Card ref={hoverRef} isHovered={isHovered}>
        <Styled.CardContainer>
          <Styled.CardImage>
            <Picture img={imgSrc} />
          </Styled.CardImage>

          <Styled.CardSlider>
            <Picture img={img_mobile} largeGallery />
          </Styled.CardSlider>
          <Styled.CardLabel>{sticker_text}</Styled.CardLabel>

          <Styled.CardTextWrapper>
            <Styled.Title>{name}</Styled.Title>
            <Styled.Description dangerouslySetInnerHTML={{ __html: preview_text }}></Styled.Description>
          </Styled.CardTextWrapper>

          {hasText && (
            <Styled.CardPriceContainer>
              <>
                {price && <Styled.Price>{price}</Styled.Price>}
                {term && <Styled.PriceDescription>{term}</Styled.PriceDescription>}
              </>
            </Styled.CardPriceContainer>
          )}
        </Styled.CardContainer>

        <Styled.ButtonsWrapper isHovered={isHovered}>
          <Styled.Link to={link}>
            <Button title={moreText} blue />
          </Styled.Link>
        </Styled.ButtonsWrapper>
        <Styled.MobileButtonsWrapper>
          <Styled.Link to={link}>
            <Button title={moreText} blue />
          </Styled.Link>
        </Styled.MobileButtonsWrapper>
      </Styled.Card>
    </>
  );
};
