import styled from "styled-components";

import { flexCenter, width100 } from "@src/styles/mixins";

const PageLoader = styled.div`
  max-width: 1400px;
  height: 100vh;
  ${width100};
  margin: 0 auto;
  ${flexCenter}
`;

export const Styled = { PageLoader };
