import { FC } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useLocation } from "react-router-dom";

import { useGetMenuData } from "@src/hooks/useGetMenuData";
import { useGetRoomsLongStayQuery } from "@src/store/services";

import { Styled } from "./styles";
import { LongStayRoomCardPreview } from "../LongStayRoomCardPreview";
import { SectionTitle } from "@src/components/_Ui";
import { useGetBaseData } from "@src/hooks";

export const LongStayRoomsList: FC = () => {
  const { data: rooms } = useGetRoomsLongStayQuery();
  const { longStayTitle } = useGetMenuData();
  const { longStayPageTitle } = useGetBaseData();
  const location = useLocation();
  const isLongStayPage = location.pathname.includes("/long-stay");

  return (
    <Styled.Rooms>
      {isLongStayPage && <Styled.RoomsTitle>{longStayPageTitle}</Styled.RoomsTitle>}
      {!!rooms?.length && (
        <>
          {!isLongStayPage && (
            <Styled.SectionTitleWrapper>
              <SectionTitle text={longStayTitle} />
            </Styled.SectionTitleWrapper>
          )}
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
            <Styled.Container>
              {rooms.map((room, index) => (
                <Styled.Room key={`${room.h1}/${index}`}>
                  <LongStayRoomCardPreview data={room} isLongStayPage={isLongStayPage} />
                </Styled.Room>
              ))}
            </Styled.Container>
          </AnimationOnScroll>
        </>
      )}
    </Styled.Rooms>
  );
};
