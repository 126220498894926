import { FC, useEffect } from "react";
import Div100vh from "react-div-100vh";
import { Helmet } from "react-helmet";

import { DesktopGallery } from "@src/components";
import { MobileGallery } from "@src/components";
import { useChangeSearchParams } from "@src/hooks/useChangeSearchParams";
import { useGetRoomData } from "@src/hooks/useGetRoomData";

import { CardFooter } from "./CardFooter";
import { CardList } from "./CardList";
import { Styled } from "./styles";
import { Tags } from "./Tags";
import { LongStayCardFooter } from "./LongStayCardFooter/LongStayCardFooter";
import { BookingCardFooter } from "./BookingCardFooter";

type RoomCardProps = {
  id: number;
  isLongStay: boolean;
  handleClosePopup: () => void;
};

export const RoomCard: FC<RoomCardProps> = ({ handleClosePopup, id, isLongStay }) => {
  const {
    data,
    name,
    h1,
    metaTitle,
    metaDescription,
    metaKeywords,
    price,
    date,
    fillValues,
    markedValues,
    photos,
    description,
  } = useGetRoomData(id);

  const { setQueryParams, checkParams, removeQueryParams } = useChangeSearchParams();
  const isLongStayParam = checkParams("longstay");
  const isReservationParam = checkParams("reservation");

  useEffect(() => {
    if (!isLongStayParam && !isReservationParam) {
      setQueryParams(id);
    }
  }, [id]);

  const handleCloseBtnClick = () => {
    handleClosePopup();
    removeQueryParams("room");
  };

  return (
    <>
      {data && (
        <Div100vh>
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={metaKeywords} />
          </Helmet>
          <Styled.Card>
            <Styled.ScrollContainer>
              <Styled.CloseButton onClick={handleCloseBtnClick} />
              {photos && <MobileGallery images={photos} mediumGallery />}
              <Styled.DescriptionContainer>
                <Styled.RoomName>{name}</Styled.RoomName>
                {fillValues && <Tags data={fillValues} />}
                {photos && <DesktopGallery images={photos} />}
                <Styled.Text>{description}</Styled.Text>
                {markedValues?.map((item, index) => {
                  return <CardList {...item} key={index} />;
                })}
              </Styled.DescriptionContainer>
            </Styled.ScrollContainer>
            {isReservationParam && price && <BookingCardFooter id={id} />}
            {price && !isLongStay && !isReservationParam && (
              <CardFooter price={price} date={date} id={id} hasBookingBtn={!isReservationParam} />
            )}
            {isLongStay && <LongStayCardFooter id={id} />}
          </Styled.Card>
        </Div100vh>
      )}
    </>
  );
};
