import styled from "styled-components";

const Tags = styled.div`
  margin: -2px;
  margin-bottom: 19px;
`;

export const Styled = {
  Tags,
};
