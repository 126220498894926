import React, { Dispatch, FC, SetStateAction } from "react";

import { ReactComponent as CheckedIcon } from "@src/assets/images/icons/check.svg";
import { ReactComponent as MaestroIcon } from "@src/assets/images/icons/maestro.svg";
import { ReactComponent as MasterCardIcon } from "@src/assets/images/icons/masterCard.svg";
import { ReactComponent as MirIcon } from "@src/assets/images/icons/mir.svg";
import { ReactComponent as VisaIcon } from "@src/assets/images/icons/visa.svg";
import { Button } from "@src/components";
import { useGetBaseData, useMediaQuery } from "@src/hooks";
import { theme } from "@src/theme";

import { Styled } from "../styles";

type PaymentInfoType = {
  isPayment: "isPaymentOnline" | "isPaymentOffline";
  setIsPaymentOnline: Dispatch<SetStateAction<"isPaymentOnline" | "isPaymentOffline">>;
  errorReqField?: boolean;
  commonErrorLink?: string;
  commonErrorText?: string;
};

export const PaymentInfo: FC<PaymentInfoType> = ({
  isPayment,
  setIsPaymentOnline,
  commonErrorLink,
  commonErrorText,
  errorReqField,
}) => {
  const {
    rulesBookingStartText,
    rulesSecondText,
    rulesPrivacyPolicyLink,
    rulesPrivacyPolicyFile,
    rulesPersonalDataFile,
    rulesPersonalDataLink,
    inOutTimeRules,
    paymentVariantsTitle,
    inOutTimeText,
    bookingRules,
    paymentOfflineText,
    paymentOnlineText,
    paymentWarning,
    paymentListText,
    bookingText,
    rulesText,
    rulesLink,
    rulesLinkFile,
    bookingRulesTitle,
    bookingOnlinePaymentBtnText,
    bookingOfflinePaymentBtnText,
  } = useGetBaseData();

  const isMobile = useMediaQuery(theme.breakpoints.large);

  const handleMobileSubmitClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // TODO payment method for test
  const isOnlinePaymentEnabled = process.env.REACT_APP_ONLINE_PAYMENT_ENABLE?.toLowerCase() === "true";
  const isOfflinePaymentEnabled = process.env.REACT_APP_OFFLINE_PAYMENT_ENABLE?.toLowerCase() === "true";
  return (
    <div>
      <Styled.ItemTitle>{inOutTimeText}</Styled.ItemTitle>
      <Styled.RulesContainer dangerouslySetInnerHTML={{ __html: inOutTimeRules }} />

      <Styled.ItemTitle>{bookingRulesTitle}</Styled.ItemTitle>
      <Styled.RulesContainer dangerouslySetInnerHTML={{ __html: bookingRules }} />

      <Styled.ItemTitle>{paymentVariantsTitle}</Styled.ItemTitle>
      <Styled.Buttons>
        {/* TODO temporaly switched to isPaymentOffline */}
        {isOnlinePaymentEnabled && (
          <Button
            noHover={isPayment === "isPaymentOnline"}
            primary={isPayment === "isPaymentOnline"}
            outline={isPayment !== "isPaymentOnline"}
            // TODO: заменить "Онлайн", когда будет бэк
            title={isMobile ? bookingOnlinePaymentBtnText : paymentOnlineText}
            icon={isPayment === "isPaymentOnline" && <CheckedIcon stroke="white" />}
            onClick={() => setIsPaymentOnline("isPaymentOnline")}
            style={{ fontSize: 13, lineHeight: "24px", padding: "12px 32px", whiteSpace: "nowrap" }}
          />
        )}

        {isOfflinePaymentEnabled && (
          <Button
            noHover={isPayment === "isPaymentOffline"}
            primary={isPayment === "isPaymentOffline"}
            outline={isPayment !== "isPaymentOffline"}
            color={isPayment !== "isPaymentOffline" ? "#A0072B" : ""}
            // TODO: заменить "при заезде", когда будет бэк
            title={isMobile ? bookingOfflinePaymentBtnText : paymentOfflineText}
            icon={isPayment === "isPaymentOffline" && <CheckedIcon stroke="white" />}
            onClick={() => setIsPaymentOnline("isPaymentOffline")}
            style={{
              fontSize: 13,
              lineHeight: "24px",
              padding: "12px 20px",
              fontWeight: 600,
              whiteSpace: "nowrap",
            }}
          />
        )}
      </Styled.Buttons>
      <Styled.Rules>{paymentWarning}</Styled.Rules>
      <Styled.Warning>{paymentListText}</Styled.Warning>
      <Styled.Cards>
        <VisaIcon />
        <MasterCardIcon />
        <MirIcon />
        <MaestroIcon />
      </Styled.Cards>
      <Styled.PayMobile>
        <Button type="submit" mb="20px" primary title={bookingText} onClick={handleMobileSubmitClick} />
        {errorReqField && (
          <Styled.RoomsAmount>
            <span>{commonErrorLink}</span> {commonErrorText}
          </Styled.RoomsAmount>
        )}
        <Styled.Privacy>
          {rulesBookingStartText}
          <Styled.PrivacyLink
            href={process.env.REACT_APP_ROOT_URL + rulesPrivacyPolicyFile}
            target="_blank"
            rel="noreferrer">
            {rulesPrivacyPolicyLink}
          </Styled.PrivacyLink>{" "}
          {rulesSecondText}{" "}
          <Styled.PrivacyLink
            href={process.env.REACT_APP_ROOT_URL + rulesPersonalDataFile}
            target="_blank"
            rel="noreferrer">
            {rulesPersonalDataLink}
          </Styled.PrivacyLink>
        </Styled.Privacy>
      </Styled.PayMobile>
    </div>
  );
};
