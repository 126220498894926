import { FC } from "react";

import { useMediaQuery } from "@src/hooks";
import { CenterBanner } from "@src/interfaces";
import { theme } from "@src/theme";

import { ContentWrapper, IconButton } from "../_Ui";
import { Styled } from "./styles";

export const CenterBannerSlide: FC<CenterBanner> = (props) => {
  const { text, text_small, link, img, img_mobile, img_tablet } = props;

  const isAdaptive = useMediaQuery(theme.breakpoints.small);
  const isTablet = useMediaQuery(theme.breakpoints.tabletLarge);
  const isDesktopSize = !isAdaptive && !isTablet;
  const isTabletSize = isTablet && !isAdaptive;
  const backgroundImgSize = isDesktopSize ? img : isTabletSize ? img_tablet : img_mobile;

  const backgroundImgWebP = backgroundImgSize?.webp ? backgroundImgSize.webp.def2 : undefined;
  const backgroundImgOriginal = backgroundImgSize?.original ? backgroundImgSize.original.def2 : undefined;

  return (
    <ContentWrapper>
      {isAdaptive ? (
        <Styled.LinkWrapper href={link} target="_blank" rel="noreferrer">
          <Styled.Container imgWebp={backgroundImgWebP} imgJPG={backgroundImgOriginal}>
            <Styled.TextWrapper>
              <Styled.SecondaryText dangerouslySetInnerHTML={{ __html: text_small }} />
              <Styled.MainText>{text}</Styled.MainText>
            </Styled.TextWrapper>
          </Styled.Container>
        </Styled.LinkWrapper>
      ) : (
        <Styled.Container imgWebp={backgroundImgWebP} imgJPG={backgroundImgOriginal}>
          <Styled.TextWrapper>
            <Styled.SecondaryText dangerouslySetInnerHTML={{ __html: text_small }} />
            <Styled.MainText>{text}</Styled.MainText>
          </Styled.TextWrapper>

          {link && (
            <Styled.ButtonWrapper>
              <a href={link} target="_blank" rel="noreferrer">
                <IconButton icon={<Styled.ChevronIcon />} size="large" variant="secondary" />
              </a>
            </Styled.ButtonWrapper>
          )}
        </Styled.Container>
      )}
    </ContentWrapper>
  );
};
