export const renderBullet = (index: number, className: string) => {
  return `
        <div class="${className}-wrapper">
          <span class="${className}"></span>
          <svg class="${className}-progress" width="24" height="24">
           <circle class="${className}-circle-bg" r="11" cx="12" cy="12" />
            <circle class="${className}-circle" r="11" cx="12" cy="12" />
          </svg>
        </div>`;
};
