import { findFlagByDialCode } from "country-list-with-dial-code-and-flag";
import React, { forwardRef, InputHTMLAttributes, useState, ChangeEvent, FC, useEffect } from "react";
import { FieldError } from "react-hook-form";

import ResetIcon from "@src/assets/svg/reset-select.svg";
import useImage from "@src/hooks/useImage";

import { Styled } from "./styles";
import { getFormattedPhoneValue, handlePhoneFormat, handlePhoneKeyDown, handlePhonePaste } from "./utils";

type ResetFieldOptions = {
  keepErrors?: boolean;
  keepDirty?: boolean;
  keepIsSubmitted?: boolean;
  keepTouched?: boolean;
  keepDefaultValue?: boolean;
};

type IFieldProps = {
  placeholder?: string;
  icon?: string;
  error?: FieldError | undefined;
  isPhone?: boolean;
  errorReqField?: boolean;
  hasValue?: boolean;
  maxWidth?: boolean;
  hasFlag?: boolean;
  resetField?: (name: any, options?: ResetFieldOptions) => void;
  inputName?: string;
  maxLength?: number;
  dateModalIsOpen?: boolean;
  staticError?: boolean;
  onDateClick?: () => void;
};

type ImageType = { fileName: any };

const Image: FC<ImageType> = ({ fileName }) => {
  const { image } = useImage(fileName);

  return <>{image && <img src={image} />}</>;
};

type TypeInputPropsField = InputHTMLAttributes<HTMLInputElement> & IFieldProps;

interface IField extends TypeInputPropsField {}

export const FormInput = forwardRef<HTMLInputElement, IField>(
  (
    {
      errorReqField,
      placeholder,
      hasValue,
      icon,
      error,
      type = "text",
      required,
      isPhone,
      maxWidth,
      hasFlag,
      resetField,
      inputName,
      onDateClick,
      maxLength,
      dateModalIsOpen,
      staticError,
      ...rest
    },
    ref
  ) => {
    const [isFilled, setIsFilled] = useState(false);
    const hasError = Boolean(error);
    const hasIcon = Boolean(icon);
    const hasInputError = hasError && !isFilled && !hasValue && errorReqField;
    const minInputLengthPhone = 1;
    const minInputLengthText = 0;

    const handleInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
      const trimmedInputValue = evt.target.value.trim();
      const unmaskedPhoneValue = trimmedInputValue.replace(/[^0-9]/g, "");
      const trimmedInputValueLength = trimmedInputValue.length;
      const unmaskedPhoneValueLength = unmaskedPhoneValue.length + 1;

      const valueToCheck = isPhone ? unmaskedPhoneValueLength : trimmedInputValueLength;
      const minInputLength = isPhone ? minInputLengthPhone : minInputLengthText;

      if (valueToCheck > minInputLength) {
        setIsFilled(true);
      } else {
        setIsFilled(false);
      }
    };

    const handlePhoneBlur = (evt: ChangeEvent<HTMLInputElement>) => {
      const trimmedInputValue = evt.target.value.trim();
      const unmaskedPhoneValue = trimmedInputValue.replace(/[^0-9]/g, "");
      const unmaskedPhoneValueLength = unmaskedPhoneValue.length + 1;
      if (unmaskedPhoneValueLength > minInputLengthPhone) {
        setIsFilled(true);
      } else {
        setIsFilled(false);
      }
    };

    useEffect(() => {
      if (!hasValue) {
        setIsFilled(false);
        setCurrentFlag("");
      }
    }, [hasValue]);

    const [currentFlag, setCurrentFlag] = useState("");

    const getFlag = (value: string) => {
      const searchValue = `+${value.split(" ").join("").replace(/\D/g, "")}`;
      const flag = findFlagByDialCode(searchValue);
      if (flag?.code) {
        setCurrentFlag(flag.code.toLowerCase());
      }
    };

    const handlePhoneInput = (evt: ChangeEvent<HTMLInputElement>) => {
      handleInputChange(evt);
      handlePhoneFormat(evt);
      getFlag(getFormattedPhoneValue(evt) as string);
    };

    const handleResetInput = (evt: React.MouseEvent<HTMLImageElement>) => {
      evt.preventDefault();
      evt.stopPropagation();

      resetField?.(inputName);
      setIsFilled(false);
      setCurrentFlag("");
    };

    const handleDateClick = (evt: React.MouseEvent<HTMLInputElement>) => {
      if (onDateClick) {
        onDateClick();
        setIsFilled(true);
      }
    };

    // при закрытии модального окна даты без выбора даты, плэйсхолдер не появляется
    useEffect(() => {
      if (inputName === "date" && !hasValue && !dateModalIsOpen) {
        setIsFilled(false);
      } else if (inputName === "date" && hasValue && dateModalIsOpen) {
        setIsFilled(true);
      }
    }, [dateModalIsOpen]);

    return (
      <Styled.Container>
        <Styled.Label>
          {!isPhone ? (
            <Styled.Input
              ref={ref}
              type={type}
              {...rest}
              onChange={handleInputChange}
              hasInputError={hasInputError}
              hasInputIcon={hasIcon}
              isFilled={isFilled}
              maxWidth={maxWidth}
              hasError={hasError}
              autoComplete="off"
              onClick={handleDateClick}
              maxLength={maxLength}
            />
          ) : (
            <>
              <Styled.Input
                ref={ref}
                type={type}
                maxLength={18}
                onInput={handlePhoneInput}
                onKeyDown={handlePhoneKeyDown}
                onPaste={handlePhonePaste}
                onChange={handlePhoneInput}
                hasInputError={hasInputError}
                hasInputIcon={hasIcon}
                isFilled={isFilled}
                maxWidth={maxWidth}
                hasError={hasError}
                autoComplete="off"
                {...rest}
                onBlur={handlePhoneBlur}
              />
              {!!currentFlag && hasFlag && (
                <Styled.FlagContainer>
                  <Image fileName={currentFlag} />
                </Styled.FlagContainer>
              )}
            </>
          )}

          <Styled.Placeholder isInputFilled={isFilled || hasValue} isInputRequired={required}>
            {placeholder}
          </Styled.Placeholder>
          {isFilled && !onDateClick && (
            <Styled.Icon
              src={ResetIcon}
              alt={ResetIcon}
              onClick={handleResetInput}
              isSecondIcon={!!currentFlag && hasFlag}
            />
          )}
          {icon && (
            <>
              {isFilled && (
                <Styled.Icon src={ResetIcon} alt={ResetIcon} onClick={handleResetInput} isSecondIcon={Boolean(icon)} />
              )}
              <Styled.Icon src={icon} alt={icon} />
            </>
          )}
        </Styled.Label>
        {error && <Styled.ErrorMessage staticError={staticError}>{error.message}</Styled.ErrorMessage>}
      </Styled.Container>
    );
  }
);

FormInput.displayName = "Field";
