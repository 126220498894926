import styled from "styled-components";

import { absolutePseudoEl, flexCenter, width100 } from "@src/styles/mixins";

const Container = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    ${width100}
    display: flex;
    margin-bottom: 20px;
    margin-bottom: 46px;
  }
`;

const LogoWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.black};

  & a {
    display: block;
    padding: 18px 23px 14px 17px;
  }

  svg {
    width: 66px;
    height: 46px;
  }
`;

const ChangeLangWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;

  margin-right: 13px;

  position: absolute;
  top: 33px;
  top: 36px;
  right: 53px;
`;

const CloseButton = styled.button`
  position: relative;
  width: 32px;
  height: 32px;
  ${flexCenter}
  margin-top: 12px;

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    ${absolutePseudoEl};
    left: 15px;
    height: 17px;
    width: 2px;
    background-color: ${({ theme }) => theme.color.black};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export const Styled = { Container, LogoWrapper, ChangeLangWrapper, CloseButton };
