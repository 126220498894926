import { FC } from "react";

import { MarkedValue } from "@src/interfaces";

import { ListItem } from "../ListItem";
import { Styled } from "./styles";

export const CardList: FC<MarkedValue> = ({ name, items }) => {
  const itemsToDraw = Object.values(items);

  return (
    <>
      <Styled.ListHeader>{name}</Styled.ListHeader>
      <Styled.List>
        {itemsToDraw.map((item, index) => {
          return <ListItem key={index} {...item} />;
        })}
      </Styled.List>
    </>
  );
};
