import { FC, useRef } from "react";
import { FieldArrayWithId, FieldErrors, UseFormRegister } from "react-hook-form";
import { Controller } from "react-hook-form";
import { Control, UseFormGetValues, UseFormTrigger, UseFormWatch } from "react-hook-form/dist/types/form";

import { ReactComponent as CheckedIcon } from "@src/assets/images/icons/check.svg";
import { FormField } from "@src/components/_Ui/FormField/FormField";
import { FormTextarea } from "@src/components/_Ui/FormTextarea";
import { useGetBaseData } from "@src/hooks";
import { IGuestData } from "@src/interfaces";

import { Styled } from "./styles";

type FormProps = {
  fields: FieldArrayWithId<IGuestData>[];
  watch?: UseFormWatch<{ pay: IGuestData[] }>;
  register: UseFormRegister<{ pay: IGuestData[] }>;
  getValues: UseFormGetValues<{ pay: IGuestData[] }>;
  control?: Control<{ pay: IGuestData[] }>;
  setFillAll: (value: boolean) => void;
  onPaymentChangeClick: (isPayment: "isPaymentOnline" | "isPaymentOffline") => void;
  fillAll: boolean;
  isPayment: "isPaymentOnline" | "isPaymentOffline";
  errorReqField?: boolean;
  isFormDuplicated?: boolean;
  formErrors: FieldErrors;
  indexesOfLoyalRooms: number[];
  trigger: UseFormTrigger<{ pay: IGuestData[] }>;
};
export const Form: FC<FormProps> = ({
  onPaymentChangeClick,
  isPayment,
  getValues,
  setFillAll,
  fillAll,
  fields,
  errorReqField,
  register,
  formErrors,
  isFormDuplicated,
  control,
  indexesOfLoyalRooms,
  watch,
  trigger,
}) => {
  const {
    userLastNameLabel,
    userNameLabel,
    phoneText,
    commentText,
    userCitizenshipLabel,
    userEmailLabel,
    bookingWrongFormatError,
    userLastNameError,
    userNameError,
    userPhoneError,
    commonErrorText,
    userEmailError,
    userComment,
    userCitizenshipError,
    bookingFormDataCheckboxText,
    bookingLoyaltyInputText,
    bookingLoyaltyInputRequiredText,
    eventIncorrectPhoneError,
  } = useGetBaseData();

  const areRoomsLoyal = Boolean(indexesOfLoyalRooms.length);

  const checkboxRef = useRef<HTMLInputElement | null>(null);

  const handleCheckboxClick = async () => {
    if (!fillAll) {
      const isValid = await trigger(`pay.${0}`, { shouldFocus: true });
      if (isValid) {
        setFillAll(true);
      }
    } else {
      setFillAll(false);
    }
  };

  return (
    <Styled.Container>
      {fields.map((item: any, index) => {
        const isDisabled = fillAll && index > 0;
        const currentPhoneValue = watch?.(`pay.${index}.phone`);

        const isRussianNumber = currentPhoneValue?.[0] === "8" || currentPhoneValue?.[1] === "7";
        const RUSSIAN_PHONE_LENGTH = 18;
        const FOREIGN_COUNTRY_PHONE_LENGTH = 12;
        const phoneLengthToValidate = isRussianNumber ? RUSSIAN_PHONE_LENGTH : FOREIGN_COUNTRY_PHONE_LENGTH;
        const isCurrentRoomLoyal = indexesOfLoyalRooms.includes(index);

        return (
          <Styled.Item key={item.id} isGrey={isDisabled}>
            <Styled.ItemTitle>{item.room_name}</Styled.ItemTitle>
            <Styled.ItemSubtitle>{item.room_tarriff}</Styled.ItemSubtitle>
            <Styled.Row>
              <FormField
                maxLength={20}
                errorReqField={errorReqField}
                placeholder={userLastNameLabel}
                staticError
                {...register(`pay.${index}.lastname`, {
                  required: !isDisabled ? userLastNameError : false,
                  pattern: { value: /^[A-Za-zа-яА-Я\s-]+$/, message: bookingWrongFormatError },
                })}
                hasValue={!!getValues(`pay.${index}.lastname`)}
                error={
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  !isDisabled ? formErrors?.pay?.[index as unknown as keyof typeof formErrors.pay]?.lastname : null
                }
                required
                disabled={isDisabled}
              />
              <FormField
                maxLength={20}
                errorReqField={errorReqField}
                placeholder={userNameLabel}
                required
                staticError
                {...register(`pay.${index}.name`, {
                  required: !isDisabled ? userNameError : false,
                  pattern: { value: /^[A-Za-zа-яА-Я\s-]+$/, message: bookingWrongFormatError },
                })}
                hasValue={!!getValues(`pay.${index}.name`)}
                error={
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  !isDisabled ? formErrors?.pay?.[index as unknown as keyof typeof formErrors.pay]?.name : null
                }
                disabled={isDisabled}
              />
              <FormField
                errorReqField={errorReqField}
                placeholder={userCitizenshipLabel}
                maxLength={20}
                required
                staticError
                {...register(`pay.${index}.citizenship`, {
                  required: !isDisabled ? userCitizenshipError : false,
                  pattern: { value: /^[A-Za-zа-яА-Я\s-]+$/, message: bookingWrongFormatError },
                })}
                hasValue={!!getValues(`pay.${index}.citizenship`)}
                error={
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  !isDisabled ? formErrors?.pay?.[index as unknown as keyof typeof formErrors.pay]?.citizenship : null
                }
                disabled={isDisabled}
              />
            </Styled.Row>

            <Styled.Row>
              <FormField
                maxLength={100}
                errorReqField={errorReqField}
                placeholder={userEmailLabel}
                required
                staticError
                {...register(`pay.${index}.email`, {
                  required: !isDisabled ? userEmailError : false,
                  pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: userEmailError },
                })}
                hasValue={!!getValues(`pay.${index}.email`)}
                error={
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  !isDisabled ? formErrors?.pay?.[index as unknown as keyof typeof formErrors.pay]?.email : null
                }
                disabled={isDisabled}
              />
              <FormField
                errorReqField={errorReqField}
                placeholder={phoneText}
                maxLength={phoneLengthToValidate}
                required
                isPhone
                hasFlag
                staticError
                {...register(`pay.${index}.phone`, {
                  required: !isDisabled ? userPhoneError : false,
                  minLength: !isDisabled
                    ? {
                        value: phoneLengthToValidate,
                        message: eventIncorrectPhoneError,
                      }
                    : undefined,
                })}
                hasValue={!!getValues(`pay.${index}.phone`)}
                error={
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  !isDisabled ? formErrors?.pay?.[index as unknown as keyof typeof formErrors.pay]?.phone : null
                }
                disabled={isDisabled}
              />
              {isCurrentRoomLoyal && (
                <FormField
                  errorReqField={errorReqField}
                  maxLength={20}
                  placeholder={bookingLoyaltyInputText}
                  {...register(`pay.${index}.loyalty`, {
                    // TODO added isCurrentRoomLoyal condition
                    required: !isDisabled && isCurrentRoomLoyal ? bookingLoyaltyInputRequiredText : false,
                  })}
                  hasValue={!!getValues(`pay.${index}.loyalty`)}
                  name={`pay.${index}.loyalty`}
                  required
                  error={
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    !isDisabled ? formErrors?.pay?.[index as unknown as keyof typeof formErrors.pay]?.loyalty : null
                  }
                  disabled={isDisabled}
                  staticError
                />
              )}
            </Styled.Row>

            <Styled.Row>
              <Controller
                name={`pay.${index}.comment`}
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <FormTextarea
                    placeholder={commentText}
                    rows={1}
                    maxRows={5}
                    value={value}
                    disabled={isDisabled}
                    onChange={onChange}
                  />
                )}
              />
            </Styled.Row>
            {index === 0 && fields.length > 1 && !areRoomsLoyal && (
              <Styled.Row>
                <Styled.CheckboxWrapper>
                  <Styled.Checkbox onClick={handleCheckboxClick}>
                    <input
                      type="checkbox"
                      readOnly
                      checked={fillAll}
                      ref={checkboxRef}
                      name="forAllRooms"
                      id="forAllRooms"
                    />
                    <Styled.CheckboxCheck>
                      <CheckedIcon stroke="#A0072B" />
                    </Styled.CheckboxCheck>
                  </Styled.Checkbox>
                  <Styled.CheckboxLabel
                    htmlFor="forAllRooms"
                    dangerouslySetInnerHTML={{ __html: bookingFormDataCheckboxText }}
                  />
                </Styled.CheckboxWrapper>
              </Styled.Row>
            )}
          </Styled.Item>
        );
      })}
    </Styled.Container>
  );
};
