import styled from "styled-components";

import { font } from "@src/styles/mixins";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0 72px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xxlarge}) {
    gap: 0 24px;
  }
`;

const PhoneWrapper = styled.div<{ isSticky: boolean }>`
  display: flex;
  flex-direction: ${({ isSticky }) => (isSticky ? "column" : "row")};
  align-items: flex-start;
  gap: 0 32px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    flex-direction: column;
    align-items: center;
  }
`;

const PhoneLink = styled.a<{ isBurgerOpen?: boolean }>`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: inherit;
  text-decoration: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xxlarge}) {
    ${font({ size: "16", lineHeight: "24" })};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    ${font({ size: "16", lineHeight: "24" })};
    color: ${({ theme }) => theme.color.black};
    margin-bottom: ${({ isBurgerOpen }) => (isBurgerOpen ? "5px" : "0")};
  }
`;

const RequestCall = styled.button<{ isSticky: boolean }>`
  margin-left: 1px;
  margin-top: ${({ isSticky }) => (isSticky ? "5px" : "0")};
  ${font({ size: "15", lineHeight: "23" })};
  color: inherit;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    margin: 0;
    ${font({ size: "14", lineHeight: "20" })};
    color: ${({ theme }) => theme.color.red};

    &:hover {
      color: ${({ theme }) => theme.color.redHover};
    }
  }
`;

const ChangeLangWrapper = styled.div`
  display: block;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    display: none;
  }
`;

export const Styled = { Wrapper, PhoneWrapper, PhoneLink, RequestCall, ChangeLangWrapper };
