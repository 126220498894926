import styled from "styled-components";

import { font } from "@src/styles/mixins";

const BookingOkWrapper = styled.div``;

const Header = styled.h3`
  margin-bottom: 32px;
  font-family: Montserrat, sans-serif;
  ${font({ size: "28", lineHeight: "32", fontWeight: "500" })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 24px;
  }
`;

const BookingDetail = styled.p`
  ${font({ size: "20", lineHeight: "28", fontWeight: "700" })};
  margin: 12px 0;
`;

const BookingCardsContainer = styled.div`
  margin: 32px 0;
`;

const BookingOkCard = styled.div`
  display: flex;
  gap: 48px;
  margin: 15px 0 66px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-wrap: wrap;
  }
`;

const ReservationNumberText = styled.span`
  display: block;
  ${font({ size: "18", lineHeight: "26", fontWeight: "600" })};
  margin-bottom: 3px;
`;
const PriceText = styled.span`
  display: block;
  ${font({ size: "18", lineHeight: "26", fontWeight: "600" })};
  margin-bottom: 10px;
`;

const BookingOkCardImg = styled.img`
  width: 100%;
  max-width: 362px;
  height: 241px;
  object-fit: cover;
`;

const BookingOkCardDescription = styled.div`
  max-width: 887px;
`;

const BookingOkCardHeader = styled.h3`
  ${font({ size: "18", lineHeight: "26", fontWeight: "600" })};
`;

const Date = styled.p`
  ${font({ size: "13", lineHeight: "21", fontWeight: "400" })};
  margin: 12px 0;
  width: 250px;
`;

const GuestDetails = styled.p`
  ${font({ size: "13", lineHeight: "21", fontWeight: "400" })};
  width: 250px;
`;
const BreakfastDetails = styled.p`
  ${font({ size: "13", lineHeight: "21", fontWeight: "400" })};
  margin-bottom: 12px;
  width: 250px;
`;

const Detail = styled.div`
  margin-top: 32px;
`;

const DetailItemWrapper = styled.div`
  ${font({ size: "13", lineHeight: "21", fontWeight: "300" })};
  display: flex;
`;

const DetailTitle = styled.p`
  width: 100px;
  min-width: 100px;
  margin: 0 20px 8px 0;
`;
const DetailDescription = styled.p`
  max-width: 870px;
`;

const Departure = styled.p`
  ${font({ size: "13", lineHeight: "21", fontWeight: "400" })};
 margin: 32px 0 64px;
}
`;

const TotalCostDetailWrapper = styled.p`
  margin-top: 31px;
  margin-bottom: 104px;
  ${font({ size: "13", lineHeight: "21", fontWeight: "300" })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 64px;
  }
`;

const TotalCostDetail = styled.p`
  display: flex;

  gap: 20px;
  margin-bottom: 8px;
`;

const TotalCostDetailTitle = styled.p`
  width: 208px;
  ${font({ size: "13", lineHeight: "21", fontWeight: "400" })};
`;

const TotalCostDetailDescription = styled.p`
  ${font({ size: "13", lineHeight: "21", fontWeight: "700" })};
`;

const Pay = styled.p`
  ${font({ size: "20", lineHeight: "28", fontWeight: "700" })};
  width: 208px;
`;
const TotalCostValue = styled.p`
  ${font({ size: "20", lineHeight: "28", fontWeight: "700" })};
`;

const Rules = styled.p`
  margin: 32px 0;

  &:last-child {
    margin: 32px 0 80px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  }
`;

const RulesContainer = styled.div`
  p {
    &:last-child {
      margin-bottom: 80px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
      ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
    }
  }
`;

export const Styled = {
  Date,
  GuestDetails,
  BreakfastDetails,
  Pay,
  TotalCostDetailWrapper,
  Departure,
  TotalCostDetailDescription,
  TotalCostDetailTitle,
  TotalCostDetail,
  Detail,
  DetailDescription,
  DetailTitle,
  DetailItemWrapper,
  BookingCardsContainer,
  BookingOkCardHeader,
  BookingOkCardDescription,
  BookingOkCardImg,

  ReservationNumberText,
  PriceText,
  BookingOkCard,
  BookingDetail,
  Header,
  BookingOkWrapper,
  TotalCostValue,
  Rules,
  RulesContainer,
};
