import { FC, useEffect } from "react";
import Div100vh from "react-div-100vh";
import { useInView } from "react-intersection-observer";

import { useActions } from "@src/hooks";

import { Styled } from "./styles";
import { FullScreenCarousel } from "@src/components/FullScreenCarousel";
import { SpaRequestCallButton } from "../SpaRequestCallButton";
import { useGetSpaHeroSliderQuery } from "@src/store/services";

export const SpaHeroSection: FC = () => {
  const { data: banners, isLoading } = useGetSpaHeroSliderQuery();
  const { setFirstScreenIsView } = useActions();
  const { ref, inView, entry } = useInView({ threshold: 0.88 });

  useEffect(() => {
    if (entry) {
      setFirstScreenIsView(inView);
    }
  }, [inView, entry]);

  if (isLoading || !banners?.length) {
    return null;
  }

  return (
    <Div100vh>
      <Styled.Container ref={ref}>
        <FullScreenCarousel banners={banners} isSpaPage />
        <SpaRequestCallButton />
      </Styled.Container>
    </Div100vh>
  );
};
