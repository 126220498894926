import { FC } from "react";

import { Styled } from "./styles";

type ManIconsPopupTypes = {
  text: string;
};

export const ManIconsPopup: FC<ManIconsPopupTypes> = ({ text }) => {
  return <Styled.Container>{text}</Styled.Container>;
};
