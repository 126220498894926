import TextareaAutosize from "react-textarea-autosize";
import styled, { css } from "styled-components";

import { absolutePseudoEl, box, font } from "@src/styles/mixins";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
`;

const Label = styled.label`
  position: relative;
  display: block;
  width: 100%;
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.black};
`;

const Placeholder = styled.span<{ $isInputFilled?: boolean; isInputRequired?: boolean; hasInputValue?: boolean }>`
  position: absolute;
  top: 21px;
  left: 20px;
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.black};

  ${(props) =>
    props.$isInputFilled &&
    css`
      top: 10px;
      left: 20px;
      ${font({ size: "12", lineHeight: "20", fontWeight: "400" })}
      color: ${({ theme }) => theme.color.grey};
    `}

  ${(props) =>
    props.isInputRequired &&
    css`
      &::after {
        ${absolutePseudoEl};
        width: 4px;
        height: 4px;
        border-radius: 50%;
        top: 3px;
        right: -9px;
        background-color: ${({ theme }) => theme.color.red};
      }
    `}
`;

interface TextAreaProps {
  readonly $isInputFilled: boolean;
  readonly $minHeight?: number;
}

const TextArea = styled(TextareaAutosize)<TextAreaProps>`
  width: 100%;

  padding: 30px 20px 10px;
  ${font({ size: "16", lineHeight: "24" })};
  color: ${({ theme }) => theme.color.black};
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
  resize: none;
  box-sizing: border-box;

  ${(props) =>
    props.$minHeight &&
    css`
      min-height: ${props.$minHeight}px;
    `}

  ${(props) =>
    props.$isInputFilled &&
    css`
      padding: 30px 20px 10px;
      & ~ ${Placeholder} {
        top: 3px;
        top: 11px;
        left: 20px;

        ${font({ size: "12", lineHeight: "20", fontWeight: "400" })}
        color: ${({ theme }) => theme.color.grey};
      }
    `}

  &:focus {
    padding: 30px 20px 10px;

    & ~ ${Placeholder} {
      top: 3px;
      top: 11px;
      left: 20px;

      ${font({ size: "12", lineHeight: "20", fontWeight: "400" })}
      color: ${({ theme }) => theme.color.grey};
    }
  }

  &::-webkit-scrollbar-track {
    width: 4.5px;
    background-color: ${({ theme }) => theme.color.white};
  }

  &::-webkit-scrollbar {
    width: 4.5px;
    background-color: ${({ theme }) => theme.color.white};
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    max-height: 60px;
    width: 4.5px;
    background: #dbdbdb;
    border-radius: 2.5px;
  }

  &:active,
  &:focus {
    outline: none;
  }
`;

const Icon = styled.img`
  ${box(24)};
  position: absolute;
  right: 19px;
  top: 19px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    right: 19px;
    top: 19px;
  }
`;

const ErrorMessage = styled.span`
  ${font({ size: "13", lineHeight: "18", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.red};
  position: absolute;
  bottom: -20px;
`;

export const Styled = { Container, Label, Placeholder, Icon, ErrorMessage, TextArea };
