import { Gallery } from "@src/interfaces";
import { useGetBaseDataQuery, useGetGalleryQuery } from "@src/store/services";

type ReturnedData = { title: string; photoCount: number; textPhoto: string; gallery: Gallery[] };

export const useGetHotelGalleryData = (): ReturnedData => {
  const { data: baseData } = useGetBaseDataQuery();
  const { data: galleryData } = useGetGalleryQuery();

  const title = baseData ? baseData?.lang_phrases.hotel_gallery : "";
  const photoCount = galleryData ? galleryData.length : 0;
  const textPhoto = baseData ? baseData?.lang_phrases.photo_text : "";
  const gallery = galleryData ? galleryData : [];

  return { title, photoCount, textPhoto, gallery };
};
