import { FC } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useLocation } from "react-router-dom";

import { useGetMenuData } from "@src/hooks/useGetMenuData";
import { useGetRoomsQuery } from "@src/store/services";

import { RoomCardPreview } from "../RoomCardPreview";
import { Styled } from "./styles";

export const RoomsList: FC = () => {
  const { data: rooms } = useGetRoomsQuery();
  const { roomsServicesTitle } = useGetMenuData();
  const location = useLocation();
  const isMainPage = location.pathname === "/" || location.pathname === "/en";

  return (
    <Styled.Rooms>
      {rooms?.length && (
        <>
          <Styled.RoomsTitle isMainPage={isMainPage}>{roomsServicesTitle}</Styled.RoomsTitle>
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
            <Styled.Container>
              {rooms.map((room, index) => (
                <Styled.Room key={`${room.h1}/${index}`}>
                  <RoomCardPreview data={room} />
                </Styled.Room>
              ))}
            </Styled.Container>
          </AnimationOnScroll>
        </>
      )}
    </Styled.Rooms>
  );
};
