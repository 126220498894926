import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import { useAppSelector, useGetBaseData, useHover, useMediaQuery } from "@src/hooks";
import { eventsTableData } from "@src/mock/events";
import { useGetEventsQuery } from "@src/store/services";
import { theme } from "@src/theme";

import { FC, useEffect, useRef, useState } from "react";

import { Styled } from "./styles";
import { handleSetWidth } from "./utils";

type SelectedCellType = {
  x: number;
  y: number;
};

export const EventTable: FC = () => {
  const { data: tableData, isLoading } = useGetEventsQuery();
  const { eventsTableHallText, eventsTableSizeText, eventsTableAreaText, eventsTableHeightText } = useGetBaseData();
  const asideRef = useRef<HTMLDivElement | null>(null);
  const isAdaptive = useMediaQuery(theme.breakpoints.large);
  const [bodyWidth, setBodyWidth] = useState<number>(0);
  const [asideWidth, setAsideWidth] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [selectedColumn, setSelectedColumn] = useState<number | null>(null);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectedCell, setSelectedCell] = useState<SelectedCellType | null>(null);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleSetWidth();
  }, [bodyWidth, tableData]);

  useEffect(() => {
    if (asideRef.current) {
      const width = asideRef.current.clientWidth;
      setAsideWidth(width);
    }
  }, [asideRef.current, bodyWidth]);

  const handleResize = () => {
    const width = document.body.clientWidth;
    setBodyWidth(width);

    const element = scrollRef.current;
    if (element) {
      setIsScrollBarXActive(element._ps.scrollbarXActive);
    }
  };

  const handleColumnSet = (value: number) => {
    setSelectedColumn(value);
  };
  const handleColumnUnset = () => {
    setSelectedColumn(null);
  };
  const handleRowSet = (value: number) => {
    setSelectedRow(value);
  };
  const handleRowUnset = () => {
    setSelectedRow(null);
  };
  const handleCellSet = (value: SelectedCellType) => {
    setSelectedCell(value);
  };
  const handleCellUnset = () => {
    setSelectedCell(null);
  };

  const scrollRef = useRef<any>();
  const [isScrollBarXActive, setIsScrollBarXActive] = useState(false);
  const [hasReachedEnd, setIsHasReachedEnd] = useState(false);
  useEffect(() => {
    const element = scrollRef.current;
    if (element) {
      setIsScrollBarXActive(element._ps.scrollbarXActive);
    }
  }, [scrollRef.current]);

  const handleScroll = (container: any) => {
    setScrollLeft(container.scrollLeft);

    const contentWidth = container.scrollWidth;
    const containerWidth = container.clientWidth;
    const scrollRight = contentWidth - container.scrollLeft - containerWidth;

    if (scrollRight <= 10) {
      setIsHasReachedEnd(true);
    } else {
      setIsHasReachedEnd(false);
    }
  };

  if (isLoading) {
    return null;
  }

  const eventColumns = tableData?.eventColumns;

  const headerContentKeys = eventColumns?.map((key, index) => {
    return {
      id: `content-cell-${index + 1}`,
      name: key.name,
      axisX: index + 1,
    };
  });

  const headerIcons = eventColumns?.map((icon, index) => {
    return {
      id: `content-cell-${index + 1}`,
      icon: icon.icon,
    };
  });

  const eventTables = tableData?.eventTables;

  const asideRows = eventTables?.map((row, index) => {
    return {
      id: `aside-row-${index + 1}`,
      data: [
        { id: "aside-cell-1", title: row.name, xAxis: 1 },
        { id: "aside-cell-2", title: row.size, xAxis: 2 },
        { id: "aside-cell-3", title: row.area, xAxis: 3 },
        { id: "aside-cell-4", title: row.height, xAxis: 4 },
      ],
      yAxis: index + 1,
    };
  });
  const contentRows = eventTables?.map((row, index) => {
    return {
      id: `content-row-${index + 1}`,
      data: row.columns.map((column, index) => {
        return { id: `content-cell-${index + 1}`, title: column.value, xAxis: index + 1 };
      }),
      yAxis: index + 1,
    };
  });

  return (
    <Styled.Table>
      <Styled.TableContainer hasBoxShadow={isScrollBarXActive && !hasReachedEnd}>
        <Styled.Aside hasBoxShadow={scrollLeft > 5} ref={asideRef}>
          <Styled.AsideHeader>
            <Styled.HeaderCell data-id="aside-cell-1" isAside>
              {eventsTableHallText}
            </Styled.HeaderCell>
            <Styled.HeaderCell data-id="aside-cell-2" isAside>
              {eventsTableSizeText}
            </Styled.HeaderCell>
            <Styled.HeaderCell data-id="aside-cell-3" isAside>
              {eventsTableAreaText}
            </Styled.HeaderCell>
            <Styled.HeaderCell data-id="aside-cell-4" isAside>
              {eventsTableHeightText}
            </Styled.HeaderCell>
          </Styled.AsideHeader>

          <Styled.AsideContainer>
            {asideRows?.map((row, index) => {
              const isSelectedByLeftMenu = selectedColumn === row.yAxis;
              return (
                <Styled.AsideRow
                  data-id={row.id}
                  key={row.id}
                  onMouseEnter={() => {
                    handleColumnSet(row.yAxis);
                  }}
                  onMouseLeave={() => {
                    handleColumnUnset();
                  }}
                  isSelectedByLeftMenu={isSelectedByLeftMenu}>
                  {row?.data?.map((cell, index) => {
                    const sholdHaveRightAlign = index > 1;
                    const isSelectedByCell = index === 0 && selectedCell?.y === row.yAxis;
                    return (
                      <Styled.AsideCell
                        data-id={cell.id}
                        key={cell.id}
                        alignRight={sholdHaveRightAlign}
                        isSelectedByCell={isSelectedByCell}>
                        {cell.title}
                      </Styled.AsideCell>
                    );
                  })}
                </Styled.AsideRow>
              );
            })}
          </Styled.AsideContainer>
        </Styled.Aside>
        <PerfectScrollbar onScrollX={handleScroll} style={{ paddingBottom: "20px" }} ref={scrollRef}>
          <Styled.HeaderContainer asideWidth={asideWidth}>
            <Styled.ContentIconsHeader>
              {headerIcons?.map((item) => {
                return (
                  <Styled.HeaderIconContainer data-id={item.id} key={item.id}>
                    <Styled.HeaderIcon src={item.icon} alt={item.id} />
                  </Styled.HeaderIconContainer>
                );
              })}
            </Styled.ContentIconsHeader>
            <Styled.ContentHeader>
              {headerContentKeys?.map((item, index) => {
                const isSelectedByCell = selectedCell?.x === item.axisX;

                return (
                  <Styled.HeaderCell
                    key={item.id}
                    data-id={item.id}
                    onMouseEnter={() => {
                      handleRowSet(item.axisX);
                    }}
                    onMouseLeave={() => {
                      handleRowUnset();
                    }}
                    isSelectedByCell={isSelectedByCell}>
                    {item.name}
                  </Styled.HeaderCell>
                );
              })}
            </Styled.ContentHeader>
          </Styled.HeaderContainer>

          <Styled.Content asideWidth={asideWidth}>
            {contentRows?.map((row, index) => {
              const isSelectedByLeftMenu = selectedColumn === row.yAxis;
              return (
                <Styled.ContentRow data-id={row.id} key={row.id} selectedByMenu={isSelectedByLeftMenu}>
                  {row?.data?.map((cell, index) => {
                    const isSelectedByYAxis = selectedRow === cell.xAxis;
                    return (
                      <Styled.Cell
                        data-id={cell.id}
                        key={cell.id}
                        isSelectedByYAxis={isSelectedByYAxis}
                        onMouseEnter={(evt) => {
                          evt.preventDefault();
                          evt.stopPropagation();
                          handleCellSet({ x: cell.xAxis, y: row.yAxis });
                        }}
                        onMouseLeave={() => {
                          handleCellUnset();
                        }}>
                        {cell.title}
                      </Styled.Cell>
                    );
                  })}
                </Styled.ContentRow>
              );
            })}
          </Styled.Content>
        </PerfectScrollbar>
      </Styled.TableContainer>
    </Styled.Table>
  );
};
