import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { requestConferenceRoom } from "./requestConferenceRoom.actions";

interface requestConferenceRoomState {
  isLoading: boolean;
  isSuccess: boolean;
}

const initialState: requestConferenceRoomState = {
  isLoading: false,
  isSuccess: false,
};

export const requestConferenceRoomSlice = createSlice({
  name: "requestConferenceRoom",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestConferenceRoom.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(requestConferenceRoom.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isSuccess = action.payload === 200;
      })
      .addCase(requestConferenceRoom.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
      });
  },
});

export default requestConferenceRoomSlice.reducer;
