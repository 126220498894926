import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StatusMessageState {
  isOpen: boolean;
  isSuccess: boolean | undefined;
  isOnTop: boolean;
}

const initialState: StatusMessageState = {
  isOpen: false,
  isSuccess: undefined,
  isOnTop: false,
};

export const statusMessageSlice = createSlice({
  name: "statusMessage",
  initialState,
  reducers: {
    openStatusMessage: (state, action: PayloadAction<boolean>) => {
      state.isOpen = true;
      state.isSuccess = action.payload;
    },
    closeStatusMessage: (state) => {
      state.isOpen = false;
      state.isSuccess = undefined;
    },
    setIsStatusMessageOnTop: (state, action: PayloadAction<boolean>) => {
      state.isOnTop = action.payload;
    },
  },
});

export const statusMessageActions = statusMessageSlice.actions;
export const { openStatusMessage } = statusMessageSlice.actions;
export default statusMessageSlice.reducer;
