import { FC, useState } from "react";
import Modal from "react-modal";

import { useChangeSearchParams } from "@src/hooks/useChangeSearchParams";
import { popupStyles } from "@src/styles/popupStyles";

import { RoomCard } from "../RoomCard/RoomCard";

type RoomModal = {
  id: number;
  isLongStay: boolean;
};

export const RoomModal: FC<RoomModal> = ({ id, isLongStay }) => {
  const { removeQueryParams } = useChangeSearchParams();
  const [isOpen, setIsOpen] = useState(true);

  const handlePopupClose = () => {
    setIsOpen(false);
    removeQueryParams("room");
    removeQueryParams("longstay");
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={handlePopupClose}
      style={popupStyles}>
      <RoomCard id={id} handleClosePopup={handlePopupClose} isLongStay={isLongStay} />
    </Modal>
  );
};
