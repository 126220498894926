import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserData } from "@src/interfaces";
import { sendRequestRoomData } from "@src/store/services/requestConferenceRoom.api";
import { statusMessageActions } from "@src/store/slices";

export const requestConferenceRoom = createAsyncThunk<number, UserData>(
  "requestConferenceRoom",
  async (data, thunkAPI) => {
    try {
      const response = await sendRequestRoomData(data);
      const { openStatusMessage } = statusMessageActions;
      thunkAPI.dispatch(openStatusMessage(response.status === 200));
      return response.status;
    } catch (error) {
      const { openStatusMessage } = statusMessageActions;
      thunkAPI.dispatch(openStatusMessage(false));
      return thunkAPI.rejectWithValue(error);
    }
  }
);
