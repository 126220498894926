import { FC } from "react";
import Div100vh from "react-div-100vh";

import { useGetNotificationData } from "@src/hooks/useGetNotificationData";

import { Picture } from "../../_Ui";
import { Styled } from "./styles";

type NotificationType = {
  handleClose: () => void;
};

export const NotificationPopup: FC<NotificationType> = ({ handleClose }) => {
  const { title, text, img } = useGetNotificationData();

  const handleCloseBtnClick = () => {
    handleClose();
  };

  return (
    <Div100vh>
      <Styled.Container>
        <Styled.CloseButton onClick={handleCloseBtnClick} />
        <Styled.Image>{img && <Picture img={img} />}</Styled.Image>
        <Styled.Description>
          <Styled.Title dangerouslySetInnerHTML={{ __html: title }} />
          <Styled.Text dangerouslySetInnerHTML={{ __html: text }} />
        </Styled.Description>
      </Styled.Container>
    </Div100vh>
  );
};
