import styled from "styled-components";

import { box, font, width100 } from "@src/styles/mixins";

const Item = styled.li`
  display: flex;
  align-items: flex-start;
  padding: 19px 0 15px;

  &:not(:last-child) {
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.color.greyBorderOpacity50};
  }
`;
const Icon = styled.img`
  margin-right: 20px;
  min-width: 28px;
  ${box(28)}
  object-fit: contain;
  object-position: center;
`;

const TextContainer = styled.div`
  display: flex;

  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${width100};
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Title = styled.p`
  margin: 0;
  ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.black};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};
  }
`;

const Subtitle = styled.p`
  margin: 0;
  margin-top: 3px;
  ${font({ size: "12", lineHeight: "20", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.red};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};
    color: ${({ theme }) => theme.color.black};
  }
`;

export const Styled = { Item, Icon, TextContainer, Title, Subtitle };
