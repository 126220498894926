import React, { forwardRef, ReactNode } from "react";

import { Styled } from "./styles";

type IconButtonProps = {
  icon: ReactNode;
  size: "mediumMobile" | "small" | "medium" | "large";
  variant?: "primary" | "secondary" | "outline" | "transparent";
  onClick?: () => void;
  className?: string;
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, size = "medium", variant = "primary", onClick, className }, ref) => {
    return (
      <Styled.IconButton
        className={className}
        ref={ref}
        size={size}
        variant={variant}
        onClick={onClick}
        aria-label="Button">
        {icon}
      </Styled.IconButton>
    );
  }
);

IconButton.displayName = "IconButton";
