import styled, { css } from "styled-components";

import { box, fullscreenFixed } from "@src/styles/mixins";

const NavigationContainer = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${fullscreenFixed};
    z-index: 1;
    display: flex;
  }
`;

const NavigationBtnOverlay = styled.button`
  width: 50%;
  height: 100%;

  & div {
    opacity: 0.4;
  }

  &:hover div {
    opacity: 1;
  }
`;

const buttonCommon = css`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    cursor: pointer;
    ${box(72)}
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background-color: ${({ theme }) => theme.color.red};
    position: absolute;
    z-index: 1;
    top: 50%;

    display: flex;

    transform: translateY(-50%);
  }
`;

const NavigationButtonLeft = styled.div`
  ${buttonCommon};
  left: 40px;
`;

const NavigationButtonRight = styled.div`
  ${buttonCommon};
  right: 40px;

  svg {
    transform: rotate(180deg);
  }
`;

export const Styled = {
  NavigationContainer,
  NavigationBtnOverlay,
  NavigationButtonLeft,
  NavigationButtonRight,
};
