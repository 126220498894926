import { FC, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { Map, Placemark, YMaps, YMapsProps } from "react-yandex-maps";

import { useMediaQuery } from "@src/hooks";
import { theme } from "@src/theme";

import arrowMapIcon from "../../assets/images/icons/arrow_map.svg";
import parkingIcon from "../../assets/images/icons/parking.svg";
import parkingSpaIcon from "../../assets/images/icons/parking-spa.svg";
import {
  ENTRANCE_MARKER_OFFSET_DESKTOP,
  ENTRANCE_MARKER_OFFSET_MOBILE,
  MAIN_ENTRANCE_MARKER,
  MAIN_MARKER_OFFSET_DESKTOP,
  MAIN_MARKER_OFFSET_MOBILE,
  PARKING_MARKER,
  PARKING_MARKER_OFFSET_DESKTOP,
  PARKING_MARKER_OFFSET_MOBILE,
  PARKING_MARKER_SIZE_DESKTOP,
  PARKING_MARKER_SIZE_MOBILE,
} from "../Contacts/constants";

export interface IYMap extends YMapsProps {
  center: number[];
  zoom: number;
  width?: string;
  height?: string;
  icon?: string;
  isSpaPage?: boolean;
  children?: ReactNode;
}

export const YMap: FC<IYMap> = ({ icon, center, width, height, zoom, isSpaPage, ...props }) => {
  const location = useLocation();
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;
  const mapLanguage = !isEnglishVersion ? "ru_RU" : "en_US";
  const isAdaptive = useMediaQuery(theme.breakpoints.large);

  const iconImageOffsetValue = isAdaptive ? ENTRANCE_MARKER_OFFSET_MOBILE : ENTRANCE_MARKER_OFFSET_DESKTOP;
  const entranceMarkerOptions = {
    iconLayout: "default#image",
    iconImageHref: arrowMapIcon,
    iconImageSize: [50, 70],
    iconImageOffset: iconImageOffsetValue,
  };

  const parkingIconToRender = isSpaPage ? parkingSpaIcon : parkingIcon;

  const parkingImageSizeValue = isAdaptive ? PARKING_MARKER_SIZE_MOBILE : PARKING_MARKER_SIZE_DESKTOP;
  const parkingImageOffsetValue = isAdaptive ? PARKING_MARKER_OFFSET_MOBILE : PARKING_MARKER_OFFSET_DESKTOP;
  const parkingMarkerOptions = {
    iconLayout: "default#image",
    iconImageHref: parkingIconToRender,
    iconImageSize: parkingImageSizeValue,
    iconImageOffset: parkingImageOffsetValue,
  };

  const mainImageOffsetValue = isAdaptive ? MAIN_MARKER_OFFSET_MOBILE : MAIN_MARKER_OFFSET_DESKTOP;

  const mainMarkerOptions = {
    iconLayout: "default#image",
    iconImageHref: icon,
    iconImageSize: [63, 83],
    iconImageOffset: mainImageOffsetValue,
  };

  return (
    <YMaps {...props} query={{ lang: mapLanguage }}>
      <div>
        <Map width={width} height={height} state={{ center, zoom, behaviors: ["Drag"] }} options={{ b: false }}>
          <Placemark geometry={center} options={mainMarkerOptions} />
          <Placemark geometry={MAIN_ENTRANCE_MARKER} options={entranceMarkerOptions} />
          <Placemark geometry={PARKING_MARKER} options={parkingMarkerOptions} />
        </Map>
      </div>
    </YMaps>
  );
};

YMap.defaultProps = {
  zoom: 16,
  width: "100%",
  height: "100%",
};
