import { FC, useState } from "react";
import Div100vh from "react-div-100vh";
import { Pagination } from "swiper";
import type { Swiper as SwiperType } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { PaginationOptions } from "swiper/types";

import { ServicesPopupItem } from "../ServicesPopupItem";
import { useGetServicesData } from "../useGetServicesData";
import { Styled } from "./styles";

type ServicesPopupType = {
  slideId: number;
};

export const ServicesPopup: FC<ServicesPopupType> = ({ slideId }) => {
  const { services } = useGetServicesData();
  const PAGINATION_CLASS_NAME = "swiper-pagination";
  const [currentSlide, setCurrentSlide] = useState(0);

  const pagination: PaginationOptions = {
    type: "bullets",
  };

  return (
    <Div100vh>
      <Styled.Container>
        <Styled.CarouselWrapper>
          <Swiper
            modules={[Pagination]}
            pagination={pagination}
            slidesPerView={1}
            spaceBetween={10}
            loop
            onInit={(swiper: SwiperType) => {
              swiper.slideTo(slideId + 1, 0);
            }}
            onSlideChange={(swiper) => {
              setCurrentSlide(swiper.activeIndex - 1);
            }}>
            {services?.map((item, id) => (
              <SwiperSlide key={item.id}>
                <ServicesPopupItem isActive={currentSlide === id} {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={PAGINATION_CLASS_NAME} />
        </Styled.CarouselWrapper>
      </Styled.Container>
    </Div100vh>
  );
};
