import styled from "styled-components";

import { absolutePseudoEl, box, flexColumn, transformXCenter, width100 } from "@src/styles/mixins";

const Container = styled.div`
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;

  ${width100}
  ${flexColumn}
  background-color: ${({ theme }) => theme.color.red};

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: visible;

  &:after {
    ${absolutePseudoEl}
    z-index: 1;

    width: 42px;
    height: 4px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.white};

    top: -10px;
    ${transformXCenter}
  }
`;

const CarouselWrapper = styled.div`
  .swiper {
    width: calc(100vw - 40px);
    width: 100%;

    .swiper-slide {
        border-top-left-radius: 20px;
  border-top-right-radius: 20px;
      
    }

      ${flexColumn};

          &-pagination {

                bottom: 23px;


        &-bullet {
            ${box(4)};
                margin: 0 6px !important;
            background-color: ${({ theme }) => theme.color.white};
            opacity: 0.4;
            border-radius: unset;

            &-active {
                opacity: 1;
            }
        }

    }

    }

  }
`;

export const Styled = {
  Container,
  CarouselWrapper,
};
