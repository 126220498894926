import ReactTooltip from "react-tooltip";
import styled from "styled-components";

import { absolutePseudoEl, font, transformXCenter } from "@src/styles/mixins";

const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: auto;
  box-sizing: border-box;
  padding: 32px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 20px 20px 0 0;

  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.black};

  &::after {
    ${absolutePseudoEl}
    z-index: 1;

    width: 42px;
    height: 4px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.white};

    top: -10px;
    ${transformXCenter}
  }
`;

export const Styled = { Container };
