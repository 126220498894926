import { ru, enGB } from "date-fns/locale";
import { FC, useState } from "react";
import { Calendar } from "react-date-range";
import Hammer from "react-hammerjs";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";

import { ReactComponent as CloseIcon } from "@src/assets/images/icons/close.svg";
import { useGetBaseData, useMediaQuery } from "@src/hooks";
import { datepickerModalStyle } from "@src/styles/popupStyles";
import { theme } from "@src/theme";

import { navigationRenderer } from "./navigationRenderer";
import { Styled } from "./styles";

import "react-date-range/dist/styles.css";
import { format } from "date-fns";

export const DateCalendar: FC<any> = ({ isOpen, closeModal, register, setValue }) => {
  const location = useLocation();
  const { bookingChooseDateText } = useGetBaseData();
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;
  const currentLocale = !isEnglishVersion ? ru : enGB;
  const isTablet = useMediaQuery(theme.breakpoints.large);
  const [date, setDate] = useState<any>();

  const handleModalCloseClick = () => {
    closeModal();
  };

  const handleDatePickerSwipe = () => {
    closeModal();
  };

  const handleCalendarChange = (date: any) => {
    // TODO replaced date to formatedDate
    const formatedDate = format(new Date(date), "dd.MM.yyyy");
    // const formatedDate = format(new Date(date), "yyyy-MM-dd");
    setValue("date", formatedDate);
    setDate(formatedDate);
    handleModalCloseClick();
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      onRequestClose={handleModalCloseClick}
      className="datepickerModal"
      style={datepickerModalStyle}>
      <Hammer onSwipe={handleDatePickerSwipe} direction="DIRECTION_DOWN">
        <div>
          {!isTablet && (
            <Styled.ModalHeader>
              <Styled.ModalTitle> {bookingChooseDateText} </Styled.ModalTitle>
              <Styled.ModalCloseBtn onClick={handleModalCloseClick}>
                <CloseIcon />
              </Styled.ModalCloseBtn>
            </Styled.ModalHeader>
          )}
          <Styled.DatepickerWrapper>
            {isTablet && (
              <Styled.ResultsBar>
                <Styled.SelectedDate>{bookingChooseDateText}</Styled.SelectedDate>
              </Styled.ResultsBar>
            )}

            {isTablet ? (
              <Calendar
                locale={currentLocale}
                onChange={handleCalendarChange}
                months={4}
                direction="vertical"
                showDateDisplay={false}
                showMonthAndYearPickers={false}
                weekdayDisplayFormat="EEEEEE"
                monthDisplayFormat="LLLL yyyy"
                scroll={{ enabled: true }}
                minDate={new Date()}
              />
            ) : (
              <Calendar
                locale={currentLocale}
                onChange={handleCalendarChange}
                date={date}
                months={2}
                direction="horizontal"
                showDateDisplay={false}
                showMonthAndYearPickers={false}
                weekdayDisplayFormat="EEEEEE"
                monthDisplayFormat="LLLL yyyy"
                navigatorRenderer={navigationRenderer}
                minDate={new Date()}
              />
            )}
          </Styled.DatepickerWrapper>

          <input type="hidden" {...register("date")} value={date} />
        </div>
      </Hammer>
    </Modal>
  );
};
