import { Theme } from "./interfaces";

export const theme: Theme = {
  color: {
    red: "#A0072B",
    redHover: "#C20548",
    redBtnHover: "#C70A37",
    pink: "#F5E6EA",
    black: "#262324",
    blackHover: "#3E3F41",
    blackBtnHover: "#3E3F41",
    grey: "#928A8D",
    greyHover: "#928A8D",
    greyBtnHover: "#E6E6E6",
    lightGrey: "#f2f2f2",
    greyBorder: "#DBDBDB",
    lightGreyBorder: "#CDCCCC",
    greyBg: "#b0a8aa",
    greyBgHover: "#3e3f41",
    greyBgLogo: "#F5F5F5",
    greyBold: "#B7B5B5",
    greyHeader: "#F2F2F2",
    greyPale: "#B0A8AA",
    white: "#ffffff",
    bgColor: "#D7D7D7",
    bgCover: "rgba(38, 35, 36, 0.64)",
    bgPopup: "rgba(38, 35, 36, 0.8)",
    whiteOpacity50: "rgba(255, 255, 255, 0.5)",
    greyBorderOpacity50: "rgba(219, 219, 219, 0.5)",
    blackOpacity40: "rgba(38, 35, 36, 0.4)",
    green: "#61B587",
    greenSuccess: "#0F754E",
    blue: "#7FA9AE",
    blueSecondary: "#487A7B",
    blueHover: "#487A7B",
    blueSecondaryHover: "#3E5E6F",
  },

  breakpoints: {
    xSmall: "374px",
    small: "576px",
    medium: "768px",
    tabletSmall: "820px",
    large: "1024px",
    tabletMiddle: "1080px",
    tabletLarge: "1168px",
    tabletExtraLarge: "1230px",
    tabletXLarge: "1260px",
    tabletXLargeMin: "1261px",
    xlarge: "1280px",
    xxlarge: "1440px",
  },
};
