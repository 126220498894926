import { FC } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Image } from "@src/interfaces";
import { theme } from "@src/theme";

type PictureProps = {
  img: Image;
  index?: number;
  height?: number;
  width?: number;
  handleOpenGallery?: (index: number) => void;
  visibleByDefault?: boolean;
  smallGallery?: boolean;
  mediumGallery?: boolean;
  largeGallery?: boolean;
};

export const Picture: FC<PictureProps> = ({
  width,
  height,
  img,
  handleOpenGallery,
  index,
  visibleByDefault = false,
  smallGallery,
  mediumGallery,
  largeGallery,
}) => {
  if (smallGallery) {
    return (
      <picture>
        <source srcSet={img?.webp?.def3} type={img?.webp?.mime_type || img.original.mime_type} />
        <source srcSet={img?.original?.def3} type={img?.original?.mime_type || img.original.mime_type} />

        {handleOpenGallery && typeof index !== "undefined" ? (
          <LazyLoadImage
            width={width}
            height={height}
            src={img.original.original}
            onClick={() => handleOpenGallery(index)}
            alt={img.original.mime_type}
            threshold={800}
            visibleByDefault={visibleByDefault}
          />
        ) : (
          <LazyLoadImage
            threshold={800}
            visibleByDefault={visibleByDefault}
            width={width}
            height={height}
            src={img.original.original}
            alt={img.original.mime_type}
          />
        )}
      </picture>
    );
  }
  if (mediumGallery) {
    return (
      <picture>
        <source srcSet={img?.webp?.def2} type={img?.webp?.mime_type || img.original.mime_type} />
        <source srcSet={img?.original?.def2} type={img?.original?.mime_type || img.original.mime_type} />

        {handleOpenGallery && typeof index !== "undefined" ? (
          <LazyLoadImage
            width={width}
            height={height}
            src={img.original.original}
            onClick={() => handleOpenGallery(index)}
            alt={img.original.mime_type}
            threshold={800}
            visibleByDefault={visibleByDefault}
          />
        ) : (
          <LazyLoadImage
            threshold={800}
            visibleByDefault={visibleByDefault}
            width={width}
            height={height}
            src={img.original.original}
            alt={img.original.mime_type}
          />
        )}
      </picture>
    );
  }
  if (largeGallery) {
    return (
      <picture>
        <source srcSet={img?.webp?.def1} type={img?.webp?.mime_type || img.original.mime_type} />
        <source srcSet={img?.original?.def1} type={img?.original?.mime_type || img.original.mime_type} />

        {handleOpenGallery && typeof index !== "undefined" ? (
          <LazyLoadImage
            width={width}
            height={height}
            src={img.original.original}
            onClick={() => handleOpenGallery(index)}
            alt={img.original.mime_type}
            threshold={800}
            visibleByDefault={visibleByDefault}
          />
        ) : (
          <LazyLoadImage
            threshold={800}
            visibleByDefault={visibleByDefault}
            width={width}
            height={height}
            src={img.original.original}
            alt={img.original.mime_type}
          />
        )}
      </picture>
    );
  }
  return (
    <picture>
      <source
        media={`(max-width:  ${theme.breakpoints.medium})`}
        srcSet={img?.webp?.def1}
        type={img?.webp?.mime_type || img.original.mime_type}
      />
      <source
        media={`(max-width:  ${theme.breakpoints.medium})`}
        srcSet={img?.original?.def1}
        type={img?.original?.mime_type || img.original.mime_type}
      />

      <source
        media={`(max-width:  ${theme.breakpoints.xlarge}) and (min-width:  ${theme.breakpoints.medium})`}
        srcSet={img?.webp?.def2}
        type={img?.webp?.mime_type || img.original.mime_type}
      />
      <source
        media={`(max-width:  ${theme.breakpoints.xlarge}) and (min-width:  ${theme.breakpoints.medium})`}
        srcSet={img?.original?.def2}
        type={img?.original?.mime_type || img.original.mime_type}
      />

      <source
        media={`(min-width:  ${theme.breakpoints.xlarge})`}
        srcSet={img?.webp?.original || img.original.original}
        type={img?.webp?.mime_type || img.original.mime_type}
      />
      <source
        media={`(min-width:  ${theme.breakpoints.xlarge})`}
        srcSet={img.original.original}
        type={img.original.mime_type}
      />

      {handleOpenGallery && typeof index !== "undefined" ? (
        <LazyLoadImage
          width={width}
          height={height}
          src={img.original.original}
          onClick={() => handleOpenGallery(index)}
          alt={img.original.mime_type}
          threshold={800}
          visibleByDefault={visibleByDefault}
        />
      ) : (
        <LazyLoadImage
          threshold={800}
          visibleByDefault={visibleByDefault}
          width={width}
          height={height}
          src={img.original.original}
          alt={img.original.mime_type}
        />
      )}
    </picture>
  );
};
