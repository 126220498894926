import { FC } from "react";

import { useGetBaseData } from "@src/hooks";
import { Content } from "@src/interfaces";
import { declOfNum } from "@src/utils";

import { Styled } from "./styles";

type BookingOkCardProps = {
  room: Content;
};
export const BookingOkCard: FC<BookingOkCardProps> = ({ room, ...props }) => {
  const { name, img_tablet, rate, current_external_id, adult_count, child_under_5_count, child_under_11_count } = room;

  const {
    userCitizenshipLabel,
    userEmailLabel,
    phoneText,
    userComment,
    emailReservationNumber,
    emailTextPrice,
    currencyValue,
    userFullName,
    adultText,
    adultsPluralText,
    moreChildTextGenetive,
    oneChildText,
    oneChildGenetiveText,
    underChildren0Text,
    underChildren6Text,
  } = useGetBaseData();

  const formatedPrice = String(rate.price)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .split(".")[0];

  const guestText = `${
    adult_count ? `${adult_count} ${declOfNum(adult_count, [adultText, adultsPluralText, moreChildTextGenetive])}` : ""
  }${
    child_under_11_count
      ? `, ${child_under_11_count} ${declOfNum(child_under_11_count, [
          oneChildText,
          oneChildGenetiveText,
          moreChildTextGenetive,
        ])} ${underChildren6Text}`
      : ""
  }${
    child_under_5_count
      ? `, ${child_under_5_count} ${declOfNum(child_under_5_count, [
          oneChildText,
          oneChildGenetiveText,
          moreChildTextGenetive,
        ])} ${underChildren0Text}`
      : ""
  }
  
  `;

  return (
    <Styled.BookingOkWrapper>
      <Styled.ReservationNumberText>
        {emailReservationNumber} {current_external_id}
      </Styled.ReservationNumberText>

      <Styled.PriceText>
        {emailTextPrice} {formatedPrice} {currencyValue}
      </Styled.PriceText>

      <Styled.BookingOkCard {...props}>
        <Styled.BookingOkCardImg src={img_tablet.webp.def1} alt="booking_img" />

        <Styled.BookingOkCardDescription>
          <Styled.BookingOkCardHeader>{name}</Styled.BookingOkCardHeader>
          <Styled.GuestDetails>{guestText}</Styled.GuestDetails>
          <Styled.BreakfastDetails>{rate.name}</Styled.BreakfastDetails>
          <Styled.Detail>
            <Styled.DetailItemWrapper>
              <Styled.DetailTitle>{userFullName}</Styled.DetailTitle>
              <Styled.DetailDescription>{rate.buyer.name}</Styled.DetailDescription>
            </Styled.DetailItemWrapper>
            <Styled.DetailItemWrapper>
              <Styled.DetailTitle>{userCitizenshipLabel}</Styled.DetailTitle>
              <Styled.DetailDescription>{rate.buyer.citizenship}</Styled.DetailDescription>
            </Styled.DetailItemWrapper>
            <Styled.DetailItemWrapper>
              <Styled.DetailTitle>{userEmailLabel}</Styled.DetailTitle>
              <Styled.DetailDescription>{rate.buyer.email}</Styled.DetailDescription>
            </Styled.DetailItemWrapper>
            <Styled.DetailItemWrapper>
              <Styled.DetailTitle>{phoneText}</Styled.DetailTitle>
              <Styled.DetailDescription>{rate.buyer.phone}</Styled.DetailDescription>
            </Styled.DetailItemWrapper>
            <Styled.DetailItemWrapper>
              <Styled.DetailTitle>{userComment}</Styled.DetailTitle>
              <Styled.DetailDescription>{rate.buyer.comment}</Styled.DetailDescription>
            </Styled.DetailItemWrapper>
          </Styled.Detail>
        </Styled.BookingOkCardDescription>
      </Styled.BookingOkCard>
    </Styled.BookingOkWrapper>
  );
};
