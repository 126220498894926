import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

import { NotificationData } from "@src/interfaces/notifications.interfaces";

const basePath = "notifications";

const langId = Cookies.get("lang_id") || "";

export const notificationsApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ROOT_API_URL,
    prepareHeaders: (headers) => {
      headers.set("User-Lang-Id", langId);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getNotification: build.query<NotificationData, void>({
      query: () => `${basePath}/get-all`,
    }),
  }),
});

export const { useGetNotificationQuery } = notificationsApi;
