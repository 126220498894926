import styled from "styled-components";

import { width100 } from "@src/styles/mixins";

const Wrapper = styled.div`
  max-width: 1400px;
  ${width100};
  margin: 0 auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    max-width: 1184px;
  }
`;

export const Styled = { Wrapper };
