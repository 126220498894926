import { useEffect, useRef } from "react";

import { useActions } from "@src/hooks";

import { ContentWrapper, SectionTitle } from "../../_Ui";
import { OurRoomsCarousel } from "../../OurRoomsCarousel";
import { Styled } from "./style";
import { useGetLongStayRoomsData } from "./useGetLongStayRoomsData";

export const LongStayRoomsSection = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { setSecondScreenOffset } = useActions();
  const { title, rooms } = useGetLongStayRoomsData();

  useEffect(() => {
    const offsetTop = ref.current?.offsetTop;

    if (offsetTop) {
      setSecondScreenOffset(offsetTop);
    }
  }, [ref]);

  if (!rooms?.length) {
    return null;
  }

  return (
    <Styled.Container ref={ref}>
      <ContentWrapper>
        <Styled.SectionTitleWrapper>{rooms?.length && <SectionTitle text={title} />}</Styled.SectionTitleWrapper>
        {rooms && <OurRoomsCarousel rooms={rooms} isLongStay />}
      </ContentWrapper>
    </Styled.Container>
  );
};
