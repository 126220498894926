import { useState, useEffect, FC } from "react";

import { useAppSelector } from "@src/hooks";

import { ReactComponent as Shevron } from "../../assets/images/icons/shevron-up.svg";
import { Styled } from "./styles";

export const ScrollToTop: FC = () => {
  const [showBtn, setShowBtn] = useState(false);
  const { hasSpace } = useAppSelector((state) => state.topButton);
  const endOfSecondWindow = 1.8;
  const viewportHeight = window.innerHeight * endOfSecondWindow;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > viewportHeight) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);

  const handleGoToTopClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowBtn(false);
  };

  return (
    <>
      {showBtn && (
        <Styled.Button onClick={handleGoToTopClick} hasSpace={hasSpace}>
          <Shevron />
        </Styled.Button>
      )}
    </>
  );
};
