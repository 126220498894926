import React, { FC } from "react";

import { ReactComponent as DeteleIcon } from "@src/assets/images/icons/delete.svg";
import { useGetBaseData } from "@src/hooks";
import { IRate } from "@src/interfaces";

import { Styled } from "./styles";

type BookedRoomProps = {
  rate: IRate;
  onDeleteRoomClick: () => void;
  isFinishBookingForm?: boolean;
};

export const BookedRoom: FC<BookedRoomProps> = ({ isFinishBookingForm, onDeleteRoomClick, rate }) => {
  const { currencyValue } = useGetBaseData();
  const formatedPrice = String(rate.price)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .split(".")[0];
  return (
    <Styled.BookedContent>
      <Styled.BookedContentHeader>
        <Styled.RoomName>{rate.room_name}</Styled.RoomName>
        <Styled.Price>
          <p>
            {formatedPrice} {currencyValue}
          </p>
          {!isFinishBookingForm && <DeteleIcon onClick={onDeleteRoomClick} />}
        </Styled.Price>
      </Styled.BookedContentHeader>
      <Styled.RoomDescription>{rate.name}</Styled.RoomDescription>
    </Styled.BookedContent>
  );
};
