import styled from "styled-components";

import { ReactComponent as CheckedIcon } from "@src/assets/images/icons/check.svg";
import { flexCenter, flexSpaceBetween, font } from "@src/styles/mixins";

const ChooseRoomWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 314px;
  gap: 48px;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    grid-template-columns: 1fr;
  }
`;

const ChooseRooms = styled.div`
  width: 100%;
  display: grid;
  gap: 48px;
`;

const Booked = styled.div`
  width: 314px;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    display: none;
  }
`;

const LastRate = styled.div`
  display: flex;
  gap: 25px;
  padding-bottom: 25px;
  color: ${({ theme }) => theme.color.white};
  ${font({ size: "14", lineHeight: "20", fontWeight: "700" })};
`;

const Checked = styled(CheckedIcon)`
  margin-top: 5px;
`;

const RateDescription = styled.div`
  display: grid;
  gap: 20px;
  width: 235px;
`;

const RateName = styled.p`
  ${font({ size: "14", lineHeight: "20", fontWeight: "300" })};
`;

const BookedMobile = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    ${flexSpaceBetween};
    border: 1px solid ${({ theme }) => theme.color.greyBorder};
    align-items: center;
    box-shadow: 0 -4px 30px rgba(0, 0, 0, 0.15);
    padding: 8px 8px 8px 20px;
    height: 64px;

    position: fixed;
    z-index: 3;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    background: white;
    width: 100%;
    box-sizing: border-box;
  }
`;

const Price = styled.div`
  ${font({ size: "18", lineHeight: "26", fontWeight: "700" })};
  color: ${({ theme }) => theme.color.red};
`;

const AmountGuest = styled.div`
  ${font({ size: "12", lineHeight: "20", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.grey};
`;

const Loader = styled.div`
  ${flexCenter};
  width: 100%;
  height: 372px;
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
`;

const Title = styled.h2`
  ${font({ size: "28", lineHeight: "32", fontWeight: "500" })};
  margin: 40px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    ${font({ size: "20", lineHeight: "26", fontWeight: "700" })};
    margin: 20px 0;
  }
`;

const ChooseRoomsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.color.greyBorder};
  padding: 20px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    border: 1px solid ${({ theme }) => theme.color.greyBorder};
    padding: 0 0 0 24px;
    margin-top: 32px;
  }
`;

const BookingDate = styled.p`
  ${font({ size: "16", lineHeight: "24", fontWeight: "300" })};
  display: flex;
  gap: 48px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    padding: 20px;
    gap: 0;
    flex-direction: column;
    border-left: 1px solid ${({ theme }) => theme.color.greyBorder};
    ${font({ size: "13", lineHeight: "21", fontWeight: "300" })};
  }
`;

const NoRoomsText = styled.p`
  ${font({ size: "18", lineHeight: "24", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.black};
`;

export const Styled = {
  BookedMobile,
  AmountGuest,
  ChooseRoomsHeader,
  BookingDate,
  Loader,
  Price,
  RateDescription,
  Title,
  RateName,
  ChooseRoomWrapper,
  ChooseRooms,
  Booked,
  LastRate,
  Checked,
  NoRoomsText,
};
