import { FC } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Logo } from "@src/assets/images/icons/logo-footer.svg";

import { FooterBottomPart } from "./FooterBottomPart";
import { FooterTopPart } from "./FooterTopPart";
import { Styled } from "./styles";

interface FooterProps {
  noMargin?: boolean;
}

export const Footer: FC<FooterProps> = ({ noMargin }) => {
  const location = useLocation();
  const hasBorderTop = location.pathname.includes("/reservation");
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;

  const linkToMainPage = !isEnglishVersion ? "/" : "/en";

  return (
    <Styled.Footer hasBorderTop={hasBorderTop} noMargin={noMargin}>
      <Styled.FooterContainer>
        <Styled.LogoWrapper>
          <Link aria-label="Link to main page" to={linkToMainPage}>
            <Logo />
          </Link>
        </Styled.LogoWrapper>
        <Styled.FooterNavigation>
          <FooterTopPart />
          <FooterBottomPart />
        </Styled.FooterNavigation>
      </Styled.FooterContainer>
    </Styled.Footer>
  );
};
