import axios from "axios";

import { UserData } from "@src/interfaces";

export const sendWeddingRoomData = async (data: UserData) => {
  const response = await axios.post<UserData>(`${process.env.REACT_APP_ROOT_API_URL}/mail/wedding-request`, {
    ...data,
  });
  return response;
};
