import React, { FC, ReactNode, useEffect, useRef } from "react";

import { Styled } from "./styles";

type MobilePopupProps = {
  children: ReactNode;
  onClose: () => void;
};
export const MobilePopup: FC<MobilePopupProps> = ({ onClose, children }) => {
  const popupRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: TouchEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  return (
    <Styled.YourChoice ref={popupRef}>
      <Styled.CloseButton onClick={onClose} />
      <Styled.ScrollContainer>{children}</Styled.ScrollContainer>
    </Styled.YourChoice>
  );
};
