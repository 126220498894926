import styled from "styled-components";

import { flexSpaceBetween, flexStartBetween, font, width100 } from "@src/styles/mixins";

const Booked = styled.div`
  position: relative;
  ${flexStartBetween};
  flex-direction: column;
  position: sticky;
  top: 120px;
  min-width: 314px;
  max-width: 314px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
  max-height: calc(100vh - 162px);
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    min-width: 100%;
    max-width: 100%;
    border: none;
    padding: 0;
    margin: 0;

    overflow-y: auto;
    max-height: unset;
  }
`;

const BookedHeader = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 32px 32px 7px;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.white};
`;

const BookedList = styled.div`
  width: 100%;
  box-sizing: border-box;

  height: 100%;
  padding: 0 32px 0;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    width: 4.5px;
    background-color: ${({ theme }) => theme.color.white};
  }

  &::-webkit-scrollbar {
    width: 4.5px;
    background-color: ${({ theme }) => theme.color.white};
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    max-height: 60px;
    width: 4.5px;
    background: #dbdbdb;
    border-radius: 2.5px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    padding: 0;
  }
`;

const Title = styled.h3`
  ${font({ size: "20", lineHeight: "28", fontWeight: "600" })};
  margin-bottom: 12px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    display: none;
  }
`;

const SubTitle = styled.p`
  ${font({ size: "14", lineHeight: "22", fontWeight: "300" })};
  color: ${({ theme }) => theme.color.grey};
`;

const ButtonWrapper = styled.div`
  ${width100};

  box-sizing: border-box;
  position: sticky;
  top: 200px;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 16px 32px 20px;
  background-color: ${({ theme }) => theme.color.white};
`;

const TotalPrice = styled.div`
  ${width100};
  ${flexSpaceBetween};
  ${font({ size: "18", lineHeight: "26", fontWeight: "700" })};
  margin-bottom: 16px;
`;

const Price = styled.p`
  ${font({ size: "18", lineHeight: "26", fontWeight: "700" })};
  color: ${({ theme }) => theme.color.red};
`;

const RoomsAmount = styled.p`
  ${font({ size: "13", lineHeight: "21", fontWeight: "400" })};
  background-color: ${({ theme }) => theme.color.pink};
  flex-grow: 1;
  padding: 16px 20px;
  text-align: center;

  & span {
    color: ${({ theme }) => theme.color.red};
  }
`;

const Privacy = styled.div`
  ${font({ size: "13", lineHeight: "21", fontWeight: "300" })};
  margin-top: 28px;

  a {
    text-decoration: none;
  }
`;

const PrivacyLink = styled.a`
  color: ${({ theme }) => theme.color.red};
  cursor: pointer;
`;

const Pay = styled.div`
  ${font({ size: "13", lineHeight: "21", fontWeight: "300" })};
  ${flexSpaceBetween};
`;

const Card = styled.p`
  color: ${({ theme }) => theme.color.red};
`;

const Nights = styled.span`
  ${font({ size: "14", lineHeight: "22", fontWeight: "300" })};
  color: ${({ theme }) => theme.color.grey};
`;

export const Styled = {
  Pay,
  Card,
  Nights,
  Privacy,
  PrivacyLink,
  RoomsAmount,
  Price,
  TotalPrice,
  Title,
  SubTitle,
  ButtonWrapper,
  Booked,
  BookedHeader,
  BookedList,
};
