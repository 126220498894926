import React, { FC } from "react";
import { useLocation } from "react-router-dom";

import { useAppSelector, useMediaQuery } from "@src/hooks";
import { useGetMenuData } from "@src/hooks/useGetMenuData";
import { theme } from "@src/theme";

import { MenuLeftPart } from "./MenuLeftPart";
import { MenuRightPart } from "./MenuRightPart";
import { MobileMenuHeader } from "./MobileMenuHeader";
import { Styled } from "./styles";

type MenuProps = {
  menuIsShow: boolean;
};

const variants = {
  open: { x: 0 },
  closed: { x: "-200%" },
};

export const Menu: FC<MenuProps> = ({ menuIsShow }) => {
  const { links, isFetching, requestCallText, phoneNumberText } = useGetMenuData();
  const { firstScreenIsView } = useAppSelector((state) => state.headerAnimation);
  const isAdaptive = useMediaQuery(theme.breakpoints.tabletXLarge);

  const location = useLocation();
  const isMainPage = location.pathname === "/" || location.pathname === "/en";

  const firstScreenIsViewDesktop = firstScreenIsView && !isAdaptive;
  const animateValue = menuIsShow || firstScreenIsViewDesktop ? "open" : "closed";
  const showOnMobile = isAdaptive && menuIsShow;
  const showOnOtherPages = !isMainPage && !isAdaptive && !menuIsShow;
  const conditionToShow = !isAdaptive || showOnMobile || showOnOtherPages;

  if (isFetching) return null;

  return (
    <>
      {conditionToShow && (
        <Styled.Nav animate={animateValue} variants={variants} transition={{ ease: "linear", duration: 0.5 }}>
          <MobileMenuHeader />
          <Styled.MobileMenuScrollContainer>
            <MenuLeftPart links={links} />
            <MenuRightPart phoneNumber={phoneNumberText} requestCall={requestCallText} />
          </Styled.MobileMenuScrollContainer>
        </Styled.Nav>
      )}
    </>
  );
};
