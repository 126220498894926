import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

import { Events } from "@src/interfaces/event.interfaces";

const basePath = "event";

const langId = Cookies.get("lang_id") || "";

export const eventsApi = createApi({
  reducerPath: "api/events",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ROOT_API_URL,
    prepareHeaders: (headers) => {
      headers.set("User-Lang-Id", langId);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getEvents: build.query<Events, void>({
      query: () => `${basePath}/all`,
    }),
  }),
});

export const { useGetEventsQuery } = eventsApi;
