import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { subscribeEmail } from "./subscribeEmail.actions";

interface subscribeEmailState {
  isLoading: boolean;
  isSuccess: boolean;
}

const initialState: subscribeEmailState = {
  isLoading: false,
  isSuccess: false,
};

export const subscribeEmailSlice = createSlice({
  name: "subscribeEmail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(subscribeEmail.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(subscribeEmail.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isSuccess = action.payload === 200;
      })
      .addCase(subscribeEmail.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
      });
  },
});

export default subscribeEmailSlice.reducer;
