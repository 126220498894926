import { FC } from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { PaginationOptions } from "swiper/types";

import { useMediaQuery } from "@src/hooks";
import { theme } from "@src/theme";

import { IconButton, SectionTitle } from "../_Ui";
import { CenterBannerSlide } from "../CenterBannerSlide/CenterBannerSlide";
import {
  NAVIGATION_NEXT_CLASS_NAME,
  NAVIGATION_PREV_CLASS_NAME,
  PAGINATION_CLASS_NAME,
  SLIDE_DELAY,
} from "./constants";
import { renderBullet } from "./renderBullet";
import { Styled } from "./styles";
import { CenterBanner } from "@src/interfaces";
import { SpaActionSlide } from "../Spa/SpaActionSlide";
import { SpaAction } from "@src/interfaces/spa.interface";

interface CenterBannerSliderProps {
  data: CenterBanner[] | SpaAction[];
  isSpaPage?: boolean;
  title?: string;
}

export const CenterBannerSlider: FC<CenterBannerSliderProps> = ({ data, isSpaPage, title }) => {
  const pagination: PaginationOptions = {
    el: `.${PAGINATION_CLASS_NAME}`,
    type: "bullets",
    clickable: true,
    renderBullet,
  };

  const isAdaptive = useMediaQuery(theme.breakpoints.large);
  const iconBtnPrevVariant = !isAdaptive ? <Styled.NavigationChevronLeft /> : <Styled.NavigationArrowLeft />;
  const iconBtnNextVariant = !isAdaptive ? <Styled.NavigationChevronRight /> : <Styled.NavigationArrowRight />;
  const iconBtnSize = !isAdaptive ? "medium" : "mediumMobile";
  const iconVariant = !isAdaptive ? "outline" : "transparent";

  if (!data.length) {
    return null;
  }
  return (
    <Styled.CarouselWrapper isSpaPage={isSpaPage}>
      {data?.length && (
        <>
          {title && (
            <Styled.SectionTitleWrapper>
              <SectionTitle text={title} />
            </Styled.SectionTitleWrapper>
          )}

          <Swiper
            modules={[Pagination, Autoplay, Navigation]}
            navigation={{
              prevEl: `.${NAVIGATION_PREV_CLASS_NAME}`,
              nextEl: `.${NAVIGATION_NEXT_CLASS_NAME}`,
            }}
            slidesPerView={1}
            loop={true}
            pagination={pagination}
            spaceBetween={10}
            autoplay={{ delay: SLIDE_DELAY, disableOnInteraction: false, pauseOnMouseEnter: false }}
            watchSlidesProgress>
            {data?.map((banner) => (
              <SwiperSlide key={banner.id}>
                {isSpaPage ? (
                  <SpaActionSlide {...(banner as SpaAction)} />
                ) : (
                  <CenterBannerSlide {...(banner as CenterBanner)} />
                )}
              </SwiperSlide>
            ))}

            <Styled.NavigationWrapper>
              <div className={NAVIGATION_PREV_CLASS_NAME}>
                <IconButton icon={iconBtnPrevVariant} size={iconBtnSize} variant={iconVariant} />
              </div>

              <div className={PAGINATION_CLASS_NAME} />

              <div className={NAVIGATION_NEXT_CLASS_NAME}>
                <IconButton icon={iconBtnNextVariant} size={iconBtnSize} variant={iconVariant} />
              </div>
            </Styled.NavigationWrapper>
          </Swiper>
        </>
      )}
    </Styled.CarouselWrapper>
  );
};
