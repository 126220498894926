import styled from "styled-components";

import { flexCenter, flexColumn } from "@src/styles/mixins";

import { ReactComponent as ArrowIcon } from "../../../assets/images/icons/arrow.svg";

const CarouselWrapper = styled.div`
  .swiper {
    width: calc(100vw - 40px);
    ${flexColumn};

    &-slide {
      height: auto;
    }
  }
}
`;

const NavigationWrapper = styled.div`
  ${flexCenter};
  margin-top: 0;
  gap: 0 17px;
  margin-bottom: 25px;
`;

const NavigationArrowRight = styled(ArrowIcon)`
  width: 19px;
  height: 12px;
  stroke: ${({ theme }) => theme.color.black};
  transform: rotate(180deg);
  & path {
    stroke: ${({ theme }) => theme.color.black};
  }
`;

const NavigationArrowLeft = styled(ArrowIcon)`
  width: 19px;
  height: 12px;
  stroke: ${({ theme }) => theme.color.black};

  & path {
    stroke: ${({ theme }) => theme.color.black};
  }
`;

export const Styled = {
  CarouselWrapper,
  NavigationWrapper,
  NavigationArrowRight,
  NavigationArrowLeft,
};
