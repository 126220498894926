import styled from "styled-components";

import { flexVerticalCenter, font, width100 } from "@src/styles/mixins";

const FooterTop = styled.div`
  ${width100};
  ${flexVerticalCenter}
  justify-content: space-between;
  padding: 24px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.greyBorder};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    flex-direction: column;
    padding: 36px 0 20px;
  }
`;

const RequestCall = styled.div`
  ${flexVerticalCenter}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    flex-direction: column;
  }
`;
const Address = styled.p`
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    ${font({ size: "14", lineHeight: "24", fontWeight: "400" })};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    order: 3;
    ${font({ size: "13", lineHeight: "20", fontWeight: "400" })};
  }
`;

export const Styled = {
  FooterTop,
  RequestCall,
  Address,
};
