const compareReverse = (a: number, b: number) => {
  return b - a;
};

export const handleSetRowWidth = (id: string) => {
  const columns: NodeListOf<Element> = document.querySelectorAll(`[data-id="${id}"]`);
  const columnWidths: number[] = [];

  columns.forEach((item) => {
    const cell = item as HTMLElement;
    const cellWidth = cell.offsetWidth;
    columnWidths.push(cellWidth);
  });

  const maxWidth = columnWidths.sort(compareReverse)[0];
  columns.forEach((item) => {
    const cell = item as HTMLElement;
    cell.style.minWidth = `${maxWidth}px`;
  });
};

const handleSetAsideWidth = () => {
  handleSetRowWidth("aside-cell-1");
  handleSetRowWidth("aside-cell-2");
  handleSetRowWidth("aside-cell-3");
  handleSetRowWidth("aside-cell-4");
};
const handleSetContentWidth = () => {
  handleSetRowWidth("content-cell-1");
  handleSetRowWidth("content-cell-2");
  handleSetRowWidth("content-cell-3");
  handleSetRowWidth("content-cell-4");
  handleSetRowWidth("content-cell-5");
  handleSetRowWidth("content-cell-6");
};

export const handleSetWidth = () => {
  handleSetAsideWidth();
  handleSetContentWidth();
};
