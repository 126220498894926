import { FC } from "react";

import { useMediaQuery } from "@src/hooks";
import { theme } from "@src/theme";

import { Styled } from "./styles";
import { ContentWrapper, IconButton } from "@src/components/_Ui";
import { SpaAction } from "@src/interfaces/spa.interface";

export const SpaActionSlide: FC<SpaAction> = (props) => {
  const { slug, banner_spa_tablet, banner_spa_mobile, banner_spa } = props;

  const link = `/promo/${slug}`;

  const isAdaptive = useMediaQuery(theme.breakpoints.small);
  const isTablet = useMediaQuery(theme.breakpoints.tabletLarge);
  const isDesktopSize = !isAdaptive && !isTablet;
  const isTabletSize = isTablet && !isAdaptive;
  const backgroundImgSize = isDesktopSize ? banner_spa : isTabletSize ? banner_spa_tablet : banner_spa_mobile;

  const backgroundImgWebP = backgroundImgSize?.webp ? backgroundImgSize.webp.def1 : undefined;
  const backgroundImgOriginal = backgroundImgSize?.original ? backgroundImgSize.original.def1 : undefined;

  return (
    <ContentWrapper>
      {isAdaptive ? (
        <Styled.LinkWrapper href={link} target="_blank" rel="noreferrer">
          <Styled.Container
            // todo  не всегда приходят сжатые изображения
            imgWebp={backgroundImgWebP || backgroundImgSize?.original.original}
            imgJPG={backgroundImgOriginal || backgroundImgSize?.original.original}></Styled.Container>
        </Styled.LinkWrapper>
      ) : (
        <Styled.Container
          imgWebp={backgroundImgWebP || backgroundImgSize?.original.original}
          imgJPG={backgroundImgOriginal || backgroundImgSize?.original.original}>
          {link && (
            <Styled.ButtonWrapper>
              <a href={link} target="_blank" rel="noreferrer">
                <IconButton icon={<Styled.ChevronIcon />} size="large" variant="secondary" />
              </a>
            </Styled.ButtonWrapper>
          )}
        </Styled.Container>
      )}
    </ContentWrapper>
  );
};
