import { FC, useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";

import { Counter } from "@src/components";
import { useGetBaseData } from "@src/hooks";
import { GuestsListType, GuestsType } from "@src/interfaces/guest.interface";

import { Styled } from "./styles";

export type GuestCounterProps = {
  indexParent: number;
  setValue: UseFormSetValue<GuestsListType>;
  field: GuestsType;
  showDeleteButton: boolean;
  onRemoveClick: (index: number) => void;
  isReservationPage?: boolean;
  watchData: GuestsListType;
};
export const GuestCounter: FC<GuestCounterProps> = ({
  onRemoveClick,
  showDeleteButton,
  setValue,
  indexParent,
  field,
  isReservationPage,
  watchData,
}) => {
  const { roomText, deleteText } = useGetBaseData();

  const [isMaximum, setIsMaximum] = useState(false);


  const GUESTS_MAX_VALUE = 6;

  useEffect(() => {
    const guestData = watchData.guestList[indexParent].guests;

    const totalGuestCount = guestData.reduce((acc, item) => {
      return acc + item.count;
    }, 0);

    if (totalGuestCount >= GUESTS_MAX_VALUE) {
      setIsMaximum((prev) => (prev = true));
    } else {
      setIsMaximum((prev) => (prev = false));
    }
  }, [watchData]);

  const capitalizedRoomText = roomText ? roomText[0].toUpperCase() + roomText.slice(1) : "";

  return (
    <Styled.GuestCounterWrapper>
      <Styled.GuestCounterHeader>
        <Styled.GuestCounterRoomTitle>{`${capitalizedRoomText} ${indexParent + 1}`}</Styled.GuestCounterRoomTitle>
        {showDeleteButton && (
          <Styled.GuestDeleteButton onClick={() => onRemoveClick(indexParent)}>{deleteText}</Styled.GuestDeleteButton>
        )}
      </Styled.GuestCounterHeader>
      <Styled.GuestCounterContent isReservationPage={isReservationPage}>
        {field.guests.map((guest, index: number) => (
          <Styled.GuestCounterItem isReservationPage={isReservationPage} key={guest.id}>
            <Styled.GuestCounterTitleContainer>
              <Styled.GuestCounterTitle> {guest.name}</Styled.GuestCounterTitle>
              <Styled.GuestCounterText>{guest.age}</Styled.GuestCounterText>
            </Styled.GuestCounterTitleContainer>

            <Counter
              currCount={index === 0 ? 1 : 0}
              setValue={setValue}
              index={index}
              indexParent={indexParent}
              isMaximum={isMaximum}
            />
          </Styled.GuestCounterItem>
        ))}
      </Styled.GuestCounterContent>
    </Styled.GuestCounterWrapper>
  );
};
