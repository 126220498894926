import styled, { css } from "styled-components";

import { absolutePseudoEl, box, font } from "@src/styles/mixins";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
`;

const Label = styled.label`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 25px;
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.black};
`;

const Placeholder = styled.span<{ isInputFilled?: boolean; isInputRequired?: boolean; hasInputValue?: boolean }>`
  position: absolute;
  top: 21px;
  left: 20px;
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.black};

  ${(props) =>
    props.isInputFilled &&
    css`
      top: 10px;
      left: 20px;

      ${font({ size: "12", lineHeight: "20", fontWeight: "400" })}
      color: ${({ theme }) => theme.color.grey};
    `}

  ${(props) =>
    props.isInputRequired &&
    css`
      &::after {
        ${absolutePseudoEl};
        width: 4px;
        height: 4px;
        border-radius: 50%;
        top: 3px;
        right: -9px;
        background-color: ${({ theme }) => theme.color.red};
      }
    `}
`;

const Input = styled.input<{
  hasInputError?: boolean;
  isFilled?: boolean;
  hasInputIcon?: boolean;
  maxWidth?: boolean;
  hasError?: boolean;
}>`
  box-sizing: border-box;
  width: calc(100% - 40px);
  height: 64px;
  padding: 30px 20px 10px;
  border: 1px solid ${({ theme }) => theme.color.greyBorder};

  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.black};

  &:focus-within {
    padding-bottom: 11px;
  }

  &:focus-within ~ ${Placeholder} {
    top: 10px;
    left: 20px;

    ${font({ size: "12", lineHeight: "20", fontWeight: "400" })}
    color: ${({ theme }) => theme.color.grey};
  }

  ${(props) =>
    props.isFilled &&
    css`
      padding-bottom: 11px;
    `}

  ${(props) =>
    props.hasInputError &&
    css`
      border-color: ${({ theme }) => theme.color.red};
    `}
  ${(props) =>
    props.hasError &&
    css`
      border-color: ${({ theme }) => theme.color.red};
    `}

  ${(props) =>
    props.hasInputIcon &&
    css`
      box-sizing: border-box;
      width: 100%;
      padding-right: 50px;
    `}

    
          ${(props) =>
    props.maxWidth &&
    css`
      width: 100%;
    `}
`;

const Icon = styled.img<{ isSecondIcon?: boolean }>`
  cursor: pointer;
  ${box(24)};
  position: absolute;
  z-index: 2;
  right: 19px;
  top: 19px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    right: 19px;
    top: 19px;
  }

  ${(props) =>
    props.isSecondIcon &&
    css`
      right: 56px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        right: 56px;
      }
    `}
`;

const ErrorMessage = styled.span<{ staticError?: boolean }>`
  ${font({ size: "13", lineHeight: "18", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.red};
  position: absolute;
  bottom: -20px;

  ${(props) =>
    props.staticError &&
    css`
      display: block;
      position: static;
      margin-bottom: 8px;
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        display: block;
        position: static;
        margin-bottom: 8px;
      }
    `}
`;

const FlagContainer = styled.span`
  position: absolute;
  top: 22px;
  right: 21px;

  & img {
    border: 0.5px solid #dbdbdb;
    width: 22px;
    height: auto;
    object-fit: cover;
  }
`;

export const Styled = { Container, Label, Input, Placeholder, Icon, ErrorMessage, FlagContainer };
