import styled from "styled-components";

import { ReactComponent as CloseIcon } from "@src/assets/images/icons/close.svg";

const YourChoice = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -74px;
    margin-left: -160px;
    max-width: 319px;
    max-height: 300px;
    padding: 16px 0px 16px 20px;
    background: ${({ theme }) => theme.color.black};
    border-radius: 4px;
    z-index: 3;
  }
`;

const ScrollContainer = styled.div`
  max-height: 300px;
  overflow: scroll;
  padding-right: 20px;
`;

const CloseButton = styled(CloseIcon)`
  position: absolute;
  right: 9px;
  top: 5px;
  cursor: pointer;
  width: 8.84px;
`;

export const Styled = { YourChoice, CloseButton, ScrollContainer };
