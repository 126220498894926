import styled from "styled-components";

import { font } from "@src/styles/mixins";

const MobileModal = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 8px;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(38, 35, 36, 0.71);
  z-index: 99;
`;

const MobileModalFooter = styled.div`
  ${font({ size: "16", lineHeight: "24", fontWeight: "300" })};

  border-radius: 20px 20px 0 0;
  width: 100%;
  opacity: 1;
  background: ${({ theme }) => theme.color.white};
`;

const MobileModalUnderLine = styled.div`
  width: 40px;
  border: 2px solid ${({ theme }) => theme.color.white};
  border-radius: 2px;
`;

const MobileModalText = styled.div`
  max-width: 311px;
  color: ${({ theme }) => theme.color.black};
`;

export const Styled = {
  MobileModalText,
  MobileModalUnderLine,
  MobileModalFooter,
  MobileModal,
};
