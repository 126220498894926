import styled, { css } from "styled-components";

import { flexCenter, font } from "@src/styles/mixins";

const Container = styled.div`
  position: relative;
  margin-bottom: 96px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 64px;
  }
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & picture,
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const BackgroundBlack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  background-color: rgba(38, 35, 36, 0.8);
`;

const FeaturesContainer = styled.div`
  position: relative;
  z-index: 2;

  width: calc(100% - 99px);

  max-width: 1310px;
  margin: 0 auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: calc(100% - 48px);
    margin: 0 20px 37px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: calc(100% - 40px);
  }
`;

const Features = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 87px 58px;

  padding: 86px 0px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    flex-direction: column;
    gap: 73px 0px;
    padding: 61px 0px 55px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px auto 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    justify-content: flex-start;
  }

  & button {
    padding: 14px 32px;
    ${font({ size: "13", lineHeight: "20", fontWeight: "600" })};
    letter-spacing: 0.06em;
    text-transform: uppercase;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
      width: auto;
    }
  }
`;

export const Styled = {
  Container,
  Background,
  BackgroundBlack,
  FeaturesContainer,
  Features,
  ButtonContainer,
};
