import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

import { MetaTags } from "@src/interfaces";

const metaPath = "meta";

const langId = Cookies.get("lang_id") || "";

export const metaTagsApi = createApi({
  reducerPath: "metaTagsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ROOT_API_URL,
    prepareHeaders: (headers) => {
      headers.set("User-Lang-Id", langId);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getMetaData: build.query<MetaTags, string>({
      query: (payload) => ({
        url: `/${metaPath}/get-tags`,
        method: "POST",
        body: {
          url: payload,
        },
      }),
    }),
  }),
});

export const { useGetMetaDataQuery } = metaTagsApi;
