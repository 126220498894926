import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MainBanners } from "@src/interfaces";
import { InfrastructureRoomType } from "@src/interfaces/infrastructure.interfaces";
import { SpaAction, SpaMenuDetails, SpaMenus, SpaServiceDetailed, SpaServices } from "@src/interfaces/spa.interface";
import Cookies from "js-cookie";

const basePath = "spa";

const langId = Cookies.get("lang_id") || "";

export const spaApi = createApi({
  reducerPath: "api/spa",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ROOT_API_URL,
    prepareHeaders: (headers) => {
      headers.set("User-Lang-Id", langId);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getSpaHeroSlider: build.query<MainBanners[], void>({
      query: () => `/spa/banners`,
    }),
    getSpaInfrastructureRooms: build.query<InfrastructureRoomType[], void>({
      query: () => `${basePath}/infrastructure`,
    }),
    getSpaMenuData: build.query<SpaMenus[], void>({
      query: () => `${basePath}/spa-menu`,
    }),
    getSpaMenuDetailedData: build.query<SpaMenuDetails, number | null>({
      query: (activeMenu) => ({ url: `${basePath}/spa-menu/detail/${activeMenu}` }),
    }),
    getSpaServicesData: build.query<SpaServices[], void>({
      query: () => `${basePath}/services`,
    }),
    getSpaMenuServiceDetailedData: build.query<SpaServiceDetailed, number | null>({
      query: (id) => ({ url: `${basePath}/service/detail/${id}` }),
    }),
    getSpaPromoBanners: build.query<SpaAction[], void>({
      query: () => `${basePath}/actions`,
    }),
  }),
});

export const {
  useGetSpaInfrastructureRoomsQuery,
  useGetSpaMenuDataQuery,
  useGetSpaMenuDetailedDataQuery,
  useGetSpaServicesDataQuery,
  useGetSpaMenuServiceDetailedDataQuery,
  useGetSpaPromoBannersQuery,
  useGetSpaHeroSliderQuery,
} = spaApi;
