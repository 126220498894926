import { FC } from "react";

import { SocialNetworks } from "@src/interfaces";

import { Styled } from "./styles";

type SocialLinkProps = Pick<SocialNetworks, "icon" | "name" | "link">;

export const SocialLink: FC<SocialLinkProps> = ({ icon, name, link }) => {
  return (
    <Styled.Link href={link} target="_blank">
      <Styled.SocialIcon src={icon} alt={name} width="20px" height="20px" />
    </Styled.Link>
  );
};
