import { useSearchParams } from "react-router-dom";

export const useChangeSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getSearchParam = (paramName: string) => {
    const param = searchParams.get(paramName);
    return param;
  };

  const checkParams = (paramName: string) => {
    const param = searchParams.get(paramName);
    return Boolean(param);
  };

  const setQueryParams = (id: number, isLongStay?: boolean, isReservation?: boolean) => {
    if (isLongStay) {
      setSearchParams({ room: `${id}`, longstay: "true" });
    } else if (isReservation) {
      setSearchParams({ room: `${id}`, reservation: "true" });
    } else {
      setSearchParams({ room: `${id}` });
    }
  };

  const removeQueryParams = (paramName: string) => {
    const param = searchParams.get(paramName);

    if (param) {
      searchParams.delete(paramName);
      setSearchParams(searchParams);
    }
  };

  return { getSearchParam, checkParams, setQueryParams, removeQueryParams };
};
