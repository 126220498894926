import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import Div100vh from "react-div-100vh";
import Hammer from "react-hammerjs";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import { useActions, useGetBaseData, useMediaQuery } from "@src/hooks";
import { theme } from "@src/theme";

import { Button, FormInput } from "../_Ui";
import { Styled } from "./styles";
import { RequestFormType, handleXssFormat } from "./utils";

type RequestCallFormProps = {
  handleClosePopup: () => void;
  itemId?: number;
  spaServiceName?: string;
};

export const RequestCallForm: FC<RequestCallFormProps> = ({ handleClosePopup, itemId, spaServiceName }) => {
  const {
    requestCallText,
    validationName,
    validationEmail,
    validationPhone,
    userNameLabel,
    userEmailLabel,
    phoneText,
    sendText,
    eventIncorrectPhoneError,
    requestCallIncorrectValue,
    rulesCallStartText,
    rulesSecondText,
    rulesPrivacyPolicyLink,
    rulesPrivacyPolicyFile,
    rulesPersonalDataLink,
    rulesPersonalDataFile,
  } = useGetBaseData();
  const { requestCall } = useActions();
  const isMobile = useMediaQuery(theme.breakpoints.medium);

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(validationName)
      .matches(/^[A-Za-zа-яА-Я\s-]+$/, requestCallIncorrectValue),
    phone: yup.string().required(validationPhone).min(18, eventIncorrectPhoneError),
    email: yup.string().email(validationEmail),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    resetField,
  } = useForm<RequestFormType>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const handleFormSubmit: SubmitHandler<RequestFormType> = async (data) => {
    if (itemId) {
      data.long_stay = itemId;
    }

    if (spaServiceName) {
      data.spa_service_name = spaServiceName;
    }
    handleXssFormat(data);
    requestCall(data);
    reset();
    handleClosePopup();
  };

  const handleCloseBtnClick = () => {
    handleClosePopup();
  };

  const handleCallPopupSwipe = () => {
    if (isMobile) {
      handleClosePopup();
    }
  };

  return (
    <Div100vh>
      <Hammer onSwipe={handleCallPopupSwipe} direction="DIRECTION_DOWN">
        <Styled.Container>
          <Styled.ScrollContainer>
            <Styled.CloseButton onClick={handleCloseBtnClick}></Styled.CloseButton>
            <Styled.Heading>{requestCallText}</Styled.Heading>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <Styled.InputsContainer>
                <Styled.InputContainer>
                  <FormInput
                    {...register("name")}
                    placeholder={userNameLabel}
                    error={errors.name}
                    inputName="name"
                    resetField={resetField}
                    required
                    maxWidth
                  />
                </Styled.InputContainer>
                <Styled.InputContainer>
                  <FormInput
                    isPhone
                    {...register("phone")}
                    placeholder={phoneText}
                    error={errors.phone}
                    inputName="phone"
                    resetField={resetField}
                    required
                    maxWidth
                  />
                </Styled.InputContainer>

                <Styled.InputContainer>
                  <FormInput
                    {...register("email")}
                    placeholder={userEmailLabel}
                    error={errors.email}
                    resetField={resetField}
                    inputName="email"
                    maxWidth
                  />
                </Styled.InputContainer>
              </Styled.InputsContainer>
              <Styled.Privacy>
                {rulesCallStartText}{" "}
                <Styled.PrivacyLink
                  href={process.env.REACT_APP_ROOT_URL + rulesPrivacyPolicyFile}
                  target="_blank"
                  rel="noreferrer">
                  {rulesPrivacyPolicyLink}
                </Styled.PrivacyLink>{" "}
                {rulesSecondText}{" "}
                <Styled.PrivacyLink
                  href={process.env.REACT_APP_ROOT_URL + rulesPersonalDataFile}
                  target="_blank"
                  rel="noreferrer">
                  {rulesPersonalDataLink}
                </Styled.PrivacyLink>
              </Styled.Privacy>

              <Button height="64px" mt="32px" title={sendText} primary type="submit" />
            </form>
          </Styled.ScrollContainer>
        </Styled.Container>
      </Hammer>
    </Div100vh>
  );
};
