import styled from "styled-components";

import { absolutePseudoEl, box, flexCenter, font } from "@src/styles/mixins";

const Card = styled.div`
  position: relative;
  height: 100%;
  width: 100vw;
  background-color: ${({ theme }) => theme.color.white};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    position: fixed;
    width: calc(100% - 56px);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
    max-width: 1020px;
    margin: 28px auto;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  z-index: 3;

  right: 8px;
  top: 10px;
  ${box(40)}
  ${flexCenter}

  background-color: ${({ theme }) => theme.color.white};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${box(32)}
    right: 13px;
    top: 12px;
    background-color: transparent;
  }

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    ${absolutePseudoEl};
    left: 18px;
    height: 17px;
    width: 2px;
    background-color: ${({ theme }) => theme.color.black};

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      left: 15px;

      background-color: ${({ theme }) => theme.color.greyBg};
    }
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const ScrollContainer = styled.div`
  position: relative;
  overflow-y: auto;
  height: calc(100% - 130px);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xSmall}) {
    height: calc(100% - 88px);
  }
`;

const DescriptionContainer = styled.div`
  padding: 30px 20px 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 30px 48px 0;
  }
`;

const RoomName = styled.h2`
  margin-bottom: 26px;
  ${font({ size: "20", lineHeight: "26", fontWeight: "700" })};
  color: ${({ theme }) => theme.color.black};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 0;
    margin-bottom: 40px;
  }
`;

const H2 = styled.h2`
  margin-bottom: 18px;
  ${font({ size: "16", lineHeight: "24", fontWeight: "700" })};
  color: ${({ theme }) => theme.color.black};
`;

const Text = styled.p`
  margin-bottom: 47px;
  ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.black};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 63px;
    ${font({ size: "16", lineHeight: "24", fontWeight: "600" })};
  }
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  margin-bottom: 46px;
`;

export const Styled = {
  Card,
  CloseButton,
  ScrollContainer,
  DescriptionContainer,
  RoomName,
  H2,
  Text,
  List,
};
