import styled from "styled-components";

import { flexVerticalCenter } from "@src/styles/mixins";

const Container = styled.div`
  ${flexVerticalCenter}
`;

const Title = styled.p`
  margin-right: 49px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: none;
  }
`;

const Links = styled.div`
  ${flexVerticalCenter}

  a:not(:last-child) {
    margin-right: 12px;
  }
`;

export const Styled = { Container, Title, Links };
