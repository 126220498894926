import { FC, useEffect, useState } from "react";
import Lottie from "react-lottie";

import { Services } from "@src/interfaces";

import { Styled } from "./styles";

type ServiceActiveType = {
  isActive?: boolean;
};

type ServicesPopupItemType = Pick<Services, "description" | "animation" | "name">;

type ServicesPopupItemProps = ServicesPopupItemType & ServiceActiveType;

export const ServicesPopupItem: FC<ServicesPopupItemProps> = ({ name, description, animation, isActive }) => {
  const formattedAnimation = JSON.parse(animation);

  const [isPaused, setIsPaused] = useState(true);

  useEffect(() => {
    setIsPaused(false);

    return () => {
      setIsPaused(true);
    };
  }, [isActive]);

  const lottieOptions = {
    loop: false,
    repeat: false,
    autoplay: false,
    animationData: formattedAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Styled.ServicesCard>
      <Styled.ServicesIconWrap>
        {animation && (
          <Styled.AnimationWrapper>
            <Lottie options={lottieOptions} isPaused={isPaused} />
          </Styled.AnimationWrapper>
        )}
      </Styled.ServicesIconWrap>
      <Styled.ServicesName>{name}</Styled.ServicesName>
      <Styled.ServicesDescription dangerouslySetInnerHTML={{ __html: description }}></Styled.ServicesDescription>
    </Styled.ServicesCard>
  );
};
