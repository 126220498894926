import { flexCenter } from "@src/styles/mixins";
import styled from "styled-components";

const Rooms = styled.section`
  position: relative;
  width: calc(100% - 40px);

  gap: 48px;

  max-width: 1400px;
  margin: 0 auto 96px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    max-width: 1184px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 0 20px 64px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
`;

const SectionTitleWrapper = styled.div`
  padding-bottom: 64px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-bottom: 27px;
  }
`;

export const Styled = {
  Rooms,
  SectionTitleWrapper,
  Container,
};
