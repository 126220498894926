import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

import { CenterBanner, Gallery, MainBanners } from "@src/interfaces";

const basePath = "banners";

const langId = Cookies.get("lang_id") || "";

export const bannersApi = createApi({
  reducerPath: "bannersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ROOT_API_URL,
    prepareHeaders: (headers) => {
      headers.set("User-Lang-Id", langId);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getMainBanners: build.query<MainBanners[], void>({
      query: () => `${basePath}/get-main-banners`,
    }),
    getCenterBanner: build.query<CenterBanner, void>({
      query: () => `${basePath}/get-center-banner`,
    }),
    getCenterBannerSlider: build.query<CenterBanner[], void>({
      query: () => `${basePath}/get-center-banner`,
    }),
    getGallery: build.query<Gallery[], void>({
      query: () => `${basePath}/get-galleries`,
    }),
  }),
});

export const { useGetMainBannersQuery, useGetCenterBannerQuery, useGetCenterBannerSliderQuery, useGetGalleryQuery } =
  bannersApi;
