import { FC, useState } from "react";
import { Styled } from "./styles";
import { SpaMenu, SpaMenus } from "@src/interfaces/spa.interface";
import { ReactComponent as Shevron } from "@src/assets/images/icons/shevron-accordeon.svg";

interface SpaAccordeonProps {
  data: SpaMenus;
  activeMenu: number | null;
  onOpen: (id: number) => void;
  isOpen: boolean;
}

export const SpaAccordeon: FC<SpaAccordeonProps> = ({ data, onOpen, activeMenu }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { name, services, icon } = data;

  const handleAccordeonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = (id: number) => {
    onOpen(id);
  };

  return (
    <Styled.Container>
      <Styled.Button onClick={handleAccordeonClick} isOpen={isOpen}>
        <Styled.ButtonImage src={process.env.REACT_APP_URL_IMAGES + icon} />
        {name}
        <Shevron />
      </Styled.Button>
      <Styled.Content isOpen={isOpen}>
        {services.map((link, index) => {
          const { name, id } = link;
          const isLinkActive = isOpen && id === activeMenu;
          return (
            <Styled.Link
              onClick={() => {
                handleLinkClick(id);
              }}
              isActive={isLinkActive}
              key={`menu-link-${index}`}>
              {name}
            </Styled.Link>
          );
        })}
      </Styled.Content>
    </Styled.Container>
  );
};
