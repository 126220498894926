import { MarkedValue, FillValue, Image, Room } from "@src/interfaces";
import { useGetRoomByIdQuery } from "@src/store/services";

type ReturnedData = {
  data?: Room;
  isFetching?: boolean;
  id?: number;
  name: string;
  h1: string;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  price?: number;
  date: string;
  fillValues?: FillValue[];
  markedValues?: MarkedValue[];
  photos?: Image[];
  description: string;
};

export const useGetRoomData = (slug: number): ReturnedData => {
  const { data, isFetching } = useGetRoomByIdQuery(slug);

  const id = data && data?.id;
  const name = data ? data?.name : "";
  const h1 = data ? data?.h1 : "";
  const metaTitle = data ? data?.meta_title : "";
  const metaDescription = data ? data?.meta_description : "";
  const metaKeywords = data ? data?.meta_keywords : "";
  const price = data && data?.price;
  const date = data ? data?.on_date : "";
  const fillValues = data && data?.fill_values;
  const photos = data && data?.photos;
  const description = data ? data?.description : "";
  const markedValues = data && data?.marked_values;

  return {
    data,
    isFetching,
    id,
    name,
    h1,
    metaTitle,
    metaDescription,
    metaKeywords,
    price,
    date,
    fillValues,
    markedValues,
    photos,
    description,
  };
};
