import styled, { css, keyframes } from "styled-components";

import { absolutePseudoEl, box, font, height100, width100, widthHeight100 } from "@src/styles/mixins";

import { SLIDE_DELAY } from "./constants";

const widthAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  
  100% {
    transform: translateX(0);
  }
`;

const CarouselText = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 190px;

  ${width100};
  margin: 0 auto;

  font-family: Montserrat, sans-serif;
  ${font({ size: "86", lineHeight: "96", fontWeight: "500" })};
  color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    ${font({ size: "40", lineHeight: "54", fontWeight: "500" })};
    max-width: 1184px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: calc(100% - 40px);
    margin-left: 20px;
    bottom: 287px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: calc(100% - 40px);
    margin-left: 20px;
    ${font({ size: "26", lineHeight: "36", fontWeight: "500" })};
    bottom: 287px;
  }
`;

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  ${widthHeight100};
`;

const CarouselWrapper = styled.div<{
  isSpaPage?: boolean;
}>`
  ${widthHeight100};

  .swiper {
    ${height100}
  }

  /* .swiper-slide-active ${CarouselText} {
    animation: widthAnimation 1.5s;
  } */

  .swiper {
    &-pagination {
      position: absolute;
      left: 50%;
      bottom: 32px;
      z-index: 1;
      display: flex;
      align-items: center;
      gap: 0 2px;
      max-width: 1400px;
      width: calc(100% - 40px);
      height: 6px;
      transition: 0.3s opacity;
      transform: translateX(-50%) translate3d(0, 0, 0);

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
        max-width: 1184px;
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        justify-content: center;
        gap: 0 6px;
        bottom: 252px;
      }

      &-clickable {
        .swiper-pagination-bullet {
          cursor: pointer;
        }
      }

      &-bullet {
        position: relative;
        ${widthHeight100};
        overflow: hidden;
        background-color: ${({ theme }) => theme.color.white};
        opacity: 0.4;
        border-radius: 0;
        margin: 0 !important;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
          ${box(4)}
        }

        &-active {
          opacity: 1;

          &:before {
            background-color: ${({ theme }) => theme.color.red};
            animation: ${widthAnimation} ${SLIDE_DELAY}ms ease-in-out forwards;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
              background-color: ${({ theme }) => theme.color.white};
              animation: unset;
            }
          }
        }

        &:before {
          ${absolutePseudoEl};
          display: block;
          ${widthHeight100};
        }
      }

      &-lock {
        display: none;
      }
    }
  }

  ${({ isSpaPage }) =>
    isSpaPage &&
    css`
      .swiper-pagination-bullet-active::before {
        background-color: ${({ theme }) => theme.color.blue};
      }

      .swiper-pagination {
        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
          gap: 0 12px;
          bottom: 108px;
        }
      }

      ${CarouselText} {
        font-weight: 400;
        bottom: 190px;
        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
          bottom: 138px;
          ${font({ size: "26", lineHeight: "36", fontWeight: "400" })};
        }
      }
    `};
`;

const CarouselImgWrapper = styled.div`
  position: relative;
  ${widthHeight100};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &:before {
    ${absolutePseudoEl};
    left: 0;
    top: 0;
    ${widthHeight100};
    background-color: ${({ theme }) => theme.color.bgCover};
  }
`;

const CarouselTextWrapper = styled.div`
  max-width: 1400px;
  width: calc(100% - 40px);
  margin: 0 auto;
  box-sizing: border-box;
  padding-right: 15%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    max-width: 1184px;
    position: relative;
    margin-left: 4%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    margin-left: 3%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletExtraLarge}) {
    margin: 0 auto;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 100%;
    padding: 0;
  }
`;

export const Styled = { Container, CarouselWrapper, CarouselImgWrapper, CarouselTextWrapper, CarouselText };
