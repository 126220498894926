import { flexCenter, font } from "@src/styles/mixins";
import styled from "styled-components";

const Container = styled.div`
  width: calc(100% - 40px);

  max-width: 1400px;
  margin: 0 auto 96px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    max-width: 1184px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 0 20px 64px;
  }
`;

const SectionTitleWrapper = styled.div`
  padding: 64px 0 64px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 64px 0 32px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
  }
`;

const ImgContainer = styled.div`
  box-sizing: border-box;
  ${flexCenter};
  max-width: 435px;
  min-height: 279px;
  padding: 62px 108px;
  background-color: ${({ theme }) => theme.color.blue};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    width: 100%;
    max-width: unset;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    min-height: 223px;
    padding: 52px 83px;
  }

  svg {
    width: 220px;
    height: 156px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      width: 170px;
      height: 120px;
    }
  }
`;

const Text = styled.div`
  p {
    ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};
    color: ${({ theme }) => theme.color.black};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
    }

    &:not(:last-child) {
      margin-bottom: 24px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        margin-bottom: 22px;
      }
    }
  }
`;

export const Styled = {
  Container,
  SectionTitleWrapper,
  Content,
  ImgContainer,
  Text,
};
