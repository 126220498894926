export const ROUTES = {
  CONTACTS: "contacts",
  RESERVATION: "reservation",
  SELECT_ROOM: "roomSelect",
  GUESTS_COUNTER: "guests-counter",
  BOOKED: "booked-ok",
  CANCELLATION: "cancellation",
  RESERVATION_PAY: "reservation-pay",
  ROOMS: "rooms-and-services",
  LONG_STAY: "long-stay",
  BOOKED_MOBILE: "booked-mobile",
  EVENTS: "events",
  INFRASTRUCTURE: "infrastructure",
  WEDDINGS: "weddings",
  PROMO: "promo",
  SPA: "spa",
  BLOG: "blog",
};

export const PROMO_ACTION_ROUTE = `${ROUTES.PROMO}/:articleSlug`;

export const SPA_SERVICE_ROUTE = `${ROUTES.SPA}/service/:serviceId`;
export const BLOG_ARTICLE_ROUTE = `${ROUTES.BLOG}/:articleSlug`;

export const RESERVATION_ROUTES = {
  RESERVATION_SELECT_ROOM: `/${ROUTES.RESERVATION}/${ROUTES.SELECT_ROOM}`,
  RESERVATION_GUESTS_COUNTER: `/${ROUTES.RESERVATION}/${ROUTES.GUESTS_COUNTER}`,
  RESERVATION_SELECT_PAY: `/${ROUTES.RESERVATION}/${ROUTES.RESERVATION_PAY}`,
  BOOKED: `/${ROUTES.RESERVATION}/${ROUTES.BOOKED}`,
  CANCELLATION: `/${ROUTES.RESERVATION}/${ROUTES.CANCELLATION}`,
  BOOKED_MOBILE: `/${ROUTES.RESERVATION}/${ROUTES.BOOKED_MOBILE}`,
};
