import styled from "styled-components";

import { flexCenter, flexSpaceBetween, font } from "@src/styles/mixins";

const Calendar = styled.div`
  display: flex;
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};
  gap: 48px;
  margin-bottom: 48px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${flexCenter};
    flex-wrap: wrap;
    padding: 32px 0 0;
    margin-bottom: 24px;
    gap: 8px;
    width: 100%;
  }
`;

const Date = styled.div`
  ${flexSpaceBetween};
  width: 435px;
  height: 64px;

  border: 1px solid ${({ theme }) => theme.color.greyBorder};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 0;
  }

  input {
    height: 22px;
  }
`;

const AddRoom = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: calc(100% + 48px);
    box-sizing: border-box;
    margin-left: -24px;
    padding: 0px 24px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.color.greyBorder};
    margin-bottom: 32px;
  }
`;

const NoMoreRoomsText = styled.span`
  display: block;
  text-align: center;
  ${font({ size: "14", lineHeight: "22", fontWeight: "600" })};
  color: ${({ theme }) => theme.color.greyBold};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 24px;
  }
`;

export const Styled = { Date, Calendar, AddRoom, NoMoreRoomsText };
