import { differenceInCalendarYears, format, parseISO } from "date-fns";
import { enGB, ru } from "date-fns/locale";
import Cookies from "js-cookie";
import Modal from "react-modal";
import { FC, useState } from "react";
import { useNavigate, useLocation } from "react-router";

import { Button } from "@src/components/_Ui";
import { RESERVATION_ROUTES } from "@src/constants/_routes.constants";
import { useActions, useGetBaseData } from "@src/hooks";
import { useGetMenuData } from "@src/hooks/useGetMenuData";

import { Styled } from "./styles";
import { popupStyles } from "@src/styles/popupStyles";
import { RequestCallForm } from "@src/components/RequestCallForm";

type LongStayCardFooterProps = {
  id: number;
};

export const LongStayCardFooter: FC<LongStayCardFooterProps> = ({ id }) => {
  const { requestCallText } = useGetMenuData();
  const [isOpen, setIsOpen] = useState(false);
  const { openPopup, closePopup } = useActions();
  const { bookBtnPhrase } = useGetMenuData();

  const handleRequestRoomClick = () => {
    setIsOpen((prevState) => !prevState);
    openPopup();
  };

  const handleClosePopupClick = () => {
    setIsOpen(false);
    closePopup();
  };

  return (
    <>
      <Styled.CardFooter>
        <Styled.ButtonWrapper>
          <Button title={requestCallText} primary onClick={handleRequestRoomClick} />
        </Styled.ButtonWrapper>
      </Styled.CardFooter>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={handleClosePopupClick}
        style={popupStyles}>
        <RequestCallForm handleClosePopup={handleClosePopupClick} itemId={id} />
      </Modal>
    </>
  );
};
