import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserData } from "@src/interfaces";
import { sendWeddingRoomData } from "@src/store/services/requestWeddingRoom.api";
import { statusMessageActions } from "@src/store/slices";

export const requestWeddingRoom = createAsyncThunk<number, UserData>("requestWeddingRoom", async (data, thunkAPI) => {
  try {
    const response = await sendWeddingRoomData(data);
    const { openStatusMessage } = statusMessageActions;
    thunkAPI.dispatch(openStatusMessage(response.status === 200));
    return response.status;
  } catch (error) {
    const { openStatusMessage } = statusMessageActions;
    thunkAPI.dispatch(openStatusMessage(false));
    return thunkAPI.rejectWithValue(error);
  }
});
