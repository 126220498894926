import styled from "styled-components";

import { box, flexColumnCenter, font } from "@src/styles/mixins";

const ServicesName = styled.h3`
  width: 100%;
  ${font({ size: "18", lineHeight: "26", fontWeight: "700" })};
  color: ${({ theme }) => theme.color.white};
  margin-bottom: 15px;
`;
const ServicesDescription = styled.p`
  width: 100%;
  ${font({ size: "16", lineHeight: "24" })};
  color: ${({ theme }) => theme.color.white};

  & a {
    ${font({ size: "16", lineHeight: "24" })};
    font-weight: 600;
    color: ${({ theme }) => theme.color.white};
    text-decoration: none;
  }
`;

const ServicesCard = styled.div`
  box-sizing: border-box;

  position: relative;
  ${flexColumnCenter};

  padding: 32px 32px 74px;

  width: 100%;
  min-height: 252px;
  max-height: 88vh;
  height: auto;

  background-color: ${({ theme }) => theme.color.red};
  color: ${({ theme }) => theme.color.white};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const ServicesIconWrap = styled.div`
  ${box(120)}
  margin-bottom: 48px;
`;

const AnimationWrapper = styled.div``;

const ServicesImageWithHover = styled.img`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    ${box(120)}
    object-fit: contain;
    object-position: center;
  }
`;

export const Styled = {
  ServicesCard,
  ServicesIconWrap,
  ServicesName,
  ServicesDescription,
  ServicesImageWithHover,
  AnimationWrapper,
};
