import Cookies from "js-cookie";
import { useState, useEffect, FC } from "react";
import Modal from "react-modal";

import { useGetNotificationData } from "@src/hooks/useGetNotificationData";
import { NotificationPopupStyles } from "@src/styles/popupStyles";

import { NotificationPopup } from "./NotificationPopup";

export const Notification: FC = () => {
  const { data } = useGetNotificationData();

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (!Cookies.get("notification-shown") && data) {
      setIsOpen(true);
      Cookies.set("notification-shown", "true");
    }
  }, [data]);

  const handlePopupClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      {data && (
        <Modal
          isOpen={isOpen}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          onRequestClose={handlePopupClose}
          style={NotificationPopupStyles}>
          <NotificationPopup handleClose={handlePopupClose} />
        </Modal>
      )}
    </>
  );
};
