import styled from "styled-components";

import { box, flexCenter, font, fullscreenFixed } from "@src/styles/mixins";

const Preloader = styled.div`
  ${fullscreenFixed};
  z-index: 4;
  ${flexCenter}
  background-color: ${({ theme }) => theme.color.black};
  overflow: hidden;
  transition: all 2s;
`;

const LoadingScale = styled.div`
  ${box(160)}
  ${flexCenter}
  ${font({ size: "26", lineHeight: "36", fontWeight: "500" })};
  color: ${({ theme }) => theme.color.white};
`;

export const Styled = {
  Preloader,
  LoadingScale,
};
