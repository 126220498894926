import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

import { InfrastructureRoomType } from "@src/interfaces/infrastructure.interfaces";

const basePath = "infrastructure";

const langId = Cookies.get("lang_id") || "";

export const infrastructureApi = createApi({
  reducerPath: "api/infrastructure",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ROOT_API_URL,
    prepareHeaders: (headers) => {
      headers.set("User-Lang-Id", langId);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getInfrastructureRooms: build.query<InfrastructureRoomType[], void>({
      query: () => `${basePath}/get-list`,
    }),
  }),
});

export const { useGetInfrastructureRoomsQuery } = infrastructureApi;
