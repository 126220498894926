export const MAP_MARKER = [51.6684, 39.2055];

export const MAP_MARKER_DESKTOP = [51.6687, 39.2054];
export const MAP_MARKER_MOBILE = [51.669, 39.2062];

export const MAP_ZOOM_DESKTOP = 14.75;
export const MAP_ZOOM_MOBILE = 14.72;

export const PARKING_MARKER = [51.6675, 39.207];
export const MAIN_ENTRANCE_MARKER = [51.667, 39.203];

export const ENTRANCE_MARKER_OFFSET_MOBILE = [-7, -45];
export const ENTRANCE_MARKER_OFFSET_DESKTOP = [0, -55];

export const PARKING_MARKER_SIZE_MOBILE = [25, 50];
export const PARKING_MARKER_SIZE_DESKTOP = [25, 50];
export const PARKING_MARKER_OFFSET_MOBILE = [17, -40];
export const PARKING_MARKER_OFFSET_DESKTOP = [17, -45];

export const MAIN_MARKER_OFFSET_MOBILE = [-35, -60];
export const MAIN_MARKER_OFFSET_DESKTOP = [-18, -70];
