import { format } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as ArrowIcon } from "@src/assets/images/icons/arrow.svg";
import { Booked, Button } from "@src/components";
import { useGetOurRoomsData } from "@src/components/OurRoomsSection/useGetOurRoomsData";
import { FROM_TO_DATE_FORMAT, RESERVATION_ROUTES } from "@src/constants";
import { useActions, useAppSelector, useGetBaseData } from "@src/hooks";
import { IRate } from "@src/interfaces";
import { getDates } from "@src/store/slices/datepicker/datepicker.selector";
import { declOfNum } from "@src/utils";

import { Styled } from "./styles";

export const BookedMobile = () => {
  const location = useLocation();
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;
  const navigate = useNavigate();
  const { proposalS, pretextFor, guestsDecl, roomsDecl } = useGetBaseData();
  const { yourBookedText } = useGetOurRoomsData();
  const { setBookedFormData, setSelectedRooms, setAmountRates, setRateCounter } = useActions();
  const { guestsData, selectedRooms, amountRates, rateCounter } = useAppSelector((state) => state.bookingData);
  const { guestsNumber, roomsNumber } = useAppSelector(getDates);
  const guestsTextValue =
    guestsNumber > 0
      ? ` ${roomsNumber} ${declOfNum(guestsNumber, roomsDecl)}, ${guestsNumber} ${declOfNum(guestsNumber, guestsDecl)}`
      : "";

  const amountRoomsInitial = guestsData.rooms.length;
  const finishBookingData = {
    model: {
      date_from: guestsData.date_from,
      date_to: guestsData.date_to,
      rooms: guestsData.rooms.map((item, index: number) => ({
        ...item,
        room_code: selectedRooms[index]?.room_code,
        rate_code: selectedRooms[index]?.rate_code,
      })),
    },
    rooms: [...selectedRooms],
  };

  const totalPrice = selectedRooms.reduce((acc, curr) => acc + +curr.price, 0);

  const bookingDate = `${proposalS} ${format(
    new Date(guestsData.date_from),
    FROM_TO_DATE_FORMAT
  )} ${pretextFor} ${format(new Date(guestsData.date_to), FROM_TO_DATE_FORMAT)}`;

  const handleNavigationClick = () => {
    setBookedFormData(finishBookingData);
    !isEnglishVersion
      ? navigate(`${RESERVATION_ROUTES.RESERVATION_SELECT_PAY}`)
      : navigate(`/en${RESERVATION_ROUTES.RESERVATION_SELECT_PAY}`);
  };

  const handleDeleteRoomClick = (priceId: string, priceIndex: number) => {
    setSelectedRooms(selectedRooms.filter((room, index) => index !== priceIndex));
    setAmountRates(amountRates + 1);
    setRateCounter({ ...rateCounter, [priceId]: rateCounter[priceId] - 1 });
  };

  const handleToReservationPageClick = () => {
    !isEnglishVersion
      ? navigate(`${RESERVATION_ROUTES.RESERVATION_SELECT_ROOM}`)
      : navigate(`/en${RESERVATION_ROUTES.RESERVATION_SELECT_ROOM}`);
  };

  return (
    <div>
      <Styled.RoomSelectionHeader>
        <Button
          background="none"
          mr="20px"
          onClick={handleToReservationPageClick}
          icon={<ArrowIcon stroke="black" />}
          iconAction
        />
        <Styled.BookingDate>
          <span>{bookingDate}</span>
          {guestsTextValue}
        </Styled.BookingDate>
      </Styled.RoomSelectionHeader>
      <Styled.Title>{yourBookedText}</Styled.Title>
      <Booked
        onDeleteRoomClick={handleDeleteRoomClick}
        onNavigationClick={handleNavigationClick}
        bookedData={selectedRooms}
        roomsAmount={amountRates}
        allRates={amountRoomsInitial}
        totalPrice={totalPrice}
        isDisabled={selectedRooms.length !== amountRoomsInitial}
      />
    </div>
  );
};
