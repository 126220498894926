import { FC, useState } from "react";
import Modal from "react-modal";

import { Image } from "@src/interfaces";
import { popupStylesSolidOverlay } from "@src/styles/popupStyles";

import { LightboxGallery } from "../LightboxGallery";
import { renderGallery } from "./utils";

type DesktopGalleryProps = {
  images: Image[];
};

Modal.setAppElement("#root");

export const DesktopGallery: FC<DesktopGalleryProps> = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [openLightBox, setOpenLightBox] = useState(false);

  const handleImgClick = (index: number) => {
    setCurrentImage(index);
    setOpenLightBox(!openLightBox);
  };

  const handleCloseLightBox = () => {
    setOpenLightBox(false);
  };

  return (
    <>
      {renderGallery(images, handleImgClick)}
      <Modal isOpen={openLightBox} style={popupStylesSolidOverlay}>
        <LightboxGallery images={images} currentIndex={currentImage} handleClose={handleCloseLightBox} />
      </Modal>
    </>
  );
};
