import { FC, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import Modal from "react-modal";

import { ReactComponent as AddIcon } from "@src/assets/images/icons/add.svg";
import { ReactComponent as MinusIcon } from "@src/assets/images/icons/minus.svg";
import { Button } from "@src/components";
import { useGetBaseData, useMediaQuery } from "@src/hooks";
import { GuestsListType } from "@src/interfaces/guest.interface";
import { popupStyles } from "@src/styles/popupStyles";
import { theme } from "@src/theme";

import { CounterPopup } from "./CounterPopup";
import { Styled } from "./styles";

type CounterProps = {
  indexParent: number;
  setValue: UseFormSetValue<GuestsListType>;
  index: number;
  currCount: number;
  isMaximum: boolean;
};

export const Counter: FC<CounterProps> = ({ currCount, setValue, indexParent, index, isMaximum, ...props }) => {
  const [count, setCount] = useState(currCount);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");
  const { maxGuestCountText } = useGetBaseData();

  const handleIncrementClick = () => {
    setCount((prev) => prev + 1);
    setValue(`guestList.${indexParent}.guests.${index}.count`, count + 1);
  };

  const handleDecreaseClick = () => {
    if (count === currCount) return;
    setCount((prev) => prev - 1);
    setValue(`guestList.${indexParent}.guests.${index}.count`, count - 1);
  };

  const isAdaptive = useMediaQuery(theme.breakpoints.medium);

  const isPopupOpen = showPopup && (isAdaptive as boolean);

  const handleShowPopupClick = (text: string) => {
    setShowPopup(true);
    setPopupText((prev) => (prev = text));
  };

  const handleClosePopupClick = () => {
    setShowPopup(false);
    setPopupText((prev) => (prev = ""));
  };

  return (
    <>
      <Styled.Counter {...props}>
        <Button
          onClick={handleDecreaseClick}
          color={count === 0 || (index === 0 && count === currCount) ? theme.color.greyPale : theme.color.black}
          icon={<MinusIcon />}
          disabled={count === 0}
          iconAction
        />
        <Styled.CountValue>{count}</Styled.CountValue>
        <Styled.PlusContainer
          data-for={`guest-counter/${indexParent}`}
          data-tip={maxGuestCountText}
          onClick={() => handleShowPopupClick(maxGuestCountText)}>
          <Button
            onClick={handleIncrementClick}
            icon={<AddIcon />}
            iconAction
            disabled={isMaximum}
            color={isMaximum ? theme.color.greyPale : theme.color.black}
          />
        </Styled.PlusContainer>
        {isMaximum && !isAdaptive && <Styled.Tooltip id={`guest-counter/${indexParent}`} />}
      </Styled.Counter>
      <Modal
        isOpen={isPopupOpen && isMaximum}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={handleClosePopupClick}
        style={popupStyles}>
        <CounterPopup text={maxGuestCountText} />
      </Modal>
    </>
  );
};
