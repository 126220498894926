import { theme } from "../theme";

export const datepickerModalStyle = {
  overlay: {
    maxHeight: "100vh",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: "3",
    backgroundColor: theme.color.bgPopup,
    backdropFilter: "blur(4px)",
    overflow: "hidden",
  },
};
export const popupStyles = {
  overlay: {
    height: "100%",
    maxHeight: "100%",
    minHeight: "100%",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: "3",
    backgroundColor: theme.color.bgPopup,
    backdropFilter: "blur(4px)",
    transform: "translate3d(0,0,0)",
    overflow: "hidden",
  },
  content: {
    borderRadius: "none",
    maxHeight: "100vh",
    inset: "unset",
    padding: "0",
    backgroundColor: "none",
    background: "none",
    border: "none",
    overflow: "hidden",
  },
};
export const popupStylesWithFullWIdthContent = {
  overlay: {
    height: "100%",
    maxHeight: "100%",
    minHeight: "100%",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: "3",
    backgroundColor: theme.color.bgPopup,
    backdropFilter: "blur(4px)",
    transform: "translate3d(0,0,0)",
    overflow: "hidden",
  },
  content: {
    borderRadius: "none",
    maxHeight: "100vh",
    inset: "0",
    padding: "0",
    backgroundColor: "none",
    background: "none",
    border: "none",
    overflow: "hidden",
  },
};
export const popupStylesSolidOverlay = {
  overlay: {
    height: "100%",
    maxHeight: "100%",
    minHeight: "100%",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: "3",
    transform: "translate3d(0,0,0)",
    backgroundColor: theme.color.black,
    overflow: "hidden",
  },
  content: {
    borderRadius: "none",
    maxHeight: "100vh",
    inset: "unset",
    padding: "0",
    backgroundColor: "none",
    background: "none",
    border: "none",
    overflow: "hidden",
  },
};

export const NotificationPopupStyles = {
  overlay: {
    maxHeight: "100vh",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: "3",
    transform: "translate3d(0,0,0)",
    backgroundColor: theme.color.bgPopup,
    backdropFilter: "blur(4px)",
    overflow: "hidden",
  },
  content: {
    borderRadius: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    inset: "unset",
    padding: "0",
    backgroundColor: "none",
    background: "none",
    border: "none",
    overflow: "hidden",
  },
};
