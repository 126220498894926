import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

import { BlogArticlesListType, BlogDetailedArticleType, BlogFilterType } from "@src/interfaces/blog.interfaces";

// todo
const basePath = "blog";

const langId = Cookies.get("lang_id") || "";

export const blogApi = createApi({
  reducerPath: "api/blog",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ROOT_API_URL,
    prepareHeaders: (headers) => {
      headers.set("User-Lang-Id", langId);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getBlogItems: build.query<BlogArticlesListType, { page: number; category: number }>({
      query: ({ page, category }) => `${basePath}/posts-list?page=${page}${category ? `&category=${category}` : ""}`,
    }),
    getBlogArticleById: build.query<BlogDetailedArticleType, string>({
      query: (id) => `/${basePath}/post/${id}`,
    }),
    getBlogArticleFilters: build.query<BlogFilterType[], void>({
      query: (id) => `/${basePath}/get-categories`,
    }),
  }),
});

export const { useGetBlogItemsQuery, useGetBlogArticleByIdQuery, useGetBlogArticleFiltersQuery } = blogApi;
