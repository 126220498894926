import styled, { css } from "styled-components";

export const GAP_BETWEEN_SERVICES_CARD = 48;
export const GAP_BETWEEN_SERVICES_CARD_TABLET = 24;
export const GAP_BETWEEN_SERVICES_CARD_MOBILE = 12;

const Container = styled.div<{ isMainPage: boolean }>`
  width: calc(100% - 70px);
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 120px;

  ${({ isMainPage }) =>
    !isMainPage &&
    css`
      margin-bottom: 96px;
    `};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: calc(100% - 48px);
    margin: 0 auto;
    margin-bottom: 64px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: calc(100% - 40px);
    margin-bottom: 64px;
  }
`;

const SectionTitleWrapper = styled.div`
  padding-bottom: 64px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-bottom: 32px;
  }
`;

const ServicesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${GAP_BETWEEN_SERVICES_CARD}px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    gap: ${GAP_BETWEEN_SERVICES_CARD_TABLET}px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    gap: ${GAP_BETWEEN_SERVICES_CARD_MOBILE}px;
  }
`;

export const Styled = { Container, SectionTitleWrapper, ServicesWrapper };
