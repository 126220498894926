import { FC } from "react";

import { eventTypesData } from "@src/mock/events";
import { useGetEventsQuery } from "@src/store/services";

import { EventTypeItem } from "../EventTypeItem";
import { Styled } from "./styles";

type EventTypesProps = {
  handleScroll: () => void;
};

export const EventTypes: FC<EventTypesProps> = ({ handleScroll }) => {
  const { data, isLoading } = useGetEventsQuery();

  if (isLoading) return null;
  const eventTypesData = data?.eventTypes;
  return (
    <Styled.Container>
      {eventTypesData?.map((item) => {
        return <EventTypeItem {...item} key={item.id} handleScrollToForm={handleScroll} />;
      })}
    </Styled.Container>
  );
};
