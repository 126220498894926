import { useState } from "react";

import { Image } from "@src/interfaces";

import { Picture } from "../Picture";
import { ShowMoreButton } from "../ShowMoreButton";
import { Styled } from "./styles";

type RenderGalleryProps = (data: Image[], handleOpenLightBox: (index: number) => void) => JSX.Element | undefined;

export const renderGallery: RenderGalleryProps = (data, handleOpenLightBox) => {
  const [areHiddenImagesShown, setAreHiddenImagesShown] = useState(false);
  const imagesAmount = data.length;
  const MAIN_CONTAINER_CAPACITY = 5;

  let restImagesAmount: number;

  let hiddenPicturesData: Image[] = [];
  if (imagesAmount > MAIN_CONTAINER_CAPACITY) {
    restImagesAmount = imagesAmount - MAIN_CONTAINER_CAPACITY;
    hiddenPicturesData = data.slice(MAIN_CONTAINER_CAPACITY);
  }

  const handleShowMoreBtnClick = () => {
    setAreHiddenImagesShown(!areHiddenImagesShown);
  };

  switch (imagesAmount) {
    case 1:
      return (
        <Styled.Gallery>
          <Styled.MainContainer>
            <Picture img={data[0]} handleOpenGallery={handleOpenLightBox} index={0} />
          </Styled.MainContainer>
        </Styled.Gallery>
      );
    case 2:
      return (
        <Styled.Gallery>
          {data.map((item, index) => {
            return (
              <Styled.MainContainer size="50" key={index}>
                <Picture img={item} handleOpenGallery={handleOpenLightBox} index={index} mediumGallery />
              </Styled.MainContainer>
            );
          })}
        </Styled.Gallery>
      );
    case 3:
      return (
        <Styled.Gallery>
          <Styled.MainContainer size="76">
            <Picture img={data[0]} handleOpenGallery={handleOpenLightBox} index={0} mediumGallery />
          </Styled.MainContainer>
          <Styled.AdditionalContainer size="24" amount="2">
            {data.slice(1).map((item, index) => {
              return (
                <Picture img={item} handleOpenGallery={handleOpenLightBox} index={index + 1} key={index} smallGallery />
              );
            })}
          </Styled.AdditionalContainer>
        </Styled.Gallery>
      );
    case 4:
      return (
        <Styled.Gallery>
          <Styled.MainContainer size="85">
            <Picture img={data[0]} handleOpenGallery={handleOpenLightBox} index={0} mediumGallery />
          </Styled.MainContainer>
          <Styled.AdditionalContainer size="15" amount="3">
            {data.slice(1).map((item, index) => {
              return (
                <Picture img={item} handleOpenGallery={handleOpenLightBox} index={index + 1} key={index} smallGallery />
              );
            })}
          </Styled.AdditionalContainer>
        </Styled.Gallery>
      );
    case 5:
      return (
        <Styled.Gallery>
          <Styled.MainContainer size="50">
            <Picture img={data[0]} handleOpenGallery={handleOpenLightBox} index={0} mediumGallery />
          </Styled.MainContainer>
          <Styled.AdditionalContainer size="50" amount="4">
            {data.map((item, index) => {
              return (
                <Picture img={item} handleOpenGallery={handleOpenLightBox} index={index + 1} key={index} smallGallery />
              );
            })}
          </Styled.AdditionalContainer>
        </Styled.Gallery>
      );

    default:
      return (
        <Styled.GalleryWrapper>
          <Styled.Gallery>
            <Styled.MainContainer size="50">
              <Picture img={data[0]} handleOpenGallery={handleOpenLightBox} index={0} mediumGallery />
            </Styled.MainContainer>
            <Styled.AdditionalContainer size="50" amount="4">
              {data.slice(1, 5).map((item, index) => {
                return index === 3 && !areHiddenImagesShown ? (
                  <Styled.PictureContainer key={index} onClick={handleShowMoreBtnClick}>
                    <Picture img={item} smallGallery />
                    <Styled.PicturesValue>+{restImagesAmount}</Styled.PicturesValue>
                  </Styled.PictureContainer>
                ) : (
                  <Picture
                    img={item}
                    handleOpenGallery={handleOpenLightBox}
                    index={index + 1}
                    key={index}
                    smallGallery
                  />
                );
              })}
            </Styled.AdditionalContainer>
          </Styled.Gallery>
          {areHiddenImagesShown && (
            <Styled.HiddenPicturesContainer>
              {hiddenPicturesData.map((item, index) => {
                return (
                  <Picture
                    img={item}
                    handleOpenGallery={handleOpenLightBox}
                    index={index + MAIN_CONTAINER_CAPACITY}
                    key={index}
                    smallGallery
                  />
                );
              })}
            </Styled.HiddenPicturesContainer>
          )}
          <ShowMoreButton isOpened={areHiddenImagesShown} handleOpen={handleShowMoreBtnClick} />
        </Styled.GalleryWrapper>
      );
  }
};
