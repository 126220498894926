import styled, { css } from "styled-components";

import {
  absolutePseudoEl,
  flexCenter,
  flexColumn,
  flexSpaceBetween,
  font,
  height100,
  minMaxWidth,
  minMaxWidthHeight,
  width100,
  widthHeight100,
} from "@src/styles/mixins";

const Tooltip = styled.div`
  display: none;
  position: absolute;
  top: -37px;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  white-space: nowrap;
  height: 30px;
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.color.black};

  ${font({ size: "13", lineHeight: "14", fontWeight: 400 })};
  color: ${({ theme }) => theme.color.white};

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
    width: 10px;
    height: 10px;

    background-color: ${({ theme }) => theme.color.black};
  }
`;

const DateItemWrap = styled.span`
  position: relative;
  display: block;
  ${widthHeight100}

  &:hover {
    ${Tooltip} {
      display: block;
    }
  }
`;

const DateItem = styled.span`
  ${flexColumn};
  justify-content: center;
  ${widthHeight100};
  padding: 6px 0 8px;
  box-sizing: border-box;
`;

const DateText = styled.span`
  ${font({ size: "15", lineHeight: "21", fontWeight: 600 })};
`;

const DatePrice = styled.span`
  ${font({ size: "11", lineHeight: "12" })};
`;

const ModalHeader = styled.div`
  position: relative;
  ${flexCenter};
  margin-bottom: 53px;
`;

const ModalTitle = styled.h3`
  margin-right: auto;
  ${font({ size: "20", lineHeight: "28", fontWeight: 700 })};
  color: ${({ theme }) => theme.color.black};
`;

const ModalCloseBtn = styled.button`
  margin-left: auto;
  position: absolute;
  top: 2px;
  right: -16px;
`;

const DatepickerWrapper = styled.div<{ isScrolled?: boolean }>`
  width: 100%;
  box-sizing: border-box;

  .rdrDateRangePickerWrapper,
  .rdrDateRangeWrapper {
    position: relative;
    ${width100};
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }

  .rdrMonthAndYearWrapper {
    position: absolute;
    left: 0;
    top: 5px;
    width: 100%;
    width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
      display: none;
    }
  }

  .rdrMonths {
    &.rdrMonthsHorizontal {
      display: flex;
      justify-content: space-between;
      gap: 0 48px;
    }

    .rdrMonth {
      width: calc(64px * 7);

      .rdrMonthName {
        margin-bottom: 60px;
        ${font({ size: "16", lineHeight: "24", fontWeight: 600 })};
        color: ${({ theme }) => theme.color.black};
        text-align: center;
        text-transform: capitalize;
      }
    }
  }

  .rdrMonthsVertical {
    .rdrMonth {
      width: calc(64px * 7);
      height: auto !important;
      margin: 24px auto 0;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        width: calc(50px * 7);
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.xSmall}) {
        width: calc(37px * 7);
      }

      .rdrMonthName {
        margin-bottom: 16px;
        ${font({ size: "15", lineHeight: "21", fontWeight: 600 })};
        text-align: left;
        text-transform: capitalize;
      }

      .rdrDay {
        border: none;

        &:before {
          display: none;
        }

        &.rdrDayWeekend {
          .rdrDayNumber {
            span {
              color: ${({ theme }) => theme.color.red};
            }
          }
        }

        .rdrStartEdge,
        .rdrEndEdge {
          & + .rdrDayNumber {
            span {
              color: ${({ theme }) => theme.color.white};
              @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
                color: ${({ theme }) => theme.color.black};
              }
            }
          }
        }

        &.rdrDayDisabled {
          .rdrDayNumber {
            span {
              color: ${({ theme }) => theme.color.grey};
            }
          }
        }

        .rdrDayNumber {
          ${flexCenter};

          span {
            ${font({ size: "15", lineHeight: "21", fontWeight: 600 })};
            color: ${({ theme }) => theme.color.black};

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
              ${font({ size: "15", lineHeight: "21", fontWeight: 400 })};
            }
          }
        }
      }
    }
  }

  .rdrWeekDays {
    margin-bottom: 12px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
      justify-content: center;
      ${width100};
      margin-bottom: 0;
      padding: 20px 0;

      ${({ isScrolled }) =>
        isScrolled &&
        css`
          box-shadow: 0 4px 56px rgba(0, 0, 0, 0.12);
        `};
    }

    .rdrWeekDay {
      ${font({ size: "15", lineHeight: "21" })};
      color: ${({ theme }) => theme.color.black};

      &:nth-last-child(-n + 2) {
        color: ${({ theme }) => theme.color.red};
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
        ${minMaxWidth(64)};
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        ${minMaxWidth(50)};
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.xSmall}) {
        ${minMaxWidth(37)};
      }
    }
  }

  .rdrDay {
    position: relative;
    ${minMaxWidthHeight(63)};
    box-sizing: border-box;

    border-bottom: 1px solid ${({ theme }) => theme.color.greyBorder};
    border-right: 1px solid ${({ theme }) => theme.color.greyBorder};

    &:nth-child(-n + 7) {
      border-top: 1px solid ${({ theme }) => theme.color.greyBorder};
    }

    &:nth-child(7n + 8) {
      border-left: 1px solid ${({ theme }) => theme.color.greyBorder};
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      ${minMaxWidthHeight(50)};
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xSmall}) {
      ${minMaxWidthHeight(37)};
    }

    &.rdrDayToday {
      .rdrStartEdge,
      .rdrEndEdge {
        & + span {
          color: ${({ theme }) => theme.color.white} !important;
        }
      }
    }

    &.rdrDayStartOfMonth {
      &:before {
        ${absolutePseudoEl};
        left: -1px;
        top: -1px;
        width: 1px;
        height: calc(100% + 2px);
        background-color: ${({ theme }) => theme.color.greyBorder};
      }
    }

    &:not(.rdrDayDisabled):not(.rdrDayPassive):not(.rdrStartEdge):not(.rdrEndEdge) {
      background-color: unset;

      & .rdrInRange {
        background-color: unset !important;
      }
      & ~ .rdrDayNumber {
        ${DateText}, ${DatePrice}, span {
          color: ${({ theme }) => theme.color.white} !important;
        }
      }
    }

    .rdrStartEdge,
    .rdrEndEdge {
      background-color: ${({ theme }) => theme.color.red};

      & ~ .rdrDayNumber {
        ${DateText}, ${DatePrice}, span {
          color: ${({ theme }) => theme.color.white} !important;
        }
      }
    }

    &.rdrDayEndOfMonth {
      & ~ .rdrDayPassive {
        border-bottom: 1px solid transparent;
      }
    }

    &.rdrDayPassive {
      pointer-events: none;
      border-top: 1px solid transparent;
      border-right: 1px solid transparent;

      .rdrDayNumber,
      .rdrStartEdge,
      .rdrEndEdge,
      .rdrDayStartPreview,
      .rdrDayEndPreview,
      .rdrInRange,
      .rdrDayInPreview {
        opacity: 0;
        visibility: hidden;
      }
    }

    &.rdrDayDisabled {
      background-color: ${({ theme }) => theme.color.white};

      .rdrInRange {
        background-color: unset;
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
        background-color: transparent;
      }

      .rdrDayNumber {
        ${DateText} {
          color: ${({ theme }) => theme.color.greyBold};
        }
      }
    }

    .rdrDayNumber {
      ${height100};

      ${DateText} {
        margin-bottom: auto;
        color: ${({ theme }) => theme.color.black};
      }

      ${DatePrice} {
        color: ${({ theme }) => theme.color.green};
        font-weight: 700;
      }
    }

    .rdrStartEdge,
    .rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayEndPreview,
    .rdrInRange,
    .rdrDayInPreview {
      position: absolute;
      left: 0;
      top: 0;
      ${widthHeight100};
    }

    &.rdrDayHovered {
      .rdrStartEdge,
      .rdrEndEdge,
      .rdrDayStartPreview,
      .rdrDayEndPreview {
        background-color: ${({ theme }) => theme.color.red};

        & ~ .rdrDayNumber {
          ${DateText}, ${DatePrice}, span {
            color: ${({ theme }) => theme.color.white} !important;
          }
        }
      }
    }

    .rdrInRange,
    .rdrDayInPreview {
      background-color: ${({ theme }) => theme.color.lightGrey};
    }
  }
`;

const ResultsBar = styled.div`
  position: relative;
  z-index: 1;
  ${flexSpaceBetween};
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color.greyBorder};
  background-color: ${({ theme }) => theme.color.white};
`;

const SelectedDate = styled.p`
  ${font({ size: "18", lineHeight: "24" })};
  color: ${({ theme }) => theme.color.black};
`;

const ResetButton = styled.button`
  padding: 0 10px;
  ${font({ size: "15", lineHeight: "21" })};
  color: ${({ theme }) => theme.color.red};
`;

export const Styled = {
  ModalHeader,
  ModalTitle,
  ModalCloseBtn,
  DatepickerWrapper,
  DateItemWrap,
  DateItem,
  DateText,
  DatePrice,
  ResultsBar,
  SelectedDate,
  ResetButton,
  Tooltip,
};
