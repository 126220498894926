import { FC } from "react";

import { ReactComponent as Loader } from "@src/assets/svg/loader.svg";

import { Styled } from "./styles";

export const PageLoader: FC = () => (
  <Styled.PageLoader>
    <Loader />
  </Styled.PageLoader>
);
