import styled, { css, keyframes } from "styled-components";

import { box, flexCenter } from "@src/styles/mixins";

import { ReactComponent as ArrowIcon } from "../../assets/images/icons/arrow.svg";
import { ReactComponent as ChevronIcon } from "../../assets/images/icons/chevron.svg";
import { SLIDE_DELAY } from "./constants";

const progress = keyframes`
    0% { stroke-dashoffset: 125.664px; }
    90% { stroke-dashoffset: 0;  }
    100% { stroke-dashoffset: -125.664px;}
`;

const SectionTitleWrapper = styled.div`
  padding-bottom: 64px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-bottom: 32px;
  }
`;

const CarouselWrapper = styled.div<{ isSpaPage?: boolean }>`
  margin-bottom: 96px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 64px;
  }

  .swiper {
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
      display: flex;
      flex-direction: column;
    }
    &-wrapper {
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
        order: 2;
      }
    }

    &-slide {
      height: auto;
    }

    .swiper-pagination-bullets {
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
        display: none;
      }
    }

    &-pagination {
      @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        position: relative;
        bottom: auto;
        ${flexCenter};
        gap: 0 36px;
        width: auto;
        margin: 0 48px;
      }

      &-horizontal {
        .swiper-pagination-bullet {
          margin: 0;
        }
      }

      &-bullet {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        ${box(8)};
        opacity: 1;
        background-color: ${({ theme }) => theme.color.greyBg};

        &-wrapper {
          position: relative;
          ${box(24)}
        }

        &-active {
          background-color: ${({ theme }) => theme.color.red};

          ${({ isSpaPage }) =>
            isSpaPage &&
            css`
              background-color: ${({ theme }) => theme.color.blue};
            `};

          & + .swiper-pagination-bullet-progress {
            .swiper-pagination-bullet-circle {
              animation: ${progress} ${SLIDE_DELAY * 2}ms ease-in-out forwards;
            }
          }
        }

        &-progress {
          transform: rotate(-90deg);
        }

        &-circle-bg {
          fill: transparent;
        }

        &-active ~ .swiper-pagination-bullet-progress .swiper-pagination-bullet-circle-bg {
          fill: transparent;
          stroke: ${({ theme }) => theme.color.greyBorder};
          stroke-width: 2;
          stroke-dasharray: 0px;
          stroke-dashoffset: 0px;
        }

        &-circle {
          fill: transparent;
          stroke: ${({ theme }) => theme.color.red};
          stroke-width: 2;
          stroke-linecap: round;
          stroke-dasharray: 125.664px;
          stroke-dashoffset: 125.664px;

          ${({ isSpaPage }) =>
            isSpaPage &&
            css`
              stroke: ${({ theme }) => theme.color.blue};
            `};
        }
      }
    }
  }
`;

const NavigationWrapper = styled.div`
  ${flexCenter};
  margin-top: 66px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    order: 1;
    margin-top: 0;
    gap: 0 17px;
    margin-bottom: 28px;
  }
`;

const NavigationChevronRight = styled(ChevronIcon)`
  stroke: ${({ theme }) => theme.color.black};
`;
const NavigationChevronLeft = styled(ChevronIcon)`
  stroke: ${({ theme }) => theme.color.black};
  transform: rotate(180deg);
`;

const NavigationArrowRight = styled(ArrowIcon)`
  width: 19px;
  height: 12px;
  stroke: ${({ theme }) => theme.color.black};
  transform: rotate(180deg);
  & path {
    stroke: ${({ theme }) => theme.color.black};
  }
`;

const NavigationArrowLeft = styled(ArrowIcon)`
  width: 19px;
  height: 12px;
  stroke: ${({ theme }) => theme.color.black};

  & path {
    stroke: ${({ theme }) => theme.color.black};
  }
`;

export const Styled = {
  SectionTitleWrapper,
  CarouselWrapper,
  NavigationWrapper,
  NavigationChevronRight,
  NavigationChevronLeft,
  NavigationArrowRight,
  NavigationArrowLeft,
};
