import { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import { Breadcrumbs } from "@src/components";
import { EventTypes } from "@src/components/Events";
import { EventFeatures } from "@src/components/Events/EventFeatures";
import { EventRequestForm } from "@src/components/Events/EventRequestForm";
import { EventRooms } from "@src/components/Events/EventRooms";
import { EventTable } from "@src/components/Events/EventTable/EventTable";
import { Footer } from "@src/components/Footer";
import { useActions, useGetBaseData } from "@src/hooks";
import { useGetMetaData } from "@src/hooks/useGetMetaData";

import { Styled } from "./styles";

const Events: FC = () => {
  const location = useLocation();
  const currentLocation = location.pathname;
  const { metaTitle, metaDescription, metaKeywords } = useGetMetaData(currentLocation);

  const { eventsPageTitle } = useGetBaseData();

  const { setFirstScreenIsView, setIsStatusMessageOnTop } = useActions();
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLDivElement>(null);

  const handleScrollToForm = () => {
    if (formRef.current) {
      window.scrollTo({
        top: formRef.current.offsetTop,
        left: formRef.current.offsetLeft,
        behavior: "smooth",
      });
    }
  };

  const OFFSET_TOP_FOR_STICKY_HEADER = 30;

  const handlePageScroll = () => {
    const isScrolled = window.scrollY > OFFSET_TOP_FOR_STICKY_HEADER;
    setIsHeaderSticky(window.scrollY > 0);
    setIsStatusMessageOnTop(window.scrollY > 0);
    setFirstScreenIsView(!isScrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", handlePageScroll);
    return () => {
      window.removeEventListener("scroll", handlePageScroll);
    };
  }, []);

  const FULL_HEADER_HEIGHT = 180;
  const STICKY_HEADER_HEIGHT = 97;
  const headerHeight = !isHeaderSticky ? FULL_HEADER_HEIGHT : STICKY_HEADER_HEIGHT;
  return (
    <Styled.EventsWrapper headerHeight={headerHeight} ref={containerRef}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>
      <Breadcrumbs />
      <Styled.Header>{eventsPageTitle}</Styled.Header>
      <Styled.Events>
        <EventTypes handleScroll={handleScrollToForm} />
        <EventFeatures handleScroll={handleScrollToForm} />
        <EventRooms handleScroll={handleScrollToForm} />
        <EventTable />
        <EventRequestForm ref={formRef} />
      </Styled.Events>
      <Footer />
    </Styled.EventsWrapper>
  );
};

export default Events;
