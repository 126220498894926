import { DetailedActionType, Image } from "@src/interfaces";
import { useGetActionByIdQuery } from "@src/store/services";

type ReturnedData = {
  data?: DetailedActionType;
  isLoading?: boolean;
  id?: number;
  name: string;
  link: string;
  detailedName: string;
  sticker_text: string;
  img?: Image;
  description: string;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  detailButtonTitle: string | null;
  detailButtonLink: string | null;
  formTitle: string | null;
  formButtonText: string | null;
};

export const useGetActionDetailedData = (slug: string | undefined): ReturnedData => {
  if (!slug) {
    return {
      name: "",
      link: "",
      detailedName: "",
      sticker_text: "",
      description: "",
      metaTitle: "",
      metaDescription: "",
      metaKeywords: "",
      detailButtonTitle: "",
      detailButtonLink: "",
      formTitle: "",
      formButtonText: "",
    };
  }
  const { data, isLoading } = useGetActionByIdQuery(slug);

  const id = data && data?.id;
  const name = data ? data?.name : "";
  const link = data ? data?.slug : "";
  const detailedName = data ? data?.detail_name : "";
  const sticker_text = data ? data?.sticker_text : "";
  const img = data && data?.img;
  const description = data ? data?.description : "";
  const detailButtonTitle = data ? data?.detail_button_title : "";
  const detailButtonLink = data ? data?.detail_button_link : "";
  const formTitle = data ? data?.form_title : "";
  const formButtonText = data ? data?.form_button_text : "";

  const metaTitle = data ? data?.meta_title : "";
  const metaDescription = data ? data?.meta_description : "";
  const metaKeywords = data ? data?.meta_keywords : "";

  return {
    data,
    isLoading,
    id,
    name,
    link,
    detailedName,
    sticker_text,
    img,
    description,
    metaTitle,
    metaDescription,
    metaKeywords,
    detailButtonTitle,
    detailButtonLink,
    formTitle,
    formButtonText,
  };
};
