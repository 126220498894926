import styled, { css } from "styled-components";

import { flexColumn, flexRowWrap, font, transformCenter, width100 } from "@src/styles/mixins";

const GalleryWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    position: relative;
    margin-bottom: 47px;
  }

  button {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
  }
`;

const Gallery = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    ${width100};
    height: 308px;
    max-height: 308px;
    overflow: hidden;
    margin-bottom: 48px;
  }
`;

const MainContainer = styled.div<{ size?: string }>`
  width: ${(props) => {
    return Number(props.size) < 100 ? `calc(${props.size}% - 1px)` : "100%";
  }};
  height: 308px;

  ${(props) =>
    Boolean(props.size) &&
    css`
      margin-right: 3px;
    `}

  img {
    cursor: pointer;
    ${width100};
    height: 308px;
    object-position: center;
    object-fit: cover;
  }
`;

const AdditionalContainer = styled.div<{ size: string; amount?: string }>`
  width: ${(props) => {
    return props.size;
  }}%;
  height: 308px;
  max-height: 308px;
  overflow: hidden;

  ${(props) =>
    Number(props.amount) >= 2 &&
    css`
      height: 309px;
      max-height: 309px;
      margin: -2px;
    `}

  picture {
    margin: 2px;
    cursor: pointer;
    ${width100};
    height: auto;

    overflow: hidden;
  }

  img {
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  ${(props) =>
    props.amount === "2" &&
    css`
      display: flex;
      flex-direction: column;
      margin: -2px 0;

      picture:last-child {
        margin-bottom: 0px;
      }

      picture {
        height: 153px;
      }
    `}

  ${(props) =>
    props.amount === "3" &&
    css`
      ${flexColumn};
      margin: -2px 0;

      picture:last-child {
        margin-bottom: 0;
      }

      img {
        height: 102px;
      }
    `}

      ${(props) =>
    props.amount === "4" &&
    css`
      ${flexRowWrap}

      picture {
        width: calc(50% - 4px);
        height: 153px;
      }
    `}

    ${(props) =>
    Number(props.amount) > 4 &&
    css`
      ${flexRowWrap}

      picture {
        width: calc(50% - 2px);
        height: 153px;
      }
    `}
`;

const PictureContainer = styled.div`
  position: relative;
  width: calc(50% - 4px);
  height: 153px;
  margin-top: 2px;

  &::before {
    content: "";
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: -3px;
    bottom: 0px;
    left: 2px;
    background-color: ${({ theme }) => theme.color.bgPopup};
  }

  picture {
    width: 100%;
    height: 153px;
  }
`;

const PicturesValue = styled.span`
  cursor: pointer;
  position: absolute;
  ${transformCenter}

  ${font({ size: "16", lineHeight: "24", fontWeight: "700" })};

  letter-spacing: 0.06em;
  text-transform: uppercase;

  color: ${({ theme }) => theme.color.white};
`;

const HiddenPicturesContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: -1px;
  margin-top: 2px;

  picture {
    cursor: pointer;
    width: calc(25% - 4px);

    height: 153px;
    overflow: hidden;
    margin: 2px;
  }

  img {
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
`;

export const Styled = {
  GalleryWrapper,
  Gallery,
  MainContainer,
  AdditionalContainer,
  PictureContainer,
  PicturesValue,
  HiddenPicturesContainer,
};
