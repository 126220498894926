import styled, { css } from "styled-components";

import { font } from "@src/styles/mixins";

const ContactWrapper = styled.div<{ headerHeight: number }>`
  background: ${({ theme }) => theme.color.white};

  [class*="ymaps-2"][class*="-ground-pane"] {
    filter: grayscale(100%);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletXLargeMin}) {
    ${({ headerHeight }) => css`
      margin-top: ${headerHeight}px;
      padding-top: 1px;
    `};
  }
`;

const Contacts = styled.div`
  display: flex;
  margin-inline: auto;
  margin-bottom: 50px;
  gap: 126px;

  width: calc(100% - 40px);
  max-width: 1400px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    gap: 50px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: flex;
    flex-direction: column-reverse;
    gap: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    max-width: 1184px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 20px;
    margin-bottom: 0;
    width: auto;
  }
`;

const Header = styled.h1`
  font-family: Montserrat, sans-serif;
  ${font({ size: "50", lineHeight: "58", fontWeight: "500" })};
  padding-bottom: 64px;
  width: calc(100% - 40px);
  max-width: 1400px;
  margin-inline: auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    max-width: 1184px;
    ${font({ size: "48", lineHeight: "58", fontWeight: "500" })};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 13px;
    padding-bottom: 0px;
    ${font({ size: "28", lineHeight: "38", fontWeight: "500" })}
  }
`;

const Contact = styled.div`
  width: 100%;
`;

const ButtonTitle = styled.p`
  margin: 42px 0 16px;
  color: ${({ theme }) => theme.color.grey};
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 30px 0 14px;
    color: ${({ theme }) => theme.color.black};
    ${font({ size: "18", lineHeight: "32", fontWeight: "400" })}
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;

  a {
    text-decoration: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
    flex-direction: column;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;

  & button {
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      padding: 0;
    }
  }
`;

const RequestButton = styled.button`
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.color.red};
  ${font({ size: "18", lineHeight: "32", fontWeight: 400 })}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "14", lineHeight: "20", fontWeight: 400 })}
  }
`;

const Map = styled.div`
  display: grid;
  min-height: 55vh;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    min-height: unset;
  }
`;

export const Styled = {
  Header,
  ButtonTitle,
  Buttons,
  ContactWrapper,
  Contact,
  ButtonsWrapper,
  Contacts,
  RequestButton,
  Map,
};
