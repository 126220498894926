import styled from "styled-components";

import {
  absolutePseudoEl,
  box,
  transformCenter,
  transformXCenter,
  width100,
  font,
  flexCenter,
} from "../../styles/mixins";

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  position: fixed;
  width: calc(100% - 56px);
  max-width: 600px;
  max-height: calc(100vh - 56px);
  overflow-y: auto;
  margin: 28px auto;

  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.color.white};

  ${transformCenter};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${width100}
    max-width: unset;
    min-height: unset;
    margin: 0;

    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(0, 0);
    overflow-y: visible;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    &:after {
      ${absolutePseudoEl}
      z-index: 1;

      width: 42px;
      height: 4px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.color.white};

      top: -10px;
      ${transformXCenter}
    }
  }
`;

const ScrollContainer = styled.div`
  padding: 28px 48px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    overflow-y: auto;
    padding: 32px 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

const Heading = styled.h2`
  ${font({ size: "20", lineHeight: "28", fontWeight: "700" })}
  margin-bottom: 40px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 24px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 26px;
  right: 25px;
  ${box(32)}
  ${flexCenter}


    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    ${absolutePseudoEl};
    left: 15px;
    height: 17px;
    width: 2px;
    background-color: ${({ theme }) => theme.color.black};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 8px;

    & input {
      width: 100%;
      box-sizing: border-box;
    }
  }
`;
const InputsContainer = styled.div`
  margin-bottom: 40px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 24px;
  }
`;

const Button = styled.button`
  cursor: pointer;
  position: fixed;
  z-index: 3;
  bottom: 80px;
  right: 12px;

  ${box(48)}
  background-color: ${({ theme }) => theme.color.black};

  svg path {
    stroke: ${({ theme }) => theme.color.white};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    bottom: 20px;
  }
`;

const Privacy = styled.div`
  ${font({ size: "13", lineHeight: "21", fontWeight: "300" })};
  margin-top: 28px;
  margin-bottom: 28px;

  a {
    text-decoration: none;
  }
`;

const PrivacyLink = styled.a`
  color: ${({ theme }) => theme.color.red};
  cursor: pointer;
`;

export const Styled = {
  Container,
  Heading,
  InputContainer,
  InputsContainer,
  Button,
  CloseButton,
  ScrollContainer,
  Privacy,
  PrivacyLink,
};
