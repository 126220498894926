import { setDefaultOptions } from "date-fns";
import { ru, enGB } from "date-fns/locale";
import Cookies from "js-cookie";
import { Suspense, useEffect } from "react";
import Div100vh from "react-div-100vh";
import { Route, Routes, useLocation } from "react-router-dom";

import { BookedMobile, ReservationGuestsCounter, StatusMessage } from "@src/components";
import { BookingOk } from "@src/components/BookingOk";
import { ChooseRoom } from "@src/components/ChooseRoom";
import { Notification } from "@src/components/Notification";
import { ReservationForm } from "@src/components/ReservationForm";
import { ScrollToTop } from "@src/components/ScrollToTop";
import { BLOG_ARTICLE_ROUTE, PROMO_ACTION_ROUTE, ROUTES, SPA_SERVICE_ROUTE } from "@src/constants/_routes.constants";

import { PageLoader } from "./components/_Ui/PageLoader";
import { BookingCancellation } from "./components/BookingCancellation";
import { Preloader } from "./components/Preloader";
import { RoomModal } from "./components/RoomModal";
import { useActions } from "./hooks";
import { useChangeSearchParams } from "./hooks/useChangeSearchParams";
import { AppLayout } from "./layout";
import { Home, Page404 } from "./pages";
import { ContactPageAsync } from "./pages/ContactPage/ContactPage.async";
import { EventsPageAsync } from "./pages/Events/Events.async";
import { InfrastructurePageAsync } from "./pages/Infrastructure/Infrastructure.async";
import { PromoPageAsync } from "./pages/Promo/Promo.async";
import { PromoDetailedPageAsync } from "./pages/PromoDetailed/PromoDetailed.async";
import { ReservationPageAsync } from "./pages/Reservation/Reservation.async";
import { RoomsServicesPageAsync } from "./pages/RoomsServices/RoomsServices.async";
import { WeddingsPageAsync } from "./pages/Weddings/Weddings.async";
import { ENGLISH_LANG_ID, RUSSIAN_LANG_ID } from "./utils/constants";
import { LongStayRoomPageAsync } from "./pages/LongStayRoom/LongStayRoom.async";
import { SpaPageAsync } from "./pages/Spa";
import { SpaServiceDetailedPageAsync } from "./pages/SpaServiceDetailed/SpaServiceDetailed.async";
import Spa from "./pages/Spa/Spa";
import { BlogDetailedPageAsync } from "./pages/BlogDetailed/BlogDetailed.async";
import { BlogPageAsync } from "./pages/Blog/Blog.async";

function App() {
  const { checkParams, getSearchParam } = useChangeSearchParams();
  const isShowRoom = checkParams("room");
  const isLongStay = checkParams("longstay");
  const popupId = Number(getSearchParam("room"));
  const { setPaymentMethod, disablePayment } = useActions();

  // TODO отключение платежа
  const isOnlinePaymentAvailable = process.env.REACT_APP_ONLINE_PAYMENT_ENABLE?.toLowerCase() === "true";
  const isOfflinePaymentAvailable = process.env.REACT_APP_OFFLINE_PAYMENT_ENABLE?.toLowerCase() === "true";

  useEffect(() => {
    if (!isOnlinePaymentAvailable && !isOfflinePaymentAvailable) {
      disablePayment();
      return;
    }
    if (!isOnlinePaymentAvailable) {
      setPaymentMethod("isPaymentOffline");
    } else {
      setPaymentMethod("isPaymentOnline");
    }
  }, []);

  const currentLanguageId = Number(Cookies.get("lang_id"));

  const location = useLocation();
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;
  const currentLocale = !isEnglishVersion ? ru : enGB;

  setDefaultOptions({ locale: currentLocale });
  if (!currentLanguageId) {
    !isEnglishVersion
      ? Cookies.set("lang_id", String(RUSSIAN_LANG_ID))
      : Cookies.set("lang_id", String(ENGLISH_LANG_ID));
    window.location.reload();
  }
  if (isEnglishVersion && currentLanguageId === RUSSIAN_LANG_ID) {
    Cookies.set("lang_id", String(ENGLISH_LANG_ID));
    window.location.reload();
  } else if (!isEnglishVersion && currentLanguageId === ENGLISH_LANG_ID) {
    Cookies.set("lang_id", String(RUSSIAN_LANG_ID));
    window.location.reload();
  }

  return (
    <Div100vh>
      <Preloader />
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<Home />} />
          <Route path="/en" element={<Home />} />
          <Route
            path={ROUTES.ROOMS}
            element={
              <Suspense fallback={<>... </>}>
                <RoomsServicesPageAsync />
              </Suspense>
            }
          />
          <Route
            path={`en/${ROUTES.ROOMS}`}
            element={
              <Suspense fallback={<>... </>}>
                <RoomsServicesPageAsync />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.LONG_STAY}
            element={
              <Suspense fallback={<>... </>}>
                <LongStayRoomPageAsync />
              </Suspense>
            }
          />
          <Route
            path={`en/${ROUTES.LONG_STAY}`}
            element={
              <Suspense fallback={<>... </>}>
                <LongStayRoomPageAsync />
              </Suspense>
            }
          />

          <Route
            path={ROUTES.RESERVATION}
            element={
              <Suspense fallback={<>... </>}>
                <ReservationPageAsync />
              </Suspense>
            }>
            <Route path={`${ROUTES.SELECT_ROOM}`} element={<ChooseRoom />} />
            <Route path={`${ROUTES.GUESTS_COUNTER}`} element={<ReservationGuestsCounter />} />
            <Route path={`${ROUTES.RESERVATION_PAY}`} element={<ReservationForm />} />
            <Route path={`${ROUTES.BOOKED}/:kod`} element={<BookingOk />} />
            <Route path={`${ROUTES.CANCELLATION}/:kod`} element={<BookingCancellation />} />
            <Route path={ROUTES.BOOKED_MOBILE} element={<BookedMobile />} />
          </Route>

          <Route
            path={`en/${ROUTES.RESERVATION}`}
            element={
              <Suspense fallback={<>... </>}>
                <ReservationPageAsync />
              </Suspense>
            }>
            <Route path={`${ROUTES.SELECT_ROOM}`} element={<ChooseRoom />} />
            <Route path={`${ROUTES.GUESTS_COUNTER}`} element={<ReservationGuestsCounter />} />
            <Route path={`${ROUTES.RESERVATION_PAY}`} element={<ReservationForm />} />
            <Route path={`${ROUTES.BOOKED}/:kod`} element={<BookingOk />} />
            <Route path={`${ROUTES.CANCELLATION}/:kod`} element={<BookingCancellation />} />
            <Route path={ROUTES.BOOKED_MOBILE} element={<BookedMobile />} />
          </Route>
          <Route path={`/${ROUTES.RESERVATION}/${ROUTES.CANCELLATION}/:kod`} element={<BookingOk />} />
          <Route
            path={ROUTES.CONTACTS}
            element={
              <Suspense fallback={<>... </>}>
                <ContactPageAsync />
              </Suspense>
            }
          />
          <Route
            path={`en/${ROUTES.CONTACTS}`}
            element={
              <Suspense fallback={<>... </>}>
                <ContactPageAsync />
              </Suspense>
            }
          />

          <Route
            path={ROUTES.EVENTS}
            element={
              <Suspense fallback={<>... </>}>
                <EventsPageAsync />
              </Suspense>
            }
          />
          <Route
            path={`en/${ROUTES.EVENTS}`}
            element={
              <Suspense fallback={<>... </>}>
                <EventsPageAsync />
              </Suspense>
            }
          />

          <Route
            path={ROUTES.WEDDINGS}
            element={
              <Suspense fallback={<>... </>}>
                <WeddingsPageAsync />
              </Suspense>
            }
          />

          <Route
            path={`en/${ROUTES.WEDDINGS}`}
            element={
              <Suspense fallback={<>... </>}>
                <WeddingsPageAsync />
              </Suspense>
            }
          />

          <Route
            path={ROUTES.INFRASTRUCTURE}
            element={
              <Suspense fallback={<>... </>}>
                <InfrastructurePageAsync />
              </Suspense>
            }
          />
          <Route
            path={`en/${ROUTES.INFRASTRUCTURE}`}
            element={
              <Suspense fallback={<>... </>}>
                <InfrastructurePageAsync />
              </Suspense>
            }
          />

          <Route
            path={ROUTES.PROMO}
            element={
              <Suspense fallback={<>... </>}>
                <PromoPageAsync />
              </Suspense>
            }
          />
          <Route
            path={`en/${ROUTES.PROMO}`}
            element={
              <Suspense fallback={<>... </>}>
                <PromoPageAsync />
              </Suspense>
            }
          />

          <Route
            path={`${PROMO_ACTION_ROUTE}`}
            element={
              <Suspense fallback={<>... </>}>
                <PromoDetailedPageAsync />
              </Suspense>
            }
          />
          <Route
            path={`en/${PROMO_ACTION_ROUTE}`}
            element={
              <Suspense fallback={<>... </>}>
                <PromoDetailedPageAsync />
              </Suspense>
            }
          />

          <Route path={`${ROUTES.SPA}`} element={<Spa />} />
          <Route path={`en/${ROUTES.SPA}`} element={<Spa />} />

          <Route
            path={`${SPA_SERVICE_ROUTE}`}
            element={
              <Suspense fallback={<>... </>}>
                <SpaServiceDetailedPageAsync />
              </Suspense>
            }
          />
          <Route
            path={`en/${SPA_SERVICE_ROUTE}`}
            element={
              <Suspense fallback={<>... </>}>
                <SpaServiceDetailedPageAsync />
              </Suspense>
            }
          />

          <Route
            path={ROUTES.BLOG}
            element={
              <Suspense fallback={<>... </>}>
                <BlogPageAsync />
              </Suspense>
            }
          />
          <Route
            path={`en/${ROUTES.BLOG}`}
            element={
              <Suspense fallback={<>... </>}>
                <BlogPageAsync />
              </Suspense>
            }
          />
          <Route
            path={`${BLOG_ARTICLE_ROUTE}`}
            element={
              <Suspense fallback={<>... </>}>
                <BlogDetailedPageAsync />
              </Suspense>
            }
          />
          <Route
            path={`en/${BLOG_ARTICLE_ROUTE}`}
            element={
              <Suspense fallback={<>... </>}>
                <BlogDetailedPageAsync />
              </Suspense>
            }
          />

          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
      <ScrollToTop />
      <Notification />
      {isShowRoom && <RoomModal id={popupId} isLongStay={isLongStay} />}
      <StatusMessage />
    </Div100vh>
  );
}

export default App;
