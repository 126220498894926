import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import Cookies from "js-cookie";

import { IBookingSearch, IBookingSearchData, IGetReservation, Rooms } from "@src/interfaces";

type RequestMaxCountType = {
  date_from: string;
  date_to: string;
  room_id: null | string;
};

const langId = Cookies.get("lang_id") || "";

export const getRefundData = async (bookingId: string) => {
  const response = await axios.get<string>(`${process.env.REACT_APP_ROOT_API_URL}/booking/refund/${bookingId}`);
  return response;
};

export const bookingApi = createApi({
  reducerPath: "booking",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ROOT_API_URL,
    prepareHeaders: (headers) => {
      headers.set("User-Lang-Id", langId);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getBookingSearchData: build.query<Rooms[], IBookingSearchData>({
      query: (body) => ({
        url: `/booking/search`,
        method: "POST",
        body,
      }),
    }),

    create: build.mutation<{ url: string }, IBookingSearch>({
      query: (body) => ({
        url: `/booking/create`,
        method: "POST",
        body,
      }),
    }),
    getBookingCalendar: build.query<{ [key: string]: string }, any>({
      query: (roomId) => `/booking/calendar/${!roomId ? "all" : roomId}`,
    }),

    getBookingMaxCount: build.query<{ max_available_rooms: number }, RequestMaxCountType>({
      query: (body) => ({
        url: `/booking/calendar/max-count`,
        method: "POST",
        body,
      }),
    }),

    getBooking: build.query<IGetReservation, string | undefined>({
      query: (code) => `/booking/get/${code}`,
    }),
  }),
});

export const {
  useGetBookingSearchDataQuery,
  useGetBookingCalendarQuery,
  useCreateMutation,
  useGetBookingQuery,
  useGetBookingMaxCountQuery,
} = bookingApi;
