import styled from "styled-components";

import { box, flexVerticalCenter } from "@src/styles/mixins";

const Link = styled.a`
  ${box(48)};
  box-sizing: border-box;
  padding: 14px;
  ${flexVerticalCenter};
  justify-content: space-between;
  border-radius: 50%;

  background-color: ${({ theme }) => theme.color.lightGrey};
`;

const SocialIcon = styled.img`
  object-fit: contain;
`;

export const Styled = { Link, SocialIcon };
