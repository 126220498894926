import { FC } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { IconButton } from "@src/components/_Ui";
import { ServicesCard } from "@src/components/ServicesCard";
import { Services } from "@src/interfaces";
import { useGetBaseDataQuery } from "@src/store/services";

import {
  NAVIGATION_NEXT_CLASS_NAME_SERVICES,
  NAVIGATION_PREV_CLASS_NAME_SERVICES,
  SLIDES_PER_VIEW_MOBILE,
  SLIDES_SPACE_BETWEEN,
} from "./constants";
import { Styled } from "./styles";

type MobileMainPageSliderProps = {
  slides: Services[];
};

export const MobileMainPageSlider: FC<MobileMainPageSliderProps> = ({ slides }) => {
  const { data: baseData } = useGetBaseDataQuery();
  const detailText = baseData?.lang_phrases.more;

  return (
    <Styled.CarouselWrapper>
      {slides.length && (
        <>
          <Styled.NavigationWrapper>
            <div className={NAVIGATION_PREV_CLASS_NAME_SERVICES}>
              <IconButton icon={<Styled.NavigationArrowLeft />} size={"mediumMobile"} variant={"transparent"} />
            </div>
            <div className={NAVIGATION_NEXT_CLASS_NAME_SERVICES}>
              <IconButton icon={<Styled.NavigationArrowRight />} size={"mediumMobile"} variant={"transparent"} />
            </div>
          </Styled.NavigationWrapper>
          <Swiper
            modules={[Navigation]}
            navigation={{
              prevEl: `.${NAVIGATION_PREV_CLASS_NAME_SERVICES}`,
              nextEl: `.${NAVIGATION_NEXT_CLASS_NAME_SERVICES}`,
            }}
            loop
            slidesPerView={SLIDES_PER_VIEW_MOBILE}
            spaceBetween={SLIDES_SPACE_BETWEEN}>
            {slides.map((service, id) => (
              <SwiperSlide key={service.id}>
                <ServicesCard {...service} id={id} isSlide detailText={detailText} />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </Styled.CarouselWrapper>
  );
};
