import { FC } from "react";
import { Autoplay, Pagination, Parallax } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { useMediaQuery } from "@src/hooks";
import { MainBanners } from "@src/interfaces";
import { theme } from "@src/theme";

import { Picture } from "../_Ui";
import { SLIDE_DELAY } from "./constants";
import { Styled } from "./styles";

interface FullScreenCarouselProps {
  banners: MainBanners[];
  isSpaPage?: boolean;
}

export const FullScreenCarousel: FC<FullScreenCarouselProps> = ({ banners, isSpaPage }) => {
  const isAdaptive = useMediaQuery(theme.breakpoints.large);
  const hasNoSwiping = !isAdaptive ? true : false;

  const isMobile = useMediaQuery(theme.breakpoints.small);
  const isTablet = useMediaQuery(theme.breakpoints.medium);

  return (
    <Styled.Container>
      <Styled.CarouselWrapper isSpaPage={isSpaPage}>
        <Swiper
          modules={[Pagination, Autoplay, Parallax]}
          parallax={true}
          pagination={{ clickable: true }}
          loop={true}
          autoplay={{ delay: SLIDE_DELAY, disableOnInteraction: false, pauseOnMouseEnter: false }}
          noSwiping={hasNoSwiping}
          noSwipingClass="swiper-slide"
          speed={900}
          watchSlidesProgress>
          {banners.map((banner, index) => {
            const imgSrc = !isMobile ? (!isTablet ? banner.img : banner.img_tablet) : banner.img_mobile;

            return (
              <SwiperSlide key={banner.id}>
                <Styled.CarouselImgWrapper>
                  <Picture
                    img={imgSrc}
                    key={`${banner.img.original.original}/${index}`}
                    data-swiper-parallax="0%"
                    visibleByDefault
                  />
                  <Styled.CarouselText>
                    <Styled.CarouselTextWrapper data-swiper-parallax="-60%" data-swiper-parallax-duration="1500">
                      {banner.text}
                    </Styled.CarouselTextWrapper>
                  </Styled.CarouselText>
                </Styled.CarouselImgWrapper>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Styled.CarouselWrapper>
    </Styled.Container>
  );
};
