import { FC } from "react";

import { FillValue } from "@src/interfaces";

import { Tag } from "../../_Ui";
import { Styled } from "./styles";

type TagsProps = {
  data: FillValue[];
};

export const Tags: FC<TagsProps> = ({ data }) => {
  return (
    <Styled.Tags>
      {data.map((item, index) => {
        return <Tag key={index} {...item} />;
      })}
    </Styled.Tags>
  );
};
