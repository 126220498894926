import styled, { css } from "styled-components";

import { font, width100, box, flexCenter } from "@src/styles/mixins";

const Table = styled.section`
  background-color: ${({ theme }) => theme.color.white};
  margin-bottom: 96px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 64px;
  }
`;

const TableContainer = styled.div<{ hasBoxShadow?: boolean }>`
  z-index: 2;
  position: relative;

  width: calc(100% - 99px);

  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 66px;
  margin-bottom: 1px;
  padding-top: 64px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: calc(100% - 48px);
    flex-direction: column;
    padding: 41px 0 0;
    gap: 12px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: calc(100% - 40px);

    margin: 0 20px 4px;
  }

  ${(props) =>
    props.hasBoxShadow &&
    css`
      box-shadow: inset -9px 1px 4px -3px rgba(0, 0, 0, 0.25);
      background-position: center -58px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
        box-shadow: inset -9px -21px 4px -3px rgba(0, 0, 0, 0.25);
      }

      &::before {
        content: "";
        position: absolute;
        height: 64px;
        left: 0;
        right: 0;
        top: 0;
        z-index: 0;
        background: white;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
          height: 41px;
        }
      }

      &::after {
        content: "";
        position: absolute;
        height: 59px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background: white;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
          height: 37px;
        }
      }
    `}

  .scrollbar-container {
    position: relative;
    z-index: 1;
    padding-bottom: 59px !important;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
      padding-bottom: 22px !important;
    }
  }

  .ps__rail-x {
    opacity: 1 !important;
    height: 5px !important;
    z-index: 1;

    .ps__thumb-x {
      height: 5px !important;
      background: #dbdbdb !important;
      border-radius: 2.5px !important;
    }
  }

  .ps__rail-y {
    display: none;
  }
`;

const Aside = styled.div<{ hasBoxShadow?: boolean }>`
  position: absolute;
  top: 0;
  top: 64px;
  left: 0;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.white};
  padding-top: 103px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    top: 45px;
    padding-top: 96px;
    padding-right: 0;
  }

  ${(props) =>
    props.hasBoxShadow &&
    css`
      box-shadow: 9px 1px 4px -3px rgba(0, 0, 0, 0.25);
    `}
`;

const AsideHeader = styled.div`
  display: flex;
  align-items: center;

  gap: 68px;
  margin-bottom: 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 14px;
    gap: 20px;
  }
`;

const HeaderCell = styled.span<{ isSelectedByCell?: boolean; isAside?: boolean }>`
  cursor: pointer;
  box-sizing: border-box;
  padding: 7px 20px;

  ${font({ size: "18", lineHeight: "24", fontWeight: "700" })}
  color: ${({ theme }) => theme.color.black};
  text-align: left;
  white-space: nowrap;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    ${font({ size: "16", lineHeight: "22", fontWeight: "700" })}
  }

  &:hover {
    color: ${({ theme }) => theme.color.red};
  }

  ${(props) =>
    props.isSelectedByCell &&
    css`
      box-sizing: border-box;
      padding: 7px 20px;
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.red};
    `}

  ${(props) =>
    props.isAside &&
    css`
      padding: 0;

      &:hover {
        cursor: default;
        color: ${({ theme }) => theme.color.black};
      }

      &:first-child {
        padding: 4px 20px;
        margin-left: -20px;
        margin-right: -23px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
          margin-right: 0;
        }
      }

      &:not(:first-child) {
        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
          display: none;
        }
      }
    `}
`;

const AsideContainer = styled.div``;

const AsideRow = styled.div<{ isSelectedByLeftMenu?: boolean }>`
  display: flex;
  align-items: center;
  gap: 68px;

  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    gap: 20px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  }

  ${(props) =>
    props.isSelectedByLeftMenu &&
    css`
      position: relative;
      &::after {
        content: "";
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        height: 56px;
        width: 100%;
        background-color: ${({ theme }) => theme.color.lightGrey};

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
          width: 0;
        }
      }
    `}
`;

const AsideCell = styled.div<{ alignRight?: boolean; isSelectedByCell?: boolean; isSelectedByLeftMenu?: boolean }>`
  box-sizing: border-box;
  z-index: 1;

  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    ${font({ size: "14", lineHeight: "22", fontWeight: "400" })}
  }

  &:first-child {
    cursor: pointer;
    z-index: 1;
    background-color: ${({ theme }) => theme.color.white};
    position: relative;
    display: flex;
    align-items: center;
    height: 56px;

    padding: 7px 20px;
    margin-left: -20px;
    margin-right: -23px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
      height: 48px;
      padding-right: 10px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
      padding-right: 23px;
    }

    &:hover {
      color: ${({ theme }) => theme.color.red};
    }

    ${(props) =>
      props.isSelectedByCell &&
      css`
        height: unset;
        padding-right: 0;
        color: ${({ theme }) => theme.color.white};
        background-color: ${({ theme }) => theme.color.red};
      `}
  }

  &:not(:first-child):not(:nth-child(2)) {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    white-space: nowrap;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
      height: 48px;
    }
  }

  &:not(:first-child) {
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      display: none !important;
    }
  }

  &:last-child {
    margin-right: 38px;
  }

  ${(props) =>
    props.alignRight &&
    css`
      text-align: right;
    `}
`;

const ScrollableContainer = styled.div<{ hasBoxShadow?: boolean }>``;

const Content = styled.div<{ asideWidth: number }>`
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  width: max-content;
  ${({ asideWidth }) =>
    asideWidth &&
    css`
      padding-left: ${asideWidth}px;
    `}
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 14px;
  }
`;

const HeaderContainer = styled.div<{ asideWidth: number }>`
  box-sizing: border-box;
  width: max-content;
  ${({ asideWidth }) =>
    asideWidth &&
    css`
      padding-left: ${asideWidth}px;
    `}

  margin-bottom: 18px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 11px;
  }
`;

const ContentIconsHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 22px;
  margin-bottom: 35px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    gap: 5px;
    margin-bottom: 33px;
  }
`;
const ContentHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 22px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    gap: 5px;
  }
`;

const HeaderIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const HeaderIcon = styled.img`
  ${box(64)}
  object-fit: contain;
`;

const ContentRow = styled.div<{ selectedByMenu?: boolean }>`
  display: flex;
  align-items: center;
  gap: 22px;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    gap: 5px;
  }

  ${(props) =>
    props.selectedByMenu &&
    css`
      background-color: ${({ theme }) => theme.color.lightGrey};
    `}
`;

const Cell = styled.div<{ isSelectedByYAxis?: boolean }>`
  cursor: pointer;
  position: relative;
  z-index: 1;
  ${flexCenter}
  height: 56px;
  box-sizing: border-box;
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.black};
  text-align: center;
  white-space: nowrap;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    height: 48px;
    max-height: 48px;
    ${font({ size: "14", lineHeight: "22", fontWeight: "400" })}
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.lightGrey};
  }

  ${(props) =>
    props.isSelectedByYAxis &&
    css`
      background-color: ${({ theme }) => theme.color.lightGrey};
    `}
`;

export const Styled = {
  Table,
  TableContainer,
  Aside,
  AsideHeader,
  AsideContainer,
  AsideRow,
  ScrollableContainer,
  Content,
  HeaderContainer,
  ContentIconsHeader,
  ContentHeader,
  HeaderIconContainer,
  HeaderIcon,
  ContentRow,
  Cell,
  HeaderCell,
  AsideCell,
};
