import styled from "styled-components";

import { font } from "@src/styles/mixins";

const Title = styled.h2`
  margin: 0;
  ${font({ size: "16", lineHeight: "24", fontWeight: "700" })};
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-align: center;
`;

export const Styled = { Title };
