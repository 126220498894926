import { FC } from "react";

import { useGetMenuData } from "@src/hooks/useGetMenuData";
import { useGetBaseDataQuery } from "@src/store/services";

import { Styled } from "./styles";

type ListItemProps = {
  id?: number;
  id_lang?: number;
  name: string;
  only_request?: boolean;
  icon: string;
};

export const ListItem: FC<ListItemProps> = ({ name, only_request, icon }) => {
  const { requestMarkPhrase } = useGetMenuData();

  return (
    <Styled.Item>
      <Styled.Icon src={icon} alt="icon" />
      <Styled.TextContainer>
        <Styled.Title>{name}</Styled.Title>
        {only_request && <Styled.Subtitle>{requestMarkPhrase}</Styled.Subtitle>}
      </Styled.TextContainer>
    </Styled.Item>
  );
};
