import { FC, useState } from "react";
import Modal from "react-modal";

import { ReactComponent as ClockIcon } from "@src/assets/images/icons/clock.svg";
import { ReactComponent as CloseIcon } from "@src/assets/images/icons/close.svg";
import { Button, Picture } from "@src/components/_Ui";
import { PageLoader } from "@src/components/_Ui/PageLoader";
import { RequestCallForm } from "@src/components/RequestCallForm";
import { useAppSelector, useGetBaseData, useMediaQuery } from "@src/hooks";
import { useGetSpaMenuDetailedDataQuery } from "@src/store/services";
import { popupStyles } from "@src/styles/popupStyles";
import { theme } from "@src/theme";

import { Styled } from "./styles";

interface SpaDetailedProps {
  isOpen: boolean;
  activeMenu: number | null;
  onClose: () => void;
}

export const SpaDetailed: FC<SpaDetailedProps> = ({ activeMenu, isOpen, onClose }) => {
  const { data: menuData, isLoading: isMenuLoading } = useGetSpaMenuDetailedDataQuery(activeMenu, {
    skip: !activeMenu,
  });

  const { spaSignUpOnline } = useGetBaseData();
  const { firstScreenIsView } = useAppSelector((state) => state.headerAnimation);
  const [isRequestCallOpen, setIsRequestCallOpen] = useState(false);

  const isAdaptive = useMediaQuery(theme.breakpoints.small);
  const isTablet = useMediaQuery(theme.breakpoints.tabletLarge);
  const isDesktopSize = !isAdaptive && !isTablet;
  const isTabletSize = isTablet && !isAdaptive;

  const handleModalCloseClick = () => {
    onClose();
  };

  const handleOnRequestBtnClick = () => {
    setIsRequestCallOpen((prevState) => !prevState);
  };

  const handleClosePopupClick = () => {
    setIsRequestCallOpen(false);
  };

  if (!menuData || !activeMenu) {
    return null;
  }
  const { name, description, price, img, img_tablet, img_mobile } = menuData;

  const pricesArr = Object.values(price);

  const imgSrc = isDesktopSize ? img : isTabletSize ? img_tablet : img_mobile;

  return (
    <>
      <Styled.Background>
        <Styled.Container isCollapsed={!firstScreenIsView} isOpen={isOpen}>
          {isMenuLoading && <PageLoader />}
          <Styled.ImageContainer>
            <Styled.ModalCloseBtn onClick={handleModalCloseClick}>
              <CloseIcon />
            </Styled.ModalCloseBtn>
            <Picture img={imgSrc} />
          </Styled.ImageContainer>
          <Styled.Content>
            <Styled.Title>{name}</Styled.Title>
            <Styled.Description dangerouslySetInnerHTML={{ __html: description }} />
            <Styled.PriceWrapper>
              {pricesArr.map((item, index) => {
                const { time, price } = item;
                return (
                  <Styled.PriceContainer key={`${name}-${index}`}>
                    <Styled.Price>{price}</Styled.Price>
                    <Styled.Time>
                      <ClockIcon />
                      <span>{time}</span>
                    </Styled.Time>
                  </Styled.PriceContainer>
                );
              })}
            </Styled.PriceWrapper>
            <Styled.ButtonContainer>
              <Button title={spaSignUpOnline} blue onClick={handleOnRequestBtnClick} />
            </Styled.ButtonContainer>
          </Styled.Content>
        </Styled.Container>
      </Styled.Background>
      <Modal
        isOpen={isRequestCallOpen}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={handleClosePopupClick}
        style={popupStyles}>
        <RequestCallForm handleClosePopup={handleClosePopupClick} spaServiceName={name} />
      </Modal>
    </>
  );
};
