import { FC, useState } from "react";
import Modal from "react-modal";

import { RequestCallForm } from "@src/components/RequestCallForm";
import { useActions } from "@src/hooks";
import { useGetMenuData } from "@src/hooks/useGetMenuData";
import { popupStyles } from "@src/styles/popupStyles";
import { numberWithSpaces } from "@src/utils";

import { Styled } from "./styles";

export const RequestCall: FC = () => {
  const { phoneNumberText, requestCallText } = useGetMenuData();

  const [isOpen, setIsOpen] = useState(false);
  const { openPopup, closePopup } = useActions();

  const formattedPhoneNumber = phoneNumberText && numberWithSpaces(phoneNumberText, "## ### ### ## ##");

  const handleClosePopupClick = () => {
    setIsOpen(false);
    closePopup();
  };

  const handleOnRequestBtnClick = () => {
    setIsOpen((prevState) => !prevState);
    openPopup();
  };

  return (
    <>
      <Styled.Container>
        <Styled.PhoneContainer>
          <Styled.PhoneValue href={`tel:${phoneNumberText}`}>{formattedPhoneNumber}</Styled.PhoneValue>
          <Styled.RequestPhoneButton onClick={handleOnRequestBtnClick}>{requestCallText}</Styled.RequestPhoneButton>
        </Styled.PhoneContainer>
      </Styled.Container>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={handleClosePopupClick}
        style={popupStyles}>
        <RequestCallForm handleClosePopup={handleClosePopupClick} />
      </Modal>
    </>
  );
};
