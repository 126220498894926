import { format } from "date-fns";
import parse from "html-react-parser";
import { useState, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import Modal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as ArrowIcon } from "@src/assets/images/icons/arrow.svg";
import { ReactComponent as CloseIcon } from "@src/assets/images/icons/close.svg";
import { ReactComponent as Loader } from "@src/assets/svg/loader.svg";
import { Booked, Button, MobileModal } from "@src/components";
import { FROM_TO_DATE_FORMAT } from "@src/constants";
import { RESERVATION_ROUTES } from "@src/constants/_routes.constants";
import { useAppSelector, useGetBaseData, useMediaQuery } from "@src/hooks";
import { IFinishForm, IGuestData, IRate, ISearchRoom } from "@src/interfaces";
import { useCreateMutation } from "@src/store/services";
import { getDates } from "@src/store/slices/datepicker/datepicker.selector";
import { popupStyles } from "@src/styles/popupStyles";
import { theme } from "@src/theme";
import { declOfNum } from "@src/utils";

import { Form } from "./Form";
import { PaymentInfo } from "./PaymentInfo";
import { Styled } from "./styles";

type RequiredFieldsOption = {
  citizenship: string;
  email: string;
  name: string;
  lastname: string;
  phone: string;
  room_name: string;
  comment?: string;
  loyalty?: string;
};

type RoomFormDataType = {
  room: string;
  tarriff: string;
};

export const ReservationForm = () => {
  const {
    guestDataTitle,
    proposalS,
    pretextFor,
    commonErrorTextLink,
    commonErrorText,
    loyaltyNumberNotFount,
    loyaltyNumberNotFountText,
    roomIsUnavailable,
    roomIsUnavailableText,
    notificationError,
    roomsDecl,
    guestsDecl,
  } = useGetBaseData();
  const [create, { isLoading, error }] = useCreateMutation();

  const [isBookingBtnDisabled, setIsBookingBtnDisabled] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    if (isLoading) {
      setShowLoader((prev) => (prev = true));
    }
  }, [isLoading]);
  const { bookedData } = useAppSelector<IFinishForm>((state) => state.bookingData);
  const navigate = useNavigate();

  const location = useLocation();
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;

  const { roomsNumber, guestsNumber } = useAppSelector(getDates);
  const guestsTextValue =
    guestsNumber > 0
      ? ` ${roomsNumber} ${declOfNum(guestsNumber, roomsDecl)}, ${guestsNumber} ${declOfNum(guestsNumber, guestsDecl)}`
      : "";

  const indexesOfLoyalRooms: number[] = [];
  bookedData.rooms.forEach((room, index) => {
    if (Number(room.loyalty) > 0) {
      indexesOfLoyalRooms.push(index);
    }
  });

  const [fillAll, setFillAll] = useState(false);
  const [errorReqField, setErrorReqField] = useState(false);
  const [openLoyaltyError, setOpenLoyaltyError] = useState(false);
  const [openRoomIsUnavailError, setOpenRoomIsUnavailError] = useState(false);
  const [openConnectionError, setOpenConnectionError] = useState(false);
  const [amountFilledFields, setAmountFilledFields] = useState(0);
  // TODO temporaly switched to isPaymentOffline
  const { paymentMethod } = useAppSelector((state) => state);

  const [isPayment, setIsPaymentOnline] = useState<"isPaymentOnline" | "isPaymentOffline">(
    paymentMethod.chosenPaymentMethod
  );

  if (!bookedData.model.date_from) {
    !isEnglishVersion
      ? navigate(`${RESERVATION_ROUTES.RESERVATION_GUESTS_COUNTER}`)
      : navigate(`/en${RESERVATION_ROUTES.RESERVATION_GUESTS_COUNTER}`);
    return null;
  }

  const amountRooms = bookedData.rooms.map((room: IRate) => {
    return { room: room.room_name, tarriff: room.name };
  });
  const totalPrice = bookedData.rooms.reduce((acc: number, curr: IRate) => acc + Number(curr.price), 0);
  const isTablet = useMediaQuery(theme.breakpoints.medium);
  const bookingDate = `${proposalS} ${format(
    new Date(bookedData.model.date_from),
    FROM_TO_DATE_FORMAT
  )} ${pretextFor} ${format(new Date(bookedData.model.date_to), FROM_TO_DATE_FORMAT)}`;

  const isMobile = useMediaQuery(theme.breakpoints.large);

  const payDefaultValues = amountRooms.map((room: RoomFormDataType) => ({
    lastname: "",
    room_name: room.room,
    room_tarriff: room.tarriff,
    name: "",
    citizenship: "",
    email: "",
    phone: "",
    loyalty: "",
    comment: "",
  }));

  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors, isSubmitted, isValid, submitCount },
  } = useForm<{ pay: IGuestData[] }>({
    defaultValues: { pay: payDefaultValues },
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { fields } = useFieldArray({
    name: "pay",
    control,
  });
  const watchAllFields = watch();

  const [roomTitle, setRoomTitle] = useState("");

  useEffect(() => {
    // TODO new количество неотмеченных полей
    const errorsLength =
      errors?.pay && errors?.pay?.length && Array.isArray(errors.pay)
        ? errors.pay.reduce((acc, item) => {
            return acc + Object.keys(item as Record<string, string>).length;
          }, 0)
        : 0;

    setAmountFilledFields(errorsLength);

    if (isSubmitted && !isValid) {
      setErrorReqField(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      setErrorReqField(false);
    }
  }, [submitCount]);

  const commonErrorLink = commonErrorTextLink.replace("#error_fields_count#", String(amountFilledFields));

  const handleSubmitClick = async () => {
    const model = {
      ...bookedData.model,
      online_payment: isPayment === "isPaymentOnline",
      rooms: bookedData.model.rooms.map((room: ISearchRoom, index: number) => {
        const buyerItems = watchAllFields.pay[index] || watchAllFields.pay[index - 1];
        const buyer = fillAll
          ? {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              lastname: watchAllFields.pay[0]?.lastname,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              name: watchAllFields.pay[0]?.name,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              citizenship: watchAllFields.pay[0]?.citizenship,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              email: watchAllFields.pay[0]?.email,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              phone: watchAllFields.pay[0]?.phone,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              loyalty: watchAllFields.pay[0]?.loyalty,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              comment: watchAllFields.pay[0]?.comment,
            }
          : {
              lastname: buyerItems.lastname,
              name: buyerItems.name,
              citizenship: buyerItems.citizenship,
              email: buyerItems.email,
              phone: buyerItems.phone,
              loyalty: buyerItems.loyalty,
              comment: buyerItems.comment,
            };

        return {
          ...room,
          price: totalPrice,
          buyer,
        };
      }),
    };

    // TODO new количество неотмеченных полей
    const errorsLength =
      errors?.pay && errors?.pay?.length && Array.isArray(errors.pay)
        ? errors.pay.reduce((acc, item) => {
            return acc + Object.keys(item as Record<string, string>).length;
          }, 0)
        : 0;

    setAmountFilledFields(errorsLength);

    if (errorsLength > 0) {
      setErrorReqField(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      setErrorReqField(false);
      setIsBookingBtnDisabled(true);

      const data: any = await create(model);
      if (data?.error?.status === 403) {
        setShowLoader((prev) => (prev = false));
        setOpenLoyaltyError(true);
        setIsBookingBtnDisabled(false);
        return;
      }
      if (data?.error?.status === 500 || data?.error?.originalStatus === 500) {
        setShowLoader((prev) => (prev = false));
        setOpenConnectionError(true);
        setIsBookingBtnDisabled(false);
        return;
      }

      if (data?.error?.status === 405) {
        setShowLoader((prev) => (prev = false));
        setRoomTitle((prev) => (prev = data.error.data.name));
        setOpenRoomIsUnavailError(true);
        setIsBookingBtnDisabled(false);
        return;
      }

      if (isPayment === "isPaymentOffline") {
        const url = data.data.url.split("/");
        const kod = url[url.length - 1];
        !isEnglishVersion
          ? navigate(`${RESERVATION_ROUTES.BOOKED}/${kod}`)
          : navigate(`/en${RESERVATION_ROUTES.BOOKED}/${kod}`);
      } else {
        window.location.href = data.data.url;
      }
    }
  };

  const handleNavigationClick = () => {
    !isEnglishVersion ? navigate(`${RESERVATION_ROUTES.BOOKED}`) : navigate(`/en${RESERVATION_ROUTES.BOOKED}`);
  };

  const handleGoBackClick = () =>
    isMobile
      ? navigate(-1)
      : !isEnglishVersion
      ? navigate(RESERVATION_ROUTES.RESERVATION_SELECT_ROOM)
      : navigate(`/en${RESERVATION_ROUTES.RESERVATION_SELECT_ROOM}`);
  const handleSetValueClick = (fillAll: boolean) => {
    if (fillAll) {
      setValue(
        "pay",
        amountRooms.map((room: RoomFormDataType) => ({
          lastname: watchAllFields.pay[0].lastname,
          room_name: room.room,
          room_tarriff: room.tarriff,
          name: watchAllFields.pay[0].name,
          citizenship: watchAllFields.pay[0].citizenship,
          email: watchAllFields.pay[0].email,
          phone: watchAllFields.pay[0].phone,
          loyalty: watchAllFields.pay[0].loyalty,
          comment: watchAllFields.pay[0].comment,
        }))
      );
    } else {
      setValue(
        "pay",
        payDefaultValues.map((room: IGuestData, index: number) => (index === 0 ? watchAllFields.pay[0] : room))
      );
    }
  };

  useEffect(() => {
    if (!bookedData) {
      !isEnglishVersion
        ? navigate(`${RESERVATION_ROUTES.RESERVATION_GUESTS_COUNTER}`)
        : navigate(`/en${RESERVATION_ROUTES.RESERVATION_GUESTS_COUNTER}`);
    }
  }, [bookedData]);

  const loyaltyNumberErrorTextTitle = loyaltyNumberNotFount?.replace(
    "#NUMBER#",
    `<span>${watchAllFields.pay[0].loyalty}</span>`
  );

  const roomUnavailableErrorTextTitle = roomIsUnavailable?.replace("#ROOM_NAME#", `<span>${roomTitle}</span>`);

  return (
    <>
      <Styled.RoomSelectionHeader>
        {!isTablet ? (
          <Modal
            isOpen={openLoyaltyError}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            onRequestClose={() => setOpenLoyaltyError(false)}
            style={{ ...popupStyles, overlay: { ...popupStyles.overlay, display: "grid", placeItems: "center" } }}>
            <Styled.LoyaltyError>
              <Styled.ModalCloseBtn onClick={() => setOpenLoyaltyError(false)}>
                <CloseIcon stroke="black" />
              </Styled.ModalCloseBtn>
              <Styled.LoyaltyErrorHeader>{parse(loyaltyNumberErrorTextTitle)}</Styled.LoyaltyErrorHeader>
              <Styled.LoyaltyErrorText>{loyaltyNumberNotFountText}</Styled.LoyaltyErrorText>
            </Styled.LoyaltyError>
          </Modal>
        ) : (
          openLoyaltyError && (
            <MobileModal onClose={() => setOpenLoyaltyError(false)}>
              <Styled.LoyaltyErrorHeader>{parse(loyaltyNumberErrorTextTitle)}</Styled.LoyaltyErrorHeader>
              <Styled.LoyaltyErrorText>{loyaltyNumberNotFountText}</Styled.LoyaltyErrorText>
            </MobileModal>
          )
        )}
        {!isTablet ? (
          <Modal
            isOpen={openRoomIsUnavailError}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            onRequestClose={() => setOpenRoomIsUnavailError(false)}
            style={{ ...popupStyles, overlay: { ...popupStyles.overlay, display: "grid", placeItems: "center" } }}>
            <Styled.LoyaltyError>
              <Styled.ModalCloseBtn onClick={() => setOpenRoomIsUnavailError(false)}>
                <CloseIcon stroke="black" />
              </Styled.ModalCloseBtn>
              <Styled.LoyaltyErrorHeader>{parse(roomUnavailableErrorTextTitle)}</Styled.LoyaltyErrorHeader>
              <Styled.LoyaltyErrorText>{roomIsUnavailableText}</Styled.LoyaltyErrorText>
            </Styled.LoyaltyError>
          </Modal>
        ) : (
          openRoomIsUnavailError && (
            <MobileModal onClose={() => setOpenRoomIsUnavailError(false)}>
              <Styled.LoyaltyErrorHeader>{parse(roomUnavailableErrorTextTitle)}</Styled.LoyaltyErrorHeader>
              <Styled.LoyaltyErrorText>{roomIsUnavailableText}</Styled.LoyaltyErrorText>
            </MobileModal>
          )
        )}
        {!isTablet ? (
          <Modal
            isOpen={openConnectionError}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            onRequestClose={() => setOpenConnectionError(false)}
            style={{ ...popupStyles, overlay: { ...popupStyles.overlay, display: "grid", placeItems: "center" } }}>
            <Styled.LoyaltyError>
              <Styled.ModalCloseBtn onClick={() => setOpenConnectionError(false)}>
                <CloseIcon stroke="black" />
              </Styled.ModalCloseBtn>
              <Styled.LoyaltyErrorHeader>{notificationError}</Styled.LoyaltyErrorHeader>
            </Styled.LoyaltyError>
          </Modal>
        ) : (
          openConnectionError && (
            <MobileModal onClose={() => setOpenConnectionError(false)}>
              <Styled.LoyaltyErrorHeader>{notificationError}</Styled.LoyaltyErrorHeader>
            </MobileModal>
          )
        )}
        <Button
          background="none"
          mr="20px"
          onClick={handleGoBackClick}
          icon={<ArrowIcon stroke="black" />}
          iconAction
        />
        <Styled.BookingDate>
          <span>{bookingDate}</span>
          {guestsTextValue}
        </Styled.BookingDate>
      </Styled.RoomSelectionHeader>
      <Styled.Container onSubmit={handleSubmit(handleSubmitClick)}>
        {showLoader ? (
          <Styled.Loader>
            <Loader />
          </Styled.Loader>
        ) : (
          <Styled.Content>
            <Styled.Title>{guestDataTitle}</Styled.Title>
            <Form
              watch={watch}
              getValues={getValues}
              fields={fields}
              register={register}
              control={control}
              fillAll={fillAll}
              errorReqField={errorReqField}
              formErrors={errors}
              setFillAll={(value: boolean) => {
                setFillAll((prev) => (prev = value));
                handleSetValueClick(!fillAll);
              }}
              isPayment={isPayment}
              onPaymentChangeClick={setIsPaymentOnline}
              indexesOfLoyalRooms={indexesOfLoyalRooms}
              trigger={trigger}
            />

            <PaymentInfo
              isPayment={isPayment}
              setIsPaymentOnline={setIsPaymentOnline}
              commonErrorLink={commonErrorLink}
              commonErrorText={commonErrorText}
              errorReqField={errorReqField}
            />
          </Styled.Content>
        )}

        <Styled.Sidebar>
          {/* TODO если оплата невозможна то скрываю */}
          {!paymentMethod.isPaymentDisabled && (
            <Booked
              onNavigationClick={handleNavigationClick}
              totalPrice={totalPrice}
              bookingDate={bookingDate}
              bookedData={bookedData.rooms}
              isFinishBookingForm
              isPayment={isPayment === "isPaymentOnline"}
              commonErrorLink={commonErrorLink}
              commonErrorText={commonErrorText}
              errorReqField={errorReqField}
              isDisabled={isBookingBtnDisabled}
            />
          )}
        </Styled.Sidebar>
      </Styled.Container>
    </>
  );
};
