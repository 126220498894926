import { FC } from "react";

import { Styled } from "@src/components/BookingOk/styles";
import { useGetBaseData } from "@src/hooks";

type TotalCostProps = {
  totalPrice: number;
  numberOfNights: number;
};
export const TotalCost: FC<TotalCostProps> = ({ totalPrice, numberOfNights }) => {
  const {
    emailTextTotalCost,
    emailTextPriceForNight,
    emailBreakfast,
    emailLoyaltyDiscount,
    emailTaxes,
    emailTextTotalPrice,
    currencyValue,
  } = useGetBaseData();

  const priceForNightValue = totalPrice / numberOfNights;

  const formatedPriceForNight = String(priceForNightValue)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .split(".")[0];

  const formatedPriceTotal = String(totalPrice)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .split(".")[0];

  return (
    <>
      <Styled.BookingOkCardHeader>{emailTextTotalCost}</Styled.BookingOkCardHeader>
      <>
        <Styled.TotalCostDetailWrapper>
          <Styled.TotalCostDetail>
            <Styled.TotalCostDetailTitle>{emailTextPriceForNight}</Styled.TotalCostDetailTitle>
            <Styled.TotalCostDetailDescription>
              {formatedPriceForNight} {currencyValue}
            </Styled.TotalCostDetailDescription>
          </Styled.TotalCostDetail>

          <Styled.TotalCostDetail>
            <Styled.Pay>{emailTextTotalPrice}</Styled.Pay>
            <Styled.TotalCostValue>
              {formatedPriceTotal} {currencyValue}
            </Styled.TotalCostValue>
          </Styled.TotalCostDetail>
        </Styled.TotalCostDetailWrapper>
      </>
    </>
  );
};
