import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

import { BaseData } from "@src/interfaces";

const basePath = "base-data";

const langId = Cookies.get("lang_id") || "";

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ROOT_API_URL,
    prepareHeaders: (headers) => {
      headers.set("User-Lang-Id", langId);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getBaseData: build.query<BaseData, void>({
      query: () => `${basePath}/get-all`,
    }),
  }),
});

export const { useGetBaseDataQuery } = baseApi;
