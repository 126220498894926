import { FC } from "react";

import { useGetBaseData, useHover, useMediaQuery } from "@src/hooks";
import { theme } from "@src/theme";

import { ReactComponent as Shevron } from "../../../assets/images/icons/shevron-up.svg";
import { Styled } from "./styles";
import { NavigationLink } from "./types";

type FooterLinksProps = {
  data: NavigationLink[];
};

export const FooterLinks: FC<FooterLinksProps> = ({ data: links }) => {
  const { aboutHotelNavText, eventNavHeaderText } = useGetBaseData();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const [hoverRefEvents, isHoveredEvents] = useHover<HTMLDivElement>();
  const isAdaptive = useMediaQuery(theme.breakpoints.large);
  const popupLinks = links.filter(
    (link) =>
      link.url.includes("/rooms-and-services") ||
      link.url.includes("/infrastructure") ||
      link.url.includes("/long-stay") ||
      link.url.includes("/contacts")
  );
  const eventsLink = links.filter((link) => link.url.includes("/events") || link.url.includes("/weddings"));

  const menuLinks = links.filter(
    (link) =>
      !link.url.includes("/rooms-and-services") &&
      !link.url.includes("/long-stay") &&
      !link.url.includes("/infrastructure") &&
      !link.url.includes("/weddings") &&
      !link.url.includes("/events") &&
      !link.url.includes("/contacts")
  );
  const emptyObj = { text: "empty", url: "empty" };
  menuLinks.splice(2, 0, emptyObj);
  menuLinks.splice(5, 0, emptyObj);

  if (menuLinks) {
    const lastElementIndex = menuLinks.length - 1;
    const lastElement = menuLinks[lastElementIndex];
    menuLinks.splice(lastElementIndex, 1);

    const preLastElementIndex = menuLinks.length - 1;
    menuLinks.splice(preLastElementIndex, 0, lastElement);
  }
  return (
    <Styled.FooterLinksContainer>
      {!isAdaptive && (
        <>
          {menuLinks.map((link, index) => {
            if (index === 2) {
              return (
                <Styled.LinksMenu key={`${link.url}-${index}`} isHovered={isHovered} ref={hoverRef}>
                  <Styled.LinkMenuWrapper>
                    <Styled.LinksMenuTitle>{aboutHotelNavText}</Styled.LinksMenuTitle>
                    <Styled.LinksMenuIcon>
                      <Shevron />
                    </Styled.LinksMenuIcon>
                  </Styled.LinkMenuWrapper>
                  <Styled.LinksMenuContainer>
                    {popupLinks.map((link) => {
                      return (
                        <Styled.Link key={link.url} to={link.url}>
                          {link.text}
                        </Styled.Link>
                      );
                    })}
                  </Styled.LinksMenuContainer>
                </Styled.LinksMenu>
              );
            }

            if (index === 4) {
              return (
                <Styled.LinksMenu key={`${link.url}-${index}`} isHovered={isHoveredEvents} ref={hoverRefEvents}>
                  <Styled.LinkMenuWrapper>
                    <Styled.LinksMenuTitle>{eventNavHeaderText}</Styled.LinksMenuTitle>
                    <Styled.LinksMenuIcon>
                      <Shevron />
                    </Styled.LinksMenuIcon>
                  </Styled.LinkMenuWrapper>
                  <Styled.EventsLinksMenuContainer>
                    {eventsLink.map((link) => {
                      return (
                        <Styled.Link key={link.url} to={link.url}>
                          {link.text}
                        </Styled.Link>
                      );
                    })}
                  </Styled.EventsLinksMenuContainer>
                </Styled.LinksMenu>
              );
            }
            return (
              <Styled.Link key={link.url} to={link.url}>
                {link.text}
              </Styled.Link>
            );
          })}
        </>
      )}
      {isAdaptive &&
        links.map((link) => (
          <Styled.Link key={link.url} to={link.url}>
            {link.text}
          </Styled.Link>
        ))}
    </Styled.FooterLinksContainer>
  );
};
