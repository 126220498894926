import styled from "styled-components";

import { font, transformXCenter } from "@src/styles/mixins";

const BookingCardFooter = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 20px;
  height: 130px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
  border-left-width: 0;
  border-right-width: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xSmall}) {
    height: 88px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    position: fixed;
    width: calc(100% - 56px);
    max-width: 1020px;
    bottom: 28px;
    ${transformXCenter};
    padding: 12px 47px;
    border: none;
    background-color: ${({ theme }) => theme.color.lightGrey};
  }
`;

const PriceContainer = styled.div`
  margin-right: 20px;
  display: flex;
  margin-bottom: 20px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xSmall}) {
    display: block;
    margin-bottom: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    align-items: center;
  }
`;

const PriceValue = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.color.red};
  ${font({ size: "18", lineHeight: "26", fontWeight: "700" })};
  margin-right: 20px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xSmall}) {
    margin-right: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-right: 12px;
    ${font({ size: "24", lineHeight: "32", fontWeight: "700" })};
  }
`;

const AmountValue = styled.p`
  display: flex;
  align-items: center;
  margin: 0;

  color: ${({ theme }) => theme.color.grey};
  ${font({ size: "12", lineHeight: "20", fontWeight: "400" })};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  }
`;

const ButtonWrapper = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const Styled = { BookingCardFooter, PriceContainer, PriceValue, AmountValue, ButtonWrapper };
