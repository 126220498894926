import { Services } from "@src/interfaces";
import { useGetBaseDataQuery, useGetServicesQuery } from "@src/store/services";

type ReturnedData = { title: string; detailText: string; services?: Services[] };

export const useGetServicesData = (): ReturnedData => {
  const { data: baseData } = useGetBaseDataQuery();
  const { data: servicesData } = useGetServicesQuery();

  const title = baseData ? baseData?.lang_phrases.hotel_services : "";
  const detailText = baseData ? baseData?.lang_phrases.more : "";
  const services = servicesData ? servicesData : [];

  return { title, detailText, services };
};
