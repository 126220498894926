import { format } from "date-fns";
import { FC, useEffect, useState } from "react";

import { useAppSelector, useGetBaseData } from "@src/hooks";

import { Styled } from "./styles";
import { FROM_TO_DATE_FORMAT } from "@src/constants";
import { useGetBookingSearchDataQuery } from "@src/store/services";

interface BookingCardFooterProps {
  id: number;
}

export const BookingCardFooter: FC<BookingCardFooterProps> = ({ id }) => {
  const { textFrom, proposalS, pretextFor, currencyValue } = useGetBaseData();

  const { guestsData } = useAppSelector((state) => state.bookingData);
  const [lowestPrice, setLowestPrice] = useState<number | undefined>();

  const { data = [] } = useGetBookingSearchDataQuery(guestsData);

  useEffect(() => {
    if (data) {
      const currentRoom = data.filter((item) => {
        return item.id === id;
      })[0];
      const lowestPrice = Math.min(...currentRoom.rates.map((item) => Number(item.prices[0].total)));
      setLowestPrice(lowestPrice);
    }
  }, [data]);

  if (!lowestPrice) {
    return null;
  }
  const bookingDate = `${proposalS} ${format(
    new Date(guestsData.date_from),
    FROM_TO_DATE_FORMAT
  )} ${pretextFor} ${format(new Date(guestsData.date_to), FROM_TO_DATE_FORMAT)}`;

  const formattedPrice = String(lowestPrice)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .split(".")[0];

  return (
    <Styled.BookingCardFooter>
      <Styled.PriceContainer>
        <Styled.PriceValue>
          {textFrom} {formattedPrice} {currencyValue}
        </Styled.PriceValue>
        <Styled.AmountValue>{bookingDate}</Styled.AmountValue>
      </Styled.PriceContainer>
    </Styled.BookingCardFooter>
  );
};
