import styled from "styled-components";

import { font } from "@src/styles/mixins";

const List = styled.ul`
  padding: 0;
  margin: 0;
  margin-bottom: 46px;
`;

const ListHeader = styled.h2`
  margin-bottom: 18px;
  ${font({ size: "16", lineHeight: "25", fontWeight: "700" })};
  color: ${({ theme }) => theme.color.black};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 21px;
    ${font({ size: "18", lineHeight: "26", fontWeight: "700" })};
  }
`;

export const Styled = { List, ListHeader };
