import { Rooms } from "@src/interfaces";
import { useGetBaseDataQuery, useGetRoomsQuery } from "@src/store/services";

type ReturnedData = {
  title: string;
  rooms?: Rooms[];
  selectedDate: string;
  numberOfGuests: string;
  selectRoom: string;
  yourBookedText: string;
  youMustSelectText: string;
  proceedText: string;
  oneDoubleBedText: string;
  price: string;
  BBText: string;
  bookingDate: string;
  totalWord: string;
};

export const useGetOurRoomsData = (): ReturnedData => {
  const { data } = useGetBaseDataQuery();
  const { data: rooms } = useGetRoomsQuery();

  const title = data ? data?.lang_phrases.our_rooms : "";
  const yourBookedText = data ? data?.lang_phrases.booking_right_block_title : "";
  const proceedText = data ? data?.lang_phrases.go_forward : "";
  const youMustSelectText = data ? data?.lang_phrases.choose_rooms_warning : "";
  const totalWord = data ? data?.lang_phrases.total_word : "";
  const selectRoom = data ? data?.lang_phrases.choose_room_text : "";
  const selectedDate = "C 30 сентября по 1 октября";
  const numberOfGuests = "C 30 сентября по 1 октября";
  const oneDoubleBedText = "Делюкс с одной двуспальной кроватью";
  const BBText = "1 взрослый на основном месте Тариф с завтраком BB";
  const price = "7 200 ₽";
  const bookingDate = "1 ночь с 30 сентября по 1 октября";

  return {
    title,
    totalWord,
    rooms,
    selectedDate,
    numberOfGuests,
    selectRoom,
    yourBookedText,
    youMustSelectText,
    proceedText,
    oneDoubleBedText,
    price,
    BBText,
    bookingDate,
  };
};
