import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useActions, useAppSelector, useGetBaseData, useHover, useMediaQuery, useScrollPosition } from "@src/hooks";
import { theme } from "@src/theme";

import { ReactComponent as Shevron } from "@src/assets/images/icons/shevron-up.svg";
import { Styled } from "./styles";

type MenuLeftPartProps = {
  links: { text?: string; url: string }[];
};

export const MenuLeftPart: FC<MenuLeftPartProps> = ({ links }) => {
  const { aboutHotelNavText, eventNavHeaderText } = useGetBaseData();
  const { secondScreenOffsetTop } = useAppSelector((state) => state.headerAnimation);
  const { closeBurgerMenu } = useActions();
  const scrollPosition = useScrollPosition();
  const [isHeaderBg, setIsHeaderBg] = useState(false);

  useEffect(() => {
    if (!isAdaptive && scrollPosition > secondScreenOffsetTop) {
      setIsHeaderBg(true);
    } else {
      setIsHeaderBg(false);
    }
  }, [secondScreenOffsetTop, scrollPosition]);

  const location = useLocation();
  const isMainPage = location.pathname === "/" || location.pathname === "/en";
  const isSpaPage = location.pathname.includes("/spa") && !location.pathname.includes("/spa/service");
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;

  const isAdaptive = useMediaQuery(theme.breakpoints.tabletXLarge);
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const [hoverRefEvents, isHoveredEvents] = useHover<HTMLDivElement>();

  const handleLinkClick = () => {
    if (isAdaptive) closeBurgerMenu();
  };

  const popupLinks = links.filter(
    (link) =>
      link.url.includes("/rooms-and-services") ||
      link.url.includes("/infrastructure") ||
      link.url.includes("/long-stay") ||
      link.url.includes("/contacts")
  );

  const eventsLink = links.filter((link) => link.url.includes("/events") || link.url.includes("/weddings"));

  const menuLinks = links.filter(
    (link) =>
      !link.url.includes("/rooms-and-services") &&
      !link.url.includes("/long-stay") &&
      !link.url.includes("/infrastructure") &&
      !link.url.includes("/weddings") &&
      !link.url.includes("/events") &&
      !link.url.includes("/contacts")
  );
  const emptyObj = { text: "empty", url: "empty" };

  menuLinks.splice(2, 0, emptyObj);
  menuLinks.splice(5, 0, emptyObj);

  const isAboutHotelMenuActive =
    location.pathname.includes("/rooms-and-services") ||
    location.pathname.includes("/infrastructure") ||
    location.pathname.includes("/long-stay") ||
    location.pathname.includes("/contacts");
  const isEventsMenuActive = location.pathname.includes("/weddings") || location.pathname.includes("/events");
  const isGreyBackground = location.pathname === "/contacts" || location.pathname.includes("/reservation");

  let mobileLinks: { text?: string; url: string }[] | undefined;

  if (menuLinks) {
    const lastElementIndex = menuLinks.length - 1;
    const lastElement = menuLinks[lastElementIndex];
    menuLinks.splice(lastElementIndex, 1);

    const preLastElementIndex = menuLinks.length - 1;
    menuLinks.splice(preLastElementIndex, 0, lastElement);
  }

  if (isAdaptive) {
    mobileLinks = JSON.parse(JSON.stringify(links));
    const lastElements = mobileLinks?.splice(-2);
    mobileLinks?.splice(4, 0, ...lastElements!);
  }

  return (
    <Styled.Wrapper>
      {!isAdaptive && (
        <>
          {menuLinks.map((link, index) => {
            if (index === 2) {
              return (
                <Styled.LinksMenu
                  key={`${link.url}-${index}`}
                  isHovered={isHovered}
                  ref={hoverRef}
                  isMainPage={isMainPage || isSpaPage}
                  isHeaderBg={isHeaderBg}
                  isGreyBackground={isGreyBackground}>
                  <Styled.LinkMenuWrapper isActive={isAboutHotelMenuActive}>
                    <Styled.LinksMenuTitle>{aboutHotelNavText}</Styled.LinksMenuTitle>
                    <Styled.LinksMenuIcon>
                      <Shevron />
                    </Styled.LinksMenuIcon>
                  </Styled.LinkMenuWrapper>
                  <Styled.LinksMenuContainer>
                    {popupLinks.map((link) => {
                      return (
                        <Styled.LinkWrapper
                          key={link.url}
                          isMainPage={isMainPage || isSpaPage}
                          isActive={location.pathname === link.url}>
                          <Styled.Link key={link.url} to={link.url} onClick={handleLinkClick} isPopupLink>
                            {link.text}
                          </Styled.Link>
                        </Styled.LinkWrapper>
                      );
                    })}
                  </Styled.LinksMenuContainer>
                </Styled.LinksMenu>
              );
            }

            if (index === 4) {
              return (
                <Styled.LinksMenu
                  key={`${link.url}-${index}`}
                  isHovered={isHoveredEvents}
                  ref={hoverRefEvents}
                  isMainPage={isMainPage || isSpaPage}
                  isHeaderBg={isHeaderBg}
                  isGreyBackground={isGreyBackground}>
                  <Styled.LinkMenuWrapper isActive={isEventsMenuActive}>
                    <Styled.LinksMenuTitle>{eventNavHeaderText}</Styled.LinksMenuTitle>
                    <Styled.LinksMenuIcon>
                      <Shevron />
                    </Styled.LinksMenuIcon>
                  </Styled.LinkMenuWrapper>
                  <Styled.EventsLinksMenuContainer>
                    {eventsLink.map((link) => {
                      return (
                        <Styled.LinkWrapper
                          key={link.url}
                          isMainPage={isMainPage || isSpaPage}
                          isActive={location.pathname === link.url}>
                          <Styled.Link key={link.url} to={link.url} onClick={handleLinkClick} isPopupLink>
                            {link.text}
                          </Styled.Link>
                        </Styled.LinkWrapper>
                      );
                    })}
                  </Styled.EventsLinksMenuContainer>
                </Styled.LinksMenu>
              );
            }

            const isReservationPage = isEnglishVersion
              ? location.pathname.split("/")[2]
                ? location.pathname.split("/")[2].includes(link.url.split("/")[2])
                : false
              : location.pathname.split("/")[1].includes(link.url.split("/")[1]);

            return (
              <Styled.LinkWrapper
                key={link.url}
                isMainPage={isMainPage || isSpaPage}
                isActive={location.pathname === link.url || isReservationPage}>
                <Styled.Link key={link.url} to={link.url} onClick={handleLinkClick}>
                  {link.text}
                </Styled.Link>
              </Styled.LinkWrapper>
            );
          })}
        </>
      )}
      {isAdaptive &&
        mobileLinks?.map((link) => (
          <Styled.LinkWrapper
            key={link.url}
            isMainPage={isMainPage || isSpaPage}
            isActive={
              location.pathname === link.url || location.pathname.split("/")[1].includes(link.url.split("/")[1])
            }>
            <Styled.Link key={link.url} to={link.url} onClick={handleLinkClick}>
              {link.text}
            </Styled.Link>
          </Styled.LinkWrapper>
        ))}
    </Styled.Wrapper>
  );
};
