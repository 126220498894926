import { FC } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Button } from "@src/components/_Ui";
import { useGetBaseData, useMediaQuery } from "@src/hooks";
import { useGetEventsQuery } from "@src/store/services";
import { theme } from "@src/theme";

import featuresBg from "../../../assets/images/events/features-bg.jpg";
import featuresBgWebP from "../../../assets/images/events/features-bg.webp";
import { EventFeatureItem } from "../EventFeatureItem";
import { Styled } from "./styles";

type EventFeaturesProps = {
  handleScroll: () => void;
};

export const EventFeatures: FC<EventFeaturesProps> = ({ handleScroll }) => {
  const { data, isLoading } = useGetEventsQuery();

  const eventFeaturesData = data?.eventAdvantages;
  const { eventsRequestBtnText } = useGetBaseData();

  const handleBtnClick = () => {
    handleScroll();
  };

  const isAdaptive = useMediaQuery(theme.breakpoints.large);

  const requestBtnFullText = eventsRequestBtnText;
  const requestBtnMobileText = eventsRequestBtnText.split(" ").slice(0, 2).join(" ");
  const btnText = !isAdaptive ? requestBtnFullText : requestBtnMobileText;

  if (isLoading) return null;

  return (
    <Styled.Container>
      <Styled.Background>
        <picture>
          <source srcSet={featuresBgWebP} type="image/webp" />
          <source srcSet={featuresBg} type="image/jpeg" />
          <LazyLoadImage src={featuresBg} alt={featuresBg} />
        </picture>
      </Styled.Background>

      <Styled.BackgroundBlack />
      <Styled.FeaturesContainer>
        <Styled.Features>
          {eventFeaturesData?.map((item) => {
            return <EventFeatureItem {...item} key={item.id} />;
          })}
        </Styled.Features>
        <Styled.ButtonContainer>
          <Button title={eventsRequestBtnText} primary onClick={handleBtnClick} />
        </Styled.ButtonContainer>
      </Styled.FeaturesContainer>
    </Styled.Container>
  );
};
