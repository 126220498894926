import React, { FC } from "react";

import { ContentWrapper, SectionTitle } from "../_Ui";
import { HotelGalleryCarousel } from "../HotellGalleryCarousel";
import { Styled } from "./styles";
import { useGetHotelGalleryData } from "./useGetHotelGalleryData";

export const HotelGallerySection: FC = () => {
  const { title, textPhoto, photoCount, gallery } = useGetHotelGalleryData();

  return (
    <Styled.Container>
      <ContentWrapper>
        <Styled.SectionTitleWrapper>
          <SectionTitle text={title} />
          <Styled.PhotoCountText>
            —&nbsp;&nbsp;&nbsp;{photoCount} {textPhoto}
          </Styled.PhotoCountText>
        </Styled.SectionTitleWrapper>

        <HotelGalleryCarousel slides={gallery} textPhoto={textPhoto} />
      </ContentWrapper>
    </Styled.Container>
  );
};
