import { createSlice } from "@reduxjs/toolkit";

interface BurgerMenuState {
  isOpen: boolean;
}

const initialState: BurgerMenuState = {
  isOpen: false,
};

export const burgerMenuSlice = createSlice({
  name: "burgerMenu",
  initialState,
  reducers: {
    openBurgerMenu: (state) => {
      state.isOpen = true;
    },
    closeBurgerMenu: (state) => {
      state.isOpen = false;
    },
    toggleBurgerMenu: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const burgerMenuActions = burgerMenuSlice.actions;
export default burgerMenuSlice.reducer;
