import styled, { css } from "styled-components";

import backgroundImage from "@src/assets/images/spa/menu-detailed-bg.png";
import { box, flexCenter, flexColumn, font } from "@src/styles/mixins";

const Background = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    position: fixed;
    inset: 0px;
    background-color: rgba(38, 35, 36, 0.8);
    max-height: 100vh;
    z-index: 3;
    backdrop-filter: blur(4px);
    overflow: hidden;
  }
`;

const Container = styled.div<{ isCollapsed: boolean; isOpen: boolean }>`
  ${flexColumn};
  width: 622px;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  right: 0;
  max-width: 100vh;
  overflow-y: auto;

  background-color: ${({ theme }) => theme.color.black};
  transform: translateX(650px);
  transition: top 0.3s ease;
  transition: transform 0.5s ease;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    width: unset;

    top: 80px;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 800px;
    max-height: calc(100vh - 160px);

    background-color: ${({ theme }) => theme.color.white};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: unset;
    inset: 0;
    background-color: ${({ theme }) => theme.color.white};

    max-height: 100vh;
    overflow-y: auto;
    transform: none;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateX(0px);
    `};
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 329px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: 150px;
  }

  img {
    object-fit: cover;
    height: 329px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      height: 150px;
    }
  }
`;

const Content = styled.div`
  ${flexColumn};
  height: 100%;
  padding: 55px 64px;
  background-image: url(${backgroundImage});
  background-size: cover;
  color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    background-image: none;
    color: ${({ theme }) => theme.color.black};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 32px;
  }
`;

const Title = styled.h2`
  margin-bottom: 40px;
  ${font({ size: "20", lineHeight: "28", fontWeight: "700" })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    line-height: 26px;
  }
`;

const Description = styled.p`
  margin-bottom: 64px;
  ${font({ size: "16", lineHeight: "24", fontWeight: "600" })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 20px;
    ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  }
`;

const PriceWrapper = styled.div`
  ${flexColumn};
  gap: 12px;
  margin-bottom: 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-grow: 1;
    align-items: flex-start;
    justify-content: flex-end;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Price = styled.span`
  ${font({ size: "24", lineHeight: "32", fontWeight: "700" })};
  color: ${({ theme }) => theme.color.blue};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "18", lineHeight: "26", fontWeight: "700" })};
  }
`;

const Time = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${({ theme }) => theme.color.greyBold};
  ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};

  svg {
    ${box(20)}
    min-width: 20px;
    fill: ${({ theme }) => theme.color.greyBold};
  }
`;

const ButtonContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-grow: 0;
  }
`;

const ModalCloseBtn = styled.button`
  margin-left: auto;
  position: absolute;
  top: 33px;
  right: 32px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${flexCenter};
    ${box(40)};

    top: 11px;
    right: 12px;
    background-color: ${({ theme }) => theme.color.black};
  }

  svg {
    stroke: ${({ theme }) => theme.color.white};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      ${box(16)};
    }
  }
`;

export const Styled = {
  Background,
  Container,
  ImageContainer,
  Content,
  Title,
  Description,
  PriceWrapper,
  PriceContainer,
  Price,
  Time,
  ButtonContainer,
  ModalCloseBtn,
};
