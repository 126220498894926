import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

import { Weddings } from "@src/interfaces/wedding.interfaces";

const basePath = "wedding";

const langId = Cookies.get("lang_id") || "";

export const weddingsApi = createApi({
  reducerPath: "api/weddings",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ROOT_API_URL,
    prepareHeaders: (headers) => {
      headers.set("User-Lang-Id", langId);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getWeddings: build.query<Weddings, void>({
      query: () => `${basePath}/all`,
    }),
  }),
});

export const { useGetWeddingsQuery } = weddingsApi;
