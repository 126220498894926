import styled from "styled-components";

import { flexVerticalCenter, font } from "@src/styles/mixins";

const Container = styled.div`
  flex-grow: 1;
  ${flexVerticalCenter}
  justify-content: flex-end;

  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    ${font({ size: "14", lineHeight: "24", fontWeight: "400" })};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    order: 1;
    margin-bottom: 31px;
  }
`;

const PhoneContainer = styled.div`
  ${flexVerticalCenter}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    flex-direction: column;
    margin-right: 0;
  }
`;

const PhoneValue = styled.a`
  margin-right: 20px;
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.black};
  text-decoration: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    ${font({ size: "14", lineHeight: "24", fontWeight: "400" })};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-right: 0px;

    ${font({ size: "18", lineHeight: "20", fontWeight: "400" })};
  }
`;

const RequestPhoneButton = styled.button`
  cursor: pointer;
  ${flexVerticalCenter}
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    ${font({ size: "13", lineHeight: "20", fontWeight: "400" })};
    color: ${({ theme }) => theme.color.red};
  }
`;

export const Styled = { Container, PhoneContainer, PhoneValue, RequestPhoneButton };
