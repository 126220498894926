import styled from "styled-components";

import { flexCenter, flexSpaceBetween, font, width100 } from "@src/styles/mixins";

const BookedContent = styled.div`
  ${width100};
  margin: 32px 0;
  padding-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.color.greyBorder};

  &:last-child {
    margin-bottom: 17px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-top: 0;
    margin-bottom: 24px;
    padding-bottom: 29px;
  }
`;

const BookedContentHeader = styled.div`
  ${flexSpaceBetween};
  align-items: start;
`;

const Price = styled.div`
  ${flexCenter};
  gap: 10px;
  ${font({ size: "13", lineHeight: "21", fontWeight: "600" })};
  color: ${({ theme }) => theme.color.red};
`;

const RoomName = styled.h3`
  ${font({ size: "13", lineHeight: "21", fontWeight: "400" })};
  width: 160px;
`;

const RoomDescription = styled.p`
  ${font({ size: "12", lineHeight: "20", fontWeight: "300" })};
  color: ${({ theme }) => theme.color.grey};
  width: 200px;
`;

export const Styled = { Price, BookedContentHeader, RoomName, RoomDescription, BookedContent };
