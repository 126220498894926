import { MainBanners } from "@src/interfaces";
import { useGetMainBannersQuery } from "@src/store/services";

type ReturnedData = {
  banners: MainBanners[];
};

export const useGetCarouselData = (): ReturnedData => {
  const { data } = useGetMainBannersQuery();

  const banners = data ? data : [];

  return { banners };
};
