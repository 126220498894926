import { format } from "date-fns";
import { useParams } from "react-router-dom";

import { FROM_TO_DATE_FORMAT } from "@src/constants";
import { useGetBaseData } from "@src/hooks";
import { useGetBookingQuery } from "@src/store/services";

import { BookingOkCard } from "./BookingOkCard";
import { Styled } from "./styles";
import { TotalCost } from "./TotalCost";

const initialData = {
  total_price: 0,
  external_id: 0,
  online_payment: 0,
  date_from: new Date(),
  date_to: new Date(),
  content: [],
};
export const BookingOk = () => {
  const { kod } = useParams();
  const {
    proposalS,
    pretextFor,
    inOutTimeText,
    inOutTimeRules,
    paymentVariantsTitle,
    paymentOnlineText,
    paymentOfflineText,
    currencyValue,
    bookingConfirmedText,
    bookingFullPriceText,
    bookingRulesTitle,
    bookingRules,
  } = useGetBaseData();
  const { data = initialData, isLoading } = useGetBookingQuery(kod);
  const { online_payment, total_price, external_id, date_from, date_to, content } = data;
  const bookingDate = `${proposalS} ${format(new Date(date_from), FROM_TO_DATE_FORMAT)} ${pretextFor} ${format(
    new Date(date_to),
    FROM_TO_DATE_FORMAT
  )}`;

  const timeDiff = Math.abs(new Date(date_from).getTime() - new Date(date_to).getTime());
  const numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));

  const formatedPrice = String(total_price)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .split(".")[0];

  return (
    <Styled.BookingOkWrapper>
      <Styled.Header>{bookingConfirmedText}</Styled.Header>
      <Styled.BookingDetail>
        {bookingFullPriceText} {formatedPrice} {currencyValue}
      </Styled.BookingDetail>
      <Styled.BookingDetail>{bookingDate}</Styled.BookingDetail>
      <Styled.BookingCardsContainer>
        {content.map((room) => (
          <BookingOkCard key={room.id} room={room} />
        ))}
      </Styled.BookingCardsContainer>
      <Styled.BookingOkCardHeader>{inOutTimeText}</Styled.BookingOkCardHeader>
      <Styled.Departure>
        <Styled.RulesContainer dangerouslySetInnerHTML={{ __html: inOutTimeRules }} />
      </Styled.Departure>

      <Styled.BookingOkCardHeader>{bookingRulesTitle}</Styled.BookingOkCardHeader>
      <Styled.Departure>
        <Styled.RulesContainer dangerouslySetInnerHTML={{ __html: bookingRules }} />
      </Styled.Departure>

      <Styled.BookingOkCardHeader>{paymentVariantsTitle}</Styled.BookingOkCardHeader>
      <Styled.Departure>{online_payment ? paymentOnlineText : paymentOfflineText}</Styled.Departure>
      <TotalCost totalPrice={total_price} numberOfNights={numberOfNights} />
    </Styled.BookingOkWrapper>
  );
};
