import lottie from "lottie-web";
import { FC, LegacyRef, useEffect, useRef, useState } from "react";

import { useGetBaseData, useHover, useMediaQuery } from "@src/hooks";
import { EventType } from "@src/interfaces/event.interfaces";
import { theme } from "@src/theme";

import { Styled } from "./styles";

type EventTypesProps = {
  handleScrollToForm: () => void;
};

export const EventTypeItem: FC<EventType & EventTypesProps> = ({ text, icon, icon_anim, handleScrollToForm }) => {
  const { eventsTypeBookText } = useGetBaseData();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const isAdaptive = useMediaQuery(theme.breakpoints.large);

  const handleOnTypeClick = () => {
    handleScrollToForm();
  };

  const container = useRef<any>(null);

  const formattedAnimation = JSON.parse(icon_anim);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: formattedAnimation,
    });

    return () => {
      lottie.destroy();
    };
  }, []);

  const playAnimation = () => {
    lottie.play();
  };

  const stopAnimation = () => {
    lottie.stop();
  };

  return (
    <Styled.Event
      ref={hoverRef}
      isHovered={isHovered && !isAdaptive}
      onClick={handleOnTypeClick}
      onMouseEnter={playAnimation}
      onMouseLeave={stopAnimation}>
      <Styled.EventIconWrap>
        <Styled.EventImageWithHover src={icon} alt="icon" width="72" height="72" />
        <Styled.EventImageNoHover src={icon} alt="icon" width="72" height="72" />

        <Styled.AnimationWrapper ref={container} />
      </Styled.EventIconWrap>
      <Styled.EventTitle>{text}</Styled.EventTitle>
      <Styled.EventDescription>{eventsTypeBookText}</Styled.EventDescription>
    </Styled.Event>
  );
};
