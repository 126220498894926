import { format } from "date-fns";
import React, { FC, MouseEvent, useEffect } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as AddIcon } from "@src/assets/images/icons/add.svg";
import { ReactComponent as CalendarIcon } from "@src/assets/images/icons/calendarIcon.svg";
import { Button, GuestCounter, InputField } from "@src/components";
import { Datepicker } from "@src/components/Datepicker";
import { MAIN_DATE_FORMAT } from "@src/constants";
import { RESERVATION_ROUTES } from "@src/constants/_routes.constants";
import { useActions, useAppSelector, useGetBaseData, useMediaQuery } from "@src/hooks";
import { GuestsListType } from "@src/interfaces/guest.interface";
import { useGetBookingMaxCountQuery } from "@src/store/services";
import { getDates } from "@src/store/slices/datepicker/datepicker.selector";
import { theme } from "@src/theme";

import { Styled } from "./styles";

export const ReservationGuestsCounter: FC = () => {
  const location = useLocation();
  const roomId = location?.state?.roomId ? location.state.roomId : null;
  const navigate = useNavigate();
  const { setGuests, openModal, setRange, setGuestsData, setSelectedRooms, setAmountRates, setRateCounter } =
    useActions();
  const { modalIsOpen, range } = useAppSelector((state) => state.datepicker);
  const {
    data: languagesData,
    arrivalDate,
    departureDate,
    guestsCountList,
    guestsCount,
    addRoomText,
    buttonFindText,
    noMoreRoomsText,
  } = useGetBaseData();

  const { control, handleSubmit, watch, setValue, reset } = useForm<GuestsListType>({ defaultValues: guestsCountList });
  const { arrivalDateValue, departureDateValue } = useAppSelector(getDates);

  const body = {
    date_from: arrivalDateValue,
    date_to: departureDateValue,
    room_id: roomId,
  };

  const { data: bookingMaxCount } = useGetBookingMaxCountQuery(body, {
    skip: !arrivalDateValue && !departureDateValue,
  });
  const { fields, append, remove } = useFieldArray({
    name: "guestList",
    control,
  });

  const handleLanguagesPhrasesUpdate = () => {
    reset(guestsCountList);
  };

  useEffect(() => {
    handleLanguagesPhrasesUpdate();
  }, [languagesData]);

  const watchData = watch();

  const handleAppendClick = () => {
    append({ ...guestsCount, roomNumber: fields.length + 1 });
  };

  const handleRemoveClick = (index: number) => {
    remove(index);
  };

  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;

  const handleSubmitClick: SubmitHandler<GuestsListType> = (data) => {
    const model = {
      date_from: arrivalDateValue,
      date_to: departureDateValue,
      room_id: +roomId,
      rooms: data.guestList.map((guest) => ({
        adult_count: guest.guests.filter((i) => i.type === "adult_count")[0].count,
        child_under_11_count: guest.guests.filter((i) => i.type === "child_under_11_count")[0].count,
        child_under_5_count: guest.guests.filter((i) => i.type === "child_under_5_count")[0].count,
      })),
    };
    setGuestsData(model);
    setGuests(data.guestList);
    setSelectedRooms([]);
    setAmountRates(0);
    setRateCounter({});
    !isEnglishVersion
      ? navigate(`${RESERVATION_ROUTES.RESERVATION_SELECT_ROOM}`)
      : navigate(`/en${RESERVATION_ROUTES.RESERVATION_SELECT_ROOM}`);
  };

  const handleDateInputClick = (evt: MouseEvent<HTMLInputElement>) => {
    evt.stopPropagation();
    openModal();
  };

  const handleInputChange = (value: string) => {
    if (!value) {
      setRange({ startDate: undefined, key: "selection", endDate: undefined });
    }
  };
  const isTablet = useMediaQuery(theme.breakpoints.medium);

  const isAddNewRoomShow =
    bookingMaxCount?.max_available_rooms && watchData.guestList.length < bookingMaxCount?.max_available_rooms;

  return (
    <form onSubmit={handleSubmit(handleSubmitClick)}>
      {modalIsOpen && <Datepicker />}
      <Styled.Calendar>
        <Styled.Date>
          <InputField
            isProposalS={!!arrivalDateValue}
            label={arrivalDateValue && !isTablet ? "" : arrivalDate}
            marginTop={arrivalDateValue && !isTablet ? 10 : 27}
            name="arrival_date"
            icon={<CalendarIcon />}
            value={arrivalDateValue && range.startDate ? format(range.startDate, MAIN_DATE_FORMAT) : ""}
            onClick={handleDateInputClick}
            onChange={handleInputChange}
            withoutBorder
          />
        </Styled.Date>
        <Styled.Date>
          <InputField
            isPretextFor={!!departureDateValue}
            label={departureDateValue && !isTablet ? "" : departureDate}
            marginTop={departureDateValue && !isTablet ? 10 : 27}
            name="departure_date"
            icon={<CalendarIcon />}
            value={departureDateValue && range.endDate ? format(range.endDate, MAIN_DATE_FORMAT) : ""}
            onClick={handleDateInputClick}
            onChange={handleInputChange}
            withoutBorder
          />
        </Styled.Date>
      </Styled.Calendar>
      {fields.map((field, indexParent) => (
        <GuestCounter
          onRemoveClick={handleRemoveClick}
          setValue={setValue}
          key={field.id}
          indexParent={indexParent}
          field={field}
          showDeleteButton={fields.length > 1}
          isReservationPage
          watchData={watchData}
        />
      ))}

      {isAddNewRoomShow ? (
        <Styled.AddRoom>
          <Button onClick={handleAppendClick} icon={<AddIcon />} title={addRoomText} ghost />
        </Styled.AddRoom>
      ) : typeof bookingMaxCount?.max_available_rooms === "undefined" ? (
        ""
      ) : (
        <Styled.NoMoreRoomsText>{noMoreRoomsText}</Styled.NoMoreRoomsText>
      )}
      {!arrivalDateValue ? (
        <Button isDisabled mt="32px" title={buttonFindText} />
      ) : (
        <Button primary mt="32px" title={buttonFindText} type="submit" />
      )}
    </form>
  );
};
