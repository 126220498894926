import React, { ChangeEvent, FC, ReactNode, MouseEvent, useEffect, useRef, useState } from "react";

import { ReactComponent as ClearIcon } from "@src/assets/images/icons/clear-input.svg";
import { useFocus, useGetBaseData, useMediaQuery } from "@src/hooks";
import { theme } from "@src/theme";

import { Styled } from "./styles";

type InputFieldProps = {
  label?: string;
  name: string;
  icon?: ReactNode;
  withoutBorder?: boolean;
  value?: string;
  required?: boolean;
  isProposalS?: boolean;
  isPretextFor?: boolean;
  marginTop?: number;
  onChange?: (value: string, name: string) => void;
  onClick?: (evt: MouseEvent<HTMLInputElement>) => void;
};

export const InputField: FC<InputFieldProps> = ({
  label,
  name,
  isProposalS,
  isPretextFor,
  icon,
  withoutBorder,
  value,
  required,
  onChange,
  onClick,
  marginTop = 27,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const isFocus = useFocus(wrapperRef);
  const { proposalS, pretextFor } = useGetBaseData();
  const [isInputFocused, setIsInputFocused] = useState(false);
  const isAdaptive = useMediaQuery(theme.breakpoints.medium);
  const isTablet = useMediaQuery(theme.breakpoints.medium);
  useEffect(() => {
    if (isFocus) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
    setIsInputFocused((prev) => (prev = isFocus));

    if (inputRef.current?.value.length === 0) {
      setIsInputFocused((prev) => (prev = false));
    }
  }, [isFocus]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    setInputValue(value);

    if (onChange) onChange(value, name);
  };

  const handleClearInputClick = (evt: MouseEvent<HTMLDivElement>, name: string) => {
    evt.stopPropagation();
    inputRef.current?.blur();

    setInputValue("");
    if (onChange) onChange("", name);
  };

  const showIconCondition = !inputValue || (!!inputValue && !isAdaptive);

  return (
    <Styled.Wrapper
      ref={wrapperRef}
      withoutBorder={!!withoutBorder}
      isFocus={isInputFocused || !!inputValue}
      tabIndex={0}
      onClick={onClick}>
      <Styled.Label isRequired={!!required}>{label}</Styled.Label>
      <Styled.ProposalContainer>{isPretextFor && !isTablet && pretextFor}</Styled.ProposalContainer>
      <Styled.ProposalContainer>{isProposalS && !isTablet && proposalS}</Styled.ProposalContainer>
      <Styled.Input
        style={{ marginTop }}
        ref={inputRef}
        value={inputValue || ""}
        name={name}
        onChange={handleOnChange}
        onClick={onClick}
        readOnly
        aria-label="Text input"
      />
      {!!inputValue && (
        <Styled.ClearIcon onClick={(event: MouseEvent<HTMLDivElement>) => handleClearInputClick(event, name)}>
          <ClearIcon />
        </Styled.ClearIcon>
      )}
      {(showIconCondition || isProposalS || isPretextFor) && <Styled.Icon>{icon}</Styled.Icon>}
    </Styled.Wrapper>
  );
};
