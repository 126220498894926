import { motion } from "framer-motion";
import React, { FC, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Logo } from "@src/assets/images/icons/logo.svg";
import { useActions, useAppSelector, useMediaQuery, useScrollPosition } from "@src/hooks";
import { useGetMenuData } from "@src/hooks/useGetMenuData";
import { theme } from "@src/theme";

import { BurgerButton } from "../_Ui";
import { burgerButtonAnimation } from "./constants";
import { Menu } from "./Menu";
import { ChangeLang } from "./Menu/ChangeLang";
import { Styled } from "./styles";

export const Header: FC = () => {
  const { firstScreenIsView, secondScreenOffsetTop } = useAppSelector((state) => state.headerAnimation);
  const headerRef = useRef<HTMLInputElement>(null);
  const [isHeaderBg, setIsHeaderBg] = useState(false);
  const scrollPosition = useScrollPosition();
  const isAdaptive = useMediaQuery(theme.breakpoints.tabletXLarge);
  const { burgerMenu } = useAppSelector((state) => state);
  const { toggleBurgerMenu, closeBurgerMenu, setHeaderHeight, setIsStatusMessageOnTop } = useActions();

  const { languageId, languageName, languageIcon } = useGetMenuData();

  const animateValue = !isAdaptive ? (!firstScreenIsView ? "visible" : "hidden") : "visible";

  const location = useLocation();
  const isMainPage = location.pathname === "/" || location.pathname === "/en";
  const isSpaPage = location.pathname.includes("/spa") && !location.pathname.includes("/spa/service");

  const isPromoDetailedPage = location.pathname.includes("/promo/") && location.pathname.length > 8;
  const isBlogDetailedPage = location.pathname.includes("/blog/") && location.pathname.length > 8;
  const isSpaServiceDetailedPage = location.pathname.includes("/spa/service");
  const hasTransparentBG = isPromoDetailedPage || isSpaServiceDetailedPage || isBlogDetailedPage;

  const isGreyBackground = location.pathname.includes("/contacts") || location.pathname.includes("/reservation");
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;

  useEffect(() => {
    if (isMainPage || isSpaPage) {
      setIsStatusMessageOnTop(!firstScreenIsView);
    }
    if (firstScreenIsView && !isAdaptive) {
      closeBurgerMenu();
    }
  }, [firstScreenIsView, isAdaptive]);

  useEffect(() => {
    if (burgerMenu.isOpen) {
      closeBurgerMenu();
    }
  }, [scrollPosition]);

  useEffect(() => {
    const headerHeight = headerRef.current?.getBoundingClientRect().height;

    setHeaderHeight(headerHeight || 0);
  }, [firstScreenIsView]);

  useEffect(() => {
    if (!isAdaptive && scrollPosition > secondScreenOffsetTop) {
      setIsHeaderBg(true);
    } else {
      setIsHeaderBg(false);
    }
  }, [secondScreenOffsetTop, scrollPosition]);

  const handleMenuToggle = () => {
    toggleBurgerMenu();
  };

  const linkToMainPage = !isEnglishVersion ? "/" : "/en";

  return (
    <Styled.Container
      ref={headerRef}
      isSticky={firstScreenIsView}
      isHeaderBg={isHeaderBg}
      menuIsShow={burgerMenu.isOpen}
      isMainPage={isMainPage || isSpaPage}
      hasTransparentBG={hasTransparentBG}
      isBlogDetailedPage={isBlogDetailedPage}
      isGreyBackground={isGreyBackground}>
      <Styled.Wrapper>
        <Styled.LogoWrapper isSticky={firstScreenIsView} isMainPage={isMainPage || isSpaPage}>
          <Link aria-label="Link to main page" to={linkToMainPage}>
            <Logo />
          </Link>
        </Styled.LogoWrapper>
        <Styled.ChangeLangWrapper isMainPage={isMainPage || isSpaPage}>
          <ChangeLang languageId={languageId} name={languageName} icon={languageIcon} isMenuOpen={burgerMenu.isOpen} />
        </Styled.ChangeLangWrapper>

        <motion.div
          initial="hidden"
          animate={animateValue}
          variants={burgerButtonAnimation}
          transition={{ ease: "linear", duration: 0.5 }}>
          {!firstScreenIsView && <BurgerButton onClick={handleMenuToggle} />}
          {isAdaptive && <BurgerButton onClick={handleMenuToggle} />}
        </motion.div>

        <Styled.MenuWrapper data-text={"text"} isSticky={firstScreenIsView}>
          <Menu menuIsShow={burgerMenu.isOpen} />
        </Styled.MenuWrapper>
      </Styled.Wrapper>
    </Styled.Container>
  );
};
