import { FC, useEffect } from "react";
import { useLocation } from "react-router";
import { Outlet } from "react-router-dom";

import { Header } from "@src/components/Header";
import { Notification } from "@src/components/Notification";
import { ScrollToTop } from "@src/components/ScrollToTop";

import { Container } from "./styles";

export const AppLayout: FC = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Container>
      <Header />
      <Outlet />

      <ScrollToTop />
      <Notification />
    </Container>
  );
};
