import { FC, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Modal from "react-modal";

import AreaIcon from "@src/assets/svg/area-icon.svg";
import CapacityIcon from "@src/assets/svg/capacity-icon.svg";
import { useHover, useGetBaseData, useMediaQuery } from "@src/hooks";
import { EventHall } from "@src/interfaces/event.interfaces";
import { popupStylesSolidOverlay } from "@src/styles/popupStyles";
import { theme } from "@src/theme";

import { Button, MobileGallery, Picture, Tag } from "../../_Ui";
import { EventsGallery } from "../EventsGallery";
import { Styled } from "./styles";

type EventRoomProps = {
  data: EventHall;
  isInView?: boolean;
  handleScrollToForm: () => void;
};

export const EventRoom: FC<EventRoomProps> = ({ data, handleScrollToForm }) => {
  const { eventsHallBookText, eventsHallGalleryText } = useGetBaseData();
  const { text, img, img_mobile, img_tablet, description, capacity, area, photos } = data;
  const [openLightBox, setOpenLightBox] = useState(false);

  const isAdaptive = useMediaQuery(theme.breakpoints.small);
  const isTablet = useMediaQuery(theme.breakpoints.medium);
  const isDesktopSize = !isAdaptive && !isTablet;
  const isTabletSize = isTablet && !isAdaptive;
  const imgSrc = isDesktopSize ? img : isTabletSize ? img_tablet : img_mobile;

  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const handleBookingClick = () => {
    handleScrollToForm();
  };

  const handleConferenceRoomClick = () => {
    setOpenLightBox(true);
  };

  const handleCloseLightBox = () => {
    setOpenLightBox(false);
  };

  return (
    <>
      <Styled.EventRoom isHovered={isHovered} ref={hoverRef}>
        <Styled.EventRoomContainer>
          <Styled.EventRoomImage onClick={handleConferenceRoomClick}>
            <Picture img={img_mobile} visibleByDefault largeGallery />
          </Styled.EventRoomImage>

          <Styled.EventRoomSlider onClick={handleConferenceRoomClick}>
            <MobileGallery images={photos} hasLightbox={false} height={223} mediumGallery />
          </Styled.EventRoomSlider>

          <Styled.EventRoomTextWrapper>
            <Styled.Title>{text}</Styled.Title>
            <Styled.Description>{description}</Styled.Description>
          </Styled.EventRoomTextWrapper>

          <Styled.TagWrapper>
            {capacity && <Tag icon={CapacityIcon} value={capacity} />}
            {area && <Tag icon={AreaIcon} value={area} />}
          </Styled.TagWrapper>
        </Styled.EventRoomContainer>

        <Styled.ButtonsWrapper isHovered={isHovered}>
          <Button title={eventsHallBookText} primary onClick={handleBookingClick} />
          <Button title={eventsHallGalleryText} secondary onClick={handleConferenceRoomClick} />
        </Styled.ButtonsWrapper>
        <Styled.MobileButtonsWrapper>
          <Button title={eventsHallBookText} primary onClick={handleBookingClick} />
          <Button title={eventsHallGalleryText} secondary onClick={handleConferenceRoomClick} />
        </Styled.MobileButtonsWrapper>
      </Styled.EventRoom>
      <Modal isOpen={openLightBox} style={popupStylesSolidOverlay}>
        <EventsGallery images={photos} handleClose={handleCloseLightBox} />
      </Modal>
    </>
  );
};
