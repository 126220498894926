import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

import { Services } from "../../interfaces";

const basePath = "service";

const langId = Cookies.get("lang_id") || "";

export const servicesApi = createApi({
  reducerPath: "servicesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ROOT_API_URL,
    prepareHeaders: (headers) => {
      headers.set("User-Lang-Id", langId);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getServices: build.query<Services[], void>({
      query: () => `${basePath}/get-services`,
    }),
  }),
});

export const { useGetServicesQuery } = servicesApi;
