import { FC, useEffect, useRef, useState } from "react";
import Div100vh from "react-div-100vh";
import { LazyLoadImage } from "react-lazy-load-image-component";
import type { Swiper as SwiperType } from "swiper";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Picture } from "@src/components/_Ui";
import { NavigationButtons } from "@src/components/_Ui/DesktopGallery/NavigationButton";
import { useChangeSearchParams } from "@src/hooks/useChangeSearchParams";
import { useGetMenuData } from "@src/hooks/useGetMenuData";
import { useGetRoomData } from "@src/hooks/useGetRoomData";
import { Image } from "@src/interfaces";
import { EventImage } from "@src/interfaces/event.interfaces";

import { ReactComponent as Arrow } from "../../../assets/images/icons/arrow.svg";
import { Styled } from "./styles";
import { onBeforeInit, renderSliderFraction } from "./utils";

type EventsGalleryProps = {
  images: Image[];
  currentIndex?: number;
  handleClose: () => void;
};

export const EventsGallery: FC<EventsGalleryProps> = ({ images, currentIndex, handleClose }) => {
  const { getSearchParam } = useChangeSearchParams();
  const popupId = Number(getSearchParam("room"));
  const { name, h1 } = useGetRoomData(popupId);
  const { fractionTitlePhoto, roomDescriptionText } = useGetMenuData();

  const [swiper, setSwiper] = useState<SwiperType | null>(null);

  useEffect(() => {
    if (swiper) {
      swiper.init();
    }
  }, [swiper]);

  const imagesAmount = images.length;

  const [isNavigationShown, setIsNavigationShown] = useState(true);
  const roomTitle = name || h1;

  const buttonPrevRef = useRef<HTMLButtonElement | null>(null);
  const buttonNextRef = useRef<HTMLButtonElement | null>(null);
  const fractionRef = useRef<HTMLDivElement | null>(null);

  const handleClosePopupOnClick = () => {
    handleClose();
  };

  const handleBackgroundClick = () => {
    setIsNavigationShown(!isNavigationShown);
  };

  return (
    <>
      {images.length && (
        <Div100vh>
          <Styled.Lightbox>
            <Styled.BackButton onClick={handleClosePopupOnClick} showNavigation={!isNavigationShown}>
              <Arrow />
            </Styled.BackButton>
            <Styled.FractionContainer showNavigation={!isNavigationShown}>
              <Styled.FractionTitle> {fractionTitlePhoto} </Styled.FractionTitle>
              <Styled.Fraction ref={fractionRef}></Styled.Fraction>
            </Styled.FractionContainer>
            <Styled.GalleryWrapper onClick={handleBackgroundClick}>
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={3}
                slidesPerView={1}
                onSwiper={setSwiper}
                onInit={(swiper: SwiperType) => {
                  if (imagesAmount > 1 && currentIndex) {
                    swiper.slideTo(currentIndex + 1);
                  }
                  onBeforeInit(swiper, buttonPrevRef, buttonNextRef, fractionRef);
                }}
                navigation={{
                  nextEl: buttonNextRef.current,
                  prevEl: buttonPrevRef.current,
                }}
                loop
                loopedSlides={1}
                pagination={{
                  type: "fraction",
                  el: fractionRef.current,
                  renderFraction: renderSliderFraction,
                }}>
                {images.map((item, index) => {
                  return (
                    <SwiperSlide key={`${item.original.mime_type}/${index}`}>
                      <Picture img={item} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Styled.GalleryWrapper>
            {roomTitle && <Styled.Text showNavigation={!isNavigationShown}>{roomTitle}</Styled.Text>}
            {imagesAmount > 1 && <NavigationButtons prevRef={buttonPrevRef} nextRef={buttonNextRef} />}
          </Styled.Lightbox>
        </Div100vh>
      )}
    </>
  );
};
