import { FC } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";

import { useGetActionDetailedData } from "@src/hooks/useGetActionDetailedData";
import { useGetBlogArticleData } from "@src/hooks/useGetBlogArticleData";
import { useGetMenuData } from "@src/hooks/useGetMenuData";
import { useGetSpaServiceData } from "@src/hooks/useGetSpaServiceData";

import { Styled } from "./styles";

export const Breadcrumbs: FC = () => {
  const {
    roomsServicesTitle,
    mainText,
    contactsText,
    bookingTitle,
    infrastructureBreadcrumpText,
    eventsBreadcrump,
    weddingsBreadcrumb,
    actionsBreadcrumbsText,
    longStayBreadcrumps,
    blogBreadcrumps,
  } = useGetMenuData();

  const { articleSlug } = useParams();
  const { serviceId } = useParams();
  const { name: articleName } = useGetActionDetailedData(articleSlug);
  const { name: blogArticleName } = useGetBlogArticleData(articleSlug);
  const { name: serviceName } = useGetSpaServiceData(Number(serviceId));

  const location = useLocation();
  const isEventPage = location.pathname.includes("/events");
  const isPromoDetailedPage = location.pathname.includes("/promo/") && location.pathname.length > 8;
  const isBlogArticlePage = location.pathname.includes("/blog/") && location.pathname.length > 8;
  const isSpaServiceDetailedPage = location.pathname.includes("/spa/service");
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;

  const routesRussian = [
    { path: "/", breadcrumb: mainText },
    { path: "/rooms-and-services", breadcrumb: roomsServicesTitle },
    { path: "/long-stay", breadcrumb: longStayBreadcrumps },
    { path: "/contacts", breadcrumb: contactsText },
    { path: "/reservation", breadcrumb: bookingTitle },
    { path: "/reservation/guests-counter", breadcrumb: bookingTitle },
    { path: "/reservation/roomSelect", breadcrumb: bookingTitle },
    { path: "/reservation/reservation-pay", breadcrumb: bookingTitle },
    { path: "/events", breadcrumb: eventsBreadcrump },
    { path: "/infrastructure", breadcrumb: infrastructureBreadcrumpText },
    { path: "/weddings", breadcrumb: weddingsBreadcrumb },
    { path: "/promo", breadcrumb: actionsBreadcrumbsText },
    { path: "/blog", breadcrumb: blogBreadcrumps },
  ];
  const routesEnglish = [
    { path: "/en", breadcrumb: mainText },
    { path: "/en/rooms-and-services", breadcrumb: roomsServicesTitle },
    { path: "/en/long-stay", breadcrumb: longStayBreadcrumps },
    { path: "/en/contacts", breadcrumb: contactsText },
    { path: "/en/reservation", breadcrumb: bookingTitle },
    { path: "/en/reservation/guests-counter", breadcrumb: bookingTitle },
    { path: "/en/reservation/roomSelect", breadcrumb: bookingTitle },
    { path: "/en/reservation/reservation-pay", breadcrumb: bookingTitle },
    { path: "/en/events", breadcrumb: eventsBreadcrump },
    { path: "/en/infrastructure", breadcrumb: infrastructureBreadcrumpText },
    { path: "/en/weddings", breadcrumb: weddingsBreadcrumb },
    { path: "/en/promo", breadcrumb: actionsBreadcrumbsText },
    { path: "/blog", breadcrumb: blogBreadcrumps },
  ];

  const routes = !isEnglishVersion ? routesRussian : routesEnglish;

  const breadcrumbs = useBreadcrumbs(routes);

  if (isEnglishVersion) {
    breadcrumbs.splice(1, 1);
  }

  const isDetailedPage = isSpaServiceDetailedPage || isPromoDetailedPage || isBlogArticlePage;

  !isDetailedPage ? (breadcrumbs.length = 2) : (breadcrumbs.length = 3);

  return (
    <Styled.Breadcrumbs isEventPage={isEventPage} isDetailedPage={isDetailedPage}>
      <Styled.BreadcrumbsContainer>
        {breadcrumbs.map(({ match, breadcrumb }, index) => {
          const hasDivider = index < breadcrumbs.length - 1;
          const pathName: string = isEnglishVersion && match.pathname === "/" ? "/en" : match.pathname;
          let currentPathname = pathName;
          switch (pathName) {
            case "/reservation":
              currentPathname = "/reservation/guests-counter";
              break;
            case "/en/reservation":
              currentPathname = "/en/reservation/guests-counter";
              break;
            default:
              pathName;
          }

          if (isPromoDetailedPage && index === 2) {
            breadcrumb = articleName;
          }

          if (isBlogArticlePage && index === 2) {
            breadcrumb = blogArticleName;
          }

          if (isSpaServiceDetailedPage && index === 2) {
            breadcrumb = serviceName;
          }

          if (hasDivider) {
            return (
              <>
                <Link key={pathName} to={currentPathname}>
                  {breadcrumb}
                </Link>
                <Styled.BreadcrumbsDivider></Styled.BreadcrumbsDivider>
              </>
            );
          }
          return <Styled.BreadcrumbUnactiveLink key={pathName}>{breadcrumb}</Styled.BreadcrumbUnactiveLink>;
        })}
      </Styled.BreadcrumbsContainer>
    </Styled.Breadcrumbs>
  );
};
