import axios from "axios";

import { UserData } from "@src/interfaces";
import Cookies from "js-cookie";

const requestsPath = "mail";

const langId = Cookies.get("lang_id") || "";

export const sendRequestCallData = async (data: UserData) => {
  const response = await axios.post<UserData>(
    `${process.env.REACT_APP_ROOT_API_URL}/${requestsPath}/call-me`,
    {
      ...data,
    },
    {
      headers: {
        "User-Lang-Id": langId,
      },
    }
  );
  return response;
};
