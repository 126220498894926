import styled, { css } from "styled-components";

import { box, flexCenter, flexColumnCenterHorizontal, font, flexColumnCenter } from "@src/styles/mixins";

const EventTitle = styled.h2`
  flex-grow: 1;
  max-width: 250px;
  margin-bottom: 12px;
  ${font({ size: "20", lineHeight: "28", fontWeight: 700 })};
  text-align: center;
  word-break: break-word;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 0;
    text-align: left;
    ${font({ size: "16", lineHeight: "24", fontWeight: "500" })};
  }
`;

const EventDetailText = styled.p`
  ${font({ size: "14", lineHeight: "22" })};
  color: ${({ theme }) => theme.color.grey};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    ${font({ size: "12", lineHeight: "20", fontWeight: "400" })};
  }
`;

const EventDescription = styled.p`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
  ${font({ size: "18", lineHeight: "26", fontWeight: "700" })};
  white-space: nowrap;
  color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: none;
  }
`;

const EventImageWithHover = styled.img`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;

    object-fit: contain;
    object-position: center;
  }
`;

const EventImageNoHover = styled.img`
  display: block;

  object-fit: contain;
  object-position: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: none;
  }
`;

const AnimationWrapper = styled.div`
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: none;
  }
`;

const Event = styled.div<{ isHovered: boolean }>`
  cursor: pointer;
  position: relative;
  ${flexColumnCenterHorizontal};
  flex: 1;
  width: calc(100% / 5 - 24px);
  height: auto;
  padding: 86px 16px 40px;
  background-color: ${({ theme, isHovered }) => (isHovered ? theme.color.red : theme.color.white)};
  color: ${({ theme, isHovered }) => (isHovered ? theme.color.white : theme.color.black)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 100%;
    flex-direction: row;
    padding: 4px 24px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 100%;

    height: 100%;
    padding: 40px 32px;
    padding: 16px 24px;

    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.black};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    ${({ isHovered }) =>
      isHovered
        ? css`
            ${EventTitle}, ${EventDetailText} {
              opacity: 0;
              visibility: hidden;
            }

            ${EventDescription} {
              opacity: 1;
              visibility: visible;
            }

            ${EventImageWithHover} {
              opacity: 0;
              visibility: hidden;
              display: none;
            }

            ${AnimationWrapper} {
              opacity: 1;
              visibility: visible;
              display: block;
            }
          `
        : css`
            ${EventTitle}, ${EventDetailText} {
              opacity: 1;
              visibility: visible;
            }

            ${EventDescription} {
              opacity: 0;
              visibility: hidden;
            }

            ${EventImageWithHover} {
              opacity: 1;
              visibility: visible;
              display: block;
            }

            ${AnimationWrapper} {
              opacity: 0;
              visibility: hidden;
              display: none;
            }
          `};
  }
`;

const EventIconWrap = styled.div`
  ${box(72)}
  ${flexCenter}
margin-bottom: 52px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 0;
    margin-right: 35px;
  }
`;

export const Styled = {
  Event,
  EventIconWrap,
  EventTitle,
  EventDetailText,
  EventDescription,
  EventImageWithHover,
  EventImageNoHover,
  AnimationWrapper,
};
