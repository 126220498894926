import { FC, useState } from "react";
import Modal from "react-modal";

import { useActions, useGetBaseData, useHover } from "@src/hooks";
import { useChangeSearchParams } from "@src/hooks/useChangeSearchParams";
import { useGetMenuData } from "@src/hooks/useGetMenuData";
import { Rooms } from "@src/interfaces";

import { Button, MobileGallery, Picture, Tag } from "../../_Ui";
import { Styled } from "./styles";
import { RequestCallForm } from "../../RequestCallForm";
import { popupStyles } from "@src/styles/popupStyles";

type RoomCardPreviewProps = {
  data: Rooms;
  isLongStayPage?: boolean;
};

export const LongStayRoomCardPreview: FC<RoomCardPreviewProps> = ({ data, isLongStayPage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { openPopup, closePopup } = useActions();

  const { setQueryParams } = useChangeSearchParams();
  const { requestCallText, moreInfoButtonText } = useGetMenuData();
  const { id, img, preview_description, fill_values, name, photos } = data;

  const [hoverRef, isHovered, setIsHovered] = useHover<HTMLDivElement>();

  const handleRequestRoomClick = () => {
    setIsOpen((prevState) => !prevState);
    openPopup();
  };

  const handleClosePopupClick = () => {
    setIsOpen(false);
    closePopup();
  };

  const handleShowLongStayRoomClick = () => {
    setQueryParams(id, true);
    setIsHovered(false);
  };

  const imgToShowOnHover = photos[1] ? photos[1] : img;
  const imgToShow = !isHovered ? img : imgToShowOnHover;

  return (
    <>
      <Styled.RoomCard isLongStayPage={isLongStayPage} isHovered={isHovered} ref={hoverRef}>
        <Styled.RoomCardContainer>
          <Styled.RoomCardImage>
            <Picture img={imgToShow} visibleByDefault smallGallery />
          </Styled.RoomCardImage>

          <Styled.RoomCardSlider>
            <MobileGallery images={photos} hasLightbox={false} height={223} mediumGallery />
          </Styled.RoomCardSlider>

          <Styled.RoomCardTextWrapper>
            <Styled.Title>{name}</Styled.Title>
            <Styled.Description>{preview_description}</Styled.Description>
          </Styled.RoomCardTextWrapper>

          <Styled.TagWrapper>
            {fill_values.map((fv) => (
              <Tag key={fv.id} icon={fv.icon} value={fv.value} />
            ))}
          </Styled.TagWrapper>
          <Styled.RoomCardPrice></Styled.RoomCardPrice>
        </Styled.RoomCardContainer>
        <Styled.ButtonsWrapper isHovered={isHovered}>
          <Button title={requestCallText} primary onClick={handleRequestRoomClick} />

          <Button title={moreInfoButtonText} secondary onClick={handleShowLongStayRoomClick} />
        </Styled.ButtonsWrapper>
        <Styled.MobileButtonsWrapper>
          <Button title={requestCallText} primary onClick={handleRequestRoomClick} />
          <Button title={moreInfoButtonText} secondary onClick={handleShowLongStayRoomClick} />
        </Styled.MobileButtonsWrapper>
      </Styled.RoomCard>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={handleClosePopupClick}
        style={popupStyles}>
        <RequestCallForm handleClosePopup={handleClosePopupClick} itemId={id} />
      </Modal>
    </>
  );
};
