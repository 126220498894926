import styled from "styled-components";

import { flexVerticalCenter, font } from "@src/styles/mixins";

const Container = styled.div`
  ${flexVerticalCenter}
  justify-content: space-between;

  padding: 24px 0;

  ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.grey};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    flex-direction: column;
    padding: 25px 0;
  }
`;

const LeftContainer = styled.div`
  ${flexVerticalCenter}
  margin-right: 32px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 18px;
  }
`;

export const Styled = { Container, LeftContainer };
