import styled, { css } from "styled-components";

import { flexCenter, font } from "@src/styles/mixins";

const Rooms = styled.section`
  position: relative;

  width: calc(100% - 99px);
  gap: 48px;

  max-width: 1400px;
  margin: 0 auto;

  margin-bottom: 96px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: calc(100% - 48px);
    margin-bottom: 66px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: calc(100% - 40px);
    margin: 0 20px 64px;
  }
`;

const Title = styled.h2`
  margin-bottom: 64px;
  ${font({ size: "16", lineHeight: "24", fontWeight: "600" })};

  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;

  color: ${({ theme }) => theme.color.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 28px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
`;

export const Styled = {
  Rooms,
  Title,
  Container,
};
