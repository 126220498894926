import styled, { css } from "styled-components";

import { flexVerticalCenter, width100, flexColumn } from "@src/styles/mixins";

const Footer = styled.footer<{ hasBorderTop?: boolean; noMargin?: boolean }>`
  ${width100};
  background-color: ${({ theme }) => theme.color.white};
  margin-top: 117px;
  ${({ hasBorderTop }) =>
    hasBorderTop &&
    css`
      border-top: 1px solid ${({ theme }) => theme.color.greyBorder};
    `};

  ${({ noMargin }) =>
    noMargin &&
    css`
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        margin-top: 0;
      }
    `};
`;

const FooterContainer = styled.div`
  ${flexVerticalCenter}

  width: calc(100% - 70px);
  max-width: 1400px;
  margin: 0px auto;
  padding: 30px 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: calc(100% - 48px);
    margin: 64px auto 0px;
    padding: 41px 20px 7px;

    align-items: center;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: calc(100% - 40px);
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 0;
  }
`;

const LogoWrapper = styled.div`
  margin-right: 60px;
  padding: 28px 36px 28px 25px;
  background-color: ${({ theme }) => theme.color.greyBgLogo};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    padding: 0;
    background-color: unset;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-right: 0;
  }

  svg {
    width: 97px;
    height: 69px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
      width: 73px;
      height: 53px;
    }
  }
`;
const FooterNavigation = styled.div`
  ${width100}
  ${flexColumn}
`;

export const Styled = {
  Footer,
  FooterContainer,
  LogoWrapper,
  FooterNavigation,
};
