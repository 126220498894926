import styled, { css } from "styled-components";

import { box, flexCenter } from "@src/styles/mixins";

const Button = styled.button<{ isOpen: boolean; isOnTop: boolean; isMainPage: boolean; isGreyBackground: boolean }>`
  position: relative;
  z-index: 2;
  flex-direction: column;
  ${flexCenter};
  ${box(72)};
  min-width: 72px;
  min-height: 72px;
  height: 72px;
  background-color: ${({ theme, isOpen }) => (isOpen ? theme.color.white : theme.color.red)};
  transition: 0.3s;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    position: fixed;

    top: 27px;
    right: 16px;
    transition: unset;
    ${box(40)};
    min-width: 40px;
    min-height: 40px;
    background-color: ${({ theme }) => theme.color.white};

    ${({ isOpen, isOnTop }) =>
      !isOpen &&
      !isOnTop &&
      css`
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
      `};

    ${({ isOnTop }) =>
      isOnTop &&
      css`
        background-color: transparent;
      `};
  }

  ${({ isMainPage }) =>
    !isMainPage &&
    css`
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
        display: flex;
        top: 29px;
        background-color: ${({ theme }) => theme.color.white};
      }
    `};

  ${({ isOnTop, isGreyBackground }) =>
    isGreyBackground &&
    isOnTop &&
    css`
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
        background-color: ${({ theme }) => theme.color.greyHeader} !important;
      }
    `};

  ${({ isOpen, isGreyBackground }) =>
    isGreyBackground &&
    isOpen &&
    css`
      background-color: ${({ theme }) => theme.color.greyHeader};
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
        background-color: ${({ theme }) => theme.color.white} !important;
      }
    `};

  &:before,
  &:after {
    content: "";
    display: block;
    width: ${({ isOpen }) => (isOpen ? "21px" : "24px")};
    height: 2px;
    min-height: 2px;
    margin: 8px 0;
    border-radius: 3px;
    background-color: ${({ theme, isOpen }) => (isOpen ? theme.color.black : theme.color.white)};
    transition: transform 0.3s;

    ${({ isMainPage }) =>
      !isMainPage &&
      css`
        background-color: ${({ theme }) => theme.color.black};
      `};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
      margin: 6px 0;
      transition: unset;
      background-color: ${({ theme, isOnTop }) => (isOnTop ? theme.color.white : theme.color.black)};

      ${({ isMainPage }) =>
        !isMainPage &&
        css`
          background-color: ${({ theme }) => theme.color.black};
        `};
    }
  }

  &:before {
    ${({ isOpen }) =>
      isOpen &&
      css`
        margin: 8px 0;
        transform: translateY(10px) rotate(135deg);
      `};
  }

  &:after {
    ${({ isOpen }) =>
      isOpen &&
      css`
        margin: 8px 0;
        transform: translateY(-10px) rotate(-135deg);
      `};
  }

  ${({ isMainPage }) =>
    !isMainPage &&
    css`
      &:before,
      &:after {
        background-color: ${({ theme }) => theme.color.white};
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
        background-color: ${({ theme }) => theme.color.white};
        &:before,
        &:after {
          background-color: ${({ theme }) => theme.color.black};
        }
      }
    `};

  ${({ isMainPage, isOpen }) =>
    !isMainPage &&
    isOpen &&
    css`
      &:before,
      &:after {
        background-color: ${({ theme }) => theme.color.black};
      }
    `};

  ${({ isMainPage, isOpen }) =>
    isMainPage &&
    isOpen &&
    css`
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
        background-color: ${({ theme }) => theme.color.white};
        &:before,
        &:after {
          background-color: ${({ theme }) => theme.color.black};
        }
      }
    `};
`;

const Line = styled.div<{ isOpen: boolean; isOnTop: boolean; isMainPage: boolean }>`
  content: "";
  display: block;
  width: 24px;
  height: 2px;
  min-height: 2px;
  border-radius: 3px;
  background-color: ${({ theme, isOpen }) => (isOpen ? theme.color.black : theme.color.white)};
  transition: 0.3s;

  ${({ isMainPage }) =>
    !isMainPage &&
    css`
      background-color: ${({ theme }) => theme.color.white};
    `};
  ${({ isMainPage, isOpen }) =>
    !isMainPage &&
    isOpen &&
    css`
      background-color: ${({ theme }) => theme.color.black};
    `};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    transition: unset;
    background-color: ${({ theme, isOnTop }) => (isOnTop ? theme.color.white : theme.color.black)};

    ${({ isMainPage }) =>
      !isMainPage &&
      css`
        background-color: ${({ theme }) => theme.color.black};
      `};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: scale(0);
    `};

  ${({ isMainPage }) =>
    !isMainPage &&
    css`
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
        background-color: ${({ theme }) => theme.color.black};
      }
    `};
`;

export const Styled = { Button, Line };
