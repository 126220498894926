import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  firstScreenIsView: boolean;
  secondScreenOffsetTop: number;
  headerHeight: number;
}

const initialState: InitialState = {
  firstScreenIsView: true,
  secondScreenOffsetTop: 0,
  headerHeight: 0,
};

const headerAnimationSlice = createSlice({
  name: "headerAnimation",
  initialState,
  reducers: {
    setFirstScreenIsView: (state, action: PayloadAction<boolean>) => {
      state.firstScreenIsView = action.payload;
    },
    setSecondScreenOffset: (state, action: PayloadAction<number>) => {
      state.secondScreenOffsetTop = action.payload;
    },
    setHeaderHeight: (state, action: PayloadAction<number>) => {
      state.headerHeight = action.payload;
    },
  },
});

export const headerAnimationActions = headerAnimationSlice.actions;
export default headerAnimationSlice.reducer;
