import { flexCenter, font } from "@src/styles/mixins";
import styled, { css } from "styled-components";

const Menu = styled.div`
  position: relative;
  width: calc(100% - 40px);

  max-width: 1400px;
  margin: 0 auto 96px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    max-width: 1184px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
    margin-bottom: 64px;
  }
`;

const Container = styled.div<{ marginRight: number }>`
  padding: 64px 64px 40px 64px;
  background-color: ${({ theme }) => theme.color.white};
  transition: margin-right 0.5s ease;
  ${({ marginRight }) => css`
    margin-right: ${marginRight}px;
  `};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 48px 20px 32px;
    margin-right: 0;
  }
`;

const SectionTitleWrapper = styled.div`
  padding-bottom: 64px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`;

const SectionTitleWrapperMobile = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    padding-bottom: 47px;
  }
`;

const Accordeon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    gap: 16px;
  }
`;

const ImgContainer = styled.div`
  box-sizing: border-box;
  ${flexCenter};
  max-width: 435px;
  min-height: 279px;
  padding: 62px 108px;
  background-color: ${({ theme }) => theme.color.blue};

  svg {
    width: 220px;
    height: 156px;
  }
`;

const Text = styled.div`
  p {
    ${font({ size: "16", lineHeight: "24", fontWeight: "600" })};
    color: ${({ theme }) => theme.color.black};

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

export const Styled = {
  Menu,
  Container,
  SectionTitleWrapper,
  SectionTitleWrapperMobile,
  Accordeon,
  ImgContainer,
  Text,
};
