import { Link as RouterLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { font, absolutePseudoEl } from "@src/styles/mixins";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 40px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xxlarge}) {
    gap: 0 32px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;

    margin-bottom: 20px;
  }
`;

const Link = styled(RouterLink)<{ isPopupLink?: boolean }>`
  position: relative;
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    display: block;
    margin-bottom: 32px;
    ${font({ size: "16", lineHeight: "24" })};
    color: ${({ theme }) => theme.color.black};
  }

  ${({ isPopupLink }) =>
    isPopupLink &&
    css`
      font-weight: 400;

      &:hover {
        font-weight: 600;
      }
    `}
`;

const LinkWrapper = styled.div<{ isMainPage?: boolean; isActive?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 8px;

  &::after {
    ${absolutePseudoEl};
    left: 0;
    bottom: -8px;
    bottom: 0px;
    width: 100%;
    height: 3px;
    background-color: ${({ theme }) => theme.color.red};
    transform: ${(props) => (props.isActive ? "none" : "scaleX(0)")};
    transform-origin: bottom right;
    transition: transform 0s ease-out;
  }

  &:hover::after {
    transition: transform 0.5s ease-out;
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    padding: 0;

    &::after {
      height: 0;
    }
  }
`;

const LinkMenuWrapper = styled.div<{ isMainPage?: boolean; isActive?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 8px;

  &::after {
    ${absolutePseudoEl};
    left: 0;
    bottom: -8px;
    bottom: 0px;
    width: 100%;
    height: 3px;
    background-color: ${({ theme }) => theme.color.red};
    transform: ${(props) => (props.isActive ? "none" : "scaleX(0)")};
    transform-origin: bottom right;
    transition: transform 0s ease-out;
  }

  &:hover::after {
    transition: transform 0.5s ease-out;
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    padding: 0;

    &::after {
      height: 0;
    }
  }
`;

const LinksMenuTitle = styled.span`
  cursor: pointer;
  position: relative;
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: inherit;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    display: block;
    margin-bottom: 32px;
    ${font({ size: "16", lineHeight: "24" })};
    color: ${({ theme }) => theme.color.black};
  }
`;

const LinksMenuIcon = styled.div`
  margin-left: 5px;
  transform: rotate(180deg);
  svg {
    width: 14px;
  }
`;

const LinksMenuContainer = styled.div`
  display: none;
  min-width: 150px;
  position: absolute;
  padding: 12px 20px;
  bottom: -157px;
  left: -20px;

  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
`;

const EventsLinksMenuContainer = styled.div`
  display: none;
  min-width: 150px;
  position: absolute;
  z-index: 2;
  padding: 12px 20px;

  bottom: -88px;
  left: -20px;

  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
`;

const LinksMenu = styled.div<{
  isHovered?: boolean;
  isMainPage?: boolean;
  isSticky?: boolean;
  isHeaderBg: boolean;
  isGreyBackground?: boolean;
}>`
  position: relative;
  overflow: visible;

  ${({ isHovered }) =>
    isHovered &&
    css`
      ${LinksMenuContainer}, ${EventsLinksMenuContainer} {
        display: flex;
        flex-direction: column;
      }

      ${LinksMenuIcon} svg {
        transform: rotate(180deg);
      }
    `}

  ${({ isMainPage, isHeaderBg }) =>
    isMainPage &&
    isHeaderBg &&
    css`
      ${LinksMenuContainer}, ${EventsLinksMenuContainer} {
        color: ${({ theme }) => theme.color.black};
      }

      &{LinksMenuIcon} svg path{
        stroke: ${({ theme }) => theme.color.black};
      }
    `};

  ${({ isMainPage, isHeaderBg }) =>
    isMainPage &&
    !isHeaderBg &&
    css`
      ${LinksMenuContainer}, ${EventsLinksMenuContainer} {
        color: ${({ theme }) => theme.color.white};
        background-color: transparent;
        box-shadow: none;
      }

      &{LinksMenuIcon} svg path{
        stroke: white
      }
    `};

  ${({ isGreyBackground }) =>
    isGreyBackground &&
    css`
      ${LinksMenuContainer}, ${EventsLinksMenuContainer} {
        background-color: ${({ theme }) => theme.color.greyHeader};
      }
    `};
`;

export const Styled = {
  Wrapper,
  Link,
  LinkWrapper,
  LinkMenuWrapper,
  LinksMenu,
  LinksMenuTitle,
  LinksMenuContainer,
  EventsLinksMenuContainer,
  LinksMenuIcon,
};
