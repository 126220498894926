import { SocialNetworks } from "@src/interfaces";
import { useGetBaseDataQuery } from "@src/store/services";

type ReturnedData = {
  socialLinks: SocialNetworks[] | undefined;
};

export const useGetSocialLinks = (): ReturnedData => {
  const { data } = useGetBaseDataQuery();

  const socialLinks = data?.social;

  return { socialLinks };
};
