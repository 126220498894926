import { FC } from "react";

import { Styled } from "./styles";

type TagProps = {
  value: string;
  icon?: string;
  small?: boolean;
};

export const Tag: FC<TagProps> = ({ value, icon, small }) => (
  <Styled.Tag>
    {icon && <Styled.Icon src={icon} alt={value} small={small} />}
    <Styled.Text> {value}</Styled.Text>
  </Styled.Tag>
);
