import styled from "styled-components";

import { absolutePseudoEl, transformXCenter, width100 } from "@src/styles/mixins";

const Container = styled.div`
  width: calc(100% - 40px);
  max-width: 1400px;
  position: absolute;
  z-index: 2;
  bottom: 77px;
  ${transformXCenter};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    max-width: 1184px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    bottom: 22px;
  }
`;

const Content = styled.div`
  display: flex;
  gap: 0 12px;

  & button {
    padding: 20px 27px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
      padding: 0;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    flex-direction: column;
  }
`;

const Form = styled.div`
  ${width100};
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: 3px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(100% - 0.2px);
    margin-right: 0;
  }
`;

const InputContainer = styled.div`
  position: relative;
  flex: 1;
  margin: 0 -1px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    flex: unset;
    width: calc(50% - 4px);
    margin: 0;
    margin-bottom: 8px;

    &:last-child {
      ${width100};
    }
  }
`;

const Separator = styled.div`
  position: relative;
  z-index: 1;
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: none;
  }

  &:before {
    ${absolutePseudoEl};
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 48px;
    background-color: ${({ theme }) => theme.color.greyBorder};
  }
`;

export const Styled = { Container, Content, Form, Separator, InputContainer };
