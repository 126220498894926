import { FC, useEffect, useRef, useState } from "react";
import { Styled } from "./styles";
import { ContentWrapper, SectionTitle } from "@src/components/_Ui";
import { SpaAccordeon } from "./components/SpaAccordeon";
import { spaData } from "@src/mock/spa";
import { SpaDetailed } from "./components/SpaDetailed";
import { theme } from "@src/theme";
import { useGetBaseData, useMediaQuery } from "@src/hooks";
import { useGetSpaMenuDataQuery } from "@src/store/services";

const DETAILED_PANEL_WIDTH_DESKTOP = 622;

export const SpaMenu: FC = () => {
  const { data: menu, isLoading: isMenuLoading } = useGetSpaMenuDataQuery();
  const { spaMenuTitle } = useGetBaseData();

  // const data = isEnglishVersion ? spaData.menuEng : spaData.menu;
  const isAdaptive = useMediaQuery(theme.breakpoints.xlarge);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState<number | null>(null);
  const [marginRight, setMarginRight] = useState(0);

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
    if (isAdaptive) {
      document.body.classList.add("hidden");
    }
  };

  const handleMenuClose = () => {
    document.body.classList.remove("hidden");
    setIsMenuOpen(false);
    setActiveMenu(null);
  };

  const handleMenuChose = (id: number) => {
    setActiveMenu(id);
    handleMenuOpen();
  };

  const handleMarginSet = (element: HTMLDivElement) => {
    const { x: horizontalMargin } = element.getBoundingClientRect();
    const marginRightValue = DETAILED_PANEL_WIDTH_DESKTOP - horizontalMargin;
    if (isMenuOpen && !isAdaptive) {
      setMarginRight(marginRightValue);
    } else {
      setMarginRight(0);
    }
  };

  useEffect(() => {
    if (menuRef.current) {
      handleMarginSet(menuRef.current);
      window.addEventListener("resize", () => {
        handleMarginSet(menuRef.current!);
      });
    }

    return window.removeEventListener("resize", () => {
      handleMarginSet(menuRef.current!);
    });
  }, [menuRef.current, isMenuOpen]);

  const menuData = menu;

  if (isMenuLoading && !menuData?.length) {
    return null;
  }

  return (
    <Styled.Menu>
      <ContentWrapper>
        <Styled.Container ref={menuRef} marginRight={marginRight}>
          <Styled.SectionTitleWrapper>
            <SectionTitle text={spaMenuTitle} />
          </Styled.SectionTitleWrapper>
          <Styled.SectionTitleWrapperMobile>
            <SectionTitle text={spaMenuTitle} />
          </Styled.SectionTitleWrapperMobile>
          <Styled.Accordeon>
            {menu?.map((menu, index) => {
              return (
                <SpaAccordeon
                  data={menu}
                  key={`menu-${index}`}
                  activeMenu={activeMenu}
                  isOpen={isMenuOpen}
                  onOpen={handleMenuChose}
                />
              );
            })}
          </Styled.Accordeon>
        </Styled.Container>
      </ContentWrapper>
      <SpaDetailed isOpen={isMenuOpen} onClose={handleMenuClose} activeMenu={activeMenu} />
    </Styled.Menu>
  );
};
