import styled, { css } from "styled-components";

import { box } from "@src/styles/mixins";

const Button = styled.button<{ isOpened: boolean }>`
  display: none;

  ${(props) =>
    props.isOpened &&
    css`
      display: block;
      ${box(48)}
      background-color: ${({ theme }) => theme.color.white};
      border: 1px solid ${({ theme }) => theme.color.lightGreyBorder};

      transform: rotate(0deg);
    `}

  &:active, &:focus {
    border: 1px solid ${({ theme }) => theme.color.lightGreyBorder};
  }
`;

export const Styled = {
  Button,
};
