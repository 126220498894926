import React, { FC } from "react";

import { ReactComponent as Shevron } from "../../../assets/images/icons/shevron-up.svg";
import { Styled } from "./styles";

interface ShowMoreButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  isOpened: boolean;
  handleOpen: () => void;
}

export const ShowMoreButton: FC<ShowMoreButtonProps> = ({ isOpened, handleOpen }) => {
  return (
    <Styled.Button isOpened={isOpened} onClick={handleOpen}>
      <Shevron />
    </Styled.Button>
  );
};
