import { FC } from "react";

import { SocialLink } from "@src/components/_Ui";
import { useGetMenuData } from "@src/hooks/useGetMenuData";

import { Styled } from "./styles";
import { useGetSocialLinks } from "./useGetSocialLinks";

export const SocialNetworkLinks: FC = () => {
  const { socialLinks } = useGetSocialLinks();
  const { socialNetworksText } = useGetMenuData();

  return (
    <>
      {socialLinks && (
        <Styled.Container>
          <Styled.Title>{socialNetworksText}</Styled.Title>
          <Styled.Links>
            {socialLinks.map((item, index) => {
              return <SocialLink {...item} key={`${name}/%${index}`} />;
            })}
          </Styled.Links>
        </Styled.Container>
      )}
    </>
  );
};
