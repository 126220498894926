import Cookies from "js-cookie";
import { FC } from "react";
import { useLocation } from "react-router-dom";

import { ENGLISH_LANG_ID, RUSSIAN_LANG_ID } from "@src/utils/constants";

import { Styled } from "./styles";

type ChangeLangProps = {
  languageId?: number;
  name: string;
  icon: string;
  isMenuOpen?: boolean;
};

export const ChangeLang: FC<ChangeLangProps> = ({ languageId, name, icon, isMenuOpen }) => {
  const location = useLocation();
  const locationPathname = location.pathname;
  const isMainPage = location.pathname === "/" || location.pathname === "/en";
  const isSpaPage = location.pathname.includes("/spa");

  const handleLanguageURLUpdate = (languageId: number): string => {
    let newLocationPathname = "";
    if (languageId == ENGLISH_LANG_ID) {
      const isMainPage = locationPathname === "/";
      newLocationPathname = isMainPage ? "/en" : `/en${locationPathname}`;
      return newLocationPathname;
    } else if (languageId == RUSSIAN_LANG_ID) {
      const newLocationArray = locationPathname.split("/");
      newLocationArray.splice(0, 2);
      newLocationPathname = `/${newLocationArray.join("/")}`;
      return newLocationPathname;
    }
    return newLocationPathname;
  };

  const handleLangClick = async () => {
    if (languageId) {
      Cookies.set("lang_id", String(languageId));

      const newUrl = handleLanguageURLUpdate(languageId);
      window.location.href = newUrl;
    }
  };

  return (
    <Styled.ChangeLang onClick={handleLangClick}>
      <Styled.LangIcon>
        <img src={icon} />
      </Styled.LangIcon>
      <Styled.LangText isMainPage={isMainPage || isSpaPage} isOpen={isMenuOpen}>
        {name}
      </Styled.LangText>
    </Styled.ChangeLang>
  );
};
