import { IBookingSearch, IPrice, IRate } from "@src/interfaces";
import { GuestInRateType, RoomWithCountType } from "./ChooseRoom";

export const deleteDuplicateObjectsFromArray = (array: any, key: string) => {
  return array.reduce((uniqueArr: any, item: any) => {
    if (!uniqueArr.find((v: any) => v[key] == item[key])) {
      uniqueArr.push(item);
    }

    return uniqueArr;
  }, []);
};

export const getSelectedRatesIDs = (rateCounter: { [key: string]: number }) => {
  const rateCounterClone = structuredClone(rateCounter);
  for (const rate in rateCounterClone) {
    if (rateCounterClone[rate] === 0) {
      delete rateCounterClone[rate];
    }
  }

  return Object.keys(rateCounterClone);
};

export const handleGetUpdatedRatesOfSelectedRooms = (rates: IRate[], selectedRatesIDs: string[]) => {
  const allRatesPricesArr = rates
    .map((room) => {
      return room.prices;
    })
    .flat();

  const formattedRatesArray = allRatesPricesArr.map((item) => {
    return {
      id: item.id,
      adult_count: item.adult_count,
      child_under_11_count: item.child_under_11_count,
      child_under_5_count: item.child_under_5_count,
      total: item.total,
    };
  });

  const allRatesPricesArrWithUniqueIDs: IPrice[] = deleteDuplicateObjectsFromArray(formattedRatesArray, "id");

  const updatedRatesOfSelectedRooms = allRatesPricesArrWithUniqueIDs
    .filter((item) => {
      return selectedRatesIDs.includes(item.id);
    })
    .map((item) => {
      const rateUniqueCode = JSON.stringify({
        adult_count: item.adult_count,
        child_under_11_count: item.child_under_11_count,
        child_under_5_count: item.child_under_5_count,
      });
      return {
        rate_id: item.id,
        adult_count: item.adult_count,
        child_under_11_count: item.child_under_11_count,
        child_under_5_count: item.child_under_5_count,
        rate_string: rateUniqueCode,
      };
    });

  return updatedRatesOfSelectedRooms;
};

type ProcessedDataEntry = {
  id: string;
  count: number;
  data: GuestInRateType;
};

export const getRatesToChooseWithAmountInfo = (guestsData: IBookingSearch) => {
  const updatedProcessedData: Record<string, ProcessedDataEntry> = {};
  guestsData.rooms.forEach((item) => {
    const formattedItem = {
      adult_count: item.adult_count,
      child_under_11_count: item.child_under_11_count,
      child_under_5_count: item.child_under_5_count,
    };
    const stringifiedItem = JSON.stringify(formattedItem);
    if (!updatedProcessedData[stringifiedItem]) {
      updatedProcessedData[stringifiedItem] = {
        id: stringifiedItem,
        count: 0,
        data: item,
      };
    }
    updatedProcessedData[stringifiedItem].count++;
  });

  const updatedRoomsWithCount = Object.values(updatedProcessedData).map((entry) => ({
    ...entry.data,
    count: entry.count,
    id: entry.id,
  }));
  return updatedRoomsWithCount;
};

export const updateAmountOfRatesToChoose = (
  ratesToChooseWithAmount: RoomWithCountType[],
  allRatesOfSelectedRooms: GuestInRateType[]
) => {
  const updatedChosenRoomsWithCounter = ratesToChooseWithAmount.map((currentItem, index) => {
    const matchingFact = allRatesOfSelectedRooms.find(
      (itemFact) =>
        currentItem.adult_count === itemFact.adult_count &&
        currentItem.child_under_5_count === itemFact.child_under_5_count &&
        currentItem.child_under_11_count === itemFact.child_under_11_count
    );

    // если я выбрал комнату, то вычитаю единицу из количества

    if (matchingFact) {
      const rateOfCurrentItem = matchingFact.rate_string;

      const amountOfCurrentRate = allRatesOfSelectedRooms.filter((item) => {
        return item.rate_string === rateOfCurrentItem;
      }).length;

      const objWithUpdatedAmount = {
        ...currentItem,
        count: currentItem.count - amountOfCurrentRate!,
      };

      return objWithUpdatedAmount;
    } else {
      return currentItem;
    }
  });
  return updatedChosenRoomsWithCounter;
};
