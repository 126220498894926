import { FC, useState } from "react";
import { Styled } from "./styles";
import { useActions, useGetBaseData } from "@src/hooks";
import Modal from "react-modal";
import { Button } from "@src/components/_Ui";
import { RequestCallForm } from "@src/components/RequestCallForm";
import { popupStyles } from "@src/styles/popupStyles";

export const SpaRequestCallButton: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { openPopup, closePopup } = useActions();
  const { spaSignUpOnline } = useGetBaseData();

  const handleClosePopupClick = () => {
    setIsOpen(false);
    closePopup();
  };

  const handleOnRequestBtnClick = () => {
    setIsOpen((prevState) => !prevState);
    openPopup();
  };

  return (
    <>
      <Styled.Container>
        <Button title={spaSignUpOnline} blue onClick={handleOnRequestBtnClick} />
      </Styled.Container>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={handleClosePopupClick}
        style={popupStyles}>
        <RequestCallForm handleClosePopup={handleClosePopupClick} />
      </Modal>
    </>
  );
};
