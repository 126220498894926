import styled, { css } from "styled-components";

import { flexCenter, font } from "@src/styles/mixins";

const Container = styled.div<{ headerHeight: number }>`
  ${flexCenter};
  ${font({ size: "56", lineHeight: "60", fontWeight: 700 })};
  text-align: center;

  ${({ headerHeight }) => css`
    height: calc(100vh - ${headerHeight}px);
    margin-top: ${headerHeight}px;
  `};
`;

const PageHeader = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

export const Styled = { Container, PageHeader };
