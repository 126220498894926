import styled, { css } from "styled-components";

import { font } from "@src/styles/mixins";

const ChangeLang = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0 12px;
  min-width: 60px;

  color: ${({ theme }) => theme.color.black};
`;

const LangIcon = styled.button`
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const LangText = styled.span<{ isMainPage?: boolean; isOpen?: boolean }>`
  color: inherit;
  ${font({ size: "18", lineHeight: "26", fontWeight: "600" })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    ${font({ size: "14", lineHeight: "22" })};
    color: ${({ theme }) => theme.color.black};
  }

  ${({ isMainPage, isOpen }) =>
    isMainPage &&
    !isOpen &&
    css`
      color: ${({ theme }) => theme.color.white};
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
        color: ${({ theme }) => theme.color.white};
      }
    `};

  ${({ isOpen }) =>
    isOpen &&
    css`
      color: ${({ theme }) => theme.color.black};
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
        color: ${({ theme }) => theme.color.black};
      }
    `};
`;

export const Styled = { ChangeLang, LangIcon, LangText };
