import { MetaTags } from "@src/interfaces";
import { useGetMetaDataQuery } from "@src/store/services/metatags.api";

type ReturnedData = {
  data?: MetaTags;
  isLoading: boolean;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
};

export const useGetMetaData = (currentLocation: string): ReturnedData => {
  const { data, isLoading } = useGetMetaDataQuery(currentLocation);

  const metaTitle = data ? data?.title : "";
  const metaDescription = data ? data?.description : "";
  const metaKeywords = data ? data?.keywords : "";

  return {
    data,
    isLoading,
    metaTitle,
    metaDescription,
    metaKeywords,
  };
};
