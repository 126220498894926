import styled, { css } from "styled-components";

import { absolutePseudoEl, box, font } from "@src/styles/mixins";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
`;

const Label = styled.label`
  position: relative;
  display: block;
  width: 100%;
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.black};
`;

const Placeholder = styled.span<{ isInputFilled?: boolean; isInputRequired?: boolean; hasInputValue?: boolean }>`
  position: absolute;
  top: 21px;
  left: 20px;
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.black};

  ${(props) =>
    props.isInputFilled &&
    css`
      top: 10px;
      left: 20px;
      ${font({ size: "12", lineHeight: "20", fontWeight: "400" })}
      color: ${({ theme }) => theme.color.grey};
    `}

  ${(props) =>
    props.isInputRequired &&
    css`
      &::after {
        ${absolutePseudoEl};
        width: 4px;
        height: 4px;
        border-radius: 50%;
        top: 3px;

        right: -9px;
        background-color: ${({ theme }) => theme.color.red};
      }
    `}
`;

const Input = styled.input<{
  hasInputError?: boolean;
  hasInputIcon?: boolean;
  hasValue?: boolean;
  isInputFilled?: boolean;
}>`
  width: calc(100% - 40px);
  padding: 30px 20px 10px;
  border: 1px solid ${({ theme }) => theme.color.greyBorder};

  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.black};

  ${(props) =>
    props.isInputFilled &&
    css`
      padding: 30px 20px 10px;
      & ~ ${Placeholder} {
        top: 3px;
        top: 11px;
        left: 20px;

        ${font({ size: "12", lineHeight: "20", fontWeight: "400" })}
        color: ${({ theme }) => theme.color.grey};
      }
    `}

  &:focus {
    padding: 30px 20px 10px;

    & ~ ${Placeholder} {
      top: 3px;
      top: 11px;
      left: 20px;

      ${font({ size: "12", lineHeight: "20", fontWeight: "400" })}
      color: ${({ theme }) => theme.color.grey};
    }
  }

  ${(props) =>
    props.hasInputError &&
    css`
      border-color: ${({ theme }) => theme.color.red};
    `}

  ${(props) =>
    props.hasInputIcon &&
    css`
      box-sizing: border-box;
      width: 100%;
      padding-right: 50px;
    `}
`;

const Icon = styled.img`
  ${box(24)};
  position: absolute;
  right: 19px;
  top: 19px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    right: 19px;
    top: 19px;
  }
`;

const ErrorMessage = styled.span<{ staticError?: boolean }>`
  ${font({ size: "13", lineHeight: "18", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.red};
  position: absolute;
  bottom: -20px;

  ${(props) =>
    props.staticError &&
    css`
      position: static;
    `}
`;

const FlagContainer = styled.span`
  position: absolute;
  top: 25px;
  right: 21px;

  & img {
    border: 0.5px solid #dbdbdb;
    width: 22px;
    height: auto;
    object-fit: contain;
  }
`;

export const Styled = { Container, Label, Input, Placeholder, Icon, ErrorMessage, FlagContainer };
