import styled from "styled-components";

import { font, width100 } from "@src/styles/mixins";

const ButtonsWrapper = styled.div<{ isHovered?: boolean }>`
  position: absolute;
  bottom: 0;
  opacity: ${({ isHovered }) => (!isHovered ? "0" : "1")};

  left: 0;
  width: 100%;
  height: 67px;
  display: flex;
  align-items: center;
  align-items: stretch;
  background-color: ${({ theme }) => theme.color.black};
  transition: all 0.3s;

  button {
    ${width100}
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    height: 48px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    display: none;
  }
`;

const MobileButtonsWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 19px 28px 28px;
    border-top: 1px solid ${({ theme }) => theme.color.greyBorder};
  }
`;

const EventRoom = styled.div<{ isMainPage?: boolean; isHovered?: boolean }>`
  box-sizing: border-box;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  width: calc(100% / 3 - 32px);
  min-height: 279px;
  background-color: ${({ theme }) => theme.color.white};
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: calc(100% / 2 - 32px);
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    min-height: unset;
    width: 100%;
    height: 100%;
  }
`;

const EventRoomContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const EventRoomImage = styled.div`
  display: block;
  ${width100};
  height: 280px;
  overflow: hidden;

  img {
    width: 100%;
    height: 280px;
    object-fit: cover;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    height: 242px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    height: 223px;

    img {
      height: 223px;
      object-fit: cover;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`;

const EventRoomSlider = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    ${width100};
    overflow: hidden;
    height: 223px;

    img {
      height: 223px;
      object-fit: cover;
    }
  }
`;

const EventRoomTextWrapper = styled.div`
  padding: 28px 32px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 29px 26px 0;
  }
`;

const Image = styled.img`
  object-fit: cover;
`;

const Title = styled.div`
  margin-bottom: 12px;
  ${font({ size: "20", lineHeight: "28", fontWeight: "700" })};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 12px;
    ${font({ size: "20", lineHeight: "26", fontWeight: "700" })};
  }
`;

const Description = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${font({ size: "14", lineHeight: "22" })};
  color: ${({ theme }) => theme.color.grey};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    margin-bottom: 30px;
    flex-grow: 1;
  }
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: auto;

  padding: 40px 32px 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    padding: 41px 32px 20px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 0;
    margin-bottom: 17px;
    padding: 0 26px;
  }
`;

export const Styled = {
  EventRoom,
  EventRoomContainer,
  EventRoomImage,
  EventRoomSlider,
  EventRoomTextWrapper,
  Image,
  Title,
  Description,
  TagWrapper,

  ButtonsWrapper,
  MobileButtonsWrapper,
};
