import styled, { css } from "styled-components";

import { box } from "../../styles/mixins";

const Button = styled.button<{ hasSpace?: boolean }>`
  cursor: pointer;
  position: fixed;
  z-index: 2;
  bottom: 80px;
  right: 12px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    bottom: 20px;
  }

  ${({ hasSpace }) =>
    hasSpace &&
    css`
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        bottom: 80px;
      }
    `};

  ${box(48)}
  background-color: ${({ theme }) => theme.color.black};

  svg path {
    stroke: ${({ theme }) => theme.color.white};
  }
`;

export const Styled = { Button };
