import styled, { css } from "styled-components";

import { box, flexCenter, flexColumnCenterHorizontal, font, flexColumnCenter } from "@src/styles/mixins";

const Feature = styled.div`
  position: relative;
  min-width: 392px;
  max-width: 392px;

  color: ${({ theme }) => theme.color.white};
  ${font({ size: "20", lineHeight: "28", fontWeight: "700" })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xxlarge}) {
    min-width: 355px;
    max-width: 355px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    min-width: unset;
    ${font({ size: "16", lineHeight: "22", fontWeight: "700" })};
  }

  &::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    width: 48px;
    height: 3px;
    background-color: ${({ theme }) => theme.color.red};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
      width: 40px;
      top: -22px;
    }
  }
`;

export const Styled = {
  Feature,
};
