import { FC } from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { PaginationOptions } from "swiper/types";

import { IconButton } from "@src/components/_Ui";
import { useMediaQuery } from "@src/hooks";
import { InfrastructureRoomType } from "@src/interfaces/infrastructure.interfaces";
import { theme } from "@src/theme";

import {
  SLIDES_PER_GROUP,
  NAVIGATION_NEXT_CLASS_NAME,
  NAVIGATION_PREV_CLASS_NAME,
  PAGINATION_CLASS_NAME,
  SLIDE_DELAY,
  SLIDES_SPACE_BETWEEN,
  SWIPER_BREAKPOINTS,
  SLIDES_PER_GROUP_MOBILE,
} from "./constants";
import { renderBullet } from "./renderBullet";
import { Styled } from "./styles";
import { SpaInfrastructureCard } from "../SpaInfrastructure/SpaInfrastructureCard";
import { SpaService, SpaServices } from "@src/interfaces/spa.interface";
import { SpaServiceCard } from "../SpaServices/SpaServiceCard";

type SpaCardsCarouselProps = {
  cards: SpaServices[] | InfrastructureRoomType[];
  isService?: boolean;
};

export const SpaCardsCarousel: FC<SpaCardsCarouselProps> = ({ cards, isService }) => {
  const pagination: PaginationOptions = {
    el: `.${PAGINATION_CLASS_NAME}`,
    type: "bullets",
    clickable: true,
    renderBullet,
  };

  const isAdaptive = useMediaQuery(theme.breakpoints.large);
  const iconBtnPrevVariant = !isAdaptive ? <Styled.NavigationChevronLeft /> : <Styled.NavigationArrowLeft />;
  const iconBtnNextVariant = !isAdaptive ? <Styled.NavigationChevronRight /> : <Styled.NavigationArrowRight />;
  const iconBtnSize = !isAdaptive ? "medium" : "mediumMobile";
  const iconVariant = !isAdaptive ? "outline" : "transparent";
  const slidesPerGroupValue = !isAdaptive ? SLIDES_PER_GROUP : SLIDES_PER_GROUP_MOBILE;

  return (
    <Styled.CarouselWrapper>
      {cards.length && (
        <Swiper
          modules={[Pagination, Autoplay, Navigation]}
          navigation={{
            prevEl: `.${NAVIGATION_PREV_CLASS_NAME}`,
            nextEl: `.${NAVIGATION_NEXT_CLASS_NAME}`,
          }}
          breakpoints={SWIPER_BREAKPOINTS}
          pagination={pagination}
          spaceBetween={SLIDES_SPACE_BETWEEN}
          allowTouchMove={true}
          slidesPerGroup={slidesPerGroupValue}
          watchSlidesProgress>
          {cards?.map((card) => (
            <SwiperSlide key={card.id}>
              {isService ? (
                <SpaServiceCard data={card as SpaServices} />
              ) : (
                <SpaInfrastructureCard data={card as InfrastructureRoomType} />
              )}
            </SwiperSlide>
          ))}

          <Styled.NavigationWrapper>
            <div className={NAVIGATION_PREV_CLASS_NAME}>
              <IconButton icon={iconBtnPrevVariant} size={iconBtnSize} variant={iconVariant} />
            </div>

            <div className={PAGINATION_CLASS_NAME} />

            <div className={NAVIGATION_NEXT_CLASS_NAME}>
              <IconButton icon={iconBtnNextVariant} size={iconBtnSize} variant={iconVariant} />
            </div>
          </Styled.NavigationWrapper>
        </Swiper>
      )}
    </Styled.CarouselWrapper>
  );
};
