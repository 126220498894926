import { format } from "date-fns";

import { FROM_TO_DATE_FORMAT } from "@src/constants";
import { Content, IGetReservation } from "@src/interfaces";

export const getClientData = (data: IGetReservation, bookingCancelText: string) => {
  const { date_from, date_to, content, canceled } = data;
  const clientName = content[0].rate.buyer.name;
  const clientLastName = content[0].rate.buyer.lastname;
  const clientFullName = `${clientName} ${clientLastName}`;
  const bookings: (string | undefined)[] = [];
  content.forEach((item: Content) => {
    bookings.push(item.current_external_id);
  });
  const bookingNumbers = bookings.join(", ");

  const dateFrom = format(new Date(date_from), FROM_TO_DATE_FORMAT);
  const dateTo = format(new Date(date_to), FROM_TO_DATE_FORMAT);

  const formatedCancellationText = bookingCancelText
    .replace("#GUEST#", clientFullName)
    .replace("#BOOKINGS#", bookingNumbers)
    .replace("#FROM#", dateFrom)
    .replace("#TO#", dateTo);

  const isBookingCancelled = Boolean(canceled);

  return { canceled, formatedCancellationText, isBookingCancelled };
};

export const getFeeData = (data: IGetReservation, bookingCancelFeeText: string) => {
  const { content } = data;

  const feeAmount = content.reduce((acc, currentValue) => {
    const firstFee = Object.entries(currentValue?.prices_by_date)[0][1];
    return acc + Number(firstFee);
  }, 0);

  const formatedFeeCancellationText = bookingCancelFeeText.replace("#SUM#", String(feeAmount));
  return formatedFeeCancellationText;
};
