import { addDays, format } from "date-fns";
import { FC, useEffect, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { ReactComponent as AddIcon } from "@src/assets/images/icons/add.svg";
import { Button, GuestCounter, ModalCustom, ModalProps } from "@src/components";
import { useActions, useAppSelector, useGetBaseData, useMediaQuery } from "@src/hooks";
import { GuestsListType } from "@src/interfaces/guest.interface";
import { useGetBookingMaxCountQuery } from "@src/store/services";
import { getDates } from "@src/store/slices/datepicker/datepicker.selector";
import { theme } from "@src/theme";
import { declOfNum } from "@src/utils";

import { Styled } from "./styles";

type GuestModalProps = ModalProps;

export const GuestModal: FC<GuestModalProps> = ({ onModalCloseClick, ...props }) => {
  const {
    data: languagesData,
    guestsCountList,
    guestsCount,
    numberGuestsText,
    addRoomText,
    noMoreRoomsText,
    readyText,
    guestsDecl,
  } = useGetBaseData();
  const { setGuests } = useActions();
  const isMobile = useMediaQuery(theme.breakpoints.medium);
  const { control, watch, setValue, reset } = useForm<GuestsListType>({ defaultValues: guestsCountList });
  const { fields, append, remove } = useFieldArray({
    name: "guestList",
    control,
  });

  const handleLanguagesPhrasesUpdate = () => {
    reset(guestsCountList);
  };

  useEffect(() => {
    handleLanguagesPhrasesUpdate();
  }, [languagesData]);

  const { arrivalDateValue, departureDateValue } = useAppSelector(getDates);

  const body = {
    date_from: arrivalDateValue,
    date_to: departureDateValue,
    room_id: null,
  };

  const { data: bookingMaxCount } = useGetBookingMaxCountQuery(body, {
    skip: !arrivalDateValue && !departureDateValue,
  });

  const handleAppendClick = () => {
    append({ ...guestsCount, roomNumber: fields.length + 1 });
  };

  const handleRemoveClick = (index: number) => {
    remove(index);
  };

  const handleSubmitClick = () => {
    setGuests(watchAllFields.guestList);
    onModalCloseClick();
    reset();
  };

  const watchAllFields = watch();
  const sumCount = useMemo(() => {
    return watchAllFields.guestList.flatMap((guest) => guest.guests).reduce((acc, curr) => acc + curr.count, 0);
  }, [watchAllFields]);

  const isAddNewRoomShow =
    bookingMaxCount?.max_available_rooms && watchAllFields.guestList.length < bookingMaxCount?.max_available_rooms;

  return (
    <ModalCustom
      onModalCloseClick={onModalCloseClick}
      withButton
      buttonTitle={readyText}
      withHeader
      title={isMobile ? "" : numberGuestsText}
      {...props}
      snowCloseButton={isMobile ? false : true}
      closeIconStroke={theme.color.greyPale}
      onSubmitClick={handleSubmitClick}
      hasFixedFooter={true}>
      <Styled.GuestCount>
        {`${sumCount} ${declOfNum(sumCount, guestsDecl)}`}
        <Styled.CloseButton onClick={onModalCloseClick} />
      </Styled.GuestCount>

      <form>
        {fields.map((field, indexParent) => (
          <GuestCounter
            key={field.id}
            onRemoveClick={handleRemoveClick}
            setValue={setValue}
            indexParent={indexParent}
            field={field}
            showDeleteButton={fields.length > 1}
            watchData={watchAllFields}
          />
        ))}

        {isAddNewRoomShow ? (
          <Styled.GuestAddRoomContainer>
            <Button onClick={handleAppendClick} icon={<AddIcon />} hasIconPadding title={addRoomText} ghost />
          </Styled.GuestAddRoomContainer>
        ) : typeof bookingMaxCount?.max_available_rooms === "undefined" ? (
          ""
        ) : (
          <Styled.NoMoreRoomsText>{noMoreRoomsText}</Styled.NoMoreRoomsText>
        )}
      </form>
    </ModalCustom>
  );
};
