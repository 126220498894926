import React, { FC, ReactNode } from "react";

import { Styled } from "./styles";

export type ButtonProps = {
  primary?: boolean;
  outline?: boolean;
  ghost?: boolean;
  secondary?: boolean;
  blue?: boolean;
  blueSecondary?: boolean;
  light?: boolean;
  background?: string;
  color?: string;
  title?: string;
  onClick?: () => void;
  icon?: ReactNode;
  iconAction?: boolean;
  mr?: string;
  ml?: string;
  mb?: string;
  mt?: string;
  type?: "submit" | "reset" | "button" | undefined;
  width?: string | number;
  height?: string;
  counter?: number;
  isDisabled?: boolean;
  hasIconPadding?: boolean;
  disabled?: boolean;
  unDisabled?: boolean;
  style?: any;
  noHover?: boolean;
  noWrap?: boolean;
};

export const Button: FC<ButtonProps> = ({
  width,
  isDisabled,
  counter,
  type = "button",
  icon,
  title,
  onClick,
  disabled,
  unDisabled = false,
  noHover,
  ...props
}) => (
  <Styled.Button
    disabled={disabled}
    style={{ width }}
    isDisabled={isDisabled}
    noHover={noHover}
    {...props}
    onClick={() => {
      if (isDisabled && !unDisabled) return;
      if (onClick) {
        onClick();
      }
    }}
    type={type}>
    {!!counter && <Styled.Counter>{counter}</Styled.Counter>}
    {icon && <Styled.IconContainer>{icon}</Styled.IconContainer>}
    {title}
  </Styled.Button>
);
