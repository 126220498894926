import styled, { css } from "styled-components";

import { box, flexStartBetween, font } from "@src/styles/mixins";

const ChooseRoomsCard = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
  width: calc(100% - 2px);
  display: flex;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
    align-items: center;
  }
`;

const TotalAmount = styled.span`
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 2px 12px;
  background-color: ${({ theme }) => theme.color.black};
  ${font({ size: "12", lineHeight: "20", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.white};
  border-radius: 2px;
`;

const DescriptionWrapper = styled.div`
  ${flexStartBetween};
  padding: 28px;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 32px;
  }
`;

const Description = styled.div`
  ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.grey};
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  height: 45px;
  ${(props: { isHidden: boolean }) =>
    props.isHidden &&
    css`
      overflow: initial;
      height: auto;
    `};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`;

const MoreButton = styled.div`
  ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  width: 55%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  p {
    text-decoration: underline;
    margin-left: 5px;
    cursor: pointer;
  }
`;

const Name = styled.div`
  ${font({ size: "20", lineHeight: "28", fontWeight: "700" })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "20", lineHeight: "26", fontWeight: "700" })};
    margin: 0 0 32px 0;
    max-width: 217px;
  }
`;

const Img = styled.img`
  width: 375px;
  height: 272px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
    height: 244px;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RatesWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`;

const MobileFooter = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    border-right: 1px solid ${({ theme }) => theme.color.greyBorder};
    border-bottom: 1px solid ${({ theme }) => theme.color.greyBorder};
    border-left: 1px solid ${({ theme }) => theme.color.greyBorder};
    padding: 21px 28px;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
  margin-bottom: 12px;
`;

const SubTitle = styled.div`
  ${font({ size: "14", lineHeight: "22", fontWeight: "300" })};
  color: ${({ theme }) => theme.color.grey};
`;

const Price = styled.div`
  ${font({ size: "18", lineHeight: "26", fontWeight: "600" })};
  color: ${({ theme }) => theme.color.red};
`;

//

const SelectedRoom = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const Text = styled.span`
  flex-grow: 1;
  ${font({ size: "14", lineHeight: "22", fontWeight: "700" })};
  color: ${({ theme }) => theme.color.black};
`;

const CheckIconContainer = styled.span`
  ${box(24)}
  margin-right: 8px;
`;

const TrashIconContainer = styled.span`
  margin-left: 13px;
  ${box(18)}
`;

export const Styled = {
  SelectedRoom,
  Text,
  CheckIconContainer,
  TrashIconContainer,
};
