import ReactTooltip from "react-tooltip";
import styled from "styled-components";

import { font } from "@src/styles/mixins";

const Tooltip = styled(ReactTooltip)<{ width?: string }>`
  ${font({ size: "13", lineHeight: "16", fontWeight: "400" })};
  width: ${(props) => props.width};
`;

export const Styled = { Tooltip };
