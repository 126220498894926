import { FC, useState } from "react";
import Modal from "react-modal";

import { useGetBaseData, useHover, useMediaQuery } from "@src/hooks";
import { theme } from "@src/theme";

import { Button, MobileGallery, Picture } from "@src/components/_Ui";
import { Styled } from "./styles";
import { SpaService } from "@src/interfaces/spa.interface";
import { RequestCallForm } from "@src/components/RequestCallForm";
import { popupStyles } from "@src/styles/popupStyles";
import { InfrastructureRoomType } from "@src/interfaces/infrastructure.interfaces";

type SpaInfrastructureCardProps = {
  data: InfrastructureRoomType;
};

export const SpaInfrastructureCard: FC<SpaInfrastructureCardProps> = ({ data }) => {
  const { name, img, img_mobile, img_tablet, img_hover, img_hover_mobile, img_hover_tablet, description } = data;

  const { moreText, textFrom } = useGetBaseData();
  const [isOpen, setIsOpen] = useState(false);
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const isAdaptive = useMediaQuery(theme.breakpoints.small);
  const isTablet = useMediaQuery(theme.breakpoints.medium);
  const isDesktopSize = !isAdaptive && !isTablet;
  const isTabletSize = isTablet && !isAdaptive;
  // const imgSrc = isDesktopSize ? img : isTabletSize ? img_tablet : img_mobile;

  const mainImage = isDesktopSize ? img : isTabletSize ? img_tablet : img_mobile;
  const hoverImage = isDesktopSize ? img_hover : isTabletSize ? img_hover_tablet : img_hover_mobile;

  const staticMobileGalleryPhotos = [mainImage, hoverImage];

  // todo temporary hidden
  const hasBtn = false;

  const handleRequestCallClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleClosePopupClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Styled.Card ref={hoverRef} isHovered={isHovered} hasBtn={hasBtn}>
        <Styled.CardContainer>
          <Styled.CardImage>
            <Picture img={mainImage} largeGallery />
          </Styled.CardImage>
          <Styled.CardImageHover>
            <Picture img={hoverImage} largeGallery />
          </Styled.CardImageHover>

          <Styled.CardSliderMobile>
            <MobileGallery images={staticMobileGalleryPhotos} hasLightbox={false} height={223} largeGallery />
          </Styled.CardSliderMobile>

          <Styled.CardTextWrapper>
            <Styled.Title>{name}</Styled.Title>
            <Styled.Description dangerouslySetInnerHTML={{ __html: description }}></Styled.Description>
          </Styled.CardTextWrapper>
        </Styled.CardContainer>

        {hasBtn && (
          <>
            <Styled.ButtonsWrapper isHovered={isHovered}>
              <Button title={moreText} blue onClick={handleRequestCallClick} />
            </Styled.ButtonsWrapper>
            <Styled.MobileButtonsWrapper>
              <Button title={moreText} blue onClick={handleRequestCallClick} />
            </Styled.MobileButtonsWrapper>
          </>
        )}
      </Styled.Card>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={handleClosePopupClick}
        style={popupStyles}>
        <RequestCallForm handleClosePopup={handleClosePopupClick} />
      </Modal>
    </>
  );
};
