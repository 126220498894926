import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { css } from "styled-components";

import { flexCenter, flexVerticalCenter, font } from "@src/styles/mixins";

const FooterLinksContainer = styled.div`
  ${flexVerticalCenter}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    flex-direction: column;
    order: 2;
    margin-bottom: 21px;
  }
`;

const Link = styled(RouterLink)`
  white-space: nowrap;
  margin-right: 32px;
  text-decoration: none;
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    ${font({ size: "14", lineHeight: "24", fontWeight: "400" })};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-right: 0;
    margin-bottom: 19px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "15", lineHeight: "23", fontWeight: "400" })};
  }
`;

const LinkMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  margin-right: 32px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    padding: 0;

    &::after {
      height: 0;
    }
  }
`;

const LinksMenuTitle = styled.span`
  cursor: pointer;
  position: relative;
  display: block;
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    ${font({ size: "14", lineHeight: "24", fontWeight: "400" })};
  }
`;

const LinksMenuIcon = styled.div`
  ${flexCenter};

  margin-left: 5px;
  transform: rotate(180deg);
  svg {
    width: 12px;
  }
`;

const LinksMenuContainer = styled.div`
  display: none;
  position: absolute;
  padding: 12px 20px;
  bottom: -120px;
  left: -20px;

  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
`;

const EventsLinksMenuContainer = styled.div`
  display: none;
  position: absolute;
  padding: 12px 20px;
  bottom: -72px;
  left: -20px;

  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
`;

const LinksMenu = styled.div<{
  isHovered?: boolean;
}>`
  position: relative;
  overflow: visible;

  ${({ isHovered }) =>
    isHovered &&
    css`
      ${LinksMenuContainer}, ${EventsLinksMenuContainer} {
        display: flex;
        flex-direction: column;
      }

      ${LinksMenuIcon} svg {
        transform: rotate(180deg);
      }
    `}
`;

export const Styled = {
  FooterLinksContainer,
  Link,
  LinkMenuWrapper,
  LinksMenu,
  LinksMenuTitle,
  LinksMenuContainer,
  EventsLinksMenuContainer,
  LinksMenuIcon,
};
