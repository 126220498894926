import styled, { css } from "styled-components";

import { flexCenter, width100 } from "@src/styles/mixins";

const Container = styled.div<{
  isSticky: boolean;
  isHeaderBg: boolean;
  menuIsShow: boolean;
  isMainPage: boolean;
  hasTransparentBG: boolean;
  isBlogDetailedPage: boolean;
  isGreyBackground: boolean;
}>`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  ${width100};

  ${({ isMainPage }) => (!isMainPage ? "box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);" : null)};
  background-color: ${({ menuIsShow, isHeaderBg, theme }) =>
    menuIsShow ? (!isHeaderBg ? "transparent" : theme.color.white) : "transparent"};
  ${({ menuIsShow }) => (menuIsShow ? "box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);" : null)};

  color: ${({ isHeaderBg, theme }) => (isHeaderBg ? theme.color.black : theme.color.white)};
  transition: all 0.1s;

  ${({ isMainPage }) =>
    !isMainPage &&
    css`
      background-color: ${({ theme }) => theme.color.white};
      color: ${({ theme }) => theme.color.black};

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
        position: relative;
      }

      ${ChangeLangWrapper} {
        top: 26px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
          top: 36px;
        }
      }
    `};

  ${({ isMainPage }) =>
    isMainPage &&
    css`
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
        position: absolute;
      }
    `};

  ${({ isGreyBackground }) =>
    isGreyBackground &&
    css`
      background-color: ${({ theme }) => theme.color.greyHeader};
    `};

  ${({ hasTransparentBG }) =>
    hasTransparentBG &&
    css`
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        background-color: transparent;
        box-shadow: none;

        &{ChangeLangWrapper} {
          span {
          color: ${({ theme }) => theme.color.white};
          }
        }
      }
    `};
  ${({ isBlogDetailedPage }) =>
    isBlogDetailedPage &&
    css`
    /* todo */
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
        background-color: transparent;
        box-shadow: none;

        &{ChangeLangWrapper} {
          span {
          color: ${({ theme }) => theme.color.white};
          }
        }
      }
    `};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 1400px;
  width: calc(100% - 40px);
  margin: 0 auto;

  overflow: visible;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    max-width: 1184px;
  }
`;

const LogoWrapper = styled.div<{ isSticky: boolean; isMainPage: boolean }>`
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.black};
  transition: all 0.4s;

  & a {
    display: block;
    padding: 52px 28px 44px 24px;
  }

  ${({ isSticky }) =>
    !isSticky &&
    css`
      & a {
        padding: 0;
      }
    `};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xxlarge}) {
    & a {
      padding: 52px 24px 44px 17px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    margin-top: 19px;
    z-index: unset;

    & a {
      padding: 18px 24px 19px 17px;
    }

    ${({ isMainPage }) =>
      !isMainPage &&
      css`
        margin-top: 0;

        & a {
          padding: 26px 24px 19px 17px;
        }
      `};
  }

  ${({ isSticky }) =>
    !isSticky &&
    css`
      ${flexCenter};
      width: 86px;
      min-width: 86px;
      height: 72px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
        width: unset;
        min-width: unset;
        height: unset;
      }
    `};

  svg {
    width: ${({ isSticky }) => (!isSticky ? "66px" : "108px")};
    height: ${({ isSticky }) => (!isSticky ? "46px" : "77px")};
    transition: all 0.3s;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
      width: 66px;
      height: 46px;
    }
  }
`;

const MenuWrapper = styled.div<{ isSticky: boolean }>`
  ${width100};

  margin-top: ${({ isSticky }) => (!isSticky ? 0 : "40px")};
  margin-left: ${({ isSticky }) => (!isSticky ? "45px" : "64px")};
  margin-right: 0px;
  transition: all 0.3s;
  overflow: visible;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xxlarge}) {
    margin-left: ${({ isSticky }) => (!isSticky ? "45px" : "30px")};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    margin: 0;
  }

  ${({ isSticky }) =>
    !isSticky &&
    css`
      height: 72px;
      padding: 24px 0;
      box-sizing: border-box;
    `};
`;

const ChangeLangWrapper = styled.div<{ isMainPage: boolean }>`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    display: block;
    position: absolute;

    top: 33px;
    top: 36px;
    right: 66px;
  }
`;

export const Styled = { Container, Wrapper, LogoWrapper, MenuWrapper, ChangeLangWrapper };
