import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { css } from "styled-components";

import { font, width100 } from "@src/styles/mixins";

const ButtonsWrapper = styled.div<{ isHovered?: boolean }>`
  position: absolute;
  bottom: 0;
  opacity: ${({ isHovered }) => (!isHovered ? "0" : "1")};

  left: 0;
  width: 100%;
  height: 67px;
  display: flex;
  align-items: center;
  align-items: stretch;
  background-color: ${({ theme }) => theme.color.black};
  transition: all 0.3s;

  button {
    ${width100}
    height: 67px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    display: none;
  }
`;

const MobileButtonsWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0px 28px 28px;

    button {
      height: 48px;
    }
  }
`;

const Link = styled(RouterLink)`
  display: block;
  width: 100%;
  text-decoration: none;
`;

const CardContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const CardImage = styled.div`
  display: block;
  ${width100};
  height: 280px;
  overflow: hidden;

  img {
    width: 100%;
    height: 280px;
    object-fit: cover;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    height: 242px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    height: 223px;

    img {
      height: 223px;
      object-fit: cover;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`;
const CardImageHover = styled.div`
  display: none;
  ${width100};
  height: 280px;
  overflow: hidden;

  img {
    width: 100%;
    height: 280px;
    object-fit: cover;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    height: 242px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    height: 223px;

    img {
      height: 223px;
      object-fit: cover;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`;

const CardSlider = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    ${width100};
    overflow: hidden;
    height: 223px;

    img {
      height: 223px;
      object-fit: cover;
    }
  }
`;

const CardTextWrapper = styled.div`
  padding: 28px 32px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-grow: 1;
    padding: 29px 26px 9px;
  }
`;

const Image = styled.img`
  object-fit: cover;
`;

const Title = styled.h3`
  margin-bottom: 12px;
  ${font({ size: "20", lineHeight: "28", fontWeight: "700" })};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 12px;
    ${font({ size: "20", lineHeight: "26", fontWeight: "700" })};
  }
`;

const Description = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${font({ size: "14", lineHeight: "22" })};
  color: ${({ theme }) => theme.color.grey};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 30px;
    flex-grow: 1;
  }
`;

const CallLink = styled.a`
  width: 100%;
  display: block;
  padding: 0;
  margin: 0;
  height: 67px;
  text-decoration: none;

  button {
    height: 67px;
    width: 100%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    height: 48px;
    button {
      height: 48px;
    }
  }
`;

const CardPriceContainer = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  height: 67px;
  box-sizing: border-box;
  gap: 0 12px;
  padding: 20px 32px;
  border-top: 1px solid ${({ theme }) => theme.color.greyBorder};
  transition: all 0.5s;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 12px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    align-items: flex-start;
    height: unset;
    margin-top: 0;
    padding: 22px 28px 11px;
  }
`;

const Price = styled.div`
  ${font({ size: "18", lineHeight: "26", fontWeight: 700 })};
  color: ${({ theme }) => theme.color.blue};
`;

const PriceDescription = styled.div`
  ${font({ size: "14", lineHeight: "22" })};
  color: ${({ theme }) => theme.color.grey};
`;

const Card = styled.div<{ hasBtn?: boolean; isHovered?: boolean }>`
  box-sizing: border-box;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  width: calc(100% / 3 - 32px);

  min-height: 454px;

  background-color: ${({ theme }) => theme.color.white};
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    width: calc(100% / 2 - 32px);

    ${Description} {
      -webkit-line-clamp: 7;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}) {
    width: 100%;
    /* min-height: 550px; */
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 100%;
    min-height: unset;
    height: 100%;
  }

  ${({ hasBtn }) =>
    hasBtn &&
    css`
      min-height: 521px;
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      }
    `};
`;

const CardLabel = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  overflow-wrap: anywhere;
  margin: 31px;

  padding: 12px 20px;
  background-color: ${({ theme }) => theme.color.red};
  ${font({ size: "18", lineHeight: "26", fontWeight: 600 })}
  color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    top: 12px;
    left: 12px;

    padding: 8px 16px;
    ${font({ size: "16", lineHeight: "24", fontWeight: 600 })}
  }
`;

export const Styled = {
  Card,
  CardContainer,
  CardImage,
  CardImageHover,
  CardSlider,
  CardTextWrapper,
  Image,
  Title,
  Description,
  CardPriceContainer,
  CallLink,
  ButtonsWrapper,
  MobileButtonsWrapper,
  Price,
  PriceDescription,
  Link,
  CardLabel,
};
