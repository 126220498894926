import { FC, useEffect, useRef } from "react";

import { Styled } from "./styles";

export const Preloader: FC = () => {
  const loaderRef = useRef<HTMLDivElement | null>(null);
  const percentRef = useRef<HTMLDivElement | null>(null);

  let percentValue = 1;
  const percentFinishValue = 100;
  let loaderId: NodeJS.Timer;

  const loadHandler = () => {
    if (percentValue <= percentFinishValue) {
      if (percentRef?.current) {
        percentRef.current.style.backgroundColor = `rgba(160, 7, 43, ${0.01 * percentValue})`;
        percentRef.current.textContent = `${percentValue} %`;
      }
      percentValue++;
    } else {
      clearInterval(loaderId);

      setTimeout(() => {
        if (loaderRef?.current) {
          loaderRef.current.style.opacity = "0";
          loaderRef.current.style.visibility = "hidden";
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (loaderRef.current) {
      loaderId = setInterval(loadHandler, 15);
    }
  }, [loaderRef]);

  return (
    <Styled.Preloader ref={loaderRef}>
      <Styled.LoadingScale ref={percentRef}>0%</Styled.LoadingScale>
    </Styled.Preloader>
  );
};
