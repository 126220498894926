import styled, { css } from "styled-components";

import { absolutePseudoEl, box, flexSpaceBetween, font, width100 } from "@src/styles/mixins";
const Label = styled.div<{ isRequired: boolean }>`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  transition: all 0.3s;

  ${({ theme, isRequired }) =>
    isRequired &&
    css`
      &:after {
        ${absolutePseudoEl};
        top: 3px;
        right: -8px;
        ${box(4)};
        background-color: ${theme.color.red};
        border-radius: 50%;
      }
    `}
`;

const Input = styled.input`
  margin-top: 27px;
  padding-bottom: 12px;
  border: none;
  ${width100};
  cursor: pointer;

  ${font({ size: "16", lineHeight: "24" })};
  color: ${({ theme }) => theme.color.black};

  &:focus,
  &:active {
    outline: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 32px;
  }
`;

const ProposalContainer = styled.span`
  padding-right: 2px;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  svg {
    ${box(24)}
  }
`;

const ClearIcon = styled(Icon)`
  cursor: pointer;
  margin: 0 15px;
`;

const Wrapper = styled.div<{ withoutBorder: boolean; isFocus: boolean }>`
  position: relative;
  ${flexSpaceBetween};

  width: 100%;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.color.white};
  border: ${({ theme, withoutBorder }) => (withoutBorder ? "none" : `1px solid ${theme.color.greyBorder}`)};
  box-sizing: border-box;

  ${Label} {
    ${({ theme, isFocus }) =>
      isFocus &&
      css`
        top: 10px;
        transform: translateY(0);
        ${font({ size: "12", lineHeight: "20" })};
        color: ${theme.color.grey};
      `}
  }
`;

export const Styled = { Wrapper, Label, Input, Icon, ClearIcon, ProposalContainer };
