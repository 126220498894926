import styled from "styled-components";

import {
  absolutePseudoEl,
  flexCenter,
  flexColumn,
  flexWrapCenter,
  font,
  transformCenter,
  width100,
} from "@src/styles/mixins";

const Container = styled.div`
  position: fixed;
  width: calc(100% - 56px);
  max-width: 600px;
  min-height: 440px;
  margin: 28px auto;

  ${flexColumn}

  background-color: ${({ theme }) => theme.color.white};

  ${transformCenter};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${width100}
    max-width: unset;
    min-height: unset;
    margin: 0;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(0, 0);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  z-index: 3;
  right: 13px;
  top: 12px;
  width: 32px;
  height: 32px;
  ${flexCenter}

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    ${absolutePseudoEl};
    left: 15px;
    height: 17px;
    width: 2px;
    background-color: ${({ theme }) => theme.color.white};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const Image = styled.div`
  ${width100}
  height: 240px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: 150px;
  }

  img {
    height: 240px;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      height: 150px;
    }
  }
`;

const Description = styled.div`
  padding: 28px 48px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 32px 20px;
  }
`;

const Title = styled.h2`
  ${flexWrapCenter}
  margin-bottom: 24px;
  ${font({ size: "20", lineHeight: "28", fontWeight: "700" })};
  color: ${({ theme }) => theme.color.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "20", lineHeight: "26", fontWeight: "700" })};
  }
`;

const Text = styled.p`
  ${font({ size: "16", lineHeight: "24", fontWeight: "600" })};
  color: ${({ theme }) => theme.color.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  }

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

export const Styled = { Container, CloseButton, Image, Description, Title, Text };
