import { MouseEvent } from "react";

export const getDateFromAtr = (evt: MouseEvent<HTMLSpanElement>) => {
  const target = evt.target as HTMLElement;
  const date = target?.getAttribute("data-date") || "";

  if (!date) {
    const parentTarget = target.parentNode as HTMLElement;
    return parentTarget.getAttribute("data-date") || "";
  } else {
    return date;
  }
};
