import { FC } from "react";
import { Styled } from "./styles";
import { ContentWrapper, SectionTitle } from "@src/components/_Ui";
import { useGetBaseData, useMediaQuery } from "@src/hooks";
import { theme } from "@src/theme";
import { SpaCardsCarousel } from "../SpaCardsCarousel";
import { useGetSpaServicesDataQuery } from "@src/store/services";
import { SpaServiceCard } from "./SpaServiceCard";

export const SpaServices: FC = () => {
  const { data: servicesData, isLoading: isServicesLoading } = useGetSpaServicesDataQuery();
  const { spaServicesTitle } = useGetBaseData();
  const isAdaptive = useMediaQuery(theme.breakpoints.medium);

  if (isServicesLoading || !servicesData?.length) {
    return null;
  }
  return (
    <Styled.Container>
      <ContentWrapper>
        <Styled.SectionTitleWrapper>
          <SectionTitle text={spaServicesTitle} />
        </Styled.SectionTitleWrapper>
        <Styled.Content>
          {!isAdaptive ? (
            servicesData?.map((item) => {
              return <SpaServiceCard data={item} key={item.id} />;
            })
          ) : (
            <SpaCardsCarousel cards={servicesData} isService />
          )}
        </Styled.Content>
      </ContentWrapper>
    </Styled.Container>
  );
};
