import { createAsyncThunk } from "@reduxjs/toolkit";

import { sendSubscribeEmailData } from "@src/store/services/subscribeEmail.api";
import { statusMessageActions } from "@src/store/slices";

export const subscribeEmail = createAsyncThunk<number, { email: string }>("auth/register", async (data, thunkAPI) => {
  try {
    const response = await sendSubscribeEmailData(data);
    const { openStatusMessage } = statusMessageActions;
    thunkAPI.dispatch(openStatusMessage(response.status === 200));
    return response.status;
  } catch (error) {
    const { openStatusMessage } = statusMessageActions;
    thunkAPI.dispatch(openStatusMessage(false));
    return thunkAPI.rejectWithValue(error);
  }
});
