import styled, { css } from "styled-components";

import { ReactComponent as ChevronIcon } from "@src/assets/images/icons/chevron.svg";
import { box, flexColumn, font } from "@src/styles/mixins";

const CarouselWrapper = styled.div`
  position: relative;

  .swiper {
    max-height: 900px;
    height: 900px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
      height: 789px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      height: 250px;
      height: 304px;
    }

    &-wrapper {
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding-bottom: 50px;
      }
    }

    &-pagination {
      &-custom {
        left: 12px;
        bottom: 12px;

        ${flexColumn}
        align-items: flex-start;
        gap: 4px 0;
        max-width: 288px;
        width: 100%;
        padding: 16px 20px;
        background-color: ${({ theme }) => theme.color.white};
        ${font({ size: "14", lineHeight: "22" })};
        text-align: left;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
          left: 0;
          bottom: 4px;
          width: calc(100% - 40px);
          max-width: unset;
          background-color: unset;
        }

        span {
          color: ${({ theme }) => theme.color.grey};
        }
      }
    }

    .carousel-fraction {
      &__text {
        color: ${({ theme }) => theme.color.black};
      }

      &__current {
        color: ${({ theme }) => theme.color.black};
      }
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.meduim}) {
      .carousel-fraction {
        display: inline-block;
        width: auto;
        position: absolute;
        left: unset;
        right: 8px;
        bottom: 58px;
        padding: 2px 8px;

        background-color: ${({ theme }) => theme.color.black};

        ${font({ size: "12", lineHeight: "20", fontWeight: "400" })};

        color: ${({ theme }) => theme.color.whiteOpacity50};

        &__text {
          display: none;
        }

        &__current {
          color: ${({ theme }) => theme.color.white};
        }

        &__slide-text {
          width: 100%;
          text-align: center;
          ${font({ size: "12", lineHeight: "14", fontWeight: "400" })};

          color: ${({ theme }) => theme.color.white};
        }
      }
    }

    &-navigation-prev,
    &-navigation-next {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
        display: none;
      }

      &.swiper-button-disabled {
        opacity: 0.4;
      }
    }

    &-navigation-prev {
      left: 0;
    }

    &-navigation-next {
      right: 0;
    }

    img {
      height: 900px;
      object-fit: cover;
      object-position: center;
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
        height: 789px;
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        height: 250px;
      }
    }
  }
`;

const NavigationChevronRight = styled(ChevronIcon)`
  stroke: ${({ theme }) => theme.color.white};
`;
const NavigationChevronLeft = styled(ChevronIcon)`
  stroke: ${({ theme }) => theme.color.white};
  transform: rotate(180deg);
`;

const NavigationContainer = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
  }
`;

const NavigationBtnOverlay = styled.button`
  width: 50%;
  height: 100%;

  & div {
    opacity: 0.4;
  }

  &:hover div {
    opacity: 1;
  }
`;

const buttonCommon = css`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    cursor: pointer;
    ${box(72)}
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background-color: ${({ theme }) => theme.color.red};
    position: absolute;
    z-index: 1;
    top: 50%;

    display: flex;

    transform: translateY(-50%);
  }
`;

const NavigationButtonLeft = styled.div`
  ${buttonCommon};
  left: 0px;
`;

const NavigationButtonRight = styled.div`
  ${buttonCommon};
  right: 0px;

  svg {
    transform: rotate(180deg);
  }
`;

export const Styled = {
  CarouselWrapper,
  NavigationChevronRight,
  NavigationChevronLeft,
  NavigationContainer,
  NavigationBtnOverlay,
  NavigationButtonLeft,
  NavigationButtonRight,
};
