import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IPaymentState {
  chosenPaymentMethod: "isPaymentOnline" | "isPaymentOffline";
  isPaymentDisabled: boolean;
}

const initialState: IPaymentState = {
  chosenPaymentMethod: "isPaymentOnline",
  isPaymentDisabled: false,
};

export const paymentMethodSlice = createSlice({
  name: "paymentMethod",
  initialState,
  reducers: {
    setPaymentMethod: (state, action: PayloadAction<"isPaymentOnline" | "isPaymentOffline">) => {
      state.chosenPaymentMethod = action.payload;
      state.isPaymentDisabled = false;
    },
    disablePayment: (state) => {
      state.isPaymentDisabled = true;
    },
  },
});

export const paymentMethodActions = paymentMethodSlice.actions;

export default paymentMethodSlice.reducer;
