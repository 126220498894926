import ReactTooltip from "react-tooltip";
import styled from "styled-components";

import { flexSpaceBetween, font } from "@src/styles/mixins";

const Counter = styled.div`
  ${flexSpaceBetween};
  ${font({ size: "18", lineHeight: "24", fontWeight: "500" })};
  color: ${({ theme }) => theme.color.black};
  text-align: center;
  width: 125px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 134px;
  }
`;

const PlusContainer = styled.div`
  position: relative;
`;

const Tooltip = styled(ReactTooltip)`
  ${font({ size: "13", lineHeight: "16", fontWeight: "400" })};
  max-width: 358px;
  box-sizing: border-box;
  padding: 16px 20px;
  border-radius: 0;

  &.place-top::before {
    opacity: 0;
  }
`;

const CountValue = styled.span`
  margin: 0 12px;
`;

export const Styled = { Counter, Tooltip, PlusContainer, CountValue };
