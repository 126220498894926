import styled, { css } from "styled-components";

import { font, width100 } from "@src/styles/mixins";

const ButtonsWrapper = styled.div<{ isHovered?: boolean }>`
  position: absolute;
  bottom: 0;
  opacity: 1;

  left: 0;
  width: 100%;
  height: 67px;
  display: flex;
  align-items: center;
  align-items: stretch;
  background-color: ${({ theme }) => theme.color.black};
  transition: all 0.3s;

  button {
    ${width100}
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletExtraLarge}) {
    display: none;
  }
`;

const MobileButtonsWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletExtraLarge}) {
    display: flex;
    align-items: center;
    align-items: stretch;

    button {
      flex-grow: 1;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    padding: 0px 28px 28px;
  }
`;

const RoomCardPrice = styled.div`
  display: flex;
  align-items: center;
  height: 67px;
  box-sizing: border-box;
  gap: 0 12px;
  padding: 20px 32px;
  border-top: 1px solid ${({ theme }) => theme.color.greyBorder};
  transition: all 0.5s;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletExtraLarge}) {
    height: unset;
    margin-top: auto;

    padding: 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      padding: 20px 28px 11px;
    }
  }
`;

const RoomCard = styled.div<{ isLongStayPage?: boolean; isHovered?: boolean }>`
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  min-height: 630px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.white};
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    min-height: unset;
  }

  ${({ isLongStayPage }) =>
    !isLongStayPage &&
    css`
      @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        ${ButtonsWrapper} {
          opacity: 0;
        }
        &:hover {
          ${RoomCardPrice} {
            border-top: 1px solid transparent;
          }
          ${ButtonsWrapper} {
            opacity: 1;
          }
        }
      }
    `};
`;

const RoomCardContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const RoomCardImage = styled.div`
  display: block;
  ${width100};
  height: 280px;
  overflow: hidden;

  img {
    width: 100%;
    height: 280px;
    object-fit: cover;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    height: 242px;

    img {
      height: 242px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`;

const RoomCardSlider = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    ${width100};
    overflow: hidden;
  }
`;

const RoomCardTextWrapper = styled.div`
  padding: 28px 32px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 24px 26px 0;
  }
`;

const Image = styled.img`
  object-fit: cover;
`;

const Title = styled.div`
  margin-bottom: 12px;
  ${font({ size: "20", lineHeight: "28", fontWeight: "700" })};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 32px;
    ${font({ size: "20", lineHeight: "26", fontWeight: "700" })};
  }
`;

const Description = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${font({ size: "14", lineHeight: "22" })};
  color: ${({ theme }) => theme.color.grey};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: auto;
  padding: 0 32px 20px;
  padding: 35px 32px 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 0;
    margin-bottom: 26px;
    padding: 0 26px;
  }
`;

const Price = styled.div`
  ${font({ size: "18", lineHeight: "26", fontWeight: 700 })};
  color: ${({ theme }) => theme.color.red};
`;

const PriceDescription = styled.div`
  ${font({ size: "14", lineHeight: "22" })};
  color: ${({ theme }) => theme.color.grey};
`;

const PriceDescriptionFor = styled.span`
  display: inline;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`;

export const Styled = {
  RoomCard,
  RoomCardContainer,
  RoomCardImage,
  RoomCardSlider,
  RoomCardTextWrapper,
  Image,
  Title,
  Description,
  TagWrapper,
  RoomCardPrice,
  Price,
  PriceDescription,
  PriceDescriptionFor,
  ButtonsWrapper,
  MobileButtonsWrapper,
};
