import { Image } from "@src/interfaces";
import { NotificationData } from "@src/interfaces/notifications.interfaces";
import { useGetNotificationQuery } from "@src/store/services/notifications.api";

type ReturnedData = {
  data?: NotificationData;
  isLoading: boolean;
  name: string;
  title: string;
  text: string;
  img?: Image;
};

export const useGetNotificationData = (): ReturnedData => {
  const { data, isLoading } = useGetNotificationQuery();

  const name = data ? data?.name : "";
  const title = data ? data?.title : "";
  const text = data ? data?.text : "";
  const img = data && data?.img;

  return {
    data,
    isLoading,
    name,
    title,
    text,
    img,
  };
};
