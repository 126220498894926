import { theme } from "@src/theme";

export const SLIDES_PER_GROUP = 2;
export const SLIDES_PER_GROUP_MOBILE = 1;
export const SLIDES_PER_VIEW = 2;
export const SLIDES_PER_VIEW_MOBILE = 1;
export const SLIDES_SPACE_BETWEEN = 48;
export const SLIDE_DELAY = 6000;
export const PAGINATION_CLASS_NAME = "swiper-pagination";
export const NAVIGATION_NEXT_CLASS_NAME = "swiper-navigation-next";
export const NAVIGATION_PREV_CLASS_NAME = "swiper-navigation-prev";
const tabletBreakpoint = parseInt(theme.breakpoints.large);
const desktopBreakpoint = parseInt(theme.breakpoints.xlarge);
const startValue = 0;

export const SWIPER_BREAKPOINTS = {
  [startValue]: {
    slidesPerView: 1,
  },
  [tabletBreakpoint]: {
    slidesPerView: 2,
  },
  [desktopBreakpoint]: {
    slidesPerView: 3,
  },
};
