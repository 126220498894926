import { FC, useState } from "react";
import Modal from "react-modal";

import { RequestCallForm } from "@src/components/RequestCallForm";
import { useAppSelector } from "@src/hooks";
import { useActions } from "@src/hooks";
import { useGetMenuData } from "@src/hooks/useGetMenuData";
import { popupStyles } from "@src/styles/popupStyles";
import { numberWithSpaces } from "@src/utils";

import { ChangeLang } from "../ChangeLang";
import { Styled } from "./styles";

type MenuRightPartProps = {
  phoneNumber?: string;
  requestCall?: string;
};

export const MenuRightPart: FC<MenuRightPartProps> = ({ phoneNumber, requestCall }) => {
  const { firstScreenIsView } = useAppSelector((state) => state.headerAnimation);
  const { isOpen: isBurgerOpen } = useAppSelector((state) => state.burgerMenu);
  const { languageId, languageName, languageIcon } = useGetMenuData();

  const formattedPhoneNumber = phoneNumber && numberWithSpaces(phoneNumber, "## ### ### ## ##");

  const [isOpen, setIsOpen] = useState(false);
  const { openPopup, closePopup } = useActions();

  const handleClosePopupClick = () => {
    setIsOpen(false);
    closePopup();
  };

  const handleRequestBtnClick = () => {
    setIsOpen((prevState) => !prevState);
    openPopup();
  };

  return (
    <>
      <Styled.Wrapper>
        <Styled.PhoneWrapper isSticky={firstScreenIsView}>
          <Styled.PhoneLink href={`tel:${phoneNumber}`} isBurgerOpen={isBurgerOpen}>
            {formattedPhoneNumber}
          </Styled.PhoneLink>
          <Styled.RequestCall isSticky={firstScreenIsView} onClick={handleRequestBtnClick}>
            {requestCall}
          </Styled.RequestCall>
        </Styled.PhoneWrapper>

        {/*TODO hide change lang */}
        {firstScreenIsView && (
          <Styled.ChangeLangWrapper>
            <ChangeLang languageId={languageId} name={languageName} icon={languageIcon} />
          </Styled.ChangeLangWrapper>
        )}
      </Styled.Wrapper>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={handleClosePopupClick}
        style={popupStyles}>
        <RequestCallForm handleClosePopup={handleClosePopupClick} />
      </Modal>
    </>
  );
};
