import styled from "styled-components";

import { transformXCenter } from "@src/styles/mixins";

const Container = styled.div`
  width: calc(100% - 40px);
  max-width: 1400px;
  position: absolute;
  z-index: 2;
  bottom: 77px;
  ${transformXCenter};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    max-width: 1184px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    bottom: 22px;

    button {
      height: 64px;
    }
  }
`;

export const Styled = { Container };
