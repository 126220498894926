import { FC, ReactNode } from "react";

import { Styled } from "./styles";

type TextLabel = {
  children?: ReactNode;
  label?: string;
  text?: string;
  isLink?: boolean;
  isPhone?: boolean;
  blue?: boolean;
  dangHTML?: boolean;
};

export const TextLabel: FC<TextLabel> = ({ label, text, isLink, children, isPhone, blue, dangHTML }) => (
  <Styled.TextLabel>
    {dangHTML ? (
      label && <Styled.Label dangerouslySetInnerHTML={{ __html: label }} />
    ) : (
      <Styled.Label>{label}</Styled.Label>
    )}
    {!isLink ? (
      <>
        {dangHTML ? (
          text && <Styled.Text dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          <Styled.Text>{text}</Styled.Text>
        )}
      </>
    ) : isLink && isPhone ? (
      <Styled.Link href={`tel:${text?.split(" ").join("")}`} blue={blue}>
        {text}
      </Styled.Link>
    ) : (
      <Styled.Link href={`mailto:${text}`} blue={blue}>
        {text}
      </Styled.Link>
    )}
    {children}
  </Styled.TextLabel>
);
