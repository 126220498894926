import styled from "styled-components";

import { font, transformXCenter } from "@src/styles/mixins";

const CardFooter = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 20px;
  height: 130px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
  border-left-width: 0;
  border-right-width: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xSmall}) {
    height: 88px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    position: fixed;
    width: calc(100% - 56px);
    max-width: 1020px;
    bottom: 28px;
    ${transformXCenter};
    padding: 12px 47px;
    border: none;
    background-color: ${({ theme }) => theme.color.lightGrey};
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Styled = { CardFooter, ButtonWrapper };
