import { FC, useRef, useState } from "react";
import Modal from "react-modal";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "@src/interfaces";
import { popupStylesSolidOverlay } from "@src/styles/popupStyles";

import { LightboxGallery } from "../LightboxGallery";
import { Picture } from "../Picture/Picture";
import { Styled } from "./styles";

type MobileGalleryProps = {
  images: Image[];
  height?: number;
  hasLightbox?: boolean;
  mediumGallery?: boolean;
  largeGallery?: boolean;
};

Modal.setAppElement("#root");

export const MobileGallery: FC<MobileGalleryProps> = ({ images, hasLightbox, height, mediumGallery, largeGallery }) => {
  const fractionRef = useRef<HTMLDivElement | null>(null);
  const [openLightBox, setOpenLightBox] = useState(false);
  const [index, setIndex] = useState(0);

  const handleOpenLightBox = () => {
    setOpenLightBox(!openLightBox);
  };

  const handleCloseLightBox = () => {
    setOpenLightBox(false);
  };

  return (
    <>
      <Styled.GalleryWrapper height={height}>
        <Swiper
          modules={[Pagination]}
          spaceBetween={20}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          className="gallery__swiper"
          onSlideChange={(swiper) => {
            setIndex(swiper.activeIndex);
          }}>
          {images.map((item, index) => {
            return (
              <SwiperSlide key={`${item.original}/${index}`}>
                <Picture
                  img={item}
                  handleOpenGallery={handleOpenLightBox}
                  index={index}
                  key={index}
                  mediumGallery={mediumGallery}
                  largeGallery={largeGallery}
                />
              </SwiperSlide>
            );
          })}
          <div
            ref={(el) => {
              fractionRef.current = el;
            }}
            className="fraction"></div>
        </Swiper>
      </Styled.GalleryWrapper>

      {hasLightbox && (
        <Modal isOpen={openLightBox} style={popupStylesSolidOverlay}>
          <LightboxGallery images={images} currentIndex={index} handleClose={handleCloseLightBox} />
        </Modal>
      )}
    </>
  );
};

MobileGallery.defaultProps = {
  height: 250,
  hasLightbox: true,
};
