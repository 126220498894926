import { FC } from "react";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "@src/hooks";

import { Styled } from "./styles";

type BurgerButtonProps = {
  onClick?: () => void;
};

export const BurgerButton: FC<BurgerButtonProps> = ({ onClick }) => {
  const location = useLocation();
  const isMainPage = location.pathname === "/" || location.pathname === "/en";
  const isSpaPage = location.pathname.includes("/spa");
  const isGreyBackground = location.pathname.includes("/contacts") || location.pathname.includes("/reservation");

  const { burgerMenu } = useAppSelector((state) => state);
  const { firstScreenIsView } = useAppSelector((state) => state.headerAnimation);

  const handleButtonClick = () => {
    if (onClick) onClick();
  };

  return (
    <Styled.Button
      onClick={handleButtonClick}
      isOpen={burgerMenu.isOpen}
      isOnTop={firstScreenIsView}
      isMainPage={isMainPage || isSpaPage}
      isGreyBackground={isGreyBackground}
      aria-label="Burger button">
      <Styled.Line isOpen={burgerMenu.isOpen} isOnTop={firstScreenIsView} isMainPage={isMainPage || isSpaPage} />
    </Styled.Button>
  );
};
