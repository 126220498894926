import { FC } from "react";

import { Button } from "@src/components";
import { useGetOurRoomsData } from "@src/components/OurRoomsSection/useGetOurRoomsData";
import { useGetBaseData } from "@src/hooks";
import { IRate } from "@src/interfaces";

import { BookedRoom } from "./BookedRoom";
import { Styled } from "./styles";

type BookedProps = {
  bookingDate?: string;
  bookedData: IRate[];
  roomsAmount?: number;
  onDeleteRoomClick?: (priceId: string, priceIndex: number) => void;
  onNavigationClick: () => void;
  totalPrice: number;
  isFinishBookingForm?: boolean;
  isPayment?: boolean;
  isDisabled?: boolean;
  errorReqField?: boolean;
  commonErrorText?: string;
  commonErrorLink?: string;
  nightsText?: string;
  allRates?: number;
};

export const Booked: FC<BookedProps> = ({
  onNavigationClick,
  nightsText,
  commonErrorLink,
  commonErrorText,
  onDeleteRoomClick,
  bookedData,
  bookingDate,
  roomsAmount,
  totalPrice,
  isPayment,
  isFinishBookingForm,
  isDisabled,
  errorReqField,
  allRates,
}) => {
  const { yourBookedText, proceedText, youMustSelectText, totalWord } = useGetOurRoomsData();
  const {
    rulesBookingStartText,
    rulesSecondText,
    rulesPrivacyPolicyLink,
    rulesPrivacyPolicyFile,
    rulesPersonalDataLink,
    rulesPersonalDataFile,
    bookingText,
    currencyValue,
    bookingPaymentText,
    paymentOnlineText,
    paymentOfflineText,
  } = useGetBaseData();

  const mustSelectText = youMustSelectText.replace("#rooms_count#", String(roomsAmount));
  const formatedPrice = String(totalPrice)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .split(".")[0];

  return (
    <Styled.Booked>
      <Styled.BookedHeader>
        <Styled.Title>{yourBookedText}</Styled.Title>
        <Styled.SubTitle>{bookingDate}</Styled.SubTitle>
        <Styled.Nights>{nightsText}</Styled.Nights>
      </Styled.BookedHeader>
      <Styled.BookedList>
        {bookedData.map(
          (rate, index) =>
            !!rate.id && (
              <BookedRoom
                onDeleteRoomClick={() => {
                  if (onDeleteRoomClick) {
                    onDeleteRoomClick(rate.priceId, index);
                  }
                }}
                isFinishBookingForm={isFinishBookingForm}
                key={index}
                rate={rate}
              />
            )
        )}
      </Styled.BookedList>
      <Styled.ButtonWrapper>
        {isFinishBookingForm && (
          <Styled.Pay>
            {bookingPaymentText}: <Styled.Card>{isPayment ? paymentOnlineText : paymentOfflineText}</Styled.Card>
          </Styled.Pay>
        )}
        <Styled.TotalPrice>
          {totalPrice !== 0 && (
            <>
              {totalWord}{" "}
              <Styled.Price>
                {formatedPrice} {currencyValue}
              </Styled.Price>
            </>
          )}
        </Styled.TotalPrice>
        {isFinishBookingForm ? (
          <>
            <Button
              type="submit"
              width="100%"
              mb="8px"
              title={bookingText}
              primary={!isDisabled}
              isDisabled={isDisabled}
            />
            {errorReqField && (
              <Styled.RoomsAmount>
                <span>{commonErrorLink}</span> {commonErrorText}
              </Styled.RoomsAmount>
            )}
            <Styled.Privacy>
              {rulesBookingStartText}{" "}
              <Styled.PrivacyLink
                href={process.env.REACT_APP_ROOT_URL + rulesPrivacyPolicyFile}
                target="_blank"
                rel="noreferrer">
                {rulesPrivacyPolicyLink}
              </Styled.PrivacyLink>{" "}
              {rulesSecondText}{" "}
              <Styled.PrivacyLink
                href={process.env.REACT_APP_ROOT_URL + rulesPersonalDataFile}
                target="_blank"
                rel="noreferrer">
                {rulesPersonalDataLink}
              </Styled.PrivacyLink>
            </Styled.Privacy>
          </>
        ) : (
          <>
            <Button
              style={{ padding: "14px 0", width: "100%" }}
              onClick={onNavigationClick}
              width="100%"
              mb="8px"
              title={proceedText}
              isDisabled={isDisabled}
              primary={!isDisabled}
            />
            {!!roomsAmount && allRates && allRates > 1 && <Styled.RoomsAmount>{mustSelectText}</Styled.RoomsAmount>}
          </>
        )}
      </Styled.ButtonWrapper>
    </Styled.Booked>
  );
};
