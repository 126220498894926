import styled from "styled-components";

import { font, width100 } from "@src/styles/mixins";

const GalleryWrapper = styled.div<{ height?: number }>`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
  }

  & img {
    ${width100};
    height: ${(props) => {
      return props.height;
    }}px;
    object-fit: cover;
    object-position: center;
  }

  .swiper-pagination-fraction {
    display: inline-block;
    width: auto;
    position: absolute;
    left: unset;
    right: 12px;
    bottom: 16px;

    padding: 2px 8px;

    background-color: ${({ theme }) => theme.color.black};

    ${font({ size: "12", lineHeight: "20", fontWeight: "400" })};

    color: ${({ theme }) => theme.color.whiteOpacity50};
  }

  .swiper-pagination-current {
    color: ${({ theme }) => theme.color.white};
  }
`;
const GalleryImage = styled.img`
  ${width100};
  height: 250px;
  object-fit: cover;
`;

export const Styled = { GalleryWrapper, GalleryImage };
