import styled from "styled-components";

import { font } from "@src/styles/mixins";

const BookingOkWrapper = styled.div``;

const Header = styled.h3`
  max-width: 1050px;
  margin-bottom: 40px;
  font-family: Montserrat, sans-serif;
  ${font({ size: "28", lineHeight: "38", fontWeight: "500" })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    max-width: unset;
    margin-top: 24px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Loader = styled.div`
  width: 28px;
  height: 28px;
  margin-left: 30px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-left: 0;
    margin-top: 20px;
  }
`;

const Error = styled.div`
  width: 504px;
  padding: 48px;
  background-color: ${({ theme }) => theme.color.white};
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  position: relative;
`;

const ErrorHeader = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 24px;

  span {
    color: ${({ theme }) => theme.color.red};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    box-sizing: border-box;
    padding: 32px 32px 24px;
    margin: 0;
  }
`;

const ErrorText = styled.div`
  width: 500px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: auto;
    box-sizing: border-box;
    padding: 0px 32px 24px;
    ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  }
`;

const ModalCloseBtn = styled.div`
  position: absolute;
  top: 34px;
  right: 34px;
  cursor: pointer;
`;

export const Styled = {
  Header,
  BookingOkWrapper,
  ButtonWrapper,
  Error,
  ModalCloseBtn,
  ErrorText,
  ErrorHeader,
  Loader,
};
