import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "@src/components";
import { Styled } from "@src/components/ChooseRoom/styles";
import { useGetOurRoomsData } from "@src/components/OurRoomsSection/useGetOurRoomsData";
import { RESERVATION_ROUTES } from "@src/constants";
import { useActions, useGetBaseData } from "@src/hooks";
import { declOfNum } from "@src/utils";

type BookedMobileProps = {
  totalPrice: number;
  allRates: number;
  allGuests: number;
};

export const BookedMobile: FC<BookedMobileProps> = ({ allRates, allGuests, totalPrice }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;
  const { currencyValue, roomsDecl, guestsDecl } = useGetBaseData();
  const { toggleSpace } = useActions();
  const { proceedText } = useGetOurRoomsData();
  const formatedPrice = String(totalPrice)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .split(".")[0];

  const roomText = `${allRates} ${declOfNum(+allRates, roomsDecl)}`;
  const guestsText = `${allGuests} ${declOfNum(+allGuests, guestsDecl)}`;

  const handleNextBtnClick = () => {
    toggleSpace(false);
    !isEnglishVersion
      ? navigate(`${RESERVATION_ROUTES.BOOKED_MOBILE}`)
      : navigate(`/en${RESERVATION_ROUTES.BOOKED_MOBILE}`);
  };

  return (
    <Styled.BookedMobile>
      <div>
        <Styled.Price>
          {formatedPrice} {currencyValue}
        </Styled.Price>
        <Styled.AmountGuest>
          {roomText}, {guestsText}
        </Styled.AmountGuest>
      </div>
      <Button onClick={handleNextBtnClick} width={170} title={proceedText} primary />
    </Styled.BookedMobile>
  );
};
