import { bindActionCreators } from "@reduxjs/toolkit";

import {
  headerAnimationActions,
  burgerMenuActions,
  popupActions,
  datepickerActions,
  statusMessageActions,
  bookingActions,
  topButtonActions,
  paymentMethodActions,
} from "../store/slices";
import * as requestCallActions from "../store/slices/requestCall/requestCall.actions";
import * as requestConferenceRoomActions from "../store/slices/requestConferenceRoom/requestConferenceRoom.actions";
import * as requestWeddingRoomActions from "../store/slices/requestWeddingRoom/requestWeddingRoom.actions";
import * as subscribeEmailActions from "../store/slices/subscribeEmail/subscribeEmail.actions";
import { useAppDispatch } from "./useAppDispatch";

const allActions = {
  ...headerAnimationActions,
  ...popupActions,
  ...bookingActions,
  ...burgerMenuActions,
  ...datepickerActions,
  ...statusMessageActions,
  ...requestCallActions,
  ...subscribeEmailActions,
  ...requestConferenceRoomActions,
  ...topButtonActions,
  ...paymentMethodActions,
  ...requestWeddingRoomActions,
};

export const useActions = () => {
  const dispatch = useAppDispatch();

  return bindActionCreators(allActions, dispatch);
};
