import React from "react";
import type { Swiper as SwiperType } from "swiper";

export const renderSliderFraction = (currentClass: string, totalClass: string): string => {
  return `<span class="${currentClass} lightbox-fraction-current"></span> / <span class="
      ${totalClass} lightbox-fraction-total"></span>`;
};

export const onBeforeInit = (
  swiper: SwiperType,
  prevRef: React.RefObject<HTMLButtonElement>,
  nextRef: React.RefObject<HTMLButtonElement>,
  paginationRef: React.RefObject<HTMLDivElement>
) => {
  if (typeof swiper.params.navigation !== "boolean") {
    const navigation = swiper.params.navigation;
    if (navigation) {
      navigation.prevEl = prevRef.current;
      navigation.nextEl = nextRef.current;
    }
  }

  if (typeof swiper.params.pagination !== "boolean" && swiper.params.pagination) {
    swiper.params.pagination.el = paginationRef.current;
  }

  swiper.navigation.init();
  swiper.navigation.update();
};
