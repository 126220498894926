import { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import { ServicesSection } from "@src/components";
import { Breadcrumbs } from "@src/components/_Ui/Breadcrumps";
import { Footer } from "@src/components/Footer";
import { useActions } from "@src/hooks";
import { useGetMetaData } from "@src/hooks/useGetMetaData";

import { RoomsList } from "../../components/RoomsList";
import { Styled } from "./styles";
import { LongStayRoomsList } from "@src/components/LongStayBooking/LongStayRoomsList";

const RoomsServices: FC = () => {
  const location = useLocation();
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);
  let currentLocation = location.pathname;
  if (currentLocation.includes("/en")) {
    currentLocation = currentLocation.substring(3);
  }
  const { metaTitle, metaDescription, metaKeywords } = useGetMetaData(currentLocation);

  const containerRef = useRef<HTMLDivElement>(null);
  const { setFirstScreenIsView, setIsStatusMessageOnTop } = useActions();

  const OFFSET_TOP_FOR_STICKY_HEADER = 30;

  const handlePageScroll = () => {
    const isScrolled = window.scrollY > OFFSET_TOP_FOR_STICKY_HEADER;
    setIsHeaderSticky(window.scrollY > 0);
    setIsStatusMessageOnTop(window.scrollY > 0);
    setFirstScreenIsView(!isScrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", handlePageScroll);
    return () => {
      window.removeEventListener("scroll", handlePageScroll);
    };
  }, []);

  const FULL_HEADER_HEIGHT = 180;
  const STICKY_HEADER_HEIGHT = 97;
  const headerHeight = !isHeaderSticky ? FULL_HEADER_HEIGHT : STICKY_HEADER_HEIGHT;

  return (
    <Styled.Container headerHeight={headerHeight} ref={containerRef}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>
      <Breadcrumbs />
      <RoomsList />
      <LongStayRoomsList />
      <ServicesSection />
      <Footer />
    </Styled.Container>
  );
};

export default RoomsServices;
