import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

import { ActionsType, DetailedActionType } from "@src/interfaces";

const basePath = "actions";

const langId = Cookies.get("lang_id") || "";

export const actionsApi = createApi({
  reducerPath: "api/actions",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ROOT_API_URL,
    prepareHeaders: (headers) => {
      headers.set("User-Lang-Id", langId);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getActions: build.query<ActionsType[], void>({
      query: () => `${basePath}/all`,
    }),
    getActionById: build.query<DetailedActionType, string>({
      query: (id) => `/${basePath}/one/${id}`,
    }),
  }),
});

export const { useGetActionByIdQuery, useGetActionsQuery } = actionsApi;
