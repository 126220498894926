import styled, { css } from "styled-components";

import { box, flexCenter, font } from "@src/styles/mixins";

import { ButtonProps } from "./Button";

type ButtonStyleProps = Omit<ButtonProps, "title" & "onClick">;

const IconContainer = styled.span`
  ${flexCenter};
`;

const Button = styled.button`
  box-sizing: border-box;
  ${flexCenter};
  position: relative;
  gap: 17px;
  color: ${({ theme }) => theme.color.red};
  letter-spacing: 0.06em;
  color: ${({ theme }) => theme.color.red};
  ${font({ size: "14", lineHeight: "22", fontWeight: "700" })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "13", lineHeight: "20", fontWeight: "700" })};
  }

  ${(props: ButtonStyleProps) => css`
    width: ${props.width || "auto"};
  `}

  ${(props: ButtonStyleProps) => css`
    height: ${props.height || "auto"};
  `}

  ${(props: ButtonStyleProps) =>
    props.light &&
    css`
      ${font({ size: "14", lineHeight: "22", fontWeight: 500 })}
      color: ${({ theme }) => props.color || theme.color.black};
      background: ${({ theme }) => props.background || theme.color.pink};
      padding: 16px 20px;
    `};

  ${(props: ButtonStyleProps) =>
    props.isDisabled &&
    css`
      background: ${({ theme }) => theme.color.lightGrey};
      color: ${({ theme }) => theme.color.greyBold};
      padding: 20px 30px;
      font-weight: 600;
      text-transform: uppercase;
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 100%;
        padding: 14px 28px;
      }
    `};

  ${(props: ButtonStyleProps) =>
    props.hasIconPadding &&
    css`
      ${IconContainer} {
        padding-right: 15px;
      }
    `};

  ${(props: ButtonStyleProps) =>
    props.outline &&
    css`
      border: 1px solid ${({ theme }) => props.color || theme.color.red};
      color: ${({ theme }) => props.color || theme.color.red};
      letter-spacing: 0.06em;
      text-transform: uppercase;
      padding: 20px 30px;

      &:focus {
        border: 1px solid ${({ theme }) => props.color || theme.color.red};
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 100%;
        padding: 14px 28px;
      }
    `};

  ${(props: ButtonStyleProps) => css`
    margin-right: ${props.mr || "0"};
    margin-left: ${props.ml || "0"};
    margin-bottom: ${props.mb || "0"};
    margin-top: ${props.mt || "0"};
  `};

  ${(props: ButtonStyleProps) =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 14px 28px;
    ${font({ size: "13", lineHeight: "20", fontWeight: 700 })}
  }

  ${(props: ButtonStyleProps) =>
    props.primary &&
    css`
      background: ${({ theme }) => props.background || theme.color.red};
      color: ${({ theme }) => props.color || theme.color.white};
      text-transform: uppercase;
      letter-spacing: 0.06em;
      padding: 20px 30px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 100%;
        padding: 14px 28px;
        ${font({ size: "14", lineHeight: "22", fontWeight: 700 })}
      }

      &:hover {
        background: ${({ theme }) => props.background || theme.color.redBtnHover};
      }

      &:disabled {
        cursor: not-allowed;
        background: ${({ theme }) => props.background || theme.color.lightGrey};
        color: ${({ theme }) => props.color || theme.color.grey};
      }

      ${(props: ButtonStyleProps) =>
        props.noHover &&
        css`
          &:hover {
            background-color: ${({ theme }) => props.background || theme.color.grey};
          }
        `};
    `}

  ${(props: ButtonStyleProps) =>
    props.blue &&
    css`
      height: 64px;
      background: ${({ theme }) => props.background || theme.color.blue};
      color: ${({ theme }) => props.color || theme.color.white};
      text-transform: uppercase;
      letter-spacing: 0.06em;
      padding: 20px 30px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 100%;
        height: 48px;
        padding: 14px 28px;
        ${font({ size: "14", lineHeight: "22", fontWeight: 700 })}
      }

      &:hover {
        background: ${({ theme }) => props.background || theme.color.blueHover};
      }

      &:disabled {
        cursor: not-allowed;
        background: ${({ theme }) => props.background || theme.color.lightGrey};
        color: ${({ theme }) => props.color || theme.color.grey};
      }

      ${(props: ButtonStyleProps) =>
        props.noHover &&
        css`
          &:hover {
            background-color: ${({ theme }) => props.background || theme.color.grey};
          }
        `};
    `}

      ${(props: ButtonStyleProps) =>
    props.blueSecondary &&
    css`
      background: ${({ theme }) => props.background || theme.color.blueSecondary};
      color: ${({ theme }) => props.color || theme.color.white};
      text-transform: uppercase;
      letter-spacing: 0.06em;
      padding: 20px 30px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 100%;
        padding: 14px 28px;
        ${font({ size: "14", lineHeight: "22", fontWeight: 700 })}
      }

      &:hover {
        background: ${({ theme }) => props.background || theme.color.blueSecondaryHover};
      }

      &:disabled {
        cursor: not-allowed;
        background: ${({ theme }) => props.background || theme.color.lightGrey};
        color: ${({ theme }) => props.color || theme.color.grey};
      }

      ${(props: ButtonStyleProps) =>
        props.noHover &&
        css`
          &:hover {
            background-color: ${({ theme }) => props.background || theme.color.grey};
          }
        `};
    `}

  ${(props: ButtonStyleProps) =>
    props.secondary &&
    css`
      background: ${({ theme }) => props.background || theme.color.black};
      color: ${({ theme }) => props.color || theme.color.white};
      text-transform: uppercase;
      padding: 20px 30px;
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 100%;
        padding: 14px 28px;
      }

      &:hover {
        background: ${({ theme }) => props.background || theme.color.blackBtnHover};
      }
    `}

  ${(props: ButtonStyleProps) =>
    props.ghost &&
    css`
      ${font({ size: "15", lineHeight: "23", fontWeight: "500" })}
      color: ${({ theme }) => props.color || theme.color.red};
      background: ${({ theme }) => props.background || theme.color.lightGrey};
      width: 100%;
      padding: 9px 0;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 14px 28px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.greyBtnHover};
      }
    `};

  ${(props: ButtonStyleProps) =>
    props.iconAction &&
    css`
      ${box(40)};

      background: ${({ theme }) => props.background || theme.color.greyHeader};
      border-radius: 100%;

      svg {
        stroke: ${({ theme }) => props.color || theme.color.black};
      }

      &:disabled {
        width: 40px;
        padding: 0;
      }
      &:not(:disabled):hover {
        background-color: ${({ theme }) => theme.color.greyBtnHover};

        svg {
          stroke: ${({ theme }) => theme.color.red};
        }
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 0;
      }
    `};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 0;
  }
`;

const Counter = styled.span`
  ${box(32)};
  ${flexCenter};
  background: ${({ theme }) => theme.color.black};
  color: ${({ theme }) => theme.color.white};
  position: absolute;
  right: -16px;
  top: -16px;
`;

export const Styled = { Button, IconContainer, Counter };
