import styled from "styled-components";

import { absolutePseudoEl, box, flexCenter, font } from "@src/styles/mixins";

const Message = styled.div<{ success?: boolean; isOnTop?: boolean }>`
  position: fixed;
  z-index: 5;
  top: 8px;
  top: ${({ isOnTop }) => (isOnTop ? "80px" : "8px")};
  right: 8px;

  min-height: 64px;
  display: flex;
  align-items: stretch;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    max-width: calc(100% - 16px);
    width: 100%;
    left: 8px;
  }
`;

const Icon = styled.div<{ success?: boolean }>`
  min-width: 64px;
  width: 64px;
  min-height: 64px;

  ${flexCenter};
  background-color: ${({ theme, success }) => (success ? theme.color.greenSuccess : theme.color.red)};
`;

const Description = styled.div`
  position: relative;
  box-sizing: border-box;
  min-height: 64px;
  padding: 20px 60px 20px 24px;
  background-color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-grow: 1;
    padding: 12px 60px 12px 24px;
  }
`;

const Text = styled.p`
  ${font({ size: "15", lineHeight: "24", fontWeight: "400" })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    align-items: center;
    align-content: center;
    height: 100%;
    ${font({ size: "14", lineHeight: "20" })};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 17px;
  right: 15px;
  ${box(32)}
  ${flexCenter}


  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    ${absolutePseudoEl};
    left: 15px;
    height: 17px;
    width: 2px;
    background-color: ${({ theme }) => theme.color.greyBg};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export const Styled = { Message, Icon, Description, Text, CloseButton };
