import Swiper from "swiper";

const restrictTextValue = (str: string, maxValue = 60): string => {
  if (str.length >= maxValue) {
    return `${str.substr(0, maxValue)}...`;
  }
  return str;
};

export const renderCustomFraction = (swiper: Swiper, current: number, total: number, textPhoto?: string) => {
  const slideName = swiper.slides[swiper.activeIndex]?.getAttribute("data-slide-name");

  return `<div class="carousel-fraction"><span class="carousel-fraction__text">${textPhoto}</span> <span class="carousel-fraction__current">${current}</span><span class="carousel-fraction__total"> / ${total}</span></div>
         <div class="carousel-fraction__slide-text">${slideName ? restrictTextValue(slideName) : ""}</div>
`;
};
