import React from "react";
import { CalendarProps } from "react-date-range";

import { ReactComponent as LeftArrowIcon } from "../../assets/images/icons/left-arrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/images/icons/right-arrow.svg";

export const navigationRenderer = (
  currFocusedDate: Date,
  changeShownDate: (value: Date | number | string, mode?: "set" | "setYear" | "setMonth" | "monthOffset") => void,
  props: CalendarProps
): JSX.Element => {
  const { showMonthArrow } = props;

  return (
    <div onMouseUp={(e) => e.stopPropagation()} className="rdrMonthAndYearWrapper">
      {showMonthArrow ? (
        <button
          type="button"
          className="rdrNextPrevButton rdrPrevButton"
          onClick={() => changeShownDate(-1, "monthOffset")}>
          <LeftArrowIcon />
        </button>
      ) : null}

      {showMonthArrow ? (
        <button
          type="button"
          className="rdrNextPrevButton rdrNextButton"
          onClick={() => changeShownDate(+1, "monthOffset")}>
          <RightArrowIcon />
        </button>
      ) : null}
    </div>
  );
};
