import ReactTooltip from "react-tooltip";
import styled from "styled-components";

import { font } from "@src/styles/mixins";

const ManIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Tooltip = styled(ReactTooltip)`
  ${font({ size: "13", lineHeight: "16", fontWeight: "400" })};
  max-width: 358px;
  box-sizing: border-box;
  padding: 16px 20px;
  border-radius: 0;

  &.place-top::before {
    opacity: 0;
  }
`;

export const Styled = { ManIcons, IconsContainer, Tooltip };
