import styled, { css } from "styled-components";

import { flexCenter, flexSpaceBetween, flexStartBetween, font, width100 } from "@src/styles/mixins";

const GuestCounterWrapper = styled.div`
  ${width100};
  ${font({ size: "14", lineHeight: "22", fontWeight: "600" })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}) {
    margin-bottom: 14px;
    margin-right: -20px;
    margin-left: -20px;
    padding: 20px 40px;
    width: auto;
    border-top: 1px solid ${({ theme }) => theme.color.greyBorderOpacity50};
  }
`;

const GuestCounterHeader = styled.div`
  ${flexStartBetween};
  margin-bottom: 12px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}) {
    margin-bottom: 32px;
  }
`;

const GuestCounterRoomTitle = styled.h3`
  ${font({ size: "14", lineHeight: "22", fontWeight: "500" })};
  color: ${({ theme }) => theme.color.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}) {
    ${font({ size: "18", lineHeight: "26", fontWeight: "600" })};
  }
`;

const GuestCounterContent = styled.div`
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
  margin-bottom: 20px;
  padding: 30px 30px 38px;

  ${(props: { isReservationPage?: boolean }) =>
    props.isReservationPage &&
    css`
      ${flexSpaceBetween};
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletExtraLarge}) {
        padding: 30px 20px 38px;
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}) {
        flex-wrap: wrap;
        border: none;
        padding: 30px 30px 38px;
      }
    `};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}) {
    border: none;
    margin-bottom: 9px;
    padding: 0;
  }
`;

const GuestCounterItem = styled.div`
  // ${flexSpaceBetween};
  margin-bottom: 29px;
  ${(props: { isReservationPage?: boolean }) =>
    props.isReservationPage &&
    css`
      width: 100%;
      margin: 0 48px 0 0;
      height: 64px;
      &:not(:last-child) {
        padding-right: 48px;
        border-right: 1px solid ${({ theme }) => theme.color.greyBorderOpacity50};
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletExtraLarge}) {
        &:not(:last-child) {
          padding-right: 20px;
        }
      }
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}) {
        margin: 28px 0;

        &:not(:last-child) {
          padding-right: 0;
          border-right: none;
        }
      }
    `};

  &:nth-child(3) {
    margin: 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}) {
      margin: 28px 0;
    }
  }

  &:last-child {
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}) {
      margin-bottom: 0;
    }
  }
`;

const GuestCounterTitleContainer = styled.div`
  margin-right: 20px;
`;

const GuestCounterTitle = styled.p`
  ${font({ size: "15", lineHeight: "21", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.black};
`;
const GuestCounterText = styled.div`
  ${font({ size: "13", lineHeight: "19", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.grey};
`;

const GuestDeleteButton = styled.button`
  box-sizing: border-box;
  ${flexCenter};
  position: relative;
  gap: 7px;
  color: ${({ theme }) => theme.color.red};
  letter-spacing: 0.06em;
  ${font({ size: "14", lineHeight: "22", fontWeight: "600" })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}) {
    padding-top: 3px;
    font-weight: 500;
  }
`;

export const Styled = {
  GuestCounterText,
  GuestCounterWrapper,
  GuestCounterContent,
  GuestCounterHeader,
  GuestCounterRoomTitle,
  GuestCounterItem,
  GuestCounterTitleContainer,
  GuestCounterTitle,
  GuestDeleteButton,
};
