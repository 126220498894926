import { createSlice } from "@reduxjs/toolkit";

import { IBookingSearch, IRate } from "@src/interfaces";
const initialState: {
  selectedRooms: IRate[];
  bookedData: { model: IBookingSearch; rooms: IRate[] };
  guestsData: IBookingSearch;
  amountRates: number;
  rateCounter: { [key: string]: number };
  roomCounter: { [key: string]: number };
  tariffCounter: { [key: string]: number };
} = {
  bookedData: {
    model: {
      date_from: "",
      date_to: "",
      room_id: null,
      rooms: [
        {
          room_id: 0,
          adult_count: 0,
          child_under_5_count: 0,
          child_under_11_count: 0,
        },
      ],
    },
    rooms: [
      {
        name: "",
        id: "",
        cancel_text: "",
        room_name: "",
        payment_text: "",
        help_text: "",
        room_code: "",
        rate_code: "",
        loyalty: false,
        price: "",
        priceId: "",
        prices: [
          {
            adult_count: 1,
            child_under_5_count: 1,
            child_under_11_count: 1,
            id: "",
            total: "",
          },
        ],
      },
    ],
  },
  guestsData: {
    date_from: "",
    date_to: "",
    room_id: null,
    rooms: [
      {
        room_id: 0,
        adult_count: 0,
        child_under_5_count: 0,
        child_under_11_count: 0,
      },
    ],
  },

  selectedRooms: [
    {
      name: "",
      id: "",
      room_name: "",
      cancel_text: "",
      payment_text: "",
      help_text: "",
      room_code: "",
      rate_code: "",
      loyalty: false,
      price: "",
      priceId: "",
      prices: [
        {
          adult_count: 1,
          child_under_5_count: 1,
          child_under_11_count: 1,
          id: "",
          total: "",
        },
      ],
    },
  ],
  amountRates: 0,
  rateCounter: {},
  roomCounter: {},
  tariffCounter: {},
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setGuestsData: (state, action) => {
      state.guestsData = action.payload;
    },
    setBookedFormData: (state, action) => {
      state.bookedData = action.payload;
    },

    setSelectedRooms: (state, action) => {
      state.selectedRooms = action.payload;
    },

    setAmountRates: (state, action) => {
      state.amountRates = action.payload;
    },
    setRateCounter: (state, action) => {
      state.rateCounter = action.payload;
    },
    setRoomCounter: (state, action) => {
      state.roomCounter = action.payload;
    },
    setTariffCounter: (state, action) => {
      state.tariffCounter = action.payload;
    },
  },
});

export const bookingActions = bookingSlice.actions;
export default bookingSlice.reducer;
