import styled from "styled-components";

import { font } from "@src/styles/mixins";

const SelectionModalContent = styled.div`
  padding: 0 20px;
`;

const SelectionModalHeader = styled.p`
  ${font({ size: "20", lineHeight: "26", fontWeight: "500" })};
  margin: 0 0 19px;
`;

export const Styled = { SelectionModalContent, SelectionModalHeader };
