import styled from "styled-components";

import { flexVerticalCenter } from "@src/styles/mixins";

const Copyright = styled.p`
  ${flexVerticalCenter}
  margin-right: 32px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

export const Styled = { Copyright };
