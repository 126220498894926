import React, { useEffect, useRef } from "react";

import { useActions } from "@src/hooks";

import { ContentWrapper, SectionTitle } from "../_Ui";
import { OurRoomsCarousel } from "../OurRoomsCarousel";
import { Styled } from "./style";
import { useGetOurRoomsData } from "./useGetOurRoomsData";

export const OurRoomsSection = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { setSecondScreenOffset } = useActions();
  const { title, rooms } = useGetOurRoomsData();

  useEffect(() => {
    const offsetTop = ref.current?.offsetTop;

    if (offsetTop) {
      setSecondScreenOffset(offsetTop);
    }
  }, [ref]);

  return (
    <Styled.Container ref={ref}>
      <ContentWrapper>
        <Styled.SectionTitleWrapper>{rooms?.length && <SectionTitle text={title} />}</Styled.SectionTitleWrapper>
        {rooms && <OurRoomsCarousel rooms={rooms} />}
      </ContentWrapper>
    </Styled.Container>
  );
};
