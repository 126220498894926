import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Range } from "react-date-range";

import { GuestsType } from "@src/interfaces/guest.interface";

interface InitialState {
  modalIsOpen: boolean;
  range: Range;
  isDateModified: boolean;
  guestList: GuestsType[];
}

const defaultGuestValue = [
  {
    roomNumber: 1,
    guests: [
      {
        id: 1,
        type: "adult_count",
        name: "Взрослые",
        age: "от 12 лет",
        count: 1,
      },
      {
        id: 3,
        type: "child_under_11_count",
        name: "Дети",
        age: "от 6 до 11 лет",
        count: 0,
      },
      {
        id: 2,
        type: "child_under_5_count",
        name: "Дети",
        age: "от 0 до 5 лет",
        count: 0,
      },
    ],
  },
];

const initialState: InitialState = {
  modalIsOpen: false,
  range: {
    startDate: undefined,
    endDate: undefined,
    key: "selection",
  },
  isDateModified: false,
  guestList: defaultGuestValue,
};

export const datepickerSlice = createSlice({
  name: "datepicker",
  initialState,
  reducers: {
    openModal: (state) => {
      state.modalIsOpen = true;
    },
    closeModal: (state) => {
      state.modalIsOpen = false;
    },
    setRange: (state, action: PayloadAction<Range>) => {
      state.isDateModified = true;
      state.range = action.payload;
    },
    setIsDateModified: (state, action: PayloadAction<boolean>) => {
      state.isDateModified = action.payload;
    },
    setGuests: (state, action: PayloadAction<GuestsType[]>) => {
      state.guestList = action.payload;
    },
  },
});

export const datepickerActions = datepickerSlice.actions;
export default datepickerSlice.reducer;
