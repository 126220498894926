import { FC, useRef, useState } from "react";
import { Navigation, Pagination } from "swiper";
import type { Swiper as SwiperType } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { PaginationOptions } from "swiper/types";

import { ReactComponent as Shevron } from "@src/assets/images/icons/shevron-left.svg";
import { renderCustomFraction } from "@src/components/HotellGalleryCarousel/renderCustomFraction";
import { useMediaQuery } from "@src/hooks";
import { Gallery } from "@src/interfaces";
import { theme } from "@src/theme";

import { Picture } from "../_Ui";
import { NAVIGATION_NEXT_CLASS_NAME_HOTEL, NAVIGATION_PREV_CLASS_NAME_HOTEL } from "./constants";
import { Styled } from "./styles";

type HotelGalleryCarouselProps = {
  slides: Gallery[];
  textPhoto?: string;
};

export const HotelGalleryCarousel: FC<HotelGalleryCarouselProps> = ({ slides, textPhoto }) => {
  const isMobile = useMediaQuery(theme.breakpoints.medium);
  const buttonPrevRef = useRef<HTMLButtonElement | null>(null);
  const buttonNextRef = useRef<HTMLButtonElement | null>(null);
  const [swiper, setSwiper] = useState<SwiperType | null>(null);

  const pagination: PaginationOptions = {
    type: "custom",
    renderCustom: (swiper, current, total) => renderCustomFraction(swiper, current, total, textPhoto),
  };

  return (
    <>
      {slides.length && (
        <Styled.CarouselWrapper>
          <Swiper
            modules={[Pagination, Navigation]}
            navigation={{
              prevEl: `.${NAVIGATION_PREV_CLASS_NAME_HOTEL}`,
              nextEl: `.${NAVIGATION_NEXT_CLASS_NAME_HOTEL}`,
            }}
            onSwiper={setSwiper}
            pagination={pagination}
            watchSlidesProgress
            loop>
            {slides.map((slide) => {
              return (
                <SwiperSlide key={slide.id} data-slide-name={slide.name}>
                  {!isMobile ? <Picture img={slide.img} /> : <Picture img={slide.img} mediumGallery />}
                </SwiperSlide>
              );
            })}
          </Swiper>

          <Styled.NavigationContainer>
            <Styled.NavigationBtnOverlay ref={buttonPrevRef} className={NAVIGATION_PREV_CLASS_NAME_HOTEL}>
              <Styled.NavigationButtonLeft aria-label="Button">
                <Shevron />
              </Styled.NavigationButtonLeft>
            </Styled.NavigationBtnOverlay>
            <Styled.NavigationBtnOverlay ref={buttonNextRef} className={NAVIGATION_NEXT_CLASS_NAME_HOTEL}>
              <Styled.NavigationButtonRight aria-label="Button">
                <Shevron />
              </Styled.NavigationButtonRight>
            </Styled.NavigationBtnOverlay>
          </Styled.NavigationContainer>
        </Styled.CarouselWrapper>
      )}
    </>
  );
};
