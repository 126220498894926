import styled, { css } from "styled-components";

import { font } from "@src/styles/mixins";

const EventsWrapper = styled.div<{ headerHeight: number }>`
  background: ${({ theme }) => theme.color.white};
  background-color: #f2f2f2;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletXLargeMin}) {
    ${({ headerHeight }) => css`
      margin-top: ${headerHeight}px;
      padding-top: 1px;
    `};
  }
`;

const Events = styled.div`
  display: flex;
  flex-direction: column;
  margin-inline: auto;
`;

const Header = styled.h1`
  font-family: Montserrat, sans-serif;
  ${font({ size: "50", lineHeight: "58", fontWeight: "500" })};
  padding-bottom: 64px;
  width: calc(100% - 99px);
  width: calc(100% - 40px);
  max-width: 1400px;
  margin: 0 auto;
  margin-inline: auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    max-width: 1184px;
    ${font({ size: "48", lineHeight: "58", fontWeight: "500" })};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    width: calc(100% - 40px);
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 31px;
    padding-bottom: 0px;
    width: calc(100% - 40px);
    ${font({ size: "28", lineHeight: "38", fontWeight: "500" })}
  }
`;

export const Styled = {
  EventsWrapper,
  Events,
  Header,
};
