import { flexCenter, font } from "@src/styles/mixins";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: calc(100% - 40px);

  gap: 48px;

  max-width: 1400px;
  margin: 0 auto 96px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 0 20px 64px;
    gap: 20px;
  }
`;

const SectionTitleWrapper = styled.div`
  padding-bottom: 64px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-bottom: 27px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
`;

const ImgContainer = styled.div`
  box-sizing: border-box;
  ${flexCenter};
  max-width: 435px;
  min-height: 279px;
  padding: 62px 108px;
  background-color: ${({ theme }) => theme.color.blue};

  svg {
    width: 220px;
    height: 156px;
  }
`;

const Text = styled.div`
  p {
    ${font({ size: "16", lineHeight: "24", fontWeight: "600" })};
    color: ${({ theme }) => theme.color.black};

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

export const Styled = {
  Container,
  SectionTitleWrapper,
  Content,
  ImgContainer,
  Text,
};
