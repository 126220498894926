import { forwardRef, InputHTMLAttributes, useState, ChangeEvent, useEffect } from "react";
import { FieldError } from "react-hook-form";

import { Styled } from "./styles";

export interface IFieldProps {
  placeholder?: string;
  error?: FieldError | undefined;
  isPhone?: boolean;
  disabled?: boolean;
  errorReqField?: boolean;
  hasValue?: boolean;
  rows?: number;
  maxRows?: number;
  minHeight?: number;
  onChange: (value: string) => void;
}

type TypeInputPropsField = InputHTMLAttributes<HTMLTextAreaElement> & IFieldProps;

interface IField extends TypeInputPropsField {}

export const FormTextarea = forwardRef<HTMLTextAreaElement, IField>(
  (
    {
      errorReqField,
      placeholder,
      hasValue,
      error,
      type = "text",
      required,
      rows,
      disabled,
      maxRows,
      value,
      onChange,
      minHeight,
      ...rest
    },
    ref
  ) => {
    const [isFilled, setIsFilled] = useState(false);
    const [isPlaceHolderShown, setIsPlaceHolderShown] = useState(true);
    const handleInputChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(evt.target.value);
      const trimmedInputValueLength = evt.target.value.trim().length;
      const minInputLength = 0;

      if (trimmedInputValueLength > minInputLength) {
        setIsFilled(true);
      } else {
        setIsFilled(false);
      }
    };

    const TextAreaHeight = 161;

    const handleHeightChange = (rowHeight: number) => {
      if (rowHeight > TextAreaHeight) {
        setIsPlaceHolderShown(false);
      } else {
        setIsPlaceHolderShown(true);
      }
    };

    const handleInputFocus = () => {
      setIsFilled(true);
    };

    const handleInputBlur = () => {
      if (!hasValue) {
        setIsFilled(false);
      }
    };

    useEffect(() => {
      if (!hasValue) {
        setIsFilled(false);
      }
    }, [hasValue]);

    return (
      <Styled.Container>
        <Styled.Label>
          <Styled.TextArea
            ref={ref}
            rows={rows}
            maxRows={maxRows}
            onChange={handleInputChange}
            onHeightChange={handleHeightChange}
            disabled={disabled}
            $isInputFilled={isFilled || Boolean(value)}
            value={value}
            $minHeight={minHeight}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />

          {isPlaceHolderShown && (
            <Styled.Placeholder $isInputFilled={isFilled || Boolean(value)} isInputRequired={required}>
              {placeholder}
            </Styled.Placeholder>
          )}
        </Styled.Label>
        {error && !isFilled && !hasValue && errorReqField && <Styled.ErrorMessage>{error.message}</Styled.ErrorMessage>}
      </Styled.Container>
    );
  }
);

FormTextarea.displayName = "FormTextarea";
