import Cookies from "js-cookie";
import { FC } from "react";
import { useLocation } from "react-router-dom";

import { useGetMenuData } from "@src/hooks/useGetMenuData";
import { ENGLISH_LANG_ID, RUSSIAN_LANG_ID } from "@src/utils/constants";

import { Styled } from "./styles";

export const FooterSwitchBtn: FC = () => {
  const location = useLocation();
  const locationPathname = location.pathname;
  const { textToChangeLanguage, languageId } = useGetMenuData();

  const handleLanguageURLUpdate = (languageId: number): string => {
    let newLocationPathname = "";
    if (languageId == ENGLISH_LANG_ID) {
      newLocationPathname = `/en${locationPathname}`;
      return newLocationPathname;
    } else if (languageId == RUSSIAN_LANG_ID) {
      const newLocationArray = locationPathname.split("/");
      newLocationArray.splice(0, 2);
      newLocationPathname = `/${newLocationArray.join("/")}`;
      return newLocationPathname;
    }
    return newLocationPathname;
  };
  const handleSwitchBtnClick = async () => {
    if (languageId) {
      Cookies.set("lang_id", String(languageId));
      const newUrl = handleLanguageURLUpdate(languageId);
      window.location.href = newUrl;
    }
  };

  return <Styled.Button onClick={handleSwitchBtnClick}>{textToChangeLanguage}</Styled.Button>;
};
