import styled, { css } from "styled-components";

import { flexSpaceBetween, font } from "@src/styles/mixins";

const RatesMobileWrapper = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    border: none;
  }
`;

const RatesMobileHeader = styled.div`
  display: flex;
  padding-bottom: 26px;
  border-bottom: 1px solid ${({ theme }) => theme.color.greyBorder};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.h3`
  ${font({ size: "14", lineHeight: "20", fontWeight: "700" })};
  padding-left: 18px;
  max-width: 217px;
`;

const RoomsNumber = styled.span`
  display: inline-block;
  margin-top: 8px;
  padding-left: 18px;
  ${font({ size: "12", lineHeight: "20", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.grey};
`;

const Img = styled.img`
  width: 101px;
  height: 71px;
`;

const ImgContainer = styled.div`
  width: 101px;
  height: 71px;

  & img,
  & picture {
    width: 101px;
    height: 71px;
    object-fit: cover;
  }
`;

const RateWrapper = styled.div`
  padding: 28px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.greyBorder};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    &:last-child {
      border: none;
      margin-bottom: -100px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 92px;
      border-bottom: 1px solid ${({ theme }) => theme.color.greyBorder};
    }
  }
`;

const RateName = styled.div`
  ${font({ size: "14", lineHeight: "22", fontWeight: "700" })};
  margin-bottom: 8px;
`;

const RateDescription = styled.div`
  ${font({ size: "13", lineHeight: "21", fontWeight: "300" })};
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.color.black};
`;

const RateFooter = styled.div`
  ${flexSpaceBetween};
  margin-top: 28px;
`;

const PriceWrapper = styled.div`
  ${flexSpaceBetween};
`;

const Price = styled.div`
  ${font({ size: "18", lineHeight: "26", fontWeight: "700" })};
  margin-right: 21px;

  ${(props: { isSecond?: boolean }) =>
    css`
      color: ${({ theme }) => (!props.isSecond ? theme.color.red : theme.color.black)};
    `}
`;

const PerNight = styled.div`
  ${font({ size: "14", lineHeight: "22", fontWeight: "300" })};
  color: ${({ theme }) => theme.color.grey};
`;

const MobileModal = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 8px;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(38, 35, 36, 0.71);
  z-index: 99;
`;

const MobileModalFooter = styled.div`
  ${font({ size: "16", lineHeight: "24", fontWeight: "300" })};
  height: 184px;
  border-radius: 40px 40px 0 0;
  display: grid;
  place-items: center;
  width: 100%;
  opacity: 1;
  background: ${({ theme }) => theme.color.white};
`;

const MobileModalUnderLine = styled.div`
  width: 40px;
  border: 2px solid ${({ theme }) => theme.color.white};
  border-radius: 2px;
`;

const MobileModalText = styled.div`
  max-width: 311px;
`;

const CancelWarning = styled.div`
  padding: 32px;
  width: 331px;
  color: ${({ theme }) => theme.color.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    width: unset;
  }
`;

export const Styled = {
  RatesMobileWrapper,
  CancelWarning,
  MobileModalText,
  MobileModalUnderLine,
  MobileModalFooter,
  MobileModal,
  RatesMobileHeader,
  RateDescription,
  RateName,
  PerNight,
  RateWrapper,
  RateFooter,
  PriceWrapper,
  Price,
  Img,
  ImgContainer,
  Name,
  RoomsNumber,
  HeaderContainer,
};
