import { addDays, differenceInDays, format, isAfter, isBefore, isEqual, isToday } from "date-fns";
import React, { Dispatch, MouseEvent, SetStateAction, useState } from "react";
import { Range } from "react-date-range";

import { Styled } from "@src/components/Datepicker/styles";
import { getDateFromAtr } from "@src/components/Datepicker/utils";
import { useGetBaseData } from "@src/hooks";
import { DatepickerData } from "@src/mock";
import { declOfNum } from "@src/utils/declOfNum";

export const dayContentRenderer = (
  date: Date,
  datepickerMockData: DatepickerData[],
  range: Range,
  textNights: string,
  textNight: string,
  textNightsGenetiv: string,
  isDateModified: boolean,
  setNights: (night: string | null) => void,
  nights: any,
  unavailableDatesText: string
): JSX.Element => {
  const currentDate = datepickerMockData.filter((data) => +data.day === +date)[0];
  const tooltip = currentDate && currentDate.tooltip;
  const price = currentDate && currentDate.price;
  const nextDay = addDays(new Date(), -1);
  const isDisabled = isBefore(date, nextDay);

  const handleMouseEnter = (event: MouseEvent<HTMLSpanElement>) => {
    if (range.startDate && range.endDate) {
      if (isEqual(range.startDate, range.endDate) && isAfter(date, nextDay) && isDateModified) {
        const hoveredDay = new Date(getDateFromAtr(event));

        const daysCount = differenceInDays(hoveredDay, range.startDate);
        if (daysCount === 0) {
          setNights(null);
          return;
        }
        const formattedDaysCount = String(daysCount).replace("-", "");
        const nightsText = `${formattedDaysCount} ${declOfNum(Number(formattedDaysCount), [
          textNight,
          textNights,
          textNightsGenetiv,
        ])}`;
        setNights(nightsText);
      } else {
        setNights(null);
      }
    }
  };

  return (
    <Styled.DateItemWrap data-for="nights" data-tip>
      <Styled.DateItem
        data-for="disabledDates"
        data-tip={tooltip}
        data-padding="8px 12px"
        data-date={date}
        onMouseEnter={(event) => handleMouseEnter(event)}>
        <Styled.DateText>{date.getDate()}</Styled.DateText>
        {price && <Styled.DatePrice>{price} &#8381;</Styled.DatePrice>}
      </Styled.DateItem>
      {nights && <Styled.Tooltip id="disabledDates">{nights}</Styled.Tooltip>}
      {isDisabled && <Styled.Tooltip id="disabledDates">{unavailableDatesText}</Styled.Tooltip>}
    </Styled.DateItemWrap>
  );
};
