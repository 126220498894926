import styled, { css } from "styled-components";

import { box, flexCenter, flexColumn, font } from "@src/styles/mixins";

const Container = styled.div`
  width: calc(100% - 70px);
  max-width: 1400px;
  margin: 0 auto;
  ${flexCenter};
  flex-direction: column;
  margin-bottom: 96px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    max-width: 1184px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0;
    margin-bottom: 0px;
    width: 100%;
  }
`;

const Header = styled.h2`
  ${font({ size: "16", lineHeight: "24", fontWeight: "700" })}
  text-align: center;
  margin-bottom: 64px;
  letter-spacing: 0.06em;
  text-transform: uppercase;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-top: 0;
    margin-bottom: 32px;
  }
`;

const ContactsWrapper = styled.div`
  width: 100%;
  display: grid;

  position: relative;

  [class*="ymaps-2"][class*="-ground-pane"] {
    filter: grayscale(100%);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    min-height: 716px;
    grid-auto-flow: column;

    grid-auto-columns: 52% 48%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 100%;
    grid-auto-columns: 1fr;
  }
`;

const Contacts = styled.div`
  ${flexColumn};

  padding: 72px 64px 64px;
  background: ${({ theme }) => theme.color.white};
  font-weight: 300;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    box-sizing: border-box;
    padding: 32px 20px;
  }
`;

const Contact = styled.div`
  margin-bottom: 32px;
`;

const RequestCallContainer = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: 0;

    button {
      padding: 0px;
    }
  }
`;

const RequestButton = styled.button<{ isSpaPage?: boolean }>`
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.color.red};
  text-align: left;
  ${font({ size: "18", lineHeight: "32", fontWeight: 400 })}

  ${({ isSpaPage }) =>
    isSpaPage &&
    css`
      color: ${({ theme }) => theme.color.blueSecondary};
    `};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "14", lineHeight: "20", fontWeight: 400 })}
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;

  a {
    text-decoration: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletXLarge}) {
    width: 100%;
    flex-direction: column;

    button {
      width: 100%;
    }
  }
`;

const ButtonTitle = styled.p`
  margin: 64px 0 16px;
  margin-top: auto;

  ${font({ size: "18", lineHeight: "32" })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: 47px 0 16px;
  }
`;

const MapContainer = styled.div`
  width: 47%;
  max-width: 668px;
  flex-grow: 1;
  height: 774px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
  }
`;

const SpaIconContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: 28px;
  right: 22px;
  ${box(140)};
  padding: 21px 0;
  background-color: ${({ theme }) => theme.color.blue};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${box(108)};

    right: auto;
    left: 12px;
    bottom: 36px;
  }

  & svg {
    width: 140px;
    height: 99px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      width: 108px;
      height: 77px;
    }
  }
`;

export const Styled = {
  ButtonTitle,
  Container,
  Header,
  ContactsWrapper,
  RequestCallContainer,
  RequestButton,
  Contacts,
  Buttons,
  Contact,
  MapContainer,
  SpaIconContainer,
};
