import styled, { css } from "styled-components";

import { font } from "../../styles/mixins";

const Rooms = styled.section`
  width: calc(100% - 40px);
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 96px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    max-width: 1184px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0;
    margin-bottom: 0px;
    margin: 0 20px 64px;
  }
`;

const RoomsTitle = styled.h1<{ isMainPage?: boolean }>`
  ${font({ size: "16", lineHeight: "24", fontWeight: "700" })};

  text-align: center;
  letter-spacing: 0.06em;

  color: ${({ theme }) => theme.color.black};
  margin-bottom: 32px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 64px;
    text-align: left;
    ${font({ size: "50", lineHeight: "58", fontWeight: "500" })};
  }

  ${(props) =>
    !props.isMainPage &&
    css`
      ${font({ size: "28", lineHeight: "38", fontWeight: "500" })};

      text-align: left;

      color: ${({ theme }) => theme.color.black};
      margin-bottom: 32px;

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        margin-bottom: 64px;
        text-align: left;
        margin-left: -2px;
        font-family: Montserrat, sans-serif;
        ${font({ size: "50", lineHeight: "58", fontWeight: "500" })};
      }
    `}
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 0;
    gap: 48px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: 0;
    gap: 48px;
  }
`;

const Room = styled.div`
  min-height: 252px;
  cursor: pointer;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: calc(100% / 2 - 24px);

    margin: 12px;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 0px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxlarge}) {
    width: calc(100% / 3 - 32px);
    margin: 0;
  }
`;

export const Styled = { Rooms, RoomsTitle, Container, Room };
