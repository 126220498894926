import styled from "styled-components";

import { flexCenter, font } from "@src/styles/mixins";

import galleryBg from "../../assets/images/gallery-bg.jpg";

const Container = styled.div`
  padding: 64px 0 96px;
  margin-bottom: 64px;
  background-image: url(${galleryBg});
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 64px 0 43px;
    background-image: none;
    background-color: ${({ theme }) => theme.color.black};
  }
`;

const SectionTitleWrapper = styled.div`
  ${flexCenter};
  gap: 0 12px;
  margin-bottom: 64px;
  color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 33px;
  }
`;

const PhotoCountText = styled.div`
  ${font({ size: "16", lineHeight: "24", fontWeight: 700 })};
  color: ${({ theme }) => theme.color.grey};
  text-transform: uppercase;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`;

export const Styled = { Container, SectionTitleWrapper, PhotoCountText };
