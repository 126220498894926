import { isBefore, format, addDays, isEqual, parseISO, eachDayOfInterval } from "date-fns";
import { enUS, ru } from "date-fns/locale";
import Cookies from "js-cookie";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { DateRangePicker, RangeKeyDict } from "react-date-range";
import Hammer from "react-hammerjs";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { ReactComponent as CloseIcon } from "@src/assets/images/icons/close.svg";
import { FROM_TO_DATE_FORMAT } from "@src/constants";
import { useActions, useAppSelector, useGetBaseData, useMediaQuery } from "@src/hooks";
import { datepickerModalStyle } from "@src/styles/popupStyles";
import { theme } from "@src/theme";

import { dayContentRenderer } from "./dayContentRenderer";
import { navigationRenderer } from "./navigationRenderer";
import { Styled } from "./styles";

import "react-date-range/dist/styles.css";
import { useGetBookingCalendarQuery } from "@src/store/services";

export const Datepicker = () => {
  const location = useLocation();
  const roomId = location?.state?.roomId ? location.state.roomId : null;
  const [nights, setNights] = useState<string | null>(null);
  const {
    datesStay,
    textNight,
    textNightsGenetiv,
    textNights,
    bookingResetBtnText,
    checkInText,
    checkOutText,
    unavailableDatesText,
  } = useGetBaseData();
  const { modalIsOpen, range, isDateModified } = useAppSelector((state) => state.datepicker);
  const { closeModal, setRange, setIsDateModified } = useActions();
  const isTablet = useMediaQuery(theme.breakpoints.large);
  const { data = {} } = useGetBookingCalendarQuery(roomId);
  const dateArray = Object.keys(data);
  const [missingDates, setMissingDates] = useState<string[]>([]);

  // проверка на отсутствующие даты, чтобы их заблокировать в календаре
  useEffect(() => {
    if (dateArray.length) {
      const dates: Date[] = dateArray.map((date) => parseISO(date));
      const endDate = dates[dates.length - 1];
      const allDays = eachDayOfInterval({ start: new Date(), end: endDate });
      const missingDays = allDays.filter((day) => !dates.some((date) => date.getTime() === day.getTime()));
      const missingDaysStrings = missingDays.map((day) => {
        return format(day, "yyyy-MM-dd");
      });
      setMissingDates(missingDaysStrings);
    }
  }, [data]);

  const datepickerData = dateArray.map((date) => {
    const dateDetail = date.split("-");
    const year = +dateDetail[0];
    const month = +dateDetail[1] - 1;
    const day = +dateDetail[2];

    const isDisabled = missingDates.includes(date);

    return {
      day: new Date(year, month, day),
      disabled: isDisabled,
      tooltip: "",
      price: Number(data[date]),
      isLowest: true,
    };
  });

  const handleModalCloseClick = () => {
    closeModal();
  };

  const handleDateSelect = (ranges: RangeKeyDict) => {
    if (ranges.selection.startDate?.getTime() !== ranges.selection.endDate?.getTime()) {
      closeModal();
    }
    setRange(ranges.selection);
  };

  const handleResetDatesClick = () => {
    setRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
    setIsDateModified(false);
  };

  const disabledDates = datepickerData.filter((data) => data.disabled).map((data) => data.day);

  const disabledDays = (date: Date): boolean => {
    const nextDay = addDays(new Date(), -1);
    return isBefore(date, nextDay);
  };

  const renderDayContent = (date: Date): ReactNode => {
    return dayContentRenderer(
      date,
      datepickerData,
      range,
      textNights,
      textNight,
      textNightsGenetiv,
      isDateModified,
      setNights,
      nights,
      unavailableDatesText
    );
  };

  const renderSelectedDate = useMemo(() => {
    if (range.startDate && range.endDate) {
      if (isEqual(range.startDate?.getTime(), range.endDate?.getTime())) {
        return `${checkOutText}: ${format(range.startDate, FROM_TO_DATE_FORMAT)}`;
      } else {
        return `${checkInText}: ${format(range.endDate, FROM_TO_DATE_FORMAT)}`;
      }
    }
    return `${checkOutText}:`;
  }, [range]);

  const currentDate = new Date();

  const handleDatePickerSwipe = () => {
    closeModal();
  };

  const [isScrolled, setIsScrolled] = useState(false);

  const menuScrollHandler = (evt: Event) => {
    const target = evt.target as HTMLElement;
    const menuScroll = target.scrollTop;
    setIsScrolled(menuScroll > 0);
  };

  useEffect(() => {
    const container = document.querySelector(".rdrMonthsVertical") as HTMLElement | null;

    if (container) {
      container.addEventListener("scroll", menuScrollHandler);
    }

    return () => {
      container?.removeEventListener("scroll", menuScrollHandler);
    };
  }, []);

  if (isTablet === undefined) return null;
  const defaultLanguageId = "1";
  const langId = Cookies.get("lang_id") || defaultLanguageId;
  return (
    <Modal
      isOpen={modalIsOpen}
      shouldCloseOnEsc={true}
      onRequestClose={handleModalCloseClick}
      className="datepickerModal"
      style={datepickerModalStyle}>
      <Hammer onSwipe={handleDatePickerSwipe} direction="DIRECTION_DOWN">
        <div>
          {!isTablet && (
            <Styled.ModalHeader>
              <Styled.ModalTitle>{datesStay}</Styled.ModalTitle>
              <Styled.ModalCloseBtn onClick={handleModalCloseClick}>
                <CloseIcon />
              </Styled.ModalCloseBtn>
            </Styled.ModalHeader>
          )}

          <Styled.DatepickerWrapper isScrolled={isScrolled}>
            {isTablet && (
              <Styled.ResultsBar>
                <Styled.SelectedDate>{renderSelectedDate}</Styled.SelectedDate>
                <Styled.ResetButton onClick={handleResetDatesClick}>{bookingResetBtnText}</Styled.ResetButton>
              </Styled.ResultsBar>
            )}

            {isTablet ? (
              <DateRangePicker
                locale={+langId === 1 ? ru : enUS}
                onChange={handleDateSelect}
                months={4}
                ranges={[range]}
                direction="vertical"
                showDateDisplay={false}
                showMonthAndYearPickers={false}
                weekdayDisplayFormat="EEEEEE"
                monthDisplayFormat="LLLL yyyy"
                disabledDates={disabledDates}
                disabledDay={disabledDays}
                scroll={{ enabled: true }}
              />
            ) : (
              <DateRangePicker
                locale={+langId === 1 ? ru : enUS}
                onChange={handleDateSelect}
                months={2}
                ranges={[range]}
                date={new Date()}
                direction="horizontal"
                showDateDisplay={false}
                dayContentRenderer={renderDayContent}
                showMonthAndYearPickers={false}
                weekdayDisplayFormat="EEEEEE"
                monthDisplayFormat="LLLL yyyy"
                navigatorRenderer={navigationRenderer}
                disabledDates={disabledDates}
                disabledDay={disabledDays}
              />
            )}
          </Styled.DatepickerWrapper>

          <ReactTooltip id="disabledDates" />
        </div>
      </Hammer>
    </Modal>
  );
};
