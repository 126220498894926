import TextareaAutosize from "react-textarea-autosize";
import styled, { css } from "styled-components";

import { flexCenter, font } from "@src/styles/mixins";

const Container = styled.div``;

const ItemTitle = styled.h3`
  margin-bottom: 32px;
  margin-top: 48px;
  ${font({ size: "18", lineHeight: "26", fontWeight: 700 })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 24px;
    margin-top: 64px;
    ${font({ size: "16", lineHeight: "22", fontWeight: 700 })};
  }
`;
const ItemSubtitle = styled.p`
  margin-bottom: 32px;
  margin-top: -20px;
  ${font({ size: "16", lineHeight: "22", fontWeight: 500 })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: -15px;
    margin-bottom: 24px;
    ${font({ size: "14", lineHeight: "20", fontWeight: 500 })};
  }
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0 24px;
  margin-bottom: 36px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 30px;
  }
`;

const Textarea = styled(TextareaAutosize)`
  width: 100%;
  padding: 20px;
  ${font({ size: "16", lineHeight: "24" })};
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
  resize: none;
  box-sizing: border-box;

  width: 100%;
  padding: 20px;
  ${font({ size: "16", lineHeight: "24" })};
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
  resize: none;
  box-sizing: border-box;

  &::-webkit-scrollbar-track {
    width: 4.5px;
    background-color: ${({ theme }) => theme.color.white};
  }

  &::-webkit-scrollbar {
    width: 4.5px;
    background-color: ${({ theme }) => theme.color.white};
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    max-height: 60px;
    width: 4.5px;
    background: #dbdbdb;
    border-radius: 2.5px;
  }

  &:active,
  &:focus {
    outline: none;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 64px;
    margin-top: 27px;
  }
`;

const CheckboxCheck = styled.span`
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
`;

const Checkbox = styled.div`
  ${flexCenter};
  position: relative;
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
  cursor: pointer;

  input[type="checkbox"] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;

    &:checked {
      & + ${CheckboxCheck} {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;

const CheckboxLabel = styled.label`
  ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  cursor: pointer;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 232px;
  }
`;

const Rules = styled.p`
  margin: 32px 0;

  &:last-child {
    margin: 32px 0 80px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  }
`;

const Warning = styled.p`
  ${font({ size: "12", lineHeight: "20", fontWeight: 400 })};
  color: ${({ theme }) => theme.color.grey};
`;

const Buttons = styled.div`
  display: flex;
`;

const Cards = styled.div`
  display: flex;
  align-items: center;
  margin: 19px 0 108px;
  gap: 20px;
`;

const PayMobile = styled.div`
  display: none;
  ${font({ size: "13", lineHeight: "21", fontWeight: 300 })};
  margin-bottom: 64px;
  a {
    color: ${({ theme }) => theme.color.red};
    margin-left: 5px;
    cursor: pointer;
    text-decoration: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
  }
`;

const Item = styled.div<{ isGrey?: boolean }>`
  margin-bottom: 80px;

  ${({ isGrey }) =>
    isGrey &&
    css`
      & ${Row} {
        opacity: 0.4;
      }
    `};
`;

export const Styled = {
  Container,
  Buttons,
  Cards,
  Warning,
  Rules,
  Item,
  ItemTitle,
  ItemSubtitle,
  Row,
  Textarea,
  CheckboxWrapper,
  Checkbox,
  CheckboxLabel,
  CheckboxCheck,
  PayMobile,
};
