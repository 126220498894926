import styled, { css } from "styled-components";

const Container = styled.div<{ headerHeight: number }>`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletXLargeMin}) {
    ${({ headerHeight }) => css`
      margin-top: ${headerHeight}px;
      padding-top: 1px;
    `};
  }
`;

export const Styled = { Container };
