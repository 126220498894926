import { FC } from "react";

import { useGetBaseData, useMediaQuery } from "@src/hooks";
import { useGetEventsQuery } from "@src/store/services";
import { theme } from "@src/theme";

import { EventRoom } from "../EventRoom";
import { EventRoomCarousel } from "../EventRoomCarousel";
import { Styled } from "./styles";

type EventRoomsProps = {
  handleScroll: () => void;
};

export const EventRooms: FC<EventRoomsProps> = ({ handleScroll }) => {
  const { data, isLoading } = useGetEventsQuery();

  const eventRoomsData = data?.eventHalls;
  const { eventsHallText } = useGetBaseData();
  const isAdaptive = useMediaQuery(theme.breakpoints.medium);

  if (isLoading) return null;

  return (
    <Styled.Rooms>
      <Styled.Title>{eventsHallText}</Styled.Title>

      {eventRoomsData && (
        <Styled.Container>
          {!isAdaptive ? (
            eventRoomsData.map((item) => {
              return <EventRoom data={item} key={item.id} handleScrollToForm={handleScroll} />;
            })
          ) : (
            <EventRoomCarousel rooms={eventRoomsData} handleScrollToForm={handleScroll} />
          )}
        </Styled.Container>
      )}
    </Styled.Rooms>
  );
};
