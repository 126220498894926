import { findFlagByDialCode, findFlag } from "country-list-with-dial-code-and-flag";
import { forwardRef, InputHTMLAttributes, useState, ChangeEvent, FC } from "react";

import useImage from "@src/hooks/useImage";

import { Styled } from "./styles";
import {
  PATTERN,
  getFormattedPhoneValue,
  getInputNumbersValue,
  handlePhoneFormat,
  handlePhoneKeyDown,
  // handlePhonePaste,
} from "./utils";

export interface IFieldProps {
  placeholder?: string;
  icon?: string;
  // error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  error?: any;
  isPhone?: boolean;
  errorReqField?: boolean;
  hasFlag?: boolean;
  hasValue?: boolean;
  staticError?: boolean;
}

type TypeInputPropsField = InputHTMLAttributes<HTMLInputElement> & IFieldProps;

interface IField extends TypeInputPropsField {}

type ImageType = { fileName: any };

const Image: FC<ImageType> = ({ fileName }) => {
  const { image } = useImage(fileName);

  return <>{image && <img src={image} alt={image} />}</>;
};

export const FormField = forwardRef<HTMLInputElement, IField>(
  (
    {
      errorReqField,
      placeholder,
      hasValue,
      icon,
      error,
      type = "text",
      required,
      isPhone,
      hasFlag,
      staticError,
      ...rest
    },
    ref
  ) => {
    const [isFilled, setIsFilled] = useState(false);

    const hasError = Boolean(error);

    const hasIcon = Boolean(icon);
    const hasInputError = !isFilled ? hasError && !isFilled && !hasValue && errorReqField : hasError && errorReqField;
    const minInputLengthPhone = 1;
    const minInputLengthText = 0;

    const handleInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
      const trimmedInputValueLength = evt.target.value.trim().length;
      const minInputLength = !isPhone ? minInputLengthText : minInputLengthPhone;

      if (trimmedInputValueLength > minInputLength) {
        setIsFilled(true);
      } else {
        setIsFilled(false);
      }
    };

    const [currentFlag, setCurrentFlag] = useState("");

    const getFlag = (value: string) => {
      const searchValue = `+${value.split(" ").join("").replace(/\D/g, "")}`;
      const flag = findFlagByDialCode(searchValue);
      if (flag?.code) {
        setCurrentFlag(flag.code.toLowerCase());
      }
    };
    const getFlagByPhonePaste = (value: string) => {
      const searchValue = `+${value.split(" ").join("").replace(/\D/g, "")}`;
      let pasteSearchvalue;
      if (searchValue[1] === "7") {
        pasteSearchvalue = searchValue.slice(0, 2);
      } else {
        pasteSearchvalue = searchValue.slice(0, 3);
      }
      const flag = findFlagByDialCode(pasteSearchvalue);
      if (flag?.code) {
        setCurrentFlag(flag.code.toLowerCase());
      } else {
        setCurrentFlag("");
      }
    };

    const handlePhonePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
      const pasted = event.clipboardData ?? window.Clipboard;
      const input = event.target as HTMLInputElement;
      const inputNumbersValue = getInputNumbersValue(input.value);

      if (pasted) {
        const pastedText = pasted.getData("Text");

        if (PATTERN.test(pastedText)) {
          getFlagByPhonePaste(inputNumbersValue);

          input.value = inputNumbersValue;
        }
      }
    };

    const handlePhoneInput = (evt: ChangeEvent<HTMLInputElement>) => {
      handleInputChange(evt);
      handlePhoneFormat(evt);
      getFlag(getFormattedPhoneValue(evt) as string);
    };

    return (
      <Styled.Container>
        <Styled.Label>
          {!isPhone ? (
            <Styled.Input
              ref={ref}
              type={type}
              {...rest}
              onChange={handleInputChange}
              hasInputError={hasInputError}
              hasInputIcon={hasIcon}
              hasValue={hasValue}
              isInputFilled={isFilled}
            />
          ) : (
            <>
              <Styled.Input
                ref={ref}
                type={type}
                maxLength={18}
                onInput={handlePhoneInput}
                onKeyDown={handlePhoneKeyDown}
                onPaste={handlePhonePaste}
                onChange={handlePhoneInput}
                hasInputError={hasInputError}
                hasInputIcon={hasIcon}
                hasValue={hasValue}
                isInputFilled={isFilled}
                {...rest}
              />
              {!!currentFlag && hasFlag && (
                <Styled.FlagContainer>
                  <Image fileName={currentFlag} />
                </Styled.FlagContainer>
              )}
            </>
          )}

          <Styled.Placeholder isInputFilled={isFilled || hasValue} isInputRequired={required}>
            {placeholder}
          </Styled.Placeholder>
          {icon && <Styled.Icon src={icon} />}
        </Styled.Label>

        {!!error && <Styled.ErrorMessage staticError={staticError}>{error.message}</Styled.ErrorMessage>}
      </Styled.Container>
    );
  }
);

FormField.displayName = "Field";
