import styled, { css } from "styled-components";

import { flexColumnCenter, font, transformXCenter, width100 } from "@src/styles/mixins";

const Lightbox = styled.div<{ showNavigation?: boolean }>`
  position: relative;
  ${width100};
  width: 100vw;
  height: 100%;
  ${flexColumnCenter};
`;
const GalleryWrapper = styled.div`
  ${width100}

  .swiper-wrapper {
    align-items: center;
    margin: 30px 0;
  }

  .swiper-pagination-fraction {
    cursor: pointer;
    display: inline-block;
    width: auto;
    position: absolute;
    z-index: 2;
    top: 27px;
    right: 17px;
    bottom: unset;
    left: unset;
    ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};

    color: ${({ theme }) => theme.color.whiteOpacity50};
    }

    .swiper-pagination-current {
      color: ${({ theme }) => theme.color.white};
    }

    picture,
    img {
      ${width100};
    
      height: auto;
      max-height: calc(100vh - 60px);
      object-fit: contain;

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 124px 0px;
        height: 70vh;
        max-height: calc(100vh - 248px);
      
      }
    }
  }
`;

const Image = styled.img`
  ${width100};

  height: auto;
  max-height: calc(100vh - 60px);
  object-fit: contain;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 124px 0;
    max-height: calc(100vh - 248px);
  }
`;

const BackButton = styled.button<{ showNavigation?: boolean }>`
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 30px;
  left: 26px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${(props) =>
      props.showNavigation &&
      css`
        display: none;
      `}
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    align-items: center;
    top: 28px;
    left: 29px;
  }
`;

const BackButtonText = styled.span`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: inline;
    margin-left: 21px;
    ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
    color: ${({ theme }) => theme.color.white};
  }
`;

const FractionContainer = styled.div<{ showNavigation?: boolean }>`
  display: inline-block;
  width: auto;
  position: absolute;
  z-index: 2;
  top: 27px;
  right: 17px;
  bottom: unset;
  left: unset;
  ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};

  color: ${({ theme }) => theme.color.whiteOpacity50};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${(props) =>
      props.showNavigation &&
      css`
        display: none;
      `}

    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      top: 80px;
      right: unset;
      ${transformXCenter}
    }
  }

  & .swiper-pagination-current {
    color: ${({ theme }) => theme.color.white};
  }
`;

const FractionTitle = styled.span`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: inline;
    color: ${({ theme }) => theme.color.white};
  }
`;

const Fraction = styled.span``;

const Text = styled.p<{ showNavigation?: boolean }>`
  position: absolute;
  z-index: 2;
  left: 24px;
  bottom: 22px;

  ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${(props) =>
      props.showNavigation &&
      css`
        display: none;
      `}
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    bottom: 70px;
    ${transformXCenter}
  }
`;

export const Styled = {
  Lightbox,
  GalleryWrapper,
  BackButton,
  BackButtonText,
  FractionContainer,
  FractionTitle,
  Fraction,
  Image,
  Text,
};
