import { FC, useState } from "react";
import Modal from "react-modal";

import { ReactComponent as AddIcon } from "@src/assets/images/icons/add.svg";
import { ReactComponent as ManIcon } from "@src/assets/images/icons/peopleIcon.svg";
import { useMediaQuery } from "@src/hooks";
import { useGetBaseData } from "@src/hooks/useGetBaseData";
import { IPrice } from "@src/interfaces";
import { popupStyles } from "@src/styles/popupStyles";
import { theme } from "@src/theme";
import { declOfNum } from "@src/utils";

import { ManIconsPopup } from "../ManIconsPopup";
import { Styled } from "./styles";

type ManIconsTypes = {
  price: IPrice;
  color: string;
};

export const ManIcons: FC<ManIconsTypes> = ({ price, color }) => {
  const {
    adultsPluralText,
    moreChildTextGenetive,
    oneChildText,
    oneChildGenetiveText,
    adultText,
    additionalPlaceText,
    withoutPlaceText,
  } = useGetBaseData();
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");

  const tooltipText = `${
    price.adult_count
      ? `${price.adult_count} ${declOfNum(price.adult_count, [adultText, adultsPluralText, moreChildTextGenetive])}`
      : ""
  }${
    price.child_under_11_count
      ? `, ${price.child_under_11_count} ${declOfNum(price.child_under_11_count, [
          oneChildText,
          oneChildGenetiveText,
          moreChildTextGenetive,
        ])} ${additionalPlaceText}`
      : ""
  }${
    price.child_under_5_count
      ? `, ${price.child_under_5_count} ${declOfNum(price.child_under_5_count, [
          oneChildText,
          oneChildGenetiveText,
          moreChildTextGenetive,
        ])} ${withoutPlaceText}`
      : ""
  }
  
  `;

  const isAdaptive = useMediaQuery(theme.breakpoints.medium);

  const isPopupOpen = showPopup && (isAdaptive as boolean);

  const handleShowPopupClick = (text: string) => {
    setShowPopup(true);
    setPopupText((prev) => (prev = text));
  };

  const handleClosePopupClick = () => {
    setShowPopup(false);
    setPopupText((prev) => (prev = ""));
  };

  return (
    <>
      <Styled.ManIcons data-for={`guests-tooltip/${price.id}`} data-tip={tooltipText}>
        <Styled.IconsContainer onClick={() => handleShowPopupClick(tooltipText)}>
          {[...new Array(price.adult_count)].map((adult, index) => {
            return (
              <div key={index}>
                <ManIcon height={23} stroke={color} />
              </div>
            );
          })}
        </Styled.IconsContainer>

        {(!!price.child_under_11_count || !!price.child_under_5_count) && <AddIcon stroke={color} width={9} />}

        {!!price.child_under_11_count && (
          <Styled.IconsContainer onClick={() => handleShowPopupClick(tooltipText)}>
            {[...new Array(price.child_under_11_count)].map((child, index) => {
              return (
                <div key={index}>
                  <ManIcon height={20} stroke={color} />
                </div>
              );
            })}
          </Styled.IconsContainer>
        )}

        {!!price.child_under_5_count && (
          <Styled.IconsContainer onClick={() => handleShowPopupClick(tooltipText)}>
            {[...new Array(price.child_under_5_count)].map((child, index) => {
              return (
                <div key={index}>
                  <ManIcon height={17} key={index} stroke={color === "#A0072B" ? "#CF8395" : "#DBDBDB"} />
                </div>
              );
            })}
          </Styled.IconsContainer>
        )}

        {!isAdaptive && <Styled.Tooltip id={`guests-tooltip/${price.id}`} />}
      </Styled.ManIcons>
      <Modal
        isOpen={isPopupOpen}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={handleClosePopupClick}
        style={popupStyles}>
        <ManIconsPopup text={popupText} />
      </Modal>
    </>
  );
};
