export type RequestFormType = {
  name: string;
  phone: string;
  email: string;
  long_stay?: number;
  spa_service_name?: string;
};

export const handleXssFormat = (values: any) => {
  for (const i in values) {
    if (typeof values[i] === "string") {
      values[i] = values[i].replace(/script/g, "");
    } else {
      handleXssFormat(values[i]);
    }
  }
};
