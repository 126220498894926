import { FC } from "react";

import { useGetNavigation } from "@src/hooks/useGetNavigation";

import { FooterLinks } from "../FooterLinks";
import { RequestCall } from "../RequestCall";
import { Styled } from "./styles";

export const FooterTopPart: FC = () => {
  const { links } = useGetNavigation();

  return (
    <Styled.FooterTop>
      <FooterLinks data={links} />

      <RequestCall />
    </Styled.FooterTop>
  );
};
