import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { requestCall } from "./requestCall.actions";

interface requestCallState {
  isLoading: boolean;
  isSuccess: boolean;
}

const initialState: requestCallState = {
  isLoading: false,
  isSuccess: false,
};

export const requestCallSlice = createSlice({
  name: "requestCall",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestCall.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(requestCall.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isSuccess = action.payload === 200;
      })
      .addCase(requestCall.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
      });
  },
});

export default requestCallSlice.reducer;
