import { format } from "date-fns";
import React, { MouseEvent, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { ReactComponent as CalendarIcon } from "@src/assets/images/icons/calendar.svg";
import { ReactComponent as UserIcon } from "@src/assets/images/icons/user-input.svg";
import { Button, ContentWrapper, GuestModal, InputField } from "@src/components";
import { Datepicker } from "@src/components/Datepicker";
import { BOOKING_DATE_FORMAT_FOR_MAIN_FORM, MAIN_DATE_FORMAT } from "@src/constants";
import { RESERVATION_ROUTES } from "@src/constants/_routes.constants";
import { useActions, useAppSelector, useGetBaseData, useMediaQuery } from "@src/hooks";
import { getDates } from "@src/store/slices/datepicker/datepicker.selector";
import { theme } from "@src/theme";
import { declOfNum } from "@src/utils";

import { Styled } from "./styles";

export const MainBookingForm = () => {
  const location = useLocation();
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;

  const {
    openModal,
    setRange,
    setIsDateModified,
    setGuestsData,
    setSelectedRooms,
    setAmountRates,
    setRateCounter,
    setGuests,
  } = useActions();
  const { arrivalDate, departureDate, bookingGuests, bookingText, checkInText, checkOutText, roomsDecl, guestsDecl } =
    useGetBaseData();
  const { guestList, modalIsOpen, range } = useAppSelector((state) => state.datepicker);
  const { arrivalDateValue, departureDateValue, guestsNumber, roomsNumber } = useAppSelector(getDates);
  const guestsValue =
    guestsNumber > 0
      ? ` ${roomsNumber} ${declOfNum(guestsNumber, roomsDecl)}, ${guestsNumber} ${declOfNum(guestsNumber, guestsDecl)}`
      : "";

  const [isOpenGuestsCounter, setIsOpenGuestsCounter] = useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.medium);

  const formatedDepartureDate =
    departureDateValue && format(new Date(departureDateValue), BOOKING_DATE_FORMAT_FOR_MAIN_FORM);
  const formatedArrivalDate = arrivalDateValue && format(new Date(arrivalDateValue), BOOKING_DATE_FORMAT_FOR_MAIN_FORM);

  const navigate = useNavigate();
  const handleBookingClick = () => {
    const model = {
      date_from: arrivalDateValue,
      date_to: departureDateValue,
      rooms: guestList.map((guest) => ({
        adult_count: guest.guests.filter((i) => i.type === "adult_count")[0].count,
        child_under_5_count: guest.guests.filter((i) => i.type === "child_under_5_count")[0].count,
        child_under_11_count: guest.guests.filter((i) => i.type === "child_under_11_count")[0].count,
      })),
    };
    setGuestsData(model);
    setSelectedRooms([]);
    setAmountRates(0);
    setRateCounter({});
    !isEnglishVersion
      ? navigate(`${RESERVATION_ROUTES.RESERVATION_SELECT_ROOM}`)
      : navigate(`/en${RESERVATION_ROUTES.RESERVATION_SELECT_ROOM}`);
  };

  const handleDateInputClick = (evt: MouseEvent<HTMLInputElement>) => {
    evt.stopPropagation();
    openModal();
  };

  const handleInputChange = (value: string) => {
    if (!value) {
      setRange({ startDate: undefined, key: "selection", endDate: undefined });
      setIsDateModified(false);
    }
  };
  const handleGuestsInputChange = (value: string) => {
    if (!value) {
      setGuests([]);
    }
  };

  const handleOpenGuestsCounterClick = () => {
    setIsOpenGuestsCounter(true);
  };

  const handleCloseGuestsCounterClick = () => {
    setIsOpenGuestsCounter(false);
  };
  const endDate = range.endDate ? format(range.endDate, MAIN_DATE_FORMAT) : "";
  const startDate = range.startDate ? format(range.startDate, MAIN_DATE_FORMAT) : "";

  const mobileDepartureDateValue = departureDateValue
    ? `${endDate.split(" ")[0]} ${endDate?.split(" ")[1]?.slice(0, 3)}.`
    : "";
  const mobileArrivalDateValue = arrivalDateValue
    ? `${startDate.split(" ")[0]} ${startDate?.split(" ")[1]?.slice(0, 3)}.`
    : "";

  const departureValue = isTablet ? mobileDepartureDateValue : formatedDepartureDate;
  const arrivalValue = isTablet ? mobileArrivalDateValue : formatedArrivalDate;

  const arrivalDateText = !isTablet && !arrivalValue ? arrivalDate : checkInText;
  const departureDateText = !isTablet && !departureValue ? departureDate : checkOutText;

  const separator = !isTablet && <Styled.Separator />;
  const isDisabled = (!!arrivalDateValue || !!departureDateValue) && !!guestsValue;

  return (
    <Styled.Container>
      <ContentWrapper>
        <Styled.Content>
          <Styled.Form>
            <Styled.InputContainer>
              <InputField
                label={arrivalDateText}
                name="arrival_date"
                icon={<CalendarIcon />}
                value={arrivalValue}
                onClick={handleDateInputClick}
                onChange={handleInputChange}
                withoutBorder
              />
            </Styled.InputContainer>

            {separator}

            <Styled.InputContainer>
              <InputField
                label={departureDateText}
                name="departure_date"
                icon={<CalendarIcon />}
                value={departureValue}
                onClick={handleDateInputClick}
                onChange={handleInputChange}
                withoutBorder
              />
            </Styled.InputContainer>

            {separator}

            <Styled.InputContainer>
              <InputField
                onClick={handleOpenGuestsCounterClick}
                label={bookingGuests}
                value={guestsValue}
                icon={<UserIcon />}
                onChange={handleGuestsInputChange}
                withoutBorder
                name="booking_guests"
              />
            </Styled.InputContainer>
          </Styled.Form>

          <Button
            isDisabled={!isDisabled}
            primary={isDisabled}
            height="64px"
            title={bookingText}
            onClick={handleBookingClick}
            noWrap
          />
        </Styled.Content>
        <GuestModal onModalCloseClick={handleCloseGuestsCounterClick} isOpen={isOpenGuestsCounter} />
      </ContentWrapper>

      {modalIsOpen && <Datepicker />}
    </Styled.Container>
  );
};
