import { differenceInCalendarYears, format, parseISO } from "date-fns";
import { enGB, ru } from "date-fns/locale";
import Cookies from "js-cookie";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { RESERVATION_ROUTES } from "@src/constants/_routes.constants";
import { useHover, useGetBaseData } from "@src/hooks";
import { useChangeSearchParams } from "@src/hooks/useChangeSearchParams";
import { useGetMenuData } from "@src/hooks/useGetMenuData";
import { Rooms } from "@src/interfaces";

import { Button, MobileGallery, Picture, Tag } from "../_Ui";
import { Styled } from "./styles";

type RoomCardPreviewProps = {
  data: Rooms;
  isInView?: boolean;
};

export const RoomCardPreview: FC<RoomCardPreviewProps> = ({ data, isInView }) => {
  const location = useLocation();
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;
  const isMainPage = location.pathname === "/" || location.pathname === "/en";

  const { setQueryParams } = useChangeSearchParams();
  const { bookBtnPhrase, moreInfoButtonText } = useGetMenuData();
  const { textFrom, textNight, textSuggestionFor } = useGetBaseData();
  const { id, img, preview_description, on_date, price, fill_values, name, photos } = data;

  const [hoverRef, isHovered, setIsHovered] = useHover<HTMLDivElement>();

  const isItemAvailable = Boolean(price) && Boolean(on_date);
  const defaultLanguageId = "1";
  const langId = Cookies.get("lang_id") || defaultLanguageId;
  const currentLocale = langId === "1" ? ru : enGB;
  let formattedPrice, formattedDate;
  const differenceInYears = differenceInCalendarYears(parseISO(on_date), Date.now());

  if (isItemAvailable) {
    formattedPrice = String(price)
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      .split(".")[0];

    formattedDate = !differenceInYears
      ? format(parseISO(on_date), "d MMMM", { locale: currentLocale })
      : format(parseISO(on_date), "d MMMM yyyy", { locale: currentLocale });
  }

  const navigate = useNavigate();
  const handleBookingClick = () => {
    !isEnglishVersion
      ? navigate(`${RESERVATION_ROUTES.RESERVATION_GUESTS_COUNTER}`, {
          state: { roomId: id },
        })
      : navigate(`/en${RESERVATION_ROUTES.RESERVATION_GUESTS_COUNTER}`, {
          state: { roomId: id },
        });
  };

  const handleShowRoomClick = () => {
    setQueryParams(id);
    setIsHovered(false);
  };

  const imgToShowOnHover = photos[1] ? photos[1] : img;
  const imgToShow = !isHovered ? img : imgToShowOnHover;

  return (
    <>
      <Styled.RoomCard isMainPage={isMainPage} isHovered={isHovered} ref={hoverRef}>
        <Styled.RoomCardContainer onClick={handleShowRoomClick}>
          <Styled.RoomCardImage>
            <Picture img={imgToShow} visibleByDefault smallGallery />
          </Styled.RoomCardImage>

          <Styled.RoomCardSlider>
            <MobileGallery images={photos} hasLightbox={false} height={223} mediumGallery />
          </Styled.RoomCardSlider>

          <Styled.RoomCardTextWrapper>
            <Styled.Title>{name}</Styled.Title>
            <Styled.Description>{preview_description}</Styled.Description>
          </Styled.RoomCardTextWrapper>

          <Styled.TagWrapper>
            {fill_values.map((fv) => (
              <Tag key={fv.id} icon={fv.icon} value={fv.value} />
            ))}
          </Styled.TagWrapper>

          <Styled.RoomCardPrice>
            {isItemAvailable && (
              <>
                <Styled.Price>
                  {textFrom}&nbsp;{formattedPrice}&nbsp;₽
                </Styled.Price>
                <Styled.PriceDescription>
                  <Styled.PriceDescriptionFor>{textSuggestionFor}</Styled.PriceDescriptionFor> 1 {textNight}&nbsp;
                  {formattedDate}
                </Styled.PriceDescription>
              </>
            )}
          </Styled.RoomCardPrice>
        </Styled.RoomCardContainer>

        <Styled.ButtonsWrapper isHovered={isHovered}>
          <Button title={bookBtnPhrase} primary onClick={handleBookingClick} />

          <Button title={moreInfoButtonText} secondary onClick={handleShowRoomClick} />
        </Styled.ButtonsWrapper>
        <Styled.MobileButtonsWrapper>
          <Button title={bookBtnPhrase} primary onClick={handleBookingClick} />
        </Styled.MobileButtonsWrapper>
      </Styled.RoomCard>
    </>
  );
};
