import { FC } from "react";

import { FooterCopyright } from "../FooterCopyright";
import { FooterSwitchBtn } from "../FooterSwitchBtn";
import { SocialNetworkLinks } from "../SocialNetworkLinks";
import { Styled } from "./styles";

export const FooterBottomPart: FC = () => {
  return (
    <Styled.Container>
      <Styled.LeftContainer>
        <FooterCopyright />
        {/* TODO hide change lang */}
        <FooterSwitchBtn />
      </Styled.LeftContainer>

      <SocialNetworkLinks />
    </Styled.Container>
  );
};
