import styled from "styled-components";

import { width100 } from "@src/styles/mixins";

const Container = styled.div`
  position: relative;
  ${width100};
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: 100%;
    min-height: 100%;
  }
`;

export const Styled = { Container };
