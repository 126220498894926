import styled from "styled-components";

import { font } from "@src/styles/mixins";

const RoomSelectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.color.greyBorder};
  padding: 20px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    border: 1px solid ${({ theme }) => theme.color.greyBorder};
    padding: 0 0 0 24px;
    margin-top: 32px;
  }
`;

const BookingDate = styled.p`
  ${font({ size: "16", lineHeight: "24", fontWeight: "300" })};
  display: flex;
  gap: 48px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 20px;
    gap: 0;
    flex-direction: column;
    border-left: 1px solid ${({ theme }) => theme.color.greyBorder};
    ${font({ size: "13", lineHeight: "21", fontWeight: "300" })};
  }
`;

const Title = styled.p`
  ${font({ size: "20", lineHeight: "26", fontWeight: "600" })};
  margin: 32px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 32px 0;
  }
`;
export const Styled = { Title, RoomSelectionHeader, BookingDate };
