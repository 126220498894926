import { FC, useEffect, useRef } from "react";
import { Styled } from "./styles";
import { ContentWrapper, SectionTitle } from "@src/components/_Ui";
import { ReactComponent as OleaIcon } from "@src/assets/svg/olea-icon.svg";
import { useActions, useGetBaseData } from "@src/hooks";
import { useGetSpaHeroSliderQuery } from "@src/store/services";

export const SpaAbout: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { data: banners } = useGetSpaHeroSliderQuery();
  const { spaAboutTitle, spaAboutText } = useGetBaseData();
  const { setSecondScreenOffset } = useActions();

  useEffect(() => {
    const offsetTop = ref.current?.offsetTop;

    if (offsetTop) {
      setSecondScreenOffset(offsetTop + 100);
    }
  }, [ref, banners]);

  if (!spaAboutTitle && !spaAboutText) {
    return null;
  }
  return (
    <Styled.Container ref={ref}>
      <ContentWrapper>
        <Styled.SectionTitleWrapper>
          <SectionTitle text={spaAboutTitle} />
        </Styled.SectionTitleWrapper>
        <Styled.Content>
          <Styled.ImgContainer>
            <OleaIcon />
          </Styled.ImgContainer>
          <Styled.Text dangerouslySetInnerHTML={{ __html: spaAboutText }} />
        </Styled.Content>
      </ContentWrapper>
    </Styled.Container>
  );
};
