import styled, { css } from "styled-components";

import { flexCenter, font, width100 } from "@src/styles/mixins";

const FormContainer = styled.section`
  width: calc(100% - 70px);

  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: -20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: calc(100% - 48px);
    flex-direction: column;
    gap: 12px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
    margin: 0;
    margin-bottom: -117px;
  }
`;

const Title = styled.h2`
  margin-bottom: 64px;

  ${font({ size: "16", lineHeight: "24", fontWeight: "700" })}
  color: ${({ theme }) => theme.color.black};
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 32px;
    padding: 0 20px;
  }
`;

const Form = styled.form`
  padding: 64px;
  background-color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 32px 20px;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px -12px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    flex-direction: column;
    margin: 0;
  }
`;

const InputContainer = styled.div<{ hasError?: boolean }>`
  width: calc(100% / 3 - 24px);
  margin: 0 12px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 100%;
    margin: 0;
    margin-bottom: -17px;

    ${({ hasError }) =>
      hasError &&
      css`
        margin-bottom: 0;
      `};
  }
`;

const RoomContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 10px;
  }
`;
const CommentContainer = styled.div`
  width: 100%;
  margin-bottom: 41px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 19px;
  }
`;

const ErrorsContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-top: 8px;
  padding: 16px 20px;
  ${flexCenter};
  background-color: ${({ theme }) => theme.color.pink};
`;

const ErrorText = styled.span`
  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.black};
`;

const ErrorTextValue = styled.span`
  color: ${({ theme }) => theme.color.red};
  margin-right: 5px;
`;

export const Styled = {
  FormContainer,
  Title,
  Form,
  InputsContainer,
  InputContainer,
  RoomContainer,
  CommentContainer,
  ErrorsContainer,
  ErrorText,
  ErrorTextValue,
};
