import styled from "styled-components";

const Container = styled.div`
  width: calc(100% - 70px);
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 96px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: calc(100% - 48px);
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: calc(100% - 40px);
    margin: 0 20px 64px;
  }
`;

const SectionTitleWrapper = styled.div`
  padding: 64px 0 60px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 64px 0 27px;
  }
`;

export const Styled = { Container, SectionTitleWrapper };
