import styled from "styled-components";

import { font } from "@src/styles/mixins";

const PageHeader = styled.h1`
  font-family: Montserrat, sans-serif;
  ${font({ size: "50", lineHeight: "58", fontWeight: "500" })};
  width: calc(100% - 70px);
  max-width: 1400px;
  margin: 40px auto 0;
  margin-inline: auto;
  text-align: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    ${font({ size: "48", lineHeight: "58", fontWeight: "500" })};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: calc(100% - 40px);
    ${font({ size: "28", lineHeight: "38", fontWeight: "500" })}
  }
`;

export const Styled = { PageHeader };
