import { FC, useEffect, useRef, MouseEvent, useCallback } from "react";

import { ReactComponent as ErrorIcon } from "@src/assets/images/icons/error.svg";
import { ReactComponent as SuccessIcon } from "@src/assets/images/icons/success.svg";
import { useActions, useAppSelector, useGetBaseData } from "@src/hooks";
import { NOTIFICATION_MESSAGE_CLOSE_TIMEOUT } from "@src/utils/constants";

import { Styled } from "./styles";

export const StatusMessage: FC = () => {
  const { notificationSuccess, notificationError } = useGetBaseData();

  const { isOpen, isOnTop, isSuccess } = useAppSelector((state) => state.statusMessage);
  const { closeStatusMessage } = useActions();
  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      closeStatusMessage();
    }, NOTIFICATION_MESSAGE_CLOSE_TIMEOUT);
  }, [isOpen]);

  const handleCloseBtnClick = () => {
    closeStatusMessage();
  };

  return (
    <>
      {isOpen && (
        <Styled.Message success={isSuccess} isOnTop={isOnTop} ref={messageRef}>
          <Styled.Icon success={isSuccess}>{isSuccess ? <SuccessIcon /> : <ErrorIcon />}</Styled.Icon>
          <Styled.Description>
            <Styled.Text>{isSuccess ? notificationSuccess : notificationError}</Styled.Text>
            <Styled.CloseButton onClick={handleCloseBtnClick}></Styled.CloseButton>
          </Styled.Description>
        </Styled.Message>
      )}
    </>
  );
};
