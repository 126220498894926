import { FC } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Logo } from "@src/assets/images/icons/logo.svg";
import { BurgerButton } from "@src/components/_Ui";
import { useAppSelector, useMediaQuery } from "@src/hooks";
import { useActions } from "@src/hooks";
import { useGetMenuData } from "@src/hooks/useGetMenuData";
import { theme } from "@src/theme";

import { ChangeLang } from "../ChangeLang";
import { Styled } from "./styles";

export const MobileMenuHeader: FC = () => {
  const { isOpen } = useAppSelector((state) => state.burgerMenu);
  const { toggleBurgerMenu, closeBurgerMenu } = useActions();
  const { languageId, languageName, languageIcon } = useGetMenuData();
  const isAdaptive = useMediaQuery(theme.breakpoints.tabletXLarge);

  const location = useLocation();
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;
  const linkToMainPage = !isEnglishVersion ? "/" : "/en";

  const handleCloseBtnClick = () => {
    toggleBurgerMenu();
  };

  const handleLinkClick = () => {
    if (isAdaptive) closeBurgerMenu();
  };

  return (
    <Styled.Container>
      <Styled.LogoWrapper>
        <Link to={linkToMainPage} onClick={handleLinkClick}>
          <Logo />
        </Link>
      </Styled.LogoWrapper>
      {/* TODO hide change lang */}
      <Styled.ChangeLangWrapper>
        <ChangeLang languageId={languageId} name={languageName} icon={languageIcon} isMenuOpen={isOpen} />
      </Styled.ChangeLangWrapper>
      <BurgerButton onClick={handleCloseBtnClick} />
    </Styled.Container>
  );
};
