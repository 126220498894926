import { FC } from "react";

import { useGetBaseData, useMediaQuery } from "@src/hooks";
import { useGetSpaInfrastructureRoomsQuery } from "@src/store/services/spa.api";
import { theme } from "@src/theme";
import { Styled } from "./styles";
import { SectionTitle } from "@src/components/_Ui";
import { SpaInfrastructureCard } from "./SpaInfrastructureCard";
import { SpaCardsCarousel } from "../SpaCardsCarousel";

export const SpaInfrastructure: FC = () => {
  const { data: infrastructure } = useGetSpaInfrastructureRoomsQuery();
  const { spaInfrastractureTitle } = useGetBaseData();

  const isAdaptive = useMediaQuery(theme.breakpoints.medium);
  if (!infrastructure?.length) {
    return null;
  }
  return (
    <Styled.Rooms>
      <Styled.SectionTitleWrapper>
        <SectionTitle text={spaInfrastractureTitle} />
      </Styled.SectionTitleWrapper>
      <Styled.Container>
        {!isAdaptive ? (
          infrastructure?.map((item) => {
            return <SpaInfrastructureCard data={item} key={item.id} />;
          })
        ) : (
          <SpaCardsCarousel cards={infrastructure} />
        )}
      </Styled.Container>
    </Styled.Rooms>
  );
};
